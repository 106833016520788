/**
 * Component short description
 *
 * @see ErrorPage.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./ErrorPage.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ErrorPage.lang.ro-ro";
import { hu_hu } from "./ErrorPage.lang.hu-hu";
import { en_us } from "./ErrorPage.lang.en-us";
import { de_de } from "./ErrorPage.lang.de-de";
import Layout from "../Layout";
import { TextHeadline, TextLarge } from "../Theme/Theme";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "ErrorPage", ro_ro);
i18n.addResourceBundle("hu-HU", "ErrorPage", hu_hu);
i18n.addResourceBundle("en-US", "ErrorPage", en_us);
i18n.addResourceBundle("de-DE", "ErrorPage", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh",
    flexDirection: "column",
  },
}));

/**
 * Displays the component
 */
const ErrorPage = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("ErrorPage");
  const { search } = useLocation();
  const isServerSideError = search.includes("isServerError=true");
  const title = isServerSideError
    ? t("server_side_error_title")
    : t("page_not_found_title");
  const description = isServerSideError
    ? t("server_side_error_description")
    : t("page_not_found_description");

  return (
    <Layout>
      <Grid container>
        <Grid item className={clsx(container, "ErrorPage")}>
          <TextHeadline text={title} />
          <TextLarge text={description} isHTML={true} />
        </Grid>
      </Grid>
    </Layout>
  );
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
export {
  propTypes as ErrorPagePropTypes,
  defaultProps as ErrorPageDefaultProps,
};
