/**
 * Component short description
 *
 * @see OrderForCheckout.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextSection, TextPrice } from "../../Theme";
import OrderItem from "../OrderItem";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderForCheckout.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderForCheckout.lang.ro-ro";
import { hu_hu } from "./OrderForCheckout.lang.hu-hu";
import { en_us } from "./OrderForCheckout.lang.en-us";
import { de_de } from "./OrderForCheckout.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderForCheckout", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderForCheckout", hu_hu);
i18n.addResourceBundle("en-US", "OrderForCheckout", en_us);
i18n.addResourceBundle("de-DE", "OrderForCheckout", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  /**
   * Trying to replicate the OrderItemForCart grid
   */
  alignLeftNegative2: {
    marginLeft: -theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginLeft: -theme.spacing(8),
    },
  },

  alignLeftNegative: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: -theme.spacing(4),
    },
  },
}));

/**
 * Displays the component
 */
const OrderForCheckout = (props) => {
  const { seller, itemsPrice, products } = props;
  const { container, alignLeftNegative } = useStyles(props);
  const { t } = useTranslation("OrderForCheckout");

  /**
   * Displays the hero card
   */
  const title = `${t("OrderForCart:sold_by")} ${seller}`;
  const cardContent1 = <TextSection subtitle={title} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  /**
   * Displays the items
   */
  const itemsList =
    products &&
    products.map((item, index) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderItem {...item} index={index} />
        </Grid>
      );
    });

  /**
   * Displays the summary
   *
   * - copy / pasted from OrderForCart.js
   */
  const summary = (
    <List>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item xs={6} sm={9} lg={10}>
            {t("OrderForCart:items_price")}:
          </Grid>
          <Grid item xs={6} sm={3} lg={2} className={clsx(alignLeftNegative)}>
            <TextPrice text={` ${itemsPrice} RON`} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item xs={6} sm={9} lg={10}>
            {t("OrderForCart:delivery_price")}:
          </Grid>
          <Grid item xs={6} sm={3} lg={2} className={clsx(alignLeftNegative)}>
            <TextPrice text={t("OrderForCart:pending")} />
          </Grid>
        </Grid>{" "}
      </ListItem>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item xs={6} sm={9} lg={10}>
            {t("OrderForCart:total_price")}:
          </Grid>
          <Grid item xs={6} sm={3} lg={2} className={clsx(alignLeftNegative)}>
            <TextPrice text={t("OrderForCart:pending")} />
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );

  return (
    <Grid container className={clsx(container, "OrderForCheckout")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia1} />
      </Grid>
      <Grid item xs={12}>
        {itemsList}
      </Grid>
      <Grid item xs={12}>
        {summary}
      </Grid>
    </Grid>
  );
};

OrderForCheckout.propTypes = propTypes;
OrderForCheckout.defaultProps = defaultProps;

export default OrderForCheckout;
export {
  propTypes as OrderForCheckoutPropTypes,
  defaultProps as OrderForCheckoutDefaultProps,
};
