/**
 * Component short description
 *
 * @see ForgotPassForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";

/**
 * Imports other components and hooks
 */
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ForgotPassForm.data";

/**
 * Imports Material UI components
 */
import { TextField, Button, makeStyles, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ForgotPassForm.lang.ro-ro";
import { hu_hu } from "./ForgotPassForm.lang.hu-hu";
import { en_us } from "./ForgotPassForm.lang.en-us";
import { de_de } from "./ForgotPassForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "ForgotPassForm", ro_ro);
i18n.addResourceBundle("hu-HU", "ForgotPassForm", hu_hu);
i18n.addResourceBundle("en-US", "ForgotPassForm", en_us);
i18n.addResourceBundle("de-DE", "ForgotPassForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const ForgotPassForm = (props) => {
  const { defaultValues, onSubmit, openDialog } = props;
  const { container } = useStyles();

  const { t } = useTranslation("ForgotPassForm");
  const { getForgotPasswordFormSchema } = useValidationSchemas();

  const { register, errors, reset, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: getForgotPasswordFormSchema(),
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (...args) => {
    reset(defaultValues);
    onSubmit(...args);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Grid container spacing={2} className={clsx(container, "ForgotPassForm")}>
        <Grid item xs={12}>
          Short description
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="email"
            label={t("email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {t("submit")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {t("return_to")}&nbsp;
          <Link
            component="button"
            variant="body2"
            onClick={() => openDialog("login")}
          >
            {t("sign_in")}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

ForgotPassForm.propTypes = propTypes;
ForgotPassForm.defaultProps = defaultProps;

export default ForgotPassForm;
export {
  propTypes as ForgotPassFormPropTypes,
  defaultProps as ForgotPassFormDefaultProps,
};
