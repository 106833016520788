/**
 * Defines the data requirements for the component
 *
 * @see Carousel.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The slides / content of the carousel
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.any
    })
  ),
  /**
   * The navigation controls
   * @see https://material-ui.com/api/mobile-stepper/#mobilestepper-api
   */
  mobileStepper: PropTypes.shape({
    variant: PropTypes.oneOf(["dots", "progress", "text"]),
    nextButtonText: PropTypes.string,
    backButtonText: PropTypes.string
  }),
  /**
   * Tells if the step title is displayed
   */
  isTitleDisplayed: PropTypes.bool,
  /**
   * Tells if the navigation controls are displayed
   */
  isMobileStepperDisplayed: PropTypes.bool,
  /**
   * Tells if the navigation controls are aligned to right
   */
  isMobileStepperAlignedRight: PropTypes.bool,
  /**
   * Tells if the slides play automatically
   */
  isAutoPlay: PropTypes.bool
};

/**
 * Defines the default props
 */
const defaultProps = {
  steps: Array(3).fill({
    id: shortid.generate(),
    title: "Step title",
    content: "Step content"
  }),
  mobileStepper: {
    variant: "dots",
    nextButtonText: "",
    backButtonText: ""
  },
  isTitleDisplayed: true,
  isMobileStepperDisplayed: true,
  isMobileStepperAlignedRight: false,
  isAutoPlay: false
};

export { propTypes, defaultProps };
