/**
 * Reusable business logic
 * - For the web and the React Native apps
 */

/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import useRequests from "../../../hooks/useRequests";

const usePartnersSwr = (props) => {
  const { get } = useRequests();

  return useSWR("partners", get);
};

const usePartnersInfoSwr = (props) => {
  const { get } = useRequests();

  return useSWR("partners/info", get);
};

export { usePartnersInfoSwr, usePartnersSwr };
