/**
 * Component short description
 *
 * @see useSlugs.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { createContext, useState, useContext } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useSlugs.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./useSlugs.lang.ro-ro";
import { hu_hu } from "./useSlugs.lang.hu-hu";
import { en_us } from "./useSlugs.lang.en-us";
import { de_de } from "./useSlugs.lang.de-de";

i18n.addResourceBundle("ro-RO", "useSlugs", ro_ro);
i18n.addResourceBundle("hu-HU", "useSlugs", hu_hu);
i18n.addResourceBundle("en-US", "useSlugs", en_us);
i18n.addResourceBundle("de-DE", "useSlugs", de_de);

/**
 * Styles the component
 */

const SlugContext = createContext();

const SlugProvider = (props) => {
  const { children } = props;

  const [slugs, setSlugs] = useState([]);

  const addSlug = (slug) => {
    setSlugs((prevState) => [...prevState, slug]);
  };

  return (
    <SlugContext.Provider
      value={{
        slugs,
        setSlugs,
        addSlug,
      }}
    >
      {children}
    </SlugContext.Provider>
  );
};

/**
 * Displays the component
 */
const useSlugs = () => {
  return useContext(SlugContext);
};

useSlugs.propTypes = propTypes;
useSlugs.defaultProps = defaultProps;

export default useSlugs;
export {
  propTypes as useSlugsPropTypes,
  defaultProps as useSlugsDefaultProps,
  SlugProvider,
};
