const ro_ro = {
  request_role: "Rolul solicitării",
  name: "Nume",
  email: "E-mail",
  seller: "Vânzător",
  host: "Gazdă",
  destination: "Destinaţie",
  location: "Locație",
  phone_number: "Numar de telefon",
  message: "Mesaj",
  send_request: "Trimite cerere",
  send_request_error: "Ceva n-a mers bine. Vă rugăm să încercați din nou.",
  agree_text: "Sunt de acord cu",
  terms_and_conditions: "Termeni si conditii",
  description: "Descriere",
};

export { ro_ro };
