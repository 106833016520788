/**
 * Component short description
 *
 * @see ProductSearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Pagination from "../../Pagination/Pagination";
import ProductAsThumb from "../ProductAsThumb";
import MarketFilters from "../../_localMarket/MarketFilters";
import { useFilters, useUpdateEffect } from "../../../hooks";
import FilteredBy from "../../FilteredBy";

import {
  useMarketSellersSwr,
  useMarketCategoriesSwr,
  useMarketProductsSwr,
} from "../../_localMarket/LocalMarket/LocalMarket.logic";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import SortBy from "../../SortBy/SortBy";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductSearch.data";

/**
 * Imports Material UI components
 */
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ProductSearch.lang.ro-ro";
import { hu_hu } from "./ProductSearch.lang.hu-hu";
import { en_us } from "./ProductSearch.lang.en-us";
import { de_de } from "./ProductSearch.lang.de-de";

i18n.addResourceBundle("ro-RO", "ProductSearch", ro_ro);
i18n.addResourceBundle("hu-HU", "ProductSearch", hu_hu);
i18n.addResourceBundle("en-US", "ProductSearch", en_us);
i18n.addResourceBundle("de-DE", "ProductSearch", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ProductSearch = (props) => {
  const { products: defaultProducts } = props;
  const { i18n } = useTranslation("ProductSearch");
  const { language } = i18n;

  const [products, setProducts] = useState(defaultProducts);

  const { total, data } = products;
  const limit = 10;

  const { filters, setData, parseFiltersForQuery, setFilters } = useFilters();
  const { page } = filters;

  /**
   * Fetches the destinations
   */
  const {
    data: destinations,
    mutate: mutateDestinations,
  } = useDestinationsSimpleSwr();

  /**
   * Fetches the sellers
   */
  const { data: sellers, mutate: mutateSellers } = useMarketSellersSwr();

  /**
   * Fetches the categories
   */
  const {
    data: categories,
    mutate: mutateCategories,
  } = useMarketCategoriesSwr();

  /**
   * Fetches the products
   */
  const { data: productsData, mutate: mutateProducts } = useMarketProductsSwr({
    filters: parseFiltersForQuery(filters),
  });

  /**
   * Set filter options
   */
  useEffect(() => {
    if (destinations && sellers && categories) {
      setData({
        destinations: destinations,
        sellers: sellers,
        categories: categories,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations, sellers, categories]);

  /**
   * Re-fetch on language change
   */
  useUpdateEffect(() => {
    mutateDestinations();
    mutateSellers();
    mutateCategories();
    mutateProducts();
  }, [language]);

  useEffect(() => {
    if (productsData) {
      setProducts(productsData);
    }
  }, [productsData]);

  const handlePagination = (_event, value) => {
    const offset = (value - 1) * limit;
    setFilters((prevState) => ({
      ...prevState,
      page: value,
      offset: offset,
      limit: limit,
    }));
  };

  const productList = data.map((item, index) => {
    const { id } = item;
    return (
      <Grid item xs={12} md={6} key={id}>
        <ProductAsThumb {...item} />
      </Grid>
    );
  });

  return (
    <Grid container className={clsx("ProductSearch")}>
      <Grid item xs={12} md={5} lg={4}>
        <MarketFilters />
      </Grid>
      <Grid container item xs={12} md={7} lg={8} spacing={2}>
        <Grid item xs={10}>
          <FilteredBy />
        </Grid>
        <Grid item xs={12} md={2}>
          <SortBy />
        </Grid>
        <Grid item container spacing={2} xs={12}>
          {productList}
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Pagination
            total={total}
            page={page}
            onChange={handlePagination}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ProductSearch.propTypes = propTypes;
ProductSearch.defaultProps = defaultProps;

export default ProductSearch;
export {
  propTypes as ProductSearchPropTypes,
  defaultProps as ProductSearchDefaultProps,
};
