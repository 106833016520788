/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsTabs.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */

import { CategoryPropTypes } from "../Category";
import { TabPanelPropTypes, TabPanelDefaultProps } from "../../TabPanel";

/**
 * Defines the prop types
 */
const propTypes = {
  onTabSelect: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
  tabPanel: PropTypes.shape(TabPanelPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  categories: [],
  tabPanel: TabPanelDefaultProps,
  onTabSelect: () => {},
};

export { propTypes, defaultProps };
