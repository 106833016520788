const en_us = {
  text: "From {{price}} RON / Night",
  number_of_guests: "Guests: {{guests}}",
  number_of_rooms: "Rooms: {{rooms}}",
  view_message: "View message",
  message_modal_title: "Message",
  show_more: "Show more",
  show_less: "Show less",
};

export { en_us };
