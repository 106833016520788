/**
 * Defines the data requirements for the component
 *
 * @see LocalMarket.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

import {
  ProductSearchPropTypes,
  ProductSearchDefaultProps,
} from "../../_product/ProductSearch";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * Props from the API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > Market Info
   */
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  productSearch: PropTypes.shape(ProductSearchPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  image: ImageResponsivePlaceholderCom169,
  productSearch: ProductSearchDefaultProps,
};

export { propTypes, defaultProps };
