/**
 * Component short description
 *
 * @see EventAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./ArticleAsThumb.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./ArticleAsThumb.lang.ro-ro";
import { hu_hu } from "./ArticleAsThumb.lang.hu-hu";
import { en_us } from "./ArticleAsThumb.lang.en-us";
import { de_de } from "./ArticleAsThumb.lang.de-de";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "EventAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "EventAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "EventAsThumb", en_us);
i18n.addResourceBundle("de-DE", "EventAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const ArticleAsThumb = (props) => {
  const { name, slug, image, destination, destinationName } = props;

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares the card
   */
  const cardContent = (
    <>
      <Typography variant="body1">{destination}</Typography>
      <Typography variant="body1">{destinationName}</Typography>
      <Typography variant="subtitle1">
        <Link
          component={RouterLink}
          to={`${url}/${getSlugForCurrentLang(slug)}`}
        >
          {name}
        </Link>
      </Typography>
    </>
  );

  const cardWithMedia = {
    image: image,
    images: null,
    content: cardContent,
    customStyles: useStyles(props),
  };

  return <Card2 {...cardWithMedia} />;
};

ArticleAsThumb.propTypes = propTypes;
ArticleAsThumb.defaultProps = defaultProps;

export default ArticleAsThumb;
export {
  propTypes as ArticleAsThumbPropTypes,
  defaultProps as ArticleAsThumbDefaultProps,
};
