/**
 * Defines the data requirements for the component
 *
 * @see DestinationAsHero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { DestinationPropTypes, DestinationDefaultProps } from "../Destination";

import {
  CategoriesAsThumbsPropTypes,
  CategoriesAsThumbsDefaultProps
} from "../../_category/CategoriesAsThumbs";

/**
 * Defines the prop types
 */
const propTypes = {
  ...DestinationPropTypes,
  ...CategoriesAsThumbsPropTypes,
  /**
   * To decide the left / right alignment
   */
  index: PropTypes.number,
  slug: PropTypes.object
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...DestinationDefaultProps,
  ...CategoriesAsThumbsDefaultProps,
  index: 0,
  showCategories: true
};

export { propTypes, defaultProps };
