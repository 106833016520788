const en_us = {
  faq: "Faq",
  media: "News",
  sitemap: "Sitemap",
  support: "Contact",
  terms: "Terms & Conditions",
  visitors_counter: "Number of visitors",
  consent: "Consent",
};

export { en_us };
