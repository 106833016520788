const ro_ro = {
  attractions: "Atracții",
  accommodations: "Cazări",
  destinations: "Destinații",
  events: "Evenimente",
  no_results: "Nu s-a găsit nici un rezultat. Încercați o altă căutare.",
  products: "Produse",
  news: "Știri",
  all: "Toate",
};

export { ro_ro };
