const en_us = {
  required: "Required field",
  max_number: "Invalid quantity",
  emails_do_not_match: "Emails do not match",
  emails_match: "New email cannot be old email",
  password_min_length: "Password has to be at least 6 characters",
  passwords_not_match: "Passwords do not match",
  min_characters: "At least 3 characters are required",
  positive_number: "Must be a positive number",
  email_invalid: "Please enter a valid email address",
  phone_invalid: "Please enter a valid phone number",
};

export { en_us };
