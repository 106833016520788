/**
 * Component short description
 *
 * @see FilterCategory.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterCategory.data";

/**
 * Imports Material UI components
 */
import { ListItemText, ListSubheader } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterCategory.lang.ro-ro";
import { hu_hu } from "./FilterCategory.lang.hu-hu";
import { en_us } from "./FilterCategory.lang.en-us";
import { de_de } from "./FilterCategory.lang.de-de";
import { makeStyles } from "@material-ui/core";

i18n.addResourceBundle("ro-RO", "FilterCategory", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterCategory", hu_hu);
i18n.addResourceBundle("en-US", "FilterCategory", en_us);
i18n.addResourceBundle("de-DE", "FilterCategory", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: theme.palette.background.paper,
  },
}));
/**
 * Displays the component
 */
const FilterCategory = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterCategory");

  const { listSection } = useStyles();
  /**
   * Handles the filters
   */
  const { filters, setFiltersAndResetPagination, data } = useFilters();
  const { categories } = data;

  const selected = getSelectedArrayValues({
    key: "id",
    selected: filters.category?.filterValue || defaultValue,
    items: categories.map((category) => category.subCategories).flat(2),
  });

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;

    const isLabel = value.some((v) => v === undefined);

    if (isLabel) {
      return;
    }

    setFiltersAndResetPagination({
      category: {
        filterValue: getArrayOfKeys({ key: "id", items: value }),
        label: getArrayOfKeys({ key: "name", items: value }),
      },
    });
  };

  const items = categories.map((category) => {
    const { subCategories, name, id } = category;
    const children = subCategories.map((subCategory) => {
      const { name, id } = subCategory;

      return (
        <MenuItem key={id} value={subCategory}>
          <ListItemText primary={name} />
        </MenuItem>
      );
    });

    return [
      <ListSubheader className={listSection} key={id}>
        {name}
      </ListSubheader>,
      children,
    ];
  });

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="category"
        value={selected}
        onChange={selectHandler}
        fullWidth
        variant="outlined"
        displayEmpty
        input={<Input variant="outlined" id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {items}
      </Select>
    </FilterListItem>
  );
};

FilterCategory.propTypes = propTypes;
FilterCategory.defaultProps = defaultProps;

export default FilterCategory;
export {
  propTypes as FilterCategoryPropTypes,
  defaultProps as FilterCategoryDefaultProps,
};
