const de_de = {
  quantity: "Menge",
  item: "Artikel",
  items: "Artikel",
  order_date: "Auftragsdatum",
  order_number: "Bestellnummer",
  order_status: "Bestellstatus",
  seller: "Verkäufer",
  pending: "steht aus",
  refused: "Abgesagt",
  confirmed: "Warten auf Zahlung",
  cancelled: "Abgesagt",
  paid: "Bezahlt",
  shipped: "Versand",
  returned: "Ist zurückgekommen",
  return_requested: "Rücksendung angefordert",
};

export { de_de };
