/**
 * Defines the data requirements for the component
 *
 * @see Modal.md for details
 */

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {
  maxWidth: "lg",
};

export { propTypes, defaultProps };
