import { useRequests } from "../../../hooks";

export const useSearch = () => {
  const { get } = useRequests();

  const getSearch = (searchUrl) => {
    return get(`search${searchUrl}`, get);
  }

  return { getSearch };
};
