const en_us = {
  select_destination: "Select destination",
  cancel: "Cancel",
  email: "Email",
  host: "Host",
  message: "Message",
  phone: "Phone number",
  name: "Name",
  role: "Role",
  terms: "Terms and conditions",
  submit: "Send request",
  seller: "Seller",
};

export { en_us };
