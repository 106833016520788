/**
 * Component short description
 *
 * @see OrderItem.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { TextSection, TextPrice, Actions } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderItem.data";

/**
 * Imports Material UI components
 */
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderItem.lang.ro-ro";
import { hu_hu } from "./OrderItem.lang.hu-hu";
import { en_us } from "./OrderItem.lang.en-us";
import { de_de } from "./OrderItem.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderItem", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderItem", hu_hu);
i18n.addResourceBundle("en-US", "OrderItem", en_us);
i18n.addResourceBundle("de-DE", "OrderItem", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const OrderItem = (props) => {
  const { name, destinationName, price, quantity, image } = props;
  const { t } = useTranslation("OrderItem");

  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down("md"));
  const imageWidth = isPortrait ? "50%" : "25%";
  const contentWidth = isPortrait ? "50%" : "75%";

  /**
   * Displays order details
   */
  const quantity2 = `${t("quantity")}: ${quantity}`;

  const price2 = <TextPrice text={`${price} RON`} />;

  const cardContent1 = (
    <TextSection description={name} overline={destinationName} />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price2 },
        { id: shortid.generate(), action: quantity2 },
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props,
      breakpoint: "sm",
      imageWidth: imageWidth,
      contentWidth: contentWidth,
      cardContentWidth: contentWidth,
      cardActonsWidth: imageWidth,
    }),
  };

  return <Card2 {...cardWithMedia1} />;
};

OrderItem.propTypes = propTypes;
OrderItem.defaultProps = defaultProps;

export default OrderItem;
export {
  propTypes as OrderItemPropTypes,
  defaultProps as OrderItemDefaultProps,
};
