/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Displays the Destinations page
 *
 * @see Destinations.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Destination from "../../_destination/Destination";
import CategoriesAsTabs from "../../_category/CategoriesAsTabs";
import DestinationsList from "../DestinationsList";
import {
  useDestinationsCategoriesSwr,
  useDestinationsSwr,
} from "../../_destination/Destination/Destination.logic";
import { useUpdateEffect } from "../../../hooks";
import { PageHeader } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Destinations.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Destinations.lang.ro-ro";
import { hu_hu } from "./Destinations.lang.hu-hu";
import { en_us } from "./Destinations.lang.en-us";
import { de_de } from "./Destinations.lang.de-de";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";

i18n.addResourceBundle("ro-RO", "Destinations", ro_ro);
i18n.addResourceBundle("hu-HU", "Destinations", hu_hu);
i18n.addResourceBundle("en-US", "Destinations", en_us);
i18n.addResourceBundle("de-DE", "Destinations", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Destinations = (props) => {
  const { categoriesAsTabs, destinationsList } = props;
  const { destinations: defaultDestinations } = destinationsList;
  const { categories: defaultCategories } = categoriesAsTabs;
  const { container } = useStyles(props);
  const { t, i18n } = useTranslation("Destinations");
  const { language } = i18n;

  const { description, title, keywords } = useMeta({
    type: "route",
    path: "destinations",
  });

  const location = useLocation();
  const { state } = location;
  const selectedCategory = state?.categoryId ?? 8;

  const { setSlugs } = useSlugs();
  const [selectedTab, setSelectedTab] = useState(selectedCategory);

  const {
    data: destinationData,
    mutate: mutateDestinations,
  } = useDestinationsSwr({
    filters:
      selectedTab !== "all"
        ? {
            order_by_category_id: selectedTab,
          }
        : {},
  });

  const {
    data: categoriesData,
    mutate: mutateCategories,
  } = useDestinationsCategoriesSwr();

  useEffect(() => {
    setSlugs([]);
  }, []);

  useUpdateEffect(() => {
    mutateDestinations();
    mutateCategories();
  }, [language]);
  /**
   * Routes to a single desitination
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Re-fetch destinations when tab changed
   */
  const handleTabSelect = (categoryId) => {
    setSelectedTab(categoryId);
  };

  const destinations = useMemo(() => {
    return destinationData ?? defaultDestinations;
  }, [destinationData]);
  const categories = categoriesData ?? defaultCategories;

  const featuredCategories = useMemo(
    () =>
      categories.filter((category) => {
        const { featured } = category;
        return featured;
      }),
    [categoriesData]
  );

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Destination categories={categories} />
      </Route>
      <Route path={path}>
        <Head title={title} description={description} keywords={keywords} />
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid
            container
            className={clsx(container, "Destinations")}
            spacing={1}
          >
            <Grid item xs={12}>
              <PageHeader title={t("title")} />
            </Grid>
            <Grid item xs={12}>
              <CategoriesAsTabs
                {...categoriesAsTabs}
                selectedTab={selectedTab}
                categories={featuredCategories}
                onTabSelect={handleTabSelect}
              />
              {/**/}
            </Grid>
            <Grid item xs={12}>
              <DestinationsList
                {...destinationsList}
                destinations={destinations}
              />
              {/**/}
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Destinations.propTypes = propTypes;
Destinations.defaultProps = defaultProps;

export default Destinations;
export {
  propTypes as DestinationsPropTypes,
  defaultProps as DestinationsDefaultProps,
};
