/**
 * Defines the data requirements for the component
 *
 * @see NewsletterForm.md for details
 */

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    email: "",
  },
};

export { propTypes, defaultProps };
