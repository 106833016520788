/**
 * Defines the data requirements for the component
 *
 * @see Question.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: null,
  question: "",
  answer: "",
};

export { propTypes, defaultProps };
