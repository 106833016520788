const de_de = {
  select_destination: "Ziel aussuchen",
  cancel: "Stornieren",
  email: "Email",
  host: "Gastgeber",
  message: "Botschaft",
  phone: "Telefonnummer",
  name: "Name",
  role: "Rolle",
  terms: "Geschäftsbedingungen",
  submit: "Anfrage senden",
  seller: "Verkäufer",
};

export { de_de };
