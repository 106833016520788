/**
 * Component short description
 *
 * @see LocalMarket.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { Switch, Route, useRouteMatch } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import ProductSearch from "../../_product/ProductSearch";
import Product from "../../_product/Product";
import { useMarketInfoSwr } from "./LocalMarket.logic";
import { FiltersProvider, useUpdateEffect } from "../../../hooks";
import { TextSection, PageHeader } from "../../Theme";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./LocalMarket.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LocalMarket.lang.ro-ro";
import { hu_hu } from "./LocalMarket.lang.hu-hu";
import { en_us } from "./LocalMarket.lang.en-us";
import { de_de } from "./LocalMarket.lang.de-de";

i18n.addResourceBundle("ro-RO", "LocalMarket", ro_ro);
i18n.addResourceBundle("hu-HU", "LocalMarket", hu_hu);
i18n.addResourceBundle("en-US", "LocalMarket", en_us);
i18n.addResourceBundle("de-DE", "LocalMarket", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const LocalMarket = (props) => {
  const { productSearch } = props;
  const { filters, data } = productSearch;
  const { container } = useStyles(props);
  const { setSlugs } = useSlugs();

  const { description: metaDescription, title, keywords } = useMeta({
    type: "route",
    path: "local_market",
  });

  const { t, i18n } = useTranslation("LocalMarket");
  const { language } = i18n;
  const { data: marketInfoData, mutate } = useMarketInfoSwr();
  const { image, description } = marketInfoData || props;

  useUpdateEffect(() => {
    mutate();
  }, [language]);

  useEffect(() => {
    setSlugs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Defines the routes for products
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Displays the hero card
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image,
    content: cardContent1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Product destinationName="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Head description={metaDescription} title={title} keywords={keywords} />
        <Layout header={{ currentPage: "Local Market" }}>
          <Grid
            container
            spacing={1}
            className={clsx(container, "LocalMarket")}
          >
            <Grid item xs={12}>
              <PageHeader title={t("title")} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia1} />
            </Grid>
            <Grid item xs={12}>
              <FiltersProvider filters={filters} data={data}>
                <ProductSearch />
              </FiltersProvider>
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

LocalMarket.propTypes = propTypes;
LocalMarket.defaultProps = defaultProps;

export default LocalMarket;
export {
  propTypes as LocalMarketPropTypes,
  defaultProps as LocalMarketDefaultProps,
};
