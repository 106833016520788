const de_de = {
  required: "Pflichtfeld",
  max_number: "Ungültige Menge",
  emails_do_not_match: "Emails stimmen nicht überein",
  emails_match: "Neue E-Mails können keine alten E-Mails sein",
  password_min_length: "Das Passwort muss aus mindestens 6 Zeichen bestehen",
  passwords_not_match: "Passwörter stimmen nicht überein",
  min_characters: "Es sind mindestens 3 Zeichen erforderlich",
  positive_number: "Muss eine positive Zahl sein",
  email_invalid: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
  phone_invalid: "Bitte geben Sie eine gültige Telefonnummer ein",
};

export { de_de };
