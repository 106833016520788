const en_us = {
  title: "Consent management",
  description:
    "Pineyard.ro uses the following cookies, for more information please refer to our Privacy Policy and Terms and Conditions. ",
  analytics_consent: "Enable Google Analytics",
  weather_consent: "Enable Weather widget",
  accept_selected: "Accept selected",
  accept_all: "Accept all",
};

export { en_us };
