/**
 * Defines the data requirements for the component
 *
 * @see RegisterDialog.md for details
 */
import PropTypes from "prop-types";
import {
  RegisterFormPropTypes,
  RegisterFormDefaultProps
} from "../RegisterForm/RegisterForm";
import { Dialog2PropTypes, Dialog2DefaultProps } from "../../Dialog2";

/**
 * Defines the prop types
 */
const propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  registerForm: PropTypes.shape(RegisterFormPropTypes),
  openDialog: PropTypes.func,
  dialog: PropTypes.shape(Dialog2PropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  open: false,
  handleClose: () => {},
  registerForm: RegisterFormDefaultProps,
  openDialog: () => {},
  dialog: Dialog2DefaultProps
};

export { propTypes, defaultProps };
