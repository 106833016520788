/**
 * Defines the data requirements for the component
 *
 * @see Events.md for details
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */

import {
  EventSearchPropTypes,
  EventSearchDefaultProps,
} from "../EventSearch/EventSearch";

import { IconPropTypes } from "../../Icon";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { EventAsThumbPropTypes } from "../../_event/EventAsThumb";
import { DestinationPropTypes } from "../../_destination/Destination";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Events/getEvents
 */
const propTypes = {
  /**
   * Internal props
   */
  eventSearch: PropTypes.shape(EventSearchPropTypes),
  events: PropTypes.arrayOf(PropTypes.shape(EventAsThumbPropTypes)),
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  monthIcon: PropTypes.shape(IconPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  eventSearch: EventSearchDefaultProps,
  events: [],
  destinations: [],
  monthIcon: {
    icon: <DateRangeIcon />,
  },
};

export { propTypes, defaultProps };
