const de_de = {
  text: "Ab {{price}} RON / Nacht",
  number_of_guests: "Gäste: {{guests}}",
  number_of_rooms: "Räume: {{rooms}}",
  view_message: "Nachricht ansehen",
  message_modal_title: "Botschaft",
  show_more: "Zeig mehr",
  show_less: "Zeige weniger",
};

export { de_de };
