const de_de = {
  email: "E-Mail-Addresse",
  forgot_pass: "Passwort vergessen?",
  register: "Jetzt beitreten!",
  login: "Einloggen",
  password: "Passwort",
  remember_me: "Behalte mich in Erinnerung",
  register_text:
    "Bitte geben Sie die erforderlichen Daten ein, um sich zu registrieren.",
};

export { de_de };
