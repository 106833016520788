const ro_ro = {
  email: "Adresă de email",
  forgot_pass: "Ai uitat parola?",
  register: "Înscrie-te acum!",
  login: "Logare",
  password: "Parola",
  remember_me: "Ţine minte",
  register_text:
    "Vă rugăm să completați datele necesare pentru a vă înregistra.",
};

export { ro_ro };
