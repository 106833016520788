/**
 * Defines the data requirements for the component
 *
 * @see ProductSearch.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { ProductAsThumbPropTypes } from "../ProductAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  filters: PropTypes.any,
  products: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape(ProductAsThumbPropTypes)),
    limit: PropTypes.number,
  }),
  data: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  filters: {
    destination: null,
    seller: null,
    category: null,
    limit: null,
    offset: null,
    order: null,
    sort: null,
    page: 1,
    perPage: 10,
  },
  products: {
    total: 0,
    data: [],
    limit: 0,
  },
  data: {
    destinations: [],
    sellers: [],
    categories: [],
  },
};

export { propTypes, defaultProps };
