/**
 * Defines the data requirements for the component
 *
 * @see LoginDialog.md for details
 */
import PropTypes from "prop-types";
import {
  LoginFormPropTypes,
  LoginFormDefaultProps
} from "../LoginForm/LoginForm";
import { Dialog2PropTypes, Dialog2DefaultProps } from "../../Dialog2";

/**
 * Defines the prop types
 */
const propTypes = {
  loginForm: PropTypes.shape(LoginFormPropTypes),
  dialog: PropTypes.shape(Dialog2PropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  loginForm: LoginFormDefaultProps,
  dialog: Dialog2DefaultProps
};

export { propTypes, defaultProps };
