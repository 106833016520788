const ro_ro = {
  quantity: "Cantitate",
  item: "Item",
  items: "Items",
  order_date: "Data comandă",
  order_number: "Număr comandă",
  order_status: "Stare comandă",
  seller: "Vânzător",
  pending: "In asteptarea",
  refused: "Anulat",
  confirmed: "Plata în așteptare",
  cancelled: "Anulat",
  paid: "Plătit",
  shipped: "Expediat",
  returned: "Întors",
  return_requested: "Retur solicitat",
};

export { ro_ro };
