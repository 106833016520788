/**
 * Component short description
 *
 * @see NewsletterForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

/**
 * Imports other components and hooks
 */
import { useNewsletter } from "../Newsletter/Newsletter.logic";
import useValidationSchemas from "../../hooks/useValidation/useValidationSchemas";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./NewsletterForm.data";
/**
 * Imports Material UI components
 */
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./NewsletterForm.lang.ro-ro";
import { hu_hu } from "./NewsletterForm.lang.hu-hu";
import { en_us } from "./NewsletterForm.lang.en-us";
import { de_de } from "./NewsletterForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "NewsletterForm", ro_ro);
i18n.addResourceBundle("hu-HU", "NewsletterForm", hu_hu);
i18n.addResourceBundle("en-US", "NewsletterForm", en_us);
i18n.addResourceBundle("de-DE", "NewsletterForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  select: {
    width: "100%",
    maxWidth: "95%",
  },

  formContainer: {
    width: "100%",
  },
}));

/**
 * Displays the component
 */
const NewsletterForm = (props) => {
  const { container, formContainer, select } = useStyles(props);
  const { t } = useTranslation("NewsletterForm");
  const { defaultValues } = props;
  const { getNewsletterFormSchema } = useValidationSchemas();
  const { createNewsletter } = useNewsletter();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, handleSubmit, formState, reset } = useForm({
    defaultValues,
    validationSchema: getNewsletterFormSchema(),
    reValidateMode: "onChange",
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (data) => {
    reset(defaultValues);

    createNewsletter(data).then(() => {
      enqueueSnackbar(t("subscribe_success"), { variant: "success" });
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate className={clsx(formContainer, "NewsletterForm")}>
      <Grid
        container
        spacing={1}
        className={clsx(container, "NewsletterForm")}
        alignItems="center"
      >
        <Grid item xs={12} lg={9}>
          <TextField
            type="email"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="email"
            label={t("email")}
            className={clsx(select)}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

NewsletterForm.propTypes = propTypes;
NewsletterForm.defaultProps = defaultProps;

export default NewsletterForm;
export {
  propTypes as NewsletterFormPropTypes,
  defaultProps as NewsletterFormDefaultProps,
};
