/**
 * Component short description
 *
 * @see FacebookShare.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { FacebookShareButton } from "react-share";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FacebookShare.data";

/**
 * Imports Material UI components
 */
import { Button, createMuiTheme, ThemeProvider } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import theme from "../../theme/theme";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FacebookShare.lang.ro-ro";
import { hu_hu } from "./FacebookShare.lang.hu-hu";
import { en_us } from "./FacebookShare.lang.en-us";
import { de_de } from "./FacebookShare.lang.de-de";

i18n.addResourceBundle("ro-RO", "FacebookShare", ro_ro);
i18n.addResourceBundle("hu-HU", "FacebookShare", hu_hu);
i18n.addResourceBundle("en-US", "FacebookShare", en_us);
i18n.addResourceBundle("de-DE", "FacebookShare", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const facebookTheme = createMuiTheme({
  ...theme,
  palette: { primary: { main: "#4267b2" } },
});

const FacebookShare = (props) => {
  const { url, ...rest } = props;
  const { t } = useTranslation("FacebookShare");
  return (
    <ThemeProvider theme={facebookTheme}>
      <FacebookShareButton url={url ?? window.location.href}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FacebookIcon htmlColor="#ffffff" />}
          size="large"
          disableElevation
          component="div"
          {...rest}
        >
          {t("text")}
        </Button>
      </FacebookShareButton>
    </ThemeProvider>
  );
};

FacebookShare.propTypes = propTypes;
FacebookShare.defaultProps = defaultProps;

export default FacebookShare;
export {
  propTypes as FacebookSharePropTypes,
  defaultProps as FacebookShareDefaultProps,
};
