/**
 * Displays an Attraction as a thumb
 *
 * @see AttractionAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./AttractionAsThumb.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./AttractionAsThumb.lang.ro-ro";
import { hu_hu } from "./AttractionAsThumb.lang.hu-hu";
import { en_us } from "./AttractionAsThumb.lang.en-us";
import { de_de } from "./AttractionAsThumb.lang.de-de";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "AttractionAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "AttractionAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "AttractionAsThumb", en_us);
i18n.addResourceBundle("de-DE", "AttractionAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%'
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

/**
 * Displays the component
 */
const AttractionAsThumb = (props) => {
  const { name, slug, image, description, customSlug } = props;

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;
  const cardContent = (
    <TextThumbnail
      to={customSlug || `${url}/${getSlugForCurrentLang(slug)}`}
      title={name}
      description={description}
    />
  );

  const cardWithMedia = {
    image: image,
    images: null,
    content: cardContent,
    customStyles: useStyles(props),
  };

  return <Card2 {...cardWithMedia} />;
};

AttractionAsThumb.propTypes = propTypes;
AttractionAsThumb.defaultProps = defaultProps;

export default AttractionAsThumb;
export {
  propTypes as AttractionAsThumbPropTypes,
  defaultProps as AttractionAsThumbDefaultProps,
};
