const de_de = {
  email: "E-Mail-Addresse",
  submit: "Begleiten Sie uns",
  have_an_account: "Sie haben bereits ein Konto?",
  conditions_part_2: "Nutzungsbedingungen & Verkauf.",
  sign_in: "Einloggen",
  name: "Dein Name",
  password: "Passwort",
  conditions_part_1:
    "Durch das Erstellen eines Kontos stimmen Sie Pineyard's zu",
  confirm_password: "Kennwort erneut eingeben",
};

export { de_de };
