import { useRequests } from "../../../hooks";
import useSWR from "swr";

export const useExchangeRate = () => {
  const { get } = useRequests();
  return useSWR("currency/eur", get);
};

export const useOrder = () => {
  const { get, post, put } = useRequests();

  const useGetOrdersSwr = () => {
    return useSWR("market/orders", get);
  };

  const useGetOrderByIdSwr = (id, condition = true) => {
    return useSWR(condition ? `market/orders/${id}` : null, get);
  };

  const updateOrderById = (id, data) => {
    return put(`market/orders/${id}`, data);
  };

  const updateOrders = (data) => {
    return post("market/orders", data);
  };

  return {
    useGetOrdersSwr,
    useGetOrderByIdSwr,
    updateOrderById,
    updateOrders,
  };
};
