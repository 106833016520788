/**
 * Defines the data requirements for the component
 *
 * @see ArticleAsThumb.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  name: PropTypes.string,
  slug: PropTypes.object,
  image: PropTypes.object,
  destination: PropTypes.string,
  destinationName: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {};

export { propTypes, defaultProps };
