import { useRequests } from "../../../hooks";
import useSWR from "swr";

export const useCart = () => {
  const { get, deleteReq } = useRequests();

  const useCartSwr = (condition = true) => {
    return useSWR(condition ? "market/cart" : null, get);
  };

  const useCartInfoSwr = () => {
    return useSWR("market/cart/info", get);
  };

  const deleteCartItem = (id) => {
    return deleteReq(`market/cart/${id}`);
  };

  const useCartOrdersSwr = () => {
    return useSWR("market/orders", get);
  };

  const useCartOrdersByIdSwr = (id) => {
    return useSWR(`market/orders/${id}`, get);
  };

  return {
    useCartSwr,
    useCartInfoSwr,
    useCartOrdersSwr,
    useCartOrdersByIdSwr,
    deleteCartItem,
  };
};
