/**
 * Defines the data requirements for the component
 *
 * @see Category.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";
import { AttractionPropTypes } from "../../_attraction/Attraction";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestinationCategories
 */
const propTypes = {
  id: PropTypes.any,
  slug: PropTypes.object,
  name: PropTypes.string,
  alternateName: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  icon: PropTypes.string,
  count: PropTypes.number,
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionPropTypes)),
  destinationName: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  slug: {},
  name: "",
  alternateName: "",
  description: "",
  image: ImageResponsivePlaceholderCom169,
  icon: null,
  count: 10,
  attractions: [],
  destinationName: "",
};

export { propTypes, defaultProps };
