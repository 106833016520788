/**
 * Component short description
 *
 * @see Events.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { Route, Switch, useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Event from "../../_event/Event";
import EventSearch from "../EventSearch";
import { FiltersProvider } from "../../../hooks";
import { PageHeader } from "../../Theme";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Events.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Events.lang.ro-ro";
import { hu_hu } from "./Events.lang.hu-hu";
import { en_us } from "./Events.lang.en-us";
import { de_de } from "./Events.lang.de-de";

i18n.addResourceBundle("ro-RO", "Events", ro_ro);
i18n.addResourceBundle("hu-HU", "Events", hu_hu);
i18n.addResourceBundle("en-US", "Events", en_us);
i18n.addResourceBundle("de-DE", "Events", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Events = (props) => {
  const { eventSearch } = props;
  const { filters, data } = eventSearch;
  const { container } = useStyles(props);
  const { t } = useTranslation("Events");
  const { setSlugs } = useSlugs();

  const { description: metaDescription, title, keywords } = useMeta({
    type: "route",
    path: "events",
  });

  /**
   * Prepares the path to an event
   */
  const match = useRouteMatch();
  const { path } = match;

  useEffect(() => {
    setSlugs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Event destination="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Head title={title} description={metaDescription} keywords={keywords}/>

        <Layout header={{ currentPage: "Events" }}>
          <Grid container spacing={1} className={clsx(container, "Events")}>
            <Grid item xs={12}>
              <PageHeader title={t("title")} />
            </Grid>
            <Grid item xs={12}>
              <FiltersProvider filters={filters} data={data}>
                <EventSearch />
              </FiltersProvider>
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;

export default Events;
export { propTypes as EventsPropTypes, defaultProps as EventsDefaultProps };
