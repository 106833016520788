const en_us = {
  quantity: "Quantity",
  item: "Item",
  items: "Items",
  order_date: "Order Date",
  order_number: "Order Number",
  order_status: "Order Status",
  seller: "Seller",
  pending: "Pending",
  refused: "Cancelled",
  confirmed: "Waiting Payment",
  cancelled: "Cancelled",
  paid: "Paid",
  shipped: "Shipped",
  returned: "Returned",
  return_requested: "Return requested",
};

export { en_us };
