/**
 * Defines the data requirements for the component
 *
 * @see AccommodationAsHero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { AccommodationAsThumbPropTypes } from "../../_accommodation/AccommodationAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  stays: PropTypes.arrayOf(PropTypes.shape(AccommodationAsThumbPropTypes)),
  destinationId: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinationId: null,
  description: "",
  stays: [],
};

export { propTypes, defaultProps };
