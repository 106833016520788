const en_us = {
  home_title: 'Tourist attractions around Giurgeu region | Pineyard.ro',
  home_description:
    'Giurgeu region includes Borsec resort and the communes: Tulgheş, Corbu, Ditrău, Remetea, Lăzarea, Joseni, Ciumani, Suseni and Voşlăbeni | Find out more on pineyard.ro',
  home_keywords: 'Pineyard,Giurgeu,regiunea Giurgeu,atractii turistice Giurgeu,Harghita,Borsec,cazare munte',
  destinations_title: 'The most important tourist attractions around Giurgeu | Pineyard.ro',
  destinations_description:
    'The region of the nine rural settlements and one resort, has an endless source of natural tourist attractions that are worth seeing and appreciating. | Pineyard.ro',
  destinations_keywords: 'obiective turistice Giurgeu,destinatii turistice Harghita,Borsec,Corbu,Ciumani',
  ditrau_title: 'Ditrău is located in the central part of Giurgeu basin | Pineyard.ro',
  ditrau_description:
    'The syenite with nepheline and sodalite, also called DITROIT, was described for the first time at the alkaline massif from Ditrău | Pineyard.ro',
  ditrau_keywords: 'Ditrau,comuna Ditrau,cazare Ditrau,vremea Ditrau',
  corbu_title: 'Corbu village is situated between Bistrița and Giurgeu mountains| Pineyard.ro',
  corbu_description:
    'Corbu commune welcomes you with several accommodation areas and a beautiful mountain landscape that you can explore on the 12 hiking trails during summer | Pineyard.ro',
  corbu_keywords: 'Corbu,cazare Corbu,primaria Corbu, vremea Corbu,pensiuni Corbu',
  ciumani_title: 'Ciumani is located in an ethno-cultural region in Eastern Transylvania| Pineyard.ro',
  ciumani_description:
    'Those who visit the commune of Ciumani, are invited to admire the panorama from the top of the ski slope and to relax in a salt water hot tub under the stars. | Pineyard.ro',
  ciumani_keywords: 'Ciumani,vremea Ciumani,cazare Ciumani,pensiuni Ciumani',
  remetea_title: "Remetea is one of Harghita county's large communes | Pineyard.ro",
  remetea_description:
    "Remetea is idyllically located opposite Ditrău, on the higher, left bank of Mureş river, in Kőpatak stream's valley | Pineyard.ro",
  remetea_keywords: 'Remetea,vremea Remetea,primaria Remetea,comuna Remetea,cazare Remetea',
  joseni_title: 'Joseni is a village in Harghita county, România | Pineyard.ro',
  joseni_description:
    'Joseni means "Lower Village" and is an ethno-cultural region in eastern Transylvania, composed of three villages | Pineyard.ro',
  joseni_keywords: 'Joseni,vremea Joseni,Joseni Harghita,cazare Joseni',
  lazarea_title: 'Lăzarea commune is one of the oldest settlements of Gurghiu valley| Pineyard.ro',
  lazarea_description:
    'The first documented notes about Lăzarea commune go back to the year 1200 and it has four important monuments, each one representing a different historic era | Pineyard.ro',
  lazarea_keywords: 'Lazarea,comuna Lazarea,Lazarea Harghita,cazare Lazarea Harghita',
  tulghes_title:
    'Tulgheș is a predominantly Romanian settlement in the northeastern part of Harghita County | Pineyard.ro',
  tulghes_description:
    'Tulgheș offers visitors natural and cultural tourist attractions, entertainment and leisure services and last but not least accommodation services | Pineyard.ro',
  tulghes_keywords: 'Tulghes,vremea Tulghes,cazare Tulghes,Tulghes Toplita,primaria Tulghes',
  borsec_title: 'Borsec is situated at the meeting point of Giurgeu, Bistrița and Călimani mountains | Pineyard.ro',
  borsec_description:
    'The greatest wealth of Borsec resort are the countless mineral water springs, while the prizes obtained at various international fairs are exhibited at the Museum of Mineral Waters | Pineyard.ro',
  borsec_keywords: 'Borsec,cazare Borsec,partia Borsec,statiunea Borsec,vremea Borsec,webcam Borsec',
  suseni_title: 'The foundation of Suseni as a settlement can be estimated around the year 1330 | Pineyard.ro',
  suseni_description:
    'The settlement named Katorzsa, situated on the outskirts of Suseni commune, can be considered as one of the oldest settlements in Giurgeu region| Pineyard.ro',
  suseni_keywords: 'Suseni,Suseni Harghita,cazare in Suseni,atractii turistice Suseni Harghita',
  voslabeni_title: 'Voşlăbeni commune consists of Voşlăbeni and Izvoru Mureşului villages| Pineyard.ro',
  voslabeni_description:
    'The village of Voşlăbeni located north from Izvoru Mureșului, is a typical mountain settlement with peaceful and hardworking people | Pineyard.ro',
  voslabeni_keywords: 'Voslabeni,vremea in Voslabeni,Voslabeni Harghita,primaria Voslabeni Harghita',
  local_market_title: 'This section of the site gives you the opportunity to buy local products online | Pineyard.ro',
  local_market_description:
    'Through this web page, you are presented with the products put up for sale by local producers in the region of Giurgeu. | Pineyard.ro',
  local_market_keywords:
    'Produse locale jud Harghita,produse locale Giurgeu,cumpara produse locale,producatori locali Giurgeu',
  stay_title: 'Giurgeu region offers two, three and four stars quality accommodation services | Pineyard.ro',
  stay_description:
    'Through our page, you can choose accommodation in the mountains, at the hotel, pension, villa, hostel, cottage or camping in Giurgeu region. | Pineyard.ro',
  stay_keywords: 'Cazare Giurgeu,cazare jud Harghita,cazare munte,pensiune cu piscina Harghita,cazare munte piscina',
  news_title: 'News and updates from Giurgeu region| Pineyard.ro',
  news_description:
    "Find out the latest news in the area, whether it's mountain accommodation or the latest local products from Giurgeu region.| Pineyard.ro",
  news_keywords: 'Stiri judetul Harghita,cazare Harghita,stiri Giurgeu,primaria Giurgeu',
  contact_title: 'Contact us with confidence, our team is at your disposal 24 hours a day| Pineyard.ro',
  contact_description:
    'If you have any questions regarding the information shared on our site, please do not hesitate to contact us. | Pineyard.ro',
  contact_keywords: 'Trimite mesaj,contact Pineyard',
  events_title: 'The most important events from Giurgeu region | Pineyard.ro',
  events_description:
    'We have gathered together the most important events in Giurgeu region, shows, fairs, inaugurations, etc. | Pineyard.ro',
  events_keywords: 'Evenimente Harghita,evenimente jud Harghita,evenimente Giurgeu,concerte Harghita',
};

export { en_us };
