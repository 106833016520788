/**
 * Displays an Article (News detail)
 *
 * @see Article.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import { useNewsArticleSwr } from "../../_news/News/News.logic";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import { Actions, PageHeader, Template, TextSection } from "../../Theme";
import Breadcrumbs from "../../Breadcrumbs";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import FacebookShare from "../../FacebookShare/FacebookShare";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Article.data";
import GeneralDescription from "../../GeneralDescription/GeneralDescription";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./Article.lang.ro-ro";
import { hu_hu } from "./Article.lang.hu-hu";
import { en_us } from "./Article.lang.en-us";
import { de_de } from "./Article.lang.de-de";

i18n.addResourceBundle("ro-RO", "Article", ro_ro);
i18n.addResourceBundle("hu-HU", "Article", hu_hu);
i18n.addResourceBundle("en-US", "Article", en_us);
i18n.addResourceBundle("de-DE", "Article", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Article = (props) => {
  const { container } = useStyles(props);
  const [article, setArticle] = useState(props);
  const { addSlug } = useSlugs();
  const { name, destination, image, description, sectionDescription1, image1 } = article;

  /**
   * Prepares the path to an article
   */
  const match = useRouteMatch();
  const {
    params: { slug },
  } = match;
  const { data: responseData } = useNewsArticleSwr(slug);

  useEffect(() => {
    if (responseData && !isEqual(responseData, article)) {
      setArticle(responseData);
      addSlug(responseData.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  /**
   * Displays the hero
   */
  const cardContent1 = <TextSection description={description} />;

  const cardActions = (
    <Actions
      items={[
        {
          id: 1,
          action: <FacebookShare />,
        },
      ]}
    />
  );

  const cardWithMedia1 = {
    image,
    images: null,
    carousel: null,
    content: cardContent1,
    actions: cardActions,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  return (
    <Layout header={{ currentPage: "News" }}>
      <Grid container spacing={1} className={clsx(container, "Article")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={destination}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template
            items={[<GeneralDescription image={image1} description={sectionDescription1}/>]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;
export { propTypes as ArticlePropTypes, defaultProps as ArticleDefaultProps };
