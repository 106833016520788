const hu_hu = {
  category: "Kategória",
  destination: "Cél",
  extras: "Extrák",
  clear_all: "Mindent kitöröl",
  room_type: "Szoba típus",
  accomodation_type: "Szállás típusa",
  seller: "Eladó",
};

export { hu_hu };
