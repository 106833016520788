/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";

/**
 * Imports data
 */

const useFaqSwr = (props) => {
  const { get } = useRequests();
  return useSWR("faq", get);
};

const useFaqInfoSwr = (props) => {
  const { get } = useRequests();
  return useSWR("faq/info", get);
};
/**
 * Displays the component
 */
const useFaq = (props) => {
  const { get } = useRequests();

  const getFaq = () => {
    return get("faq");
  };

  return { useFaqSwr, getFaq };
};

export default useFaq;
export { useFaqSwr, useFaqInfoSwr };
