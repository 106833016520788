/**
 * Component short description
 *
 * @see EventSearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import * as moment from "moment";
/**
 * Imports other components and hooks
 */
import Pagination from "../../Pagination/Pagination";
import EventAsThumb from "../../_event/EventAsThumb";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import { useUpdateEffect, useFilters } from "../../../hooks";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventSearch.data";
import useEventsSwr from "../Events/Events.logic";
import { getLocale } from "../../LanguageSelector/LanguageSelector";
import { TextDefault } from "../../Theme/Theme";
import Card2 from "../../Card2/Card2";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./EventSearch.lang.ro-ro";
import { hu_hu } from "./EventSearch.lang.hu-hu";
import { en_us } from "./EventSearch.lang.en-us";
import { de_de } from "./EventSearch.lang.de-de";

i18n.addResourceBundle("ro-RO", "EventSearch", ro_ro);
i18n.addResourceBundle("hu-HU", "EventSearch", hu_hu);
i18n.addResourceBundle("en-US", "EventSearch", en_us);
i18n.addResourceBundle("de-DE", "EventSearch", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  select: {
    width: "100%",
    maxWidth: 320,
  },
  noEventsStyle: {
    display: "flex",
    alignItems: "center",
    minHeight: "50vh",
    flexDirection: "column",
    width: '100%',
  },
}));

/**
 * Displays the component
 */
const EventSearch = (props) => {
  const { events: defaultEvents, data: defaultData } = props;
  const { destinations: defaultDestinations } = defaultData;

  const { select, container, noEventsStyle } = useStyles(props);
  const { t, i18n } = useTranslation("EventSearch");
  const { language } = i18n;
  const locale = getLocale();

  const handleOpen = () => {
    setTimeout(() => {
      const yearElement = document.querySelector('h6.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1');
      if (yearElement) {
        yearElement.remove();
      }
    }, 0)
  }

  const {
    filters,
    setFilters,
    parseFiltersForQuery,
    setFiltersAndResetPagination,
  } = useFilters();

  const { destination, month, page } = filters;

  const [events, setEvents] = useState(defaultEvents);
  const [currentMonth, setCurrentMonth] = useState(month || new Date());

  const { total, perPage } = events;
  /**
   * Fetches the events
   */
  const { data: eventsData, mutate: mutateEvents } = useEventsSwr({
    ...parseFiltersForQuery(filters),
  });

  /**
   * Fetches the destinations
   */
  const {
    data: destinations = defaultDestinations,
    mutate: mutateDestinations,
  } = useDestinationsSimpleSwr();

  /**
   * Refetch data if language changes
   */
  useUpdateEffect(() => {
    mutateEvents();
    mutateDestinations();
  }, [language]);

  /**
   * Set events
   */
  useEffect(() => {
    if (eventsData) {
      setEvents(eventsData);
    }
  }, [eventsData]);

  /**
   * Handles the Destinations select box
   */

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    setFiltersAndResetPagination({
      destination: value,
    });
  };

  /**
   * Handles the Month picker
   */
  const handleMonthChange = (month) => {
    setFiltersAndResetPagination({
      month: moment(month).format("MM"),
    });
    setCurrentMonth(month);
  };

  /**
   * Handles the pagination
   */
  const handlePagination = (_event, value) => {
    setFilters((prevState) => ({
      ...prevState,
      page: value,
      perPage: perPage,
    }));
  };

  /**
   * Displays a list of Destinations
   */
  const destinationsList = destinations.map((item) => {
    const { id, name } = item;
    return (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    );
  });

  const allDestinations = (
    <MenuItem key="all" value=" ">
      {t("all")}
    </MenuItem>
  );

  const destinationListWithAll = [allDestinations, ...destinationsList];

  /**
   * Displays the Destinations select box
   */
  const destinationsSelect = (
    <FormControl variant="outlined" className={clsx(select)}>
      <InputLabel id="events-destination-select-label">
        {t("Events:select_destination")}
      </InputLabel>
      <Select
        label={t("Events:select_destination")}
        labelId="events-destination-select-label"
        id="events-destination-select"
        value={destination}
        onChange={handleDestinationChange}
      >
        {destinationListWithAll}
      </Select>
    </FormControl>
  );

  /**
   * Displays the events
   */
  const eventsList = (list) => {
    return list.map((item, index) => {
      return (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <EventAsThumb {...item} index={index} />
        </Grid>
      );
    });
  };

  /**
   * Displays a placeholder skeleton while data is loading
   * // NOTE: When defined in `EventAsThumb` it throws an error
   */
  const skeletonsList = Array(12)
    .fill(0)
    .map((item, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <>
          <Skeleton width="60%" />
          <Skeleton variant="rect" width={320} height={180} />
          <Skeleton />
        </>
      </Grid>
    ));

  /**
   * Displays either a list of articles or their skeletons
   */
  const noEventsText = (
    <Grid item className={noEventsStyle}>
      <Card2 content={<TextDefault text={t('no_events')} />} />
    </Grid>
  );

  let eventsListOrSkeleton = skeletonsList;
  if (eventsData) {
    eventsListOrSkeleton = eventsData.data.length ? eventsList(eventsData.data) : noEventsText;
  }

  if ("en" !== locale) {
    require(`moment/locale/${locale}`);
  }

  const handleKeyboardInput = () => {
    return moment(currentMonth).format('MMMM');
  }

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className={clsx(container)}
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          {destinationsSelect}
        </Grid>
        <Grid
          item
          xs={4}
          container
          className={clsx(container)}
          justify="flex-end"
        >
          <MuiPickersUtilsProvider locale={locale} utils={MomentUtils}>
            <KeyboardDatePicker
              onOpen={handleOpen}
              autoOk
              views={["month"]}
              label={t("month")}
              value={currentMonth}
              inputVariant="outlined"
              variant="inline"
              format="MMMM"
              onChange={handleMonthChange}
              InputAdornmentProps={{ position: "start" }}
              rifmFormatter={handleKeyboardInput}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container className={clsx(container)} spacing={1} item xs={12}>
        {eventsListOrSkeleton}
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <Pagination
          total={total}
          pageSize={perPage}
          page={page}
          onChange={handlePagination}
        />
      </Grid>
    </>
  );
};

EventSearch.propTypes = propTypes;
EventSearch.defaultProps = defaultProps;

export default EventSearch;
export {
  propTypes as EventSearchPropTypes,
  defaultProps as EventSearchDefaultProps,
};
