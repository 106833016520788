/**
 * Component short description
 *
 * @see useCartItems.md for details
 */

/**
 * Imports React and third party packages
 */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "../useAuth/useAuth";
import useRequests from "../useRequests";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useCartItems.data";

const CartItemsContext = createContext();

/**
 * Displays the component
 */
const CartItemsProvider = ({ children }) => {
  const { get } = useRequests();
  const [count, setCount] = useState(0);

  const { isAuthenticated } = useAuth();

  const fetchCart = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }
    /**
     * For some reason useCart() is always returning undefined, so good old
     * Promise based fetching is used
     */
    get("market/cart").then((resp) => {
      if (resp) {
        setCount(getItemsCount(resp));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getItemsCount = (data) => {
    return data.reduce((reducer, seller) => {
      return reducer + seller.products.length;
    }, 0);
  };

  const updateCart = () => {
    fetchCart();
  };

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  return (
    <CartItemsContext.Provider value={{ count, setCount, updateCart }}>
      {children}
    </CartItemsContext.Provider>
  );
};
const useCartItems = (props) => {
  return useContext(CartItemsContext);
};

useCartItems.propTypes = propTypes;
useCartItems.defaultProps = defaultProps;

export default useCartItems;
export {
  propTypes as useCartItemsPropTypes,
  defaultProps as useCartItemsDefaultProps,
  CartItemsProvider,
};
