/**
 * Component short description
 *
 * @see OrderForCart.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextPrice, TextSection } from "../../Theme";
import OrderItemForCart from "../OrderItemForCart";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./OrderForCart.data";
/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderForCart.lang.ro-ro";
import { hu_hu } from "./OrderForCart.lang.hu-hu";
import { en_us } from "./OrderForCart.lang.en-us";
import { de_de } from "./OrderForCart.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderForCart", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderForCart", hu_hu);
i18n.addResourceBundle("en-US", "OrderForCart", en_us);
i18n.addResourceBundle("de-DE", "OrderForCart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  /**
   * Trying to replicate the OrderItemForCart grid
   */
  alignLeft: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(3),
    },
  },

  alignLeftNegative: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: -theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "inherit",
    },
  },
}));

/**
 * Displays the component
 */
const OrderForCart = (props) => {
  const {
    sellerName,
    sellerId,
    products,
    itemsPrice,
    index,
    handleItemDelete,
    handleUpdateItem,
  } = props;
  const { container, alignLeft, alignLeftNegative } = useStyles(props);
  const { t } = useTranslation("OrderForCart");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const spacing = isMobile ? 0 : 2;

  if (!products.length) {
    return null;
  }

  /**
   * Displays the hero card
   */
  const title = `${t("sold_by")} ${sellerName}`;
  const cardContent1 = <TextSection subtitle={title} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  const checkoutLink = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={{
        pathname: `/${t("Routes:checkout")}`,
        search: `?sellerId=${sellerId}`,
      }}
      {...props}
    />
  ));

  /**
   * Displays the items
   */
  const itemsList = products.map((item, index2) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12}>
        <OrderItemForCart
          {...item}
          index={(index + 1) * (index2 + 1)}
          handleItemDelete={handleItemDelete}
          handleUpdateItem={handleUpdateItem}
        />
      </Grid>
    );
  });

  /**
   * Displays the summary
   * - Trying to replicate the OrderItemForCart grid
   */
  const cardContent2 = (
    <List>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item sm={5} lg={3}>
            {t("items_price")}:
          </Grid>
          <Grid item className={clsx(alignLeft)}>
            <TextPrice text={` ${itemsPrice} RON`} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item sm={5} lg={3}>
            {t("delivery_price")}:
          </Grid>
          <Grid item className={clsx(alignLeft)}>
            <TextPrice text={t("pending")} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem divider disableGutters key={shortid.generate()}>
        <Grid container className={clsx(container)}>
          <Grid item sm={5} lg={3}>
            {t("total_price")}:
          </Grid>
          <Grid item className={clsx(alignLeft)}>
            <TextPrice text={t("pending")} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem disableGutters key={shortid.generate()}>
        <Grid container spacing={spacing} className={clsx(container)}>
          <Grid item sm={5} lg={3}></Grid>
          <Grid item className={clsx(alignLeftNegative)}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={checkoutLink}
            >
              {t("checkout")}
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );

  return (
    <Grid container className={clsx(container, "OrderForCart")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia1} />
      </Grid>
      <Grid item xs={12}>
        <Grid container className={clsx(container)}>
          {itemsList}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {cardContent2}
      </Grid>
    </Grid>
  );
};

OrderForCart.propTypes = propTypes;
OrderForCart.defaultProps = defaultProps;

export default OrderForCart;
export {
  propTypes as OrderForCartPropTypes,
  defaultProps as OrderForCartDefaultProps,
};
