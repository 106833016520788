/**
 * Defines the data requirements for the component
 *
 * @see OrdersForCart.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { OrderForCartPropTypes } from "../OrderForCart";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getCartMarket
 */
const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape(OrderForCartPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  orders: [],
};

export { propTypes, defaultProps };
