/**
 * Component short description
 *
 * @see CartIcon.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import useCartItems from "../../hooks/useCartItems/useCartItems";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CartIcon.data";

/**
 * Imports Material UI components
 */
import { Box, makeStyles, Typography } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: "inherit",
  },
  counter: {
    width: 15,
    height: 15,
    backgroundColor: "red",
    borderRadius: "50%",
    position: "absolute",
    right: -5,
    top: -5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    lineHeight: 0,
  },
}));

/**
 * Displays the component
 */
const CartIcon = (props) => {
  const { counter, icon, text } = useStyles(props);
  const { count } = useCartItems();

  return (
    <Box display="flex" position="relative">
      <ShoppingCartIcon className={icon} />
      {!!count && (
        <Box className={counter}>
          <Typography className={text} variant="caption">
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

CartIcon.propTypes = propTypes;
CartIcon.defaultProps = defaultProps;

export default CartIcon;
export { propTypes as CartIconPropTypes, defaultProps as CartIconDefaultProps };
