const ro_ro = {
  cancel: "Anulare",
  check_out: "Data plecării",
  check_in: "Data sosirii",
  message: "Mesaj",
  phone: "Număr de telefon",
  rooms: "Numărul de camere",
  guests: "Numărul oaspeţilor",
  terms: "Termeni şi condiţii",
  submit: "Trimite cerere",
  wrong_date: "Data greșită",
  checkin_early: "Data de check-in este greșită",
  wrong_checkout: "Data de plecare este greșită",
  agree_text: "Sunt de acord cu",
  invalid_date:
    "Vă rugăm să verificați formatul să corespundă cu DD-MM-YYYY -> 21-12-2020",
};

export { ro_ro };
