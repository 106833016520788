/**
 * Imports React and third party packages
 */
import useSWR from "swr";
/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";
import { stringifyParams } from "../../../hooks/useRequests/useRequests";

const useStaysSwr = (props) => {
  const { filters = {}, condition = true } = props;
  const { get } = useRequests();

  const params = stringifyParams(filters);

  return useSWR(condition ? `stay/accommodations?${params}` : null, get);
};

const useStayInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/info", get);
};

const useAccomodationTypesSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/accommodation-types", get);
};

const useRoomTypesSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/room-types", get);
};

const useExtrasSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/extras", get);
};

const useMealsSwr = () => {
  const { get } = useRequests();
  return useSWR("stay/meals", get);
};

const useStaySwr = (id) => {
  const { get } = useRequests();

  return useSWR(`stay/accommodations/${id}`, get);
};

const useStayHistorySwr = () => {
  const { get } = useRequests();
  return useSWR(`stay/bookings`, get);
};

const useStay = () => {
  const { get, post } = useRequests();

  const getStays = (params) => {
    return get("stay/accommodations", params);
  };

  const createReservation = (stayId, reservation) => {
    return post(`stay/booking/${stayId}`, reservation);
  };

  const createStayRequest = (data) => {
    return post("stay/bookings", data);
  };

  return {
    getStays,
    createReservation,
    createStayRequest,
    useStayInfoSwr,
    useAccomodationTypesSwr,
    useRoomTypesSwr,
    useExtrasSwr,
    useStaySwr,
    useStayHistorySwr,
  };
};

export default useStay;
export {
  useStaysSwr,
  useStayInfoSwr,
  useAccomodationTypesSwr,
  useRoomTypesSwr,
  useExtrasSwr,
  useStaySwr,
  useStayHistorySwr,
  useMealsSwr,
};
