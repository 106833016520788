/**
 * Component short description
 *
 * @see Contact.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { useSnackbar } from "notistack";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, { Card2SingleRowStylesInverted } from "../../Card2";
import ContactForm from "../ContactForm/ContactForm";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import useContact from "./Contact.logic";
import { useUpdateEffect } from "../../../hooks";
import { PageHeader, TextSection } from "../../Theme";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Contact.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Contact.lang.ro-ro";
import { hu_hu } from "./Contact.lang.hu-hu";
import { en_us } from "./Contact.lang.en-us";
import { de_de } from "./Contact.lang.de-de";

i18n.addResourceBundle("ro-RO", "Contact", ro_ro);
i18n.addResourceBundle("hu-HU", "Contact", hu_hu);
i18n.addResourceBundle("en-US", "Contact", en_us);
i18n.addResourceBundle("de-DE", "Contact", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Contact = (props) => {
  const { contactForm } = props;
  const { t, i18n } = useTranslation("Contact");
  const { language } = i18n;
  const { enqueueSnackbar } = useSnackbar();
  const { container } = useStyles(props);

  const { description: metaDescription, title, keywords } = useMeta({
    type: "route",
    path: "contact",
  });

  const { createContact, useContactInfoSwr } = useContact();
  const {
    data: destinationsData,
    mutate: mutateDestinations,
  } = useDestinationsSimpleSwr();
  const { data: contactData = {}, mutate: mutateContact } = useContactInfoSwr();

  const contact = contactData ? contactData : props;

  const { description, image } = contact;

  useUpdateEffect(() => {
    mutateContact();
    mutateDestinations();
  }, [language]);

  const handleFormSubmit = async (form) => {
    createContact(form).then((resp) => {
      enqueueSnackbar(t("contact_submitted"), { variant: "success" });
    });
  };

  /**
   * Displays the card
   */
  const cardContent = <TextSection description={description} />;

  const cardActions = (
    <ContactForm
      {...contactForm}
      destinations={destinationsData ?? []}
      onSubmit={handleFormSubmit}
    />
  );

  const cardWithMedia = {
    image,
    content: cardContent,
    actions: cardActions,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "lg" }),
  };

  return (
    <>
      <Head title={title} description={metaDescription} keywords={keywords} />

      <Layout header={{ currentPage: "Contact" }}>
        <Grid container spacing={1} className={clsx(container, "Contact")}>
          <Grid item xs={12}>
            <PageHeader title={t("title")} />
          </Grid>
          <Grid item xs={12}>
            <Card2 {...cardWithMedia} />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
export { propTypes as ContactPropTypes, defaultProps as ContactDefaultProps };
