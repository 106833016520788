/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsCarousel.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  CategoriesAsTabsPropTypes,
  CategoriesAsTabsDefaultProps,
} from "../CategoriesAsTabs";

import { AttractionAsThumbPropTypes } from "../../_attraction/AttractionAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  ...CategoriesAsTabsPropTypes,
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionAsThumbPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...CategoriesAsTabsDefaultProps,
  attractions: [],
};

export { propTypes, defaultProps };
