/**
 * Defines the data requirements for the component
 *
 * @see Checkout.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  OrderForCheckoutPropTypes,
  OrderForCheckoutDefaultProps,
} from "../../_account/OrderForCheckout";

/**
 * Defines the prop types
 *
 * // NOTE: Couldn't find anything in ApiDoc, going with Figma
 */
const propTypes = {
  description: PropTypes.string,
  personalName: PropTypes.string,
  personalAddress: PropTypes.string,
  personalPhone: PropTypes.string,
  deliveryName: PropTypes.string,
  deliveryAddress: PropTypes.string,
  deliveryPhone: PropTypes.string,
  order: PropTypes.shape(OrderForCheckoutPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  personalName: "",
  personalAddress: "",
  personalPhone: "",
  deliveryName: "",
  deliveryAddress: "",
  deliveryPhone: "",
  order: OrderForCheckoutDefaultProps,
};

export { propTypes, defaultProps };
