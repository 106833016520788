const hu_hu = {
  email: "Email cím",
  forgot_pass: "Elfelejtette a jelszavát?",
  register: "Csatlakozz most!",
  login: "Bejelentkezés",
  password: "Jelszó",
  remember_me: "Emlékezz rám",
  register_text: "Kérjük, töltse ki a szükséges adatokat a regisztrációhoz.",
};

export { hu_hu };
