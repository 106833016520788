/**
 * Component short description
 *
 * @see Modal.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Modal.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Modal.lang.ro-ro";
import { hu_hu } from "./Modal.lang.hu-hu";
import { en_us } from "./Modal.lang.en-us";
import { de_de } from "./Modal.lang.de-de";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "Modal", ro_ro);
i18n.addResourceBundle("hu-HU", "Modal", hu_hu);
i18n.addResourceBundle("en-US", "Modal", en_us);
i18n.addResourceBundle("de-DE", "Modal", de_de);

const titleStyleObj = {
  titleStyle: {
    "& .MuiTypography-root": {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles(() => ({
  ...titleStyleObj,
  contentStyle: {
    width: "99vw",
    height: "99vh",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const useCustomStyle = makeStyles(() => ({
  ...titleStyleObj,
  contentStyle: {
    width: "99vw",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    overflowY: "auto",
  },
}));

/**
 * Displays the component
 */
const Modal = (props) => {
  const {
    isModalOpen,
    handleClose,
    title,
    maxWidth,
    isCustomStyle,
    children,
  } = props;
  const styles = useStyles();
  const customStyle = useCustomStyle();
  const { titleStyle, contentStyle } = isCustomStyle ? customStyle : styles;
  const { t } = useTranslation("Modal");

  return (
    <Dialog
      id={shortid.generate()}
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby={`modal-dialog-label-${shortid.generate()}`}
      maxWidth={maxWidth}
    >
      <DialogTitle id={shortid.generate()} className={clsx(titleStyle)}>
        {t(title)}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={clsx(contentStyle)}>{children}</DialogContent>
    </Dialog>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
export { propTypes as ModalPropTypes, defaultProps as ModalDefaultProps };
