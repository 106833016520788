import React, { useState } from 'react';
import yup from './validations';

const useValidation = shape => {
  const schema = yup.object().shape(shape);

  const [errors, setErrors] = useState([]);
  const isError = errors.length > 0;
  const errorMessages = errors.map(message => <span>{message}</span>);

  const validateSchema = data =>
    schema.validate(data).catch(function(err) {
      setErrors(err.errors);
    });

  return [isError, errorMessages, validateSchema];
};

export default useValidation;
export { yup };
