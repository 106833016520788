const en_us = {
  attractions: "Attractions",
  accommodations: "Accomodations",
  destinations: "Destinations",
  events: "Events",
  no_results: "No results found. Please try another search.",
  products: "Products",
  news: "News",
  all: "All",
};

export { en_us };
