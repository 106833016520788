/**
 * Defines the data requirements for the component
 *
 * @see SearchForm.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { CategoryPropTypes } from "../../_category/Category";

/**
 * Defines the prop types
 */
const propTypes = {
  text: PropTypes.string,
  category: PropTypes.shape(CategoryPropTypes),
  categories: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  text: null,
  category: {},
  categories: [],
};

export { propTypes, defaultProps };
