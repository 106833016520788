const hu_hu = {
  current_email: "Jelenlegi e-mail:",
  new_email_confirm: "Erősítse meg az új e-mailt",
  new_password_confirm: "Erősítse meg az új jelszót",
  new_email: "Új Email",
  double_confirmation: "Dupla megerősítésre van szükség",
  title_general: "Tábornok",
  current_name: "Jelenlegi név:",
  new_name: "Új név",
  title_current_password: "Jelenlegi jelszó",
  current_password: "Jelenlegi jelszó",
  new_password: "Új jelszó",
  submit: "Mentés",
  title_change_email: "E-mailt változtatni",
  title_change_password: "Jelszó módosítása",
  settings: "Beállítások",
  update_profile_success: "Fiókját sikeresen frissítettük.",
  update_profile_failed: "Valami elromlott. Kérlek próbáld újra.",
};

export { hu_hu };
