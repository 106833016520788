/**
 * Component short description
 *
 * @see SelectFromListForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./SelectFromListForm.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./SelectFromListForm.lang.ro-ro";
import { hu_hu } from "./SelectFromListForm.lang.hu-hu";
import { en_us } from "./SelectFromListForm.lang.en-us";
import { de_de } from "./SelectFromListForm.lang.de-de";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "SelectFromListForm", ro_ro);
i18n.addResourceBundle("hu-HU", "SelectFromListForm", hu_hu);
i18n.addResourceBundle("en-US", "SelectFromListForm", en_us);
i18n.addResourceBundle("de-DE", "SelectFromListForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    maxWidth: 320,
  },
}));

/**
 * Displays the component
 */
const SelectFromListForm = props => {
  const { items, value, onChangeEvent, label } = props;
  const { select } = useStyles(props);
  const { t } = useTranslation("SelectFromListForm");
  const itemsWithSelectAll = [{ id: ' ', name: t("all") }, ...items];

  return (
    <FormControl variant="outlined" className={clsx(select)}>
      <InputLabel id="select-from-list-label">
        {t(label)}
      </InputLabel>
      <Select
        label={t(label)}
        labelId="events-destination-select-label"
        id="events-destination-select"
        value={value}
        onChange={onChangeEvent}
      >
        {
          itemsWithSelectAll.map((item) => {
            const { id, name } = item;

            return (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
};

SelectFromListForm.propTypes = propTypes;
SelectFromListForm.defaultProps = defaultProps;

export default SelectFromListForm;
export {
  propTypes as SelectFromListFormPropTypes,
  defaultProps as SelectFromListFormDefaultProps
};
