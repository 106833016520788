const ro_ro = {
  title: "Am uitat parola",
  reset_success: "Emaiul trimis ca parola uitată",
  login: "Logare",
  return: "sau întoarcete la",
  description:
    "Vă rugăm să introduceți adresa de e-mail pentru a vă reseta parola.",
};

export { ro_ro };
