/**
 * Defines general, project wide validation messages
 *
 * // TODO can't make it to translate ....
 */

import * as yup from "yup";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./useValidation.lang.ro-ro";
import { hu_hu } from "./useValidation.lang.hu-hu";
import { en_us } from "./useValidation.lang.en-us";
import { de_de } from "./useValidation.lang.de-de";

i18n.addResourceBundle("ro-RO", "useValidation", ro_ro);
i18n.addResourceBundle("hu-HU", "useValidation", hu_hu);
i18n.addResourceBundle("en-US", "useValidation", en_us);
i18n.addResourceBundle("de-DE", "useValidation", de_de);

yup.setLocale({
  string: {
    min: i18n.t("useValidation:Value too short"),
  },
});

export default yup;
