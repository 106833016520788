const en_us = {
  current_email: "Current email:",
  new_email_confirm: "Confirm new email",
  new_password_confirm: "Confirm new password",
  new_email: "New email",
  double_confirmation: "Double confirmation is needed",
  title_general: "General",
  current_name: "Current name:",
  new_name: "New name",
  title_current_password: "Current password",
  current_password: "Current password",
  new_password: "New password",
  submit: "Save",
  title_change_email: "Change email",
  title_change_password: "Change password",
  settings: "Settings",
  update_profile_success: "Your account has been successfully updated.",
  update_profile_failed: "Something went wrong. Please try again.",
};

export { en_us };
