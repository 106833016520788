/**
 * The business logic for the component
 *
 * @see Account.md for details
 */

import useRequests from "../../../hooks/useRequests/useRequests";
import useSWR from "swr";

/**
 * Returns an array of Profile Roles
 * - The API returns only booleans which has to be transformed into strings
 */
const getRoles = (props) => {
  const { isManufacturer, isOwner } = props;

  let roles = ["Profile Role - Normal"];

  if (isManufacturer) roles.push(["Profile Role - Local market"]);
  if (isOwner) roles.push(["Profile Role - Stay"]);

  return roles;
};

export const useAccountInfo = () => {
  const { get } = useRequests();
  return useSWR("account/profile/info", get);
};

export const useRequestRoleInfo = () => {
  const { get } = useRequests();
  return useSWR("account/request-role/info", get);
};

export const useProfileSwr = () => {
  const { get } = useRequests();
  return useSWR("account/profile", get);
};

export const useAccount = () => {
  const { put, post } = useRequests();

  const updateUserSettings = (data) => {
    return put("account/profile", data);
  };

  const updateLanguage = (language) => {
    return put("account/language", { language: language });
  };

  const requestRole = (data) => {
    return post("account/request-role", data);
  };

  return {
    updateUserSettings,
    updateLanguage,
    requestRole,
  };
};

export { getRoles };
