/**
 * Component short description
 *
 * @see Checkout.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { Redirect, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

/**
 * Imports other components and hooks
 */
import { TextSection } from "../../Theme";
import { useAuth } from "../../../hooks";
import { useCart } from "../../_cart/Cart/Cart.logic";
import { useCheckout } from "./Checkout.logic";
import Layout from "../../Layout";
import Card2 from "../../Card2/Card2";
import { PageHeader } from "../../Theme/Theme";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";
import OrderForCheckout from "../../_account/OrderForCheckout/OrderForCheckout";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Checkout.data";
import { useOrder } from "../../_account/Order/Order.logic";

/**
 * Imports Material UI components
 */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Checkout.lang.ro-ro";
import { hu_hu } from "./Checkout.lang.hu-hu";
import { en_us } from "./Checkout.lang.en-us";
import { de_de } from "./Checkout.lang.de-de";
import useCartItems from "../../../hooks/useCartItems/useCartItems";

i18n.addResourceBundle("ro-RO", "Checkout", ro_ro);
i18n.addResourceBundle("hu-HU", "Checkout", hu_hu);
i18n.addResourceBundle("en-US", "Checkout", en_us);
i18n.addResourceBundle("de-DE", "Checkout", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
  requestButton: {
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "70px",
  },
}));

/**
 * Displays the component
 */
const Checkout = (props) => {
  const { description: defaultDescription } = props;
  const { container, requestButton } = useStyles(props);
  const { t } = useTranslation("Checkout");
  const { language } = i18n;
  const { search } = useLocation();

  const [isSameAsPersonalInfo, setIsSameAsPersonalInfo] = useState(false);
  const [personalPhone, setPersonalPhone] = useState("");
  const [deliveryPhone, setDeliveryPhone] = useState("");

  const { useCartSwr } = useCart();
  const { updateOrders } = useOrder();
  const { useGetCheckoutSwr } = useCheckout();
  const { enqueueSnackbar } = useSnackbar();
  const { getCheckoutFormSchema } = useValidationSchemas();
  const { data, mutate: mutatedCart } = useCartSwr();
  const {
    data: checkoutInfo,
    mutate: mutatedCheckoutInfo,
  } = useGetCheckoutSwr();

  const { updateCart } = useCartItems();

  const sellerId = search.split("sellerId=").pop();
  const description = checkoutInfo
    ? checkoutInfo.description
    : defaultDescription;
  const cart = data
    ? data.find((item) => item.sellerId === Number(sellerId))
    : null;

  useEffect(() => {
    mutatedCart();
    mutatedCheckoutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const { register, errors, handleSubmit, formState, reset } = useForm({
    validationSchema: getCheckoutFormSchema(isSameAsPersonalInfo),
    reValidateMode: "onChange",
  });

  const { isValid, isSubmitting } = formState;
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) return <Redirect to="/" />;

  const cardWithMedia1 = {
    image: null,
    content: <TextSection description={description} />,
  };

  const isNumberKey = (event, delivery) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      delivery ? setDeliveryPhone(value) : setPersonalPhone(value);
    }
  };

  const cardContent2 = (
    <Grid container item xs={12} md={6} spacing={2} direction="column">
      <Grid item xs={12}>
        <Typography variant="h5">{t("personal_info")}</Typography>
      </Grid>
      <Grid item>
        <TextField
          type="text"
          inputRef={register}
          fullWidth
          multiline
          name="personalName"
          variant="outlined"
          label={t("personal_name")}
          error={Boolean(errors.personalName)}
          helperText={errors.personalName?.message}
        />
      </Grid>
      <Grid item>
        <TextField
          type="text"
          inputRef={register}
          fullWidth
          multiline
          name="personalAddress"
          variant="outlined"
          label={t("personal_address")}
          error={Boolean(errors.personalAddress)}
          helperText={errors.personalAddress?.message}
        />
      </Grid>
      <Grid item>
        <TextField
          InputProps={{ inputProps: { maxLength: 20 } }}
          onChange={isNumberKey}
          value={personalPhone}
          type="text"
          inputRef={register}
          fullWidth
          name="personalPhone"
          variant="outlined"
          label={t("personal_phone")}
          error={Boolean(errors.personalPhone)}
          helperText={errors.personalPhone?.message}
        />
      </Grid>
    </Grid>
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2,
  };

  const handleIsSameAsPersonalInfo = (event) => {
    setIsSameAsPersonalInfo(event.target.checked);
  };

  const cardContent3 = (
    <Grid container xs={12} md={6} spacing={2} direction="column">
      <Grid item>
        <Grid item>
          <Typography variant="h5">{t("delivery_info")}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="isSameAsPersonalInfo"
                  onChange={handleIsSameAsPersonalInfo}
                />
              }
              label={t("is_same_as_personal_info")}
            />
          </FormControl>
        </Grid>
      </Grid>
      {!isSameAsPersonalInfo && (
        <Grid container item xs={12} md={6} spacing={2} direction="column">
          <Grid item>
            <TextField
              type="text"
              inputRef={register}
              fullWidth
              multiline
              name="deliveryName"
              variant="outlined"
              label={t("delivery_name")}
              error={Boolean(errors.deliveryName)}
              helperText={errors.deliveryName?.message}
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              inputRef={register}
              fullWidth
              multiline
              name="deliveryAddress"
              variant="outlined"
              label={t("delivery_address")}
              error={Boolean(errors.deliveryAddress)}
              helperText={errors.deliveryAddress?.message}
            />
          </Grid>
          <Grid item>
            <TextField
              InputProps={{ inputProps: { maxLength: 20 } }}
              onChange={(e) => isNumberKey(e, true)}
              value={deliveryPhone}
              type="text"
              inputRef={register}
              fullWidth
              name="deliveryPhone"
              variant="outlined"
              label={t("delivery_phone")}
              error={Boolean(errors.deliveryPhone)}
              helperText={errors.deliveryPhone?.message}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3,
  };

  const handleFormSubmit = (formData) => {
    const {
      personalPhone: formPersonalPhone,
      personalName,
      personalAddress,
    } = formData;
    if (isSameAsPersonalInfo) {
      formData.deliveryName = personalName;
      formData.deliveryPhone = formPersonalPhone;
      formData.deliveryAddress = personalAddress;
    }

    updateOrders(formData).then(() => {
      reset({});
      updateCart();
      setPersonalPhone("");
      setDeliveryPhone("");
      enqueueSnackbar(t("request_sent_successfully"), { variant: "success" });
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Layout header={{ currentPage: "Cart" }}>
        <Grid container spacing={2} className={clsx(container, "Checkout")}>
          <Grid item xs={12}>
            <PageHeader title={t("checkout")} />
          </Grid>
          <Grid item xs={12}>
            <Card2 {...cardWithMedia1} />
          </Grid>
          {cart && (
            <>
              <Grid item xs={12}>
                <Card2 {...cardWithMedia2} />
              </Grid>
              <Grid item xs={12}>
                <Card2 {...cardWithMedia3} />
              </Grid>
              <Grid item xs={12}>
                <OrderForCheckout
                  {...cart}
                  items={cart.products}
                  seller={cart.sellerName}
                />
              </Grid>
              <Grid container item xs={12} className={clsx(requestButton)}>
                <Grid item xs={12} md={2}>
                  <Button
                    disabled={!isValid && isSubmitting}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                  >
                    {t("send_request")}
                  </Button>
                  <TextField
                    type="hidden"
                    inputRef={register}
                    value={cart.sellerId}
                    name="sellerId"
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Layout>
    </form>
  );
};

Checkout.propTypes = propTypes;
Checkout.defaultProps = defaultProps;

export default Checkout;
export { propTypes as CheckoutPropTypes, defaultProps as CheckoutDefaultProps };
