const en_us = {
  location_categories: "Location categories",
  pdf: "Download PDF",
  gps: "GPS",
  transportation: "Transportation",
  show_more: "Show more",
  show_less: "Show less",
  videos: "Videos",
  weather: "Weather",
  webcam: "WebCam",
};

export { en_us };
