/**
 * Component short description
 *
 * @see useRequests.md for details
 */

/**
 * Imports React and third party packages
 */
import queryString from "query-string";
import { useHistory } from "react-router-dom";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useRequests.data";
import { useSnackbar } from "notistack";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./useRequests.lang.ro-ro";
import { hu_hu } from "./useRequests.lang.hu-hu";
import { en_us } from "./useRequests.lang.en-us";
import { de_de } from "./useRequests.lang.de-de";
import { isEmpty } from "lodash";
import { useAuth } from "../useAuth/useAuth";

i18n.addResourceBundle("ro-RO", "useRequests", ro_ro);
i18n.addResourceBundle("hu-HU", "useRequests", hu_hu);
i18n.addResourceBundle("en-US", "useRequests", en_us);
i18n.addResourceBundle("de-DE", "useRequests", de_de);

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const parseLanguage = (locale) => {
  const [lng] = locale.split("-");
  return lng;
};

const stringifyParams = (params) => {
  return queryString.stringify(params, { arrayFormat: "comma" });
};

const useRequests = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("useRequests");
  const history = useHistory();

  const { token } = useAuth();
  const handleNetworkError = () => {
    enqueueSnackbar(t("network_error"), { variant: "error" });
  };

  const showErrorMessageIfAny = (err) => {
    if (err?.message) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const checkResponse = async (res) => {
    if (!history || history?.location?.pathname.includes("/errorPage")) {
      return;
    }

    if (res && res.status === 404) {
      history.replace("/errorPage");
    }

    if (res && res.status === 500) {
      history.replace({
        pathname: "/errorPage",
        search: "?isServerError=true",
      });
    }

    if (res && res.status >= 200 && res.status < 300) {
      return res.json();
    } else {
      let err;
      try {
        err = await res.json();
        showErrorMessageIfAny(err);
      } catch (e) {
        err = res;
      }

      throw err;
    }
  };

  const get = async (path, params = {}, customHeaders = {}) => {
    const queryParams = isEmpty(params) ? "" : `?${stringifyParams(params)}`;
    const res = await fetch(`${apiUrl}${path}${queryParams}`, {
      headers: {
        "Accept-Language": parseLanguage(i18n.language),
        Authorization: `Bearer ${token}`,
        ...customHeaders,
      },
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    const response = checkResponse(res);
    return response;
  };

  const post = async (path, body, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        Authorization: `Bearer ${token}`,

        ...customHeaders,
      },
      body: JSON.stringify(body),
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });

    return checkResponse(res);
  };

  const put = async (path, body = {}, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        Authorization: `Bearer ${token}`,

        ...customHeaders,
      },
      body: JSON.stringify(body),
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    const response = checkResponse(res);
    return response;
  };

  const deleteReq = async (path, customHeaders = {}) => {
    const res = await fetch(`${apiUrl}${path}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": parseLanguage(i18n.language),
        Authorization: `Bearer ${token}`,

        ...customHeaders,
      },
    }).catch((error) => {
      console.error(error);
      handleNetworkError();
    });
    const response = checkResponse(res);
    return response;
  };

  return { get, post, put, deleteReq };
};

useRequests.propTypes = propTypes;
useRequests.defaultProps = defaultProps;

export default useRequests;
export { useRequests, stringifyParams };
