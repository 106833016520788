const hu_hu = {
  Stay: "szállás",
  "Privacy Policy": "Adatvédelmi irányelvek",
  Contact: "Kapcsolatba lépni",
  Destinations: "Úticélok",
  FAQ: "GYIK",
  Sitemap: "Webhelytérkép",
  "Media & Press": "Média és sajtó",
  Home: "Főoldal",
  "Local Market": "Helyi piac",
  News: "hírek",
  Support: "Támogatás",
  "Terms & Conditions": "Felhasználási feltételek",
};

export { hu_hu };
