const hu_hu = {
  required: "kötelező mező",
  max_number: "Érvénytelen mennyiség",
  emails_do_not_match: "az e-mailek nem egyeznek",
  emails_match: "Az új e-mail nem lehet régi e-mail",
  password_min_length: "A jelszónak legalább 6 karakterből kell állnia",
  passwords_not_match: "A jelszavak nem egyeznek",
  min_characters: "Legalább 3 karakter szükséges",
  positive_number: "Pozitív számnak kell lennie",
  email_invalid: "Kérjük valós e-mail címet adjon meg",
  phone_invalid: "Adjon meg egy érvényes telefonszámot",
};

export { hu_hu };
