/**
 * Defines the data requirements for the component
 *
 * @see Stay.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

import {
  StaySearchPropTypes,
  StaySearchDefaultProps,
} from "../StaySearch/StaySearch";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * Props from the API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Stay/getStayInfo
   */
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  staySearch: PropTypes.shape(StaySearchPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  image: ImageResponsivePlaceholderCom169,
  staySearch: StaySearchDefaultProps,
};

export { propTypes, defaultProps };
