/**
 * Component short description
 *
 * @see CategoriesAsCarousel.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
/**
 * Imports other components and hooks
 */
import TabPanel from "../../TabPanel";
import Card2, { Card2LocalMarketAsHeroStylesInverted } from "../../Card2";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./CategoriesAsCarousel.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./CategoriesAsCarousel.lang.ro-ro";
import { hu_hu } from "./CategoriesAsCarousel.lang.hu-hu";
import { en_us } from "./CategoriesAsCarousel.lang.en-us";
import { de_de } from "./CategoriesAsCarousel.lang.de-de";
import DestinationAsHero from "../../_destination/DestinationAsHero";
import { useDestinationsCategoriesSwr } from "../../_destination/Destination/Destination.logic";
import { useUpdateEffect } from "../../../hooks";

i18n.addResourceBundle("ro-RO", "CategoriesAsCarousel", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoriesAsCarousel", hu_hu);
i18n.addResourceBundle("en-US", "CategoriesAsCarousel", en_us);
i18n.addResourceBundle("de-DE", "CategoriesAsCarousel", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  tabs: {
    marginBottom: theme.spacing(2),
  },

  imageContainer: {
    order: 2,

    [theme.breakpoints.up("xs")]: {
      order: 1,
    },
  },
}));

/**
 * Displays the component
 */
const CategoriesAsCarousel = (props) => {
  const { container, tabs: tabsClass, panels: panelsClass } = useStyles(props);
  const { t } = useTranslation("CategoriesAsCarousel");
  const { language } = i18n;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { data, mutate } = useDestinationsCategoriesSwr();
  let categories = data || [];

  useUpdateEffect(() => {
    mutate();
  }, [language]);

  /**
   * Manages the state of the active tab
   */
  const activeCategory = categories[activeTabIndex];
  const imageArray = activeCategory && activeCategory.image ? [activeCategory.image] : [];

  /**
   * Manages to click on tab labels
   */
  const handleTabClick = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  /**
   * Displays the tab labels
   */
  const labels = categories.map((category) => {
    const { name, id } = category;
    return <Tab label={t(name)} key={id} />;
  });

  /**
   * Prepares the panel content
   */
  const cardWithMedia = {
    image: null,
    content: <DestinationAsHero imageWidth={640} {...activeCategory} images={imageArray} showCategories={false} />,
    customStyles: Card2LocalMarketAsHeroStylesInverted({
      ...props,
      breakpoint: "sm",
    }),
    cardProps: { style: { padding: 0 } },
    cardContainerProps: { alignItems: "center" },
  };

  if (!categories.length) {
    return null;
  }

  /**
   * Displays the tab panels
   */
  const panels = categories.map((category, index) => {
    const { id } = category;

    return (
      <TabPanel
        currentTabIndex={index}
        activeTabIndex={activeTabIndex}
        key={id}
      >
        <Card2 {...cardWithMedia} />
      </TabPanel>
    );
  });

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "CategoriesAsCarousel")}
    >
      <Grid item xs={12} key={shortid.generate()}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabClick}
          variant="scrollable"
          scrollButtons="auto"
          className={clsx(tabsClass, "Tabs")}
        >
          {labels}
        </Tabs>
      </Grid>
      <Grid item xs={12} key={shortid.generate()}>
        <div className={clsx(panelsClass, "Panels")}>{panels}</div>
      </Grid>
    </Grid>
  );
};

CategoriesAsCarousel.propTypes = propTypes;
CategoriesAsCarousel.defaultProps = defaultProps;

export default CategoriesAsCarousel;
export {
  propTypes as CategoriesAsCarouselPropTypes,
  defaultProps as CategoriesAsCarouselDefaultProps,
};
