const de_de = {
  admin: "Admin",
  order_history: "Bestellverlauf",
  your_account: "Ihr Konto",
  booking_history: "Buchungsverlauf",
  settings: "die Einstellungen",
  request_role: "Rolle anfordern",
  profile_role: "Profilrolle",
  role: "Rolle",
  seller: "Verkäufer",
  host: "Gastgeber",
  description: "Konto-Erstellung",
};

export { de_de };
