const de_de = {
  category: "Kategorie",
  destination: "Ziel",
  extras: "Extras",
  clear_all: "Alles löschen",
  room_type: "Zimmertyp",
  accomodation_type: "Art der Unterkunft",
  seller: "Verkäufer",
};

export { de_de };
