const en_us = {
  home: "Home",
  admin: "Admin",
  search: "Search",
  stay: "Stay",
  privacy: "Privacy Policy",
  account: "Account",
  contact: "Contact",
  cart: "Cart",
  destinations: "Destinations",
  events: "Events",
  faq: "Faq",
  sign_out: "Sign out",
  order_history: "Order History",
  sign_in: "Sign in",
  news: "News",
  local_market: "Local Market",
  terms: "Terms & Conditions",
  language: "Language",
};

export { en_us };
