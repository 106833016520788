const ro_ro = {
  Events: "Evenimente",
  Search: "Căutare",
  Cart: "Coş cumpărături",
  Settings: "Setări",
  "Booking History": "Istoric rezervări cazare",
  "Order History": "Istoric comenzi",
  "Sign out": "Ieşire cont",
};

export { ro_ro };
