/**
 * The business logic for the component
 *
 * @see AccountRoles.md for details
 */

import shortid from "shortid";
import { has } from "lodash";
import i18n from "../../../i18n";

/**
 * Returns an array of Profile Roles and links
 * - The API returns only booleans which has to be transformed into menu items
 */
const getRoles = (props) => {
  const { isHost, isSeller, isLocalAdmin, isSuperAdmin } = props;
  let token = localStorage.getItem('localStorageKeyToken');
  let roles = [];
  if (!token) {
    return roles;
  }
  token = token.replace('"', '');

  if (isSuperAdmin) {
    roles.push({
      id: shortid.generate(),
      name: i18n.t("AccountRoles:super_admin_role"),
      url: `${process.env.REACT_APP_ADMIN}?token=${token}`,
    });
  }

  if (isLocalAdmin) {
    roles.push({
      id: shortid.generate(),
      name: i18n.t("AccountRoles:local_admin_role"),
      url: `${process.env.REACT_APP_ADMIN}?token=${token}`,
    });
  }
  if (isSeller)
    roles.push({
      id: shortid.generate(),
      name: i18n.t("AccountRoles:seller_role"),
      url: `${process.env.REACT_APP_ADMIN}?token=${token}&to=/market/products`,
    });

  if (isHost)
    roles.push({
      id: shortid.generate(),
      name: i18n.t("AccountRoles:host_role"),
      url: `${process.env.REACT_APP_ADMIN}?token=${token}&to=/stay/accommodations?token=${token}`,
    });

  if (!isLocalAdmin && !isSuperAdmin && !isHost && !isSeller) {
    roles.push({
      id: shortid.generate(),
      name: i18n.t("AccountRoles:normal_role"),
      url: i18n.t("Routes:account"),
    });
  }

  return roles;
};

/**
 * Returns an array of external Profile Roles and links
 */
const getExternalRoles = (props) => {
  const roles = getRoles(props);
  return roles.filter((item) => has(item, "url"));
};

/**
 * Return the internal Profile Role
 */
const getInternalRole = (props) => {
  const roles = getRoles(props);
  return roles.filter((item) => has(item, "path")).shift();
};

export { getRoles, getExternalRoles, getInternalRole };
