export function getGoogleMapsFormattedData(data) {
  const newData = JSON.parse(JSON.stringify(data))
  const { geometries } = newData;
  if (!geometries) {
    return newData;
  }

  geometries.forEach((item) => {
    const {coordinates, type} = item;
    if ("Polygon" !== type) {
      return;
    }

    const cleanCoordinates = coordinates[0];
    const firstItem = cleanCoordinates[0];
    const lastItem = cleanCoordinates[cleanCoordinates[0].length-1];

    if (JSON.stringify(firstItem) !== JSON.stringify(lastItem)) {
      item.coordinates[0].push(firstItem)
    }
  });

  return newData;
}