/**
 * Component short description
 *
 * @see GoogleAnalytics.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Helmet } from "react-helmet";
/**
 * Imports other components and hooks
 */
import useConsent from "../../hooks/useConsent/useConsent";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./GoogleAnalytics.data";
import { GOOGLE_ANALYTICS_API_KEY } from "../../constants/envVars";

/**
 * Displays the component
 */
const GoogleAnalytics = () => {
  const { consent } = useConsent();

  if (!consent?.analytics) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_API_KEY}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag("js", new Date());
      gtag('config', ${GOOGLE_ANALYTICS_API_KEY});`}
      </script>
    </Helmet>
  );
};

GoogleAnalytics.propTypes = propTypes;
GoogleAnalytics.defaultProps = defaultProps;

export default GoogleAnalytics;
export {
  propTypes as GoogleAnalyticsPropTypes,
  defaultProps as GoogleAnalyticsDefaultProps,
};
