const ro_ro = {
  home_title: 'Atractii turistice regiunea Giurgeu | Pineyard.ro',
  home_description:
    'Regiunea Giurgeu cuprinde staţiunea Borsec şi comunele: Tulgheş, Corbu, Ditrău, Remetea, Lăzarea, Joseni, Ciumani, Suseni şi Voşlăbeni | Află mai multe pe pineyard.ro',
  home_keywords: 'Pineyard,Giurgeu,regiunea Giurgeu,atractii turistice Giurgeu,Harghita,Borsec,cazare munte',
  destinations_title: 'Cele mai importante obiective turistice regiunea Giurgeu | Pineyard.ro',
  destinations_description:
    'Regiunea celor nouă localităţi rurale şi a unei staţiuni, dispune de o sursă nesfârșită de obiective turistice naturale care merită văzute și apreciate | Pineyard.ro',
  destinations_keywords: 'obiective turistice Giurgeu,destinatii turistice Harghita,Borsec,Corbu,Ciumani',
  ditrau_title: 'Localitatea Ditrău se află în partea centrală a bazinului Giurgeului | Pineyard.ro',
  ditrau_description:
    'La masivul alcalin de la Ditrău a fost descris pentru prima oară sienitul cu nefelin şi sodalit, care poartă denumirea de DITROIT | Pineyard.ro',
  ditrau_keywords: 'Ditrau,comuna Ditrau,cazare Ditrau,vremea Ditrau',
  corbu_title: 'Comuna Corbu este localizată între munții Bistriței și munții Giurgeului | Pineyard.ro',
  corbu_description:
    'Comuna Corbu vă întâmpină cu mai multe zone de cazare și un frumos peisaj montan pe care îl puteţi străbate vara pe cele 12 trasee de drumeţie | Pineyard.ro',
  corbu_keywords: 'Corbu,cazare Corbu,primaria Corbu, vremea Corbu,pensiuni Corbu',
  ciumani_title: 'Ciumani se află într-o regiune etno-culturală din estul Transilvaniei | Pineyard.ro',
  ciumani_description:
    'Cei care vizitează comuna Ciumani, sunt invitati să admire panorama pistei de schi și să odihnească într-un ciubăr cu apă sărată sub stele | Pineyard.ro',
  ciumani_keywords: 'Ciumani,vremea Ciumani,cazare Ciumani,pensiuni Ciumani',
  remetea_title: 'Remetea este una dintre comunele mari ale judeţului Harghita | Pineyard.ro',
  remetea_description:
    'Remetea este idilic aşezată, vis-a-vis de Ditrău, pe malul stâng, mai înalt al Mureşului, în valea pârâului Kőpatak | Pineyard.ro',
  remetea_keywords: 'Remetea,vremea Remetea,primaria Remetea,comuna Remetea,cazare Remetea',
  joseni_title: 'Joseni este o comună din județul Harghita, România | Pineyard.ro',
  joseni_description:
    'Joseni înseamnă „Satul de jos" și este o regiune etno-culturală din estul Transilvaniei, compusă din trei sate | Pineyard.ro',
  joseni_keywords: 'Joseni,vremea Joseni,Joseni Harghita,cazare Joseni',
  lazarea_title: 'Comuna Lăzarea este una dintre cele mai vechi aşezări ale depresiunii Gurghiului | Pineyard.ro',
  lazarea_description:
    'Comuna Lăzarea, are primele însemnări incă din anul 1200 și are patru monumente importante, fiecare reprezentând o altă epocă istorică | Pineyard.ro',
  lazarea_keywords: 'Lazarea,comuna Lazarea,Lazarea Harghita,cazare Lazarea Harghita',
  tulghes_title: 'Tulgheș este așezare predominant românească în partea de nord-est a județului Harghita | Pineyard.ro',
  tulghes_description:
    'Tulgheș, pune la dispoziția vizitatorilor atracții turistice naturale și culturale, servicii de distracție și agrement și nu în ultimul rând servicii de cazare | Pineyard.ro',
  tulghes_keywords: 'Tulghes,vremea Tulghes,cazare Tulghes,Tulghes Toplita,primaria Tulghes',
  borsec_title: 'Borsecul se situează la interferența munților Giurgeului, Bistriței și Călimani | Pineyard.ro',
  borsec_description:
    'Cea mai mare bogăție a Borsecului sunt numeroasele izvoare de apă minerală, premiile obținute la diverse târguri internaţionale sunt expuse la Muzeul Apelor Minerale | Pineyard.ro',
  borsec_keywords: 'Borsec,cazare Borsec,partia Borsec,statiunea Borsec,vremea Borsec,webcam Borsec',
  suseni_title: 'Formarea localității Suseni poate fi estimată cam în jurul anului 1330 | Pineyard.ro',
  suseni_description:
    'Localitatea cu denumira Katorzsa situată la marginea comunei Suseni poate fi considerată ca una dintre cele mai vechi așezări ale Depresiunii Giurgeului | Pineyard.ro',
  suseni_keywords: 'Suseni,Suseni Harghita,cazare in Suseni,atractii turistice Suseni Harghita',
  voslabeni_title: 'Comuna Voşlăbeni este compusă din satul Voşlăbeni şi satul Izvoru Mureşului | Pineyard.ro',
  voslabeni_description:
    'Satul Voşlăbeni situat in nordul Izvorului Mureş, este o localitate tipică de munte cu oameni paşnici şi muncitori | Pineyard.ro',
  voslabeni_keywords: 'Voslabeni,vremea in Voslabeni,Voslabeni Harghita,primaria Voslabeni Harghita',
  local_market_title:
    'Această secțiune a site-ului vă oferă posibilitatea să cumpărați online produse locale | Pineyard.ro',
  local_market_description:
    'Prin intermediul acestei pagini web, vă sunt prezentate produsele puse la vânzare de către producătorii locali din Regiunea Giurgeu | Pineyard.ro',
  local_market_keywords:
    'Produse locale jud Harghita,produse locale Giurgeu,cumpara produse locale,producatori locali Giurgeu',
  stay_title: 'Regiunea Giurgeu oferă servicii de cazare de calitate, de două, trei și patru stele | Pineyard.ro',
  stay_description:
    'Prin intermediul paginii noastre puteți alege cazare la munte, la hotel, pensiune, vilă, hostel, cabană sau camping în regiunea Giurgeu | Pineyard.ro',
  stay_keywords: 'Cazare Giurgeu,cazare jud Harghita,cazare munte,pensiune cu piscina Harghita,cazare munte piscina',
  news_title: 'Știri și noutăți din zona regiunii Giurgeului | Pineyard.ro',
  news_description:
    'Află ultimele noutăți din zonă, indiferent dacă este vorba de oferte de cazare la munte sau cele mai recente produse locale din regiunea Giurgeului | Pineyard.ro',
  news_keywords: 'Stiri judetul Harghita,cazare Harghita,stiri Giurgeu,primaria Giurgeu',
  contact_title: 'Contactați-ne cu încredere, echipa noastră vă stă la dispoziție 24 de ore | Pineyard.ro',
  contact_description:
    'Dacă aveți vreo nelămurire sau întrebare legată de informațiile împărtășite pe site-ul nostru, nu ezitați să ne contactați | Pineyard.ro',
  contact_keywords: 'Trimite mesaj,contact Pineyard',
  events_title: 'Cele mai importante evenimente din zona regiunii Giurgeului | Pineyard.ro',
  events_description:
    'V-am adunat la un loc cele mai importante evenimente din regiunea Giurgeu, spectacole, târguri, inaugurări etc. | Pineyard.ro',
  events_keywords: 'Evenimente Harghita,evenimente jud Harghita,evenimente Giurgeu,concerte Harghita',
};

export { ro_ro };
