/**
 * Displays the logo
 *
 * @see Logo.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Logo.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import SvgIcon from "@material-ui/core/SvgIcon";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Logo.lang.ro-ro";
import { hu_hu } from "./Logo.lang.hu-hu";
import { en_us } from "./Logo.lang.en-us";
import { de_de } from "./Logo.lang.de-de";

i18n.addResourceBundle("ro-RO", "Logo", ro_ro);
i18n.addResourceBundle("hu-HU", "Logo", hu_hu);
i18n.addResourceBundle("en-US", "Logo", en_us);
i18n.addResourceBundle("de-DE", "Logo", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  logo: {
    /**
     * Limits the width of the logo
     */
    display: "flex",
    maxWidth: "12.5em",

    [theme.breakpoints.up("sm")]: {
      maxWidth: "15em",
    },
  },

  icon: {
    /**
     * Overwrites SvgIcon defaults
     */
    width: "100%",
    height: "auto",
  },
}));

/**
 * Displays the component
 */
const Logo = (props) => {
  const { svg, svgWhite, isWhite, url } = props;
  const { logo, icon } = useStyles(props);

  return (
    <Link component={RouterLink} to={url} className={clsx(logo, "Logo")}>
      <SvgIcon
        htmlColor="white"
        component={isWhite ? svgWhite : svg}
        viewBox="0 0 505.10002 78.499998"
        className={clsx(icon, "Icon")}
      />
    </Link>
  );
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
export { propTypes as LogoPropTypes, defaultProps as LogoDefaultProps };
