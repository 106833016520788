import * as React from "react";
import LogoWhiteSvg from "./py_logo_horizontal_white.svg";

const logoGreen = (props) => {
  return (
    <svg
      id="py_logo_horizontal_color-cropped_svg__Layer_1"
      x={0}
      y={0}
      xmlSpace="preserve"
      width={505.1}
      height={78.5}
      {...props}
    >
      <style type="text/css" id="style2">
        {".py_logo_horizontal_color-cropped_svg__st0{fill:#005837}"}
      </style>
      <g
        id="py_logo_horizontal_color-cropped_svg__g32"
        transform="translate(-42.7 -350.2)"
      >
        <g id="py_logo_horizontal_color-cropped_svg__g20">
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M173.5 372.9c-1.7-.6-3.9-.9-6.7-.9H162c-1.3 0-2.5.1-3.7.1-1.1.1-2.3.2-3.6.3v34.2h5v-13.5c1.1.1 2.3.2 3.5.3 1.3.1 2.7.1 4.1.1 2.6 0 4.7-.3 6.3-.9 1.6-.6 2.7-1.7 3.4-3.3.7-1.6 1-3.8 1-6.6s-.4-5-1.1-6.6c-.6-1.5-1.7-2.6-3.4-3.2zm-1 13.9c-.4 1-1.1 1.6-2 2-.9.4-2.2.6-3.8.6h-6.9v-13.3h7c1.6 0 2.8.2 3.8.6.9.4 1.6 1 2 2 .4 1 .6 2.3.6 4.1-.1 1.7-.3 3.1-.7 4z"
            id="py_logo_horizontal_color-cropped_svg__path4"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            id="py_logo_horizontal_color-cropped_svg__rect6"
            fill="#005837"
            d="M209.1 372.4h5v34.2h-5z"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M269.1 396.2v2.6c0 .9.1 1.8.1 2.7h-.8c-.4-.9-.8-1.7-1.2-2.6-.4-.9-.8-1.7-1.2-2.4L255.3 374c-.2-.5-.5-.9-.8-1.1-.3-.3-.8-.4-1.3-.4H249c-1.3 0-1.9.6-1.9 1.9v32.3h4.9V382v-2.4c0-.8-.1-1.5-.1-2.3h.7c.2.7.5 1.5.9 2.4.4.8.7 1.6 1 2.3l11.2 23.1c.2.5.5.9.8 1.1.3.3.8.4 1.3.4h4.3c1.2 0 1.8-.6 1.8-1.9v-32.3h-4.8z"
            id="py_logo_horizontal_color-cropped_svg__path8"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M312.4 377.2c.5-.5 1.2-.7 2.2-.7h13.6l.2-3.8c-1.5-.1-3.1-.2-4.8-.2-1.7 0-3.4-.1-5.1-.1h-5.1c-2 0-3.7.5-4.9 1.6-1.2 1.1-1.8 2.6-1.9 4.5v22.1c0 1.9.7 3.3 1.9 4.4 1.2 1.1 2.8 1.7 4.9 1.7h5.1c1.7 0 3.4 0 5.1-.1 1.7 0 3.3-.1 4.8-.2l-.2-3.8h-13.6c-1 0-1.7-.3-2.2-.8-.5-.5-.7-1.3-.7-2.4v-8.9h14.6v-3.8h-14.6v-7.1c0-1.1.2-1.9.7-2.4z"
            id="py_logo_horizontal_color-cropped_svg__path10"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M378.4 372.4l-6.9 14.1c-.3.6-.5 1.2-.8 1.9-.2.7-.5 1.3-.7 2h-.3c-.2-.7-.5-1.3-.7-2-.2-.7-.5-1.3-.8-2l-6.9-14.1H356l11.3 22.3v11.9h5v-11.8l11.3-22.4h-5.2z"
            id="py_logo_horizontal_color-cropped_svg__path12"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M425.4 372.8c-.3-.2-.7-.4-1.1-.4h-5.1c-.4 0-.8.1-1.1.4-.3.2-.6.6-.7 1l-10.8 32.9h5.1l3.2-10.4h13.6l3.2 10.4h5.2l-10.8-32.9c-.2-.4-.4-.8-.7-1zm-9.2 19.3l4-13c.2-.5.3-1 .4-1.5.1-.5.3-1 .4-1.4h1.4c.1.4.3.9.4 1.4.1.5.3 1 .4 1.5l4 13z"
            id="py_logo_horizontal_color-cropped_svg__path14"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M486.5 393.3c-.5-.7-1-1.2-1.6-1.6-.6-.4-1.1-.7-1.7-.8v-.2c1.6-.2 2.8-.6 3.7-1.3.9-.7 1.6-1.7 2-2.9.4-1.2.6-2.7.6-4.6 0-2.6-.4-4.6-1.1-6-.8-1.4-2-2.4-3.6-3-1.7-.6-3.9-.9-6.6-.9h-4.8c-1.3 0-2.6.1-3.7.1-1.1.1-2.3.2-3.6.3v34.2h4.9v-14.2h7.2c1.1 0 2 .3 2.7.8.7.5 1.2 1.4 1.6 2.7l3 10.7h5.3l-3.2-10.8c-.2-1-.6-1.8-1.1-2.5zm-4.5-5.5c-.9.3-2.2.5-3.8.6h-7v-12.2h7c1.6 0 2.9.2 3.8.5.9.3 1.6 1 2 1.8.4.9.6 2.1.6 3.7s-.2 2.9-.6 3.8c-.4.8-1.1 1.5-2 1.8z"
            id="py_logo_horizontal_color-cropped_svg__path16"
            fill="#005837"
          />
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M547.1 381.2c-.5-2.3-1.2-4.1-2.3-5.4a9.64 9.64 0 00-4.2-2.9c-1.7-.6-3.9-.9-6.4-.9-2.4 0-4.6 0-6.6.1s-4 .2-5.8.3v34.2c1.8.1 3.7.2 5.8.3 2 .1 4.2.1 6.6.1 2.5 0 4.6-.3 6.4-.9 1.7-.6 3.1-1.6 4.2-2.9 1.1-1.3 1.8-3.1 2.3-5.4.5-2.3.7-5 .7-8.3 0-3.3-.2-6.1-.7-8.3zm-5.3 16.3c-.5 1.9-1.4 3.3-2.6 4.1-1.2.8-2.9 1.2-5 1.2s-4 0-5.7-.1c-.6 0-1.2 0-1.8-.1V376h7.4c2.1 0 3.8.4 5 1.3 1.2.8 2.1 2.2 2.6 4.2.5 1.9.8 4.6.8 8 .1 3.4-.1 6.1-.7 8z"
            id="py_logo_horizontal_color-cropped_svg__path18"
            fill="#005837"
          />
        </g>
        <g id="py_logo_horizontal_color-cropped_svg__g30">
          <g id="py_logo_horizontal_color-cropped_svg__g24">
            <path
              d="M69.3 393.8c3.6 3.6 8.3 10.8 8.3 10.8 3.9-1 1.2-4 1.2-4 4.8 1.8.6 7.2.6 7.2 1.4-.4 5.6-2.4 5.4-2.8 1.9 2.7-3.5 4.8-3.5 4.8 5 .9 10.5-.5 10.5-.5-3.4 2.5-8.2 2.8-8.2 2.8C94.4 415.9 98 410 98 410c.2 2.1-2.5 4.5-2.5 4.5 3.9-.4 8.8-3.2 8.8-3.2-1.4 3.6-9 7.3-9 7.3 11.4 1.9 15.9-3.6 15.9-3.6.8 3-4.9 9.5-4.9 9.5 15.3 0 15.9-12.7 15.9-12.7v-14.5c-19.3 13-47.3-.5-52.9-3.5z"
              id="py_logo_horizontal_color-cropped_svg__path22"
              fill="#89a527"
            />
          </g>
          <path
            className="py_logo_horizontal_color-cropped_svg__st0"
            d="M122.2 350.2s-34.8 7.6-52 .9c0 0 6.7 9.6 18.4 8.3 11.7-1.3-.9 4.1-.9 4.1s12.3 4.9 20.5-2.9c0 0-.9 4.6-9.1 9 0 0 13.1 3.6 16.8-4.8 0 0 .4 3.4-3.3 10.8 0 0 6.1.4 9.5-9.8v-15.6z"
            id="py_logo_horizontal_color-cropped_svg__path26"
            fill="#005837"
          />
          <path
            d="M60 350.5v-.1H42.7v13c1-.4 2.2-7.6 2.2-7.6 1.8 2-.8 11.3-.8 11.3 3-1.6 5.2-7.4 5.2-7.4-.2 1.5-.3 5.9-.3 10.4 0 0-4.1 4-6.3 3.8v23.7c0 .4 6.2-3.7 7.1-14.2.5 6.3 1.2 12.4 2.1 17.9 0 0-4.6 5.2-9.2 6.9v19.1s3.5-5.3 5.2-14.5c0 0 4.4 4.7 1.9 12.3 0 0 2.7-1.3 3.7-14.3 2 10.8 4.1 17.9 4.1 17.9H73c-1.8-2.6-1.8-4.6-2.2-6.2.4 0 1.8.8 4.2 4.2.5.6.7 1.4 1.2 2h5.9c-1.7-.9-2.6-4.8-2.6-4.8 1.5 1.9 4.6 2.8 6 4.8H95c0-.8.1-1.3.1-1.3.3.5.6.9.9 1.3h26.3v-.3c-27.6-.8-56.8-20.7-58.2-21.6-3.8-11.5-5-18.2-5.3-27.8.4 1.9 2.2 3.4 2.2 3.4-1.2-4 0-9.7 0-9.7 1.8 17.1 13.9 19.4 13.9 19.4-4.3-8.3 1.5-12.9 1.5-12.9-1.3 11.9 12.6 16 12.6 16-1.9-2.4-2.1-4.6-2.1-4.6 4.3 6.1 15.3 4.2 15.3 4.2-18.7-3.7-14.7-14.3-14.7-14.3 7.3 14.3 23.7 11.6 23.7 11.6-11.1-4.5-9.2-10.5-9.2-10.5 8.2 8.4 20.3 7.9 20.3 7.9v-9.9C78.4 377 61.4 352.3 60 350.5z"
            id="py_logo_horizontal_color-cropped_svg__path28"
            fill="#2e8232"
          />
        </g>
      </g>
    </svg>
  );
};

const logoWhite = (props) => {
  return (
    <svg
      id="Layer_1"
      x={0}
      y={0}
      xmlSpace="preserve"
      width={505.1}
      height={78.5}
      {...props}
    >
      <style type="text/css">{".st0{fill:#FFFFFF;}"}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M173.5,372.9c-1.7-0.6-3.9-0.9-6.7-0.9c-1.9,0-3.4,0-4.8,0c-1.3,0-2.5,0.1-3.7,0.1c-1.1,0.1-2.3,0.2-3.6,0.3
			v34.2h5v-13.5c1.1,0.1,2.3,0.2,3.5,0.3c1.3,0.1,2.7,0.1,4.1,0.1c2.6,0,4.7-0.3,6.3-0.9c1.6-0.6,2.7-1.7,3.4-3.3
			c0.7-1.6,1-3.8,1-6.6c0-2.8-0.4-5-1.1-6.6C176.3,374.6,175.2,373.5,173.5,372.9z M172.5,386.8c-0.4,1-1.1,1.6-2,2
			c-0.9,0.4-2.2,0.6-3.8,0.6c-1.9,0-3.8,0-5.7,0c-0.4,0-0.8,0-1.2,0v-13.3c2,0,4.3,0,7,0c1.6,0,2.8,0.2,3.8,0.6c0.9,0.4,1.6,1,2,2
			c0.4,1,0.6,2.3,0.6,4.1C173.1,384.5,172.9,385.9,172.5,386.8z"
          />
          <rect x="209.1" y="372.4" className="st0" width="5" height="34.2" />
          <path
            className="st0"
            d="M269.1,396.2c0,0.9,0,1.7,0,2.6c0,0.9,0.1,1.8,0.1,2.7h-0.8c-0.4-0.9-0.8-1.7-1.2-2.6
			c-0.4-0.9-0.8-1.7-1.2-2.4L255.3,374c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.3-0.8-0.4-1.3-0.4H249c-1.3,0-1.9,0.6-1.9,1.9v32.3h4.9V382
			c0-0.8,0-1.6,0-2.4c0-0.8-0.1-1.5-0.1-2.3h0.7c0.2,0.7,0.5,1.5,0.9,2.4c0.4,0.8,0.7,1.6,1,2.3l11.2,23.1c0.2,0.5,0.5,0.9,0.8,1.1
			c0.3,0.3,0.8,0.4,1.3,0.4h4.3c1.2,0,1.8-0.6,1.8-1.9v-32.3h-4.8V396.2z"
          />
          <path
            className="st0"
            d="M312.4,377.2c0.5-0.5,1.2-0.7,2.2-0.7h13.6l0.2-3.8c-1.5-0.1-3.1-0.2-4.8-0.2c-1.7,0-3.4-0.1-5.1-0.1
			c-1.7,0-3.4,0-5.1,0c-2,0-3.7,0.5-4.9,1.6c-1.2,1.1-1.8,2.6-1.9,4.5v22.1c0,1.9,0.7,3.3,1.9,4.4c1.2,1.1,2.8,1.7,4.9,1.7
			c1.7,0,3.3,0,5.1,0c1.7,0,3.4,0,5.1-0.1c1.7,0,3.3-0.1,4.8-0.2l-0.2-3.8h-13.6c-1,0-1.7-0.3-2.2-0.8c-0.5-0.5-0.7-1.3-0.7-2.4
			v-8.9h14.6v-3.8h-14.6v-7.1C311.7,378.5,311.9,377.7,312.4,377.2z"
          />
          <path
            className="st0"
            d="M378.4,372.4l-6.9,14.1c-0.3,0.6-0.5,1.2-0.8,1.9c-0.2,0.7-0.5,1.3-0.7,2h-0.3c-0.2-0.7-0.5-1.3-0.7-2
			c-0.2-0.7-0.5-1.3-0.8-2l-6.9-14.1H356l11.3,22.3v11.9h5v-11.8l11.3-22.4H378.4z"
          />
          <path
            className="st0"
            d="M425.4,372.8c-0.3-0.2-0.7-0.4-1.1-0.4h-5.1c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.2-0.6,0.6-0.7,1l-10.8,32.9h5.1
			l3.2-10.4h13.6l3.2,10.4h5.2l-10.8-32.9C425.9,373.4,425.7,373,425.4,372.8z M416.2,392.1l4-13c0.2-0.5,0.3-1,0.4-1.5
			c0.1-0.5,0.3-1,0.4-1.4h1.4c0.1,0.4,0.3,0.9,0.4,1.4c0.1,0.5,0.3,1,0.4,1.5l4,13H416.2z"
          />
          <path
            className="st0"
            d="M486.5,393.3c-0.5-0.7-1-1.2-1.6-1.6c-0.6-0.4-1.1-0.7-1.7-0.8v-0.2c1.6-0.2,2.8-0.6,3.7-1.3
			c0.9-0.7,1.6-1.7,2-2.9c0.4-1.2,0.6-2.7,0.6-4.6c0-2.6-0.4-4.6-1.1-6c-0.8-1.4-2-2.4-3.6-3c-1.7-0.6-3.9-0.9-6.6-0.9
			c-1.9,0-3.4,0-4.8,0c-1.3,0-2.6,0.1-3.7,0.1c-1.1,0.1-2.3,0.2-3.6,0.3v34.2h4.9v-14.2c0.6,0,1.1,0,1.8,0c1.5,0,3.3,0,5.4,0
			c1.1,0,2,0.3,2.7,0.8c0.7,0.5,1.2,1.4,1.6,2.7l3,10.7h5.3l-3.2-10.8C487.4,394.8,487,394,486.5,393.3z M482,387.8
			c-0.9,0.3-2.2,0.5-3.8,0.6h-7v-12.2c2,0,4.3,0,7,0c1.6,0,2.9,0.2,3.8,0.5c0.9,0.3,1.6,1,2,1.8c0.4,0.9,0.6,2.1,0.6,3.7
			c0,1.6-0.2,2.9-0.6,3.8C483.6,386.8,482.9,387.5,482,387.8z"
          />
          <path
            className="st0"
            d="M547.1,381.2c-0.5-2.3-1.2-4.1-2.3-5.4c-1.1-1.3-2.5-2.3-4.2-2.9c-1.7-0.6-3.9-0.9-6.4-0.9
			c-2.4,0-4.6,0-6.6,0.1c-2,0.1-4,0.2-5.8,0.3v34.2c1.8,0.1,3.7,0.2,5.8,0.3c2,0.1,4.2,0.1,6.6,0.1c2.5,0,4.6-0.3,6.4-0.9
			c1.7-0.6,3.1-1.6,4.2-2.9c1.1-1.3,1.8-3.1,2.3-5.4c0.5-2.3,0.7-5,0.7-8.3C547.8,386.2,547.6,383.4,547.1,381.2z M541.8,397.5
			c-0.5,1.9-1.4,3.3-2.6,4.1c-1.2,0.8-2.9,1.2-5,1.2c-2.1,0-4,0-5.7-0.1c-0.6,0-1.2,0-1.8-0.1v-26.6c0.6,0,1.3,0,1.9,0
			c1.6,0,3.5,0,5.5,0c2.1,0,3.8,0.4,5,1.3c1.2,0.8,2.1,2.2,2.6,4.2c0.5,1.9,0.8,4.6,0.8,8C542.6,392.9,542.4,395.6,541.8,397.5z"
          />
        </g>
        <g>
          <g>
            <path
              className="st0"
              d="M69.3,393.8c3.6,3.6,8.3,10.8,8.3,10.8c3.9-1,1.2-4,1.2-4c4.8,1.8,0.6,7.2,0.6,7.2c1.4-0.4,5.6-2.4,5.4-2.8
				c1.9,2.7-3.5,4.8-3.5,4.8c5,0.9,10.5-0.5,10.5-0.5c-3.4,2.5-8.2,2.8-8.2,2.8C94.4,415.9,98,410,98,410c0.2,2.1-2.5,4.5-2.5,4.5
				c3.9-0.4,8.8-3.2,8.8-3.2c-1.4,3.6-9,7.3-9,7.3c11.4,1.9,15.9-3.6,15.9-3.6c0.8,3-4.9,9.5-4.9,9.5c15.3,0,15.9-12.7,15.9-12.7
				v-14.5C102.9,410.3,74.9,396.8,69.3,393.8z"
            />
          </g>
          <path
            className="st0"
            d="M122.2,350.2c0,0-34.8,7.6-52,0.9c0,0,6.7,9.6,18.4,8.3s-0.9,4.1-0.9,4.1s12.3,4.9,20.5-2.9
			c0,0-0.9,4.6-9.1,9c0,0,13.1,3.6,16.8-4.8c0,0,0.4,3.4-3.3,10.8c0,0,6.1,0.4,9.5-9.8V350.2z"
          />
          <path
            className="st0"
            d="M60,350.5c0-0.1,0-0.1,0-0.1h-8.6h-0.7h-8v13c1-0.4,2.2-7.6,2.2-7.6c1.8,2-0.8,11.3-0.8,11.3
			c3-1.6,5.2-7.4,5.2-7.4c-0.2,1.5-0.3,5.9-0.3,10.4c0,0-4.1,4-6.3,3.8c0,0,0,23.3,0,23.7c0,0.4,6.2-3.7,7.1-14.2l0,0
			c0.5,6.3,1.2,12.4,2.1,17.9l0,0c0,0-4.6,5.2-9.2,6.9v19.1c0,0,3.5-5.3,5.2-14.5c0,0,4.4,4.7,1.9,12.3c0,0,2.7-1.3,3.7-14.3
			c2,10.8,4.1,17.9,4.1,17.9H73c-1.8-2.6-1.8-4.6-2.2-6.2c0.4,0,1.8,0.8,4.2,4.2c0.5,0.6,0.7,1.4,1.2,2h5.9
			c-1.7-0.9-2.6-4.8-2.6-4.8c1.5,1.9,4.6,2.8,6,4.8h9.5c0-0.8,0.1-1.3,0.1-1.3c0.3,0.5,0.6,0.9,0.9,1.3h26.3v-0.3
			c-27.6-0.8-56.8-20.7-58.2-21.6c-3.8-11.5-5-18.2-5.3-27.8c0.4,1.9,2.2,3.4,2.2,3.4c-1.2-4,0-9.7,0-9.7
			c1.8,17.1,13.9,19.4,13.9,19.4c-4.3-8.3,1.5-12.9,1.5-12.9c-1.3,11.9,12.6,16,12.6,16c-1.9-2.4-2.1-4.6-2.1-4.6
			c4.3,6.1,15.3,4.2,15.3,4.2c-18.7-3.7-14.7-14.3-14.7-14.3c7.3,14.3,23.7,11.6,23.7,11.6c-11.1-4.5-9.2-10.5-9.2-10.5
			c8.2,8.4,20.3,7.9,20.3,7.9v-9.9C78.4,377,61.4,352.3,60,350.5z"
          />
        </g>
      </g>
    </svg>
  );
};

export default logoWhite;
export { logoWhite, logoGreen, LogoWhiteSvg };
