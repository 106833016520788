/**
 * Component short description
 *
 * @see OngInfo.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
/**
 * Imports other components and hooks
 */
import { useUpdateEffect } from "../../../hooks";
import Card2, { Card2LocalMarketAsHeroStylesInverted } from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import { TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./OngInfo.data";
import { usePartnersInfoSwr, usePartnersSwr } from "../Home/Home.logic";
/**
 * Imports Material UI components
 */
import { Link, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OngInfo.lang.ro-ro";
import { hu_hu } from "./OngInfo.lang.hu-hu";
import { en_us } from "./OngInfo.lang.en-us";
import { de_de } from "./OngInfo.lang.de-de";

i18n.addResourceBundle("ro-RO", "OngInfo", ro_ro);
i18n.addResourceBundle("hu-HU", "OngInfo", hu_hu);
i18n.addResourceBundle("en-US", "OngInfo", en_us);
i18n.addResourceBundle("de-DE", "OngInfo", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    width: "100%",
  },
  linkStyle: {
      color: 'black !important'
  }
}));

/**
 * Displays the component
 */
const OngInfo = (props) => {
  const { container, linkStyle } = useStyles(props);
  const { partners: defaultPartners, info: defaultInfo } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation("OngInfo");

  /**
   * Fetches the partners list
   */
  const { data: partnersData, mutate: mutatePartners } = usePartnersSwr();

  /**
   * Fetches partners description and quote.
   */
  const { data: partnersInfoData, mutate: mutatePartnersInfo } = usePartnersInfoSwr();

  const partners = partnersData || defaultPartners;
  const info = partnersInfoData || defaultInfo;
  const { description, quote } = info;

  useUpdateEffect(() => {
    mutatePartners();
    mutatePartnersInfo();
  }, [language]);

  /**
   * Prepares the card content
   */
  const cardContent1 = (
    <TextSection title={t("title")} description={description} quote={quote} />
  );

  /**
   * Prepares the images for the carousel
   */
  const carouselSteps = partners
    .map((item, index) => {
      return index % 6 === 0
        ? {
            id: shortid.generate(),
            content: (
              <Grid
                container
                className={clsx(container, "CarouselMulti")}
                wrap="wrap"
                justify="space-around"
                spacing={1}
              >
                {Array.from(Array(6).keys()).map((index2) => {
                  const partner = partners[index + index2];
                  if (!partner) {
                    return null;
                  }

                  const { logo, id, url } = partner;
                  const key = id ? id : shortid.generate();

                  return (
                    <Grid item xs={4} key={key}>
                      <Link
                        className={clsx(linkStyle)}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={url}
                      >
                        <ImageResponsive {...{ ...logo, imageWidth: 320 }}/>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            ),
          }
        : null;
    })
    .filter((item) => item);

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    carousel: (
      <Carousel steps={carouselSteps} isMobileStepperAlignedRight={true} />
    ),
    customStyles: Card2LocalMarketAsHeroStylesInverted({
      ...props,
      breakpoint: "sm",
    }),
    cardContainerProps: { alignItems: "center" },
  };

  return <Card2 {...cardWithMedia1} />;
};

OngInfo.propTypes = propTypes;
OngInfo.defaultProps = defaultProps;

export default OngInfo;
export { propTypes as OngInfoPropTypes, defaultProps as OngInfoDefaultProps };
