const en_us = {
  Events: "Events",
  Search: "Search",
  Cart: "Cart",
  Settings: "Settings",
  "Booking History": "Booking History",
  "Order History": "Order History",
  "Sign out": "Sign out",
};

export { en_us };
