/**
 * Defines the data requirements for the component
 *
 * @see Gps.md for details
 */
import React from "react";
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { IconPropTypes } from "../Icon";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";

/**
 * Defines the prop types
 */
const propTypes = {
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  icon: PropTypes.shape(IconPropTypes),
  track: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  latitude: "44.44",
  longitude: "44.44",
  iconData: {
    gps: {
      icon: <RoomIcon />,
      name: 'gps',
    },
    route: {
      icon: <MapIcon />,
      name: 'track',
    }
  },
  track: null,
};

export { propTypes, defaultProps };
