/**
 * Displays a category
 *
 * @see Category.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import AttractionsList from "../../_attraction/AttractionsList";
import Attraction from "../../_attraction/Attraction";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Category.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Category.lang.ro-ro";
import { hu_hu } from "./Category.lang.hu-hu";
import { en_us } from "./Category.lang.en-us";
import { de_de } from "./Category.lang.de-de";
import {
  useDestinationCategorySwr,
  getDestinationSlugFromPath,
} from "../../_destination/Destination/Destination.logic";
import { useUpdateEffect } from "../../../hooks";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import { isEqual } from "lodash";

i18n.addResourceBundle("ro-RO", "Category", ro_ro);
i18n.addResourceBundle("hu-HU", "Category", hu_hu);
i18n.addResourceBundle("en-US", "Category", en_us);
i18n.addResourceBundle("de-DE", "Category", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Category = (props) => {
  const { destinationName } = props;
  const { container } = useStyles(props);
  const {
    i18n: { language },
  } = useTranslation("Category");
  const location = useLocation();
  const { addSlug } = useSlugs();

  /**
   * Routes to an attraction
   */
  const match = useRouteMatch();
  const { path, params } = match;
  const { slug } = params;

  const isMounted = useRef(false);
  const [category, setCategory] = useState(props);

  const { attractions, name, id } = category;
  /**
   * Fetch category
   */
  const {
    data: categoryData,
    mutate: mutateCategory,
  } = useDestinationCategorySwr({
    categoryId: slug,
    destinationId: getDestinationSlugFromPath(location.pathname),
  });

  useEffect(() => {
    isMounted.current = true;
    if (categoryData && !isEqual(categoryData, category)) {
      setCategory(categoryData);
      addSlug(categoryData.slug);
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData]);

  useUpdateEffect(() => {
    mutateCategory();
  }, [language]);

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Attraction destinationName={destinationName} />
      </Route>
      <Route path={path}>
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid container spacing={1} className={clsx(container, "Category")}>
            <Grid item xs={12}>
              <PageHeader
                breadcrumbs={<Breadcrumbs />}
                overline={destinationName}
                title={name}
              />
            </Grid>
            <Grid item xs={12}>
              <AttractionsList attractions={attractions} categoryId={id} />
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Category.propTypes = propTypes;
Category.defaultProps = defaultProps;

export default Category;
export { propTypes as CategoryPropTypes, defaultProps as CategoryDefaultProps };
