/**
 * Component short description
 *
 * @see Booking.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { TextSection, TextPrice, Actions } from "../../Theme";
import { TextDefault } from "../../Theme/Theme";
import Typography from "@material-ui/core/Typography";
import Modal from "../../Modal";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Booking.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Booking.lang.ro-ro";
import { hu_hu } from "./Booking.lang.hu-hu";
import { en_us } from "./Booking.lang.en-us";
import { de_de } from "./Booking.lang.de-de";

import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

i18n.addResourceBundle("ro-RO", "Booking", ro_ro);
i18n.addResourceBundle("hu-HU", "Booking", hu_hu);
i18n.addResourceBundle("en-US", "Booking", en_us);
i18n.addResourceBundle("de-DE", "Booking", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(() => ({
  linkStyle: {
    cursor: 'pointer'
  },
}));

/**
 * Displays the component
 */
const Booking = (props) => {
  const { name, destinationName, pricePerNight, checkIn, checkOut, image, message, numberOfRoms, numberOfGuests } = props;
  const { t } = useTranslation("Booking");
  const { linkStyle } = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Displays order details
   */
  const date = `${checkIn} - ${checkOut}`;
  const cardContent1 = <TextSection description={name} overline={destinationName} />;

  const price2 = !!pricePerNight && <TextPrice text={t("text", { price: pricePerNight })} />;
  const guestsSection = !!numberOfGuests && <TextDefault text={t('number_of_guests', { guests: numberOfGuests })} />;
  const roomsSection = !!numberOfRoms && <TextDefault text={t('number_of_rooms', { rooms: numberOfRoms })} />;
  const messageButton = !!message && (
    <Link onClick={() => setIsModalOpen(true)} className={clsx(linkStyle, "Booking")}>
      <Typography variant="body1">
        {t('view_message')}
      </Typography>
    </Link>
    )

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: price2 },
        { id: shortid.generate(), action: date },
        { id: shortid.generate(), action: guestsSection },
        { id: shortid.generate(), action: roomsSection },
        { id: shortid.generate(), action: messageButton },
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: image,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props,
    }),
  };

  return (
    <>
      <Card2 {...cardWithMedia1} />
      <Modal
        title={t('message_modal_title')}
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        maxWidth="sm"
        isCustomStyle={true}
      >
        {message}
      </Modal>
    </>
  )
};

Booking.propTypes = propTypes;
Booking.defaultProps = defaultProps;

export default Booking;
export { propTypes as BookingPropTypes, defaultProps as BookingDefaultProps };
