const de_de = {
  sold_by: "Artikel verkauft und geliefert von",
  checkout: "Zur Kasse",
  pending: "steht aus",
  total_price: "Gesamtpreis",
  delivery_price: "Lieferpreis",
  items_price: "Artikelpreis",
};

export { de_de };
