const ro_ro = {
  faq: "Faq - Întrebări frecvente",
  media: "Ştiri",
  sitemap: "Sitemap",
  support: "Contact",
  terms: "Termeni şi condiţii",
  visitors_counter: "Număr de vizitatori",
  consent: "Consimțământ",
};

export { ro_ro };
