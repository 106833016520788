const de_de = {
  location_categories: "Standortkategorien",
  pdf: "PDF Herunterladen",
  gps: "GPS",
  transportation: "Transport",
  show_more: "Zeig mehr",
  show_less: "Zeige weniger",
  videos: "Videos",
  weather: "Wetter",
  webcam: "Webcam",
};

export { de_de };
