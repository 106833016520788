/**
 * Component short description
 *
 * @see Accommodation.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import shortid from "shortid";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import Icons from "../../Icons";
import Gps from "../../Gps";
import { useStaySwr } from "../../_stay/Stay/Stay.logic";
import {
  Actions,
  PageHeader,
  Template,
  TextPrice,
  TextSection,
} from "../../Theme";
import Modal from "../../Modal/Modal";
import RequestStay from "../../_stay/RequestStay/RequestStay";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import { TextDefault } from "../../Theme/Theme";
import { useAuth } from "../../../hooks";
import Auth from "../../_account/Auth";
import FacebookShare from "../../FacebookShare/FacebookShare";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Accommodation.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Accommodation.lang.ro-ro";
import { hu_hu } from "./Accommodation.lang.hu-hu";
import { en_us } from "./Accommodation.lang.en-us";
import { de_de } from "./Accommodation.lang.de-de";

i18n.addResourceBundle("ro-RO", "Accommodation", ro_ro);
i18n.addResourceBundle("hu-HU", "Accommodation", hu_hu);
i18n.addResourceBundle("en-US", "Accommodation", en_us);
i18n.addResourceBundle("de-DE", "Accommodation", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  icons: {
    /**
     * Align opening hours etc icons to the GPS icon above
     * - horizontally the spacing does the job, here we adjust the vertical space
     */
    "& .MuiGrid-item": {
      paddingBottom: 0,
    },
  },
}));

/**
 * Displays the component
 */
const Accommodation = (props) => {
  const [accommodation, setAccommodation] = useState(props);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { addSlug } = useSlugs();

  const {
    name,
    images,
    description,
    price,
    sectionDescription1,
    destinationName,
    accommodationType,
    accommodationTypeIcon,
    accommodationRoomTypes,
    roomTypesIcon,
    accommodationExtras,
    extrasIcon,
    accommodationMeal,
    mealIcon,
    latitude,
    longitude,
    nrOfViews,
    track,
    id: accommodationId,
  } = accommodation;

  const {
    params: { slug },
  } = useRouteMatch();
  const { data: responseData } = useStaySwr(slug);

  useEffect(() => {
    if (responseData) {
      const newData = { ...accommodation, ...responseData };
      if (!isEqual(newData, accommodation)) {
        setAccommodation(newData);
        addSlug(responseData.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData]);

  const { name: accommodationTypeName } = accommodationType;
  const { container } = useStyles(props);
  const { t } = useTranslation("Accommodation");

  /**
   * Displays the hero card
   */
  const carouselSteps1 = images.map((image, index) => {
    const { title } = image;

    return {
      id: String(index),
      title: title,
      content: <ImageResponsive {...image} />,
    };
  });

  const cardContent1 = <TextSection description={description} />;

  const roomTypesList =
    accommodationRoomTypes &&
    accommodationRoomTypes.map((item) => item.name).join(", ");
  const extrasList =
    accommodationExtras &&
    accommodationExtras.map((item) => item.name).join(", ");
  const mealsList =
    accommodationMeal && accommodationMeal.map((item) => item.name).join(", ");

  const iconsList = (
    <Icons
      items={[
        { ...accommodationTypeIcon, name: accommodationTypeName, id: 1 },
        { ...roomTypesIcon, name: roomTypesList, id: 2 },
        { ...mealIcon, name: mealsList, id: 3 },
        { ...extrasIcon, name: extrasList, id: 4 },
      ]}
      orientation="vertical"
    />
  );

  const cardIcons1 = (
    <Actions
      items={[
        {
          id: 1,
          action: (
            <Actions
              items={[
                {
                  id: 1,
                  action: (
                    <Gps
                      latitude={latitude}
                      longitude={longitude}
                      track={track || null}
                    />
                  ),
                },
                { id: 2, action: <FacebookShare /> },
              ]}
            />
          ),
        },
        { id: 2, action: iconsList },
      ]}
      orientation="vertical"
    />
  );

  const { isAuthenticated } = useAuth();

  const requestStayButton = isAuthenticated ? (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => setIsModalOpen(true)}
    >
      {t("request_stay")}
    </Button>
  ) : (
    <Auth
      button={{
        variant: "contained",
        color: "primary",
        size: "large",
        startIcon: null,
      }}
      text={t("sign_in")}
    />
  );

  const priceTemplate = <TextPrice text={t("Booking:text", { price })} />;
  const cardButtons1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: priceTemplate },
        { id: shortid.generate(), action: requestStayButton },
      ]}
      orientation="vertical"
    />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: cardIcons1 },
        { id: shortid.generate(), action: cardButtons1 },
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  const cardWithMedia2 = {
    image: null,
    content: <TextDefault text={sectionDescription1} html={true}/>,
  };

  const visitorsCounterCard = nrOfViews && {
    image: null,
    content: (
      <TextDefault text={`${t("Footer:visitors_counter")}: ${nrOfViews}`} />
    ),
  };

  return (
    <Layout header={{ currentPage: "Stay" }}>
      <Grid container className={clsx(container, "Accommodation")} spacing={1}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...visitorsCounterCard} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template items={[<Card2 {...cardWithMedia2} />]} />
        </Grid>
        <Grid item xs={12}>
          <Modal
            title={t("request_stay")}
            isModalOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          >
            <RequestStay
              extras={iconsList}
              price={priceTemplate}
              subTitle={destinationName}
              description={description}
              accommodationId={accommodationId}
              setIsModalOpen={setIsModalOpen}
            />
          </Modal>
        </Grid>
      </Grid>
    </Layout>
  );
};

Accommodation.propTypes = propTypes;
Accommodation.defaultProps = defaultProps;

export default Accommodation;
export {
  propTypes as AccommodationPropTypes,
  defaultProps as AccommodationDefaultProps,
};
