/**
 * Component short description
 *
 * @see Faq.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Questions from "../Questions";
import { useUpdateEffect } from "../../../hooks";
import { useFaqInfoSwr, useFaqSwr } from "./Faq.logic";
import Card2 from "../../Card2";
import { PageHeader, TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Faq.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Faq.lang.ro-ro";
import { hu_hu } from "./Faq.lang.hu-hu";
import { en_us } from "./Faq.lang.en-us";
import { de_de } from "./Faq.lang.de-de";

i18n.addResourceBundle("ro-RO", "Faq", ro_ro);
i18n.addResourceBundle("hu-HU", "Faq", hu_hu);
i18n.addResourceBundle("en-US", "Faq", en_us);
i18n.addResourceBundle("de-DE", "Faq", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Faq = (props) => {
  const { description: defaultDescription, questions } = props;
  const { container } = useStyles(props);
  const { t, i18n } = useTranslation("Faq");
  const { language } = i18n;

  const { data: faqData, mutate: mutateFaq } = useFaqSwr();
  const { data: faqInfoData, mutate: mutateFaqInfo } = useFaqInfoSwr();

  useUpdateEffect(() => {
    mutateFaq();
    mutateFaqInfo();
  }, [language]);

  const list = faqData ?? questions.list;
  const description = faqInfoData
    ? faqInfoData.description
    : defaultDescription;

  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    actions: <Questions {...questions} list={list} />,
  };

  return (
    <Layout footer={{ currentPage: "FAQ" }}>
      <Grid container spacing={1} className={clsx(container, "FAQ")}>
        <Grid item xs={12}>
          <PageHeader title={t("title")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Faq.propTypes = propTypes;
Faq.defaultProps = defaultProps;

export default Faq;
export { propTypes as FaqPropTypes, defaultProps as FaqDefaultProps };
