const hu_hu = {
  email: "Email cím",
  submit: "Csatlakozz hozzánk",
  have_an_account: "Már van fiókja?",
  conditions_part_2: "Felhasználási és értékesítési feltételek.",
  sign_in: "Bejelentkezés",
  name: "A neved",
  password: "Jelszó",
  conditions_part_1: "Fiók létrehozásával elfogadja a Pineyard's-t",
  confirm_password: "Írd be újra a jelszót",
};

export { hu_hu };
