/**
 * Component short description
 *
 * @see ContactForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { useForm, Controller } from "react-hook-form";

/**
 * Imports other components and hooks
 */
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ContactForm.data";

/**
 * Imports Material UI components
 */
import {
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./ContactForm.lang.ro-ro";
import { hu_hu } from "./ContactForm.lang.hu-hu";
import { en_us } from "./ContactForm.lang.en-us";
import { de_de } from "./ContactForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "ContactForm", ro_ro);
i18n.addResourceBundle("hu-HU", "ContactForm", hu_hu);
i18n.addResourceBundle("en-US", "ContactForm", en_us);
i18n.addResourceBundle("de-DE", "ContactForm", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const ContactForm = (props) => {
  const { destinations, defaultValues, onSubmit } = props;
  const { t } = useTranslation("ContactForm");
  const { getContactFormSchema } = useValidationSchemas();

  const { errors, handleSubmit, reset, formState, control } = useForm({
    defaultValues,
    validationSchema: getContactFormSchema(),
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (...args) => {
    reset(defaultValues);
    onSubmit(...args);
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="destination_id"
            error={Boolean(errors.destination_id)}
            select
            fullWidth
            variant="outlined"
            helperText={errors.destination_id?.message}
            label={t("destination")}
            control={control}
          >
            {destinations.map((option) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Controller>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="name"
            variant="outlined"
            fullWidth
            label={t("name")}
            control={control}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="email"
            variant="outlined"
            fullWidth
            type="email"
            label={t("email")}
            control={control}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            name="message"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            label={t("message")}
            control={control}
            error={Boolean(errors.message)}
            helperText={errors.message?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl error={Boolean(errors.terms)}>
            <FormControlLabel
              control={
                <Controller
                  as={Checkbox}
                  name="terms"
                  control={control}
                  onChange={([e]) => {
                    return e.target.checked ? "checked" : "unchecked";
                  }}
                />
              }
              label={t("terms")}
            />
            {errors.terms && (
              <FormHelperText>{errors.terms.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={"auto"}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
          >
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
export {
  propTypes as ContactFormPropTypes,
  defaultProps as ContactFormDefaultProps,
};
