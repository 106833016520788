/**
 * Component short description
 *
 * @see useFilters.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { createContext, useContext, useState } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useFilters.data";

const FilterContext = createContext();

const getSelectedArrayValues = (props) => {
  const { key, selected, items } = props;

  if (!selected) {
    return [];
  }
  return items.filter((item) => selected.includes(item[key]));
};

const getArrayOfKeys = (props) => {
  const { key, items } = props;

  return items.map((item) => {
    return item[key];
  });
};

const parseReactNativeDropdownValue = (selections, items) => {
  return selections.map((selection) => {
    const original = items.find((item) => item.id === selection);
    return original;
  });
};

const getValueAndLabelArray = (items) => {
  return items.reduce(
    ([values, labels], item) => {
      const { id, name } = item;
      values.push(id);
      labels.push(name);

      return [values, labels];
    },
    [[], []]
  );
};

const FiltersProvider = (props) => {
  const { children, filters: defaultFilters, data: defaultData } = props;

  const [filters, setFilters] = useState(defaultFilters);
  const [data, setData] = useState(defaultData);

  const resetFilters = (resetToValues = {}) => {
    setFilters({
      ...defaultFilters,
      ...resetToValues,
    });
  };

  const setFiltersAndResetPagination = (newFilters) => {
    setFilters((prevState) => ({
      ...prevState,
      ...newFilters,
      page: 1,
      offset: 0,
    }));
  };

  const parseFiltersForQuery = (filtersToParse) => {
    return Object.keys(filtersToParse).reduce((obj, key) => {
      const value = filtersToParse[key];

      if (!value) {
        return obj;
      }

      const type = typeof value;

      switch (type) {
        case "object": {
          const { filterValue } = value;

          if (filterValue) {
            return {
              ...obj,
              [key]: filterValue,
            };
          }
          return obj;
        }
        case "string":
        case "number":
        default:
          return {
            ...obj,
            [key]: value,
          };
      }
    }, {});
  };

  return (
    <FilterContext.Provider
      value={{
        filters: filters,
        setFilters: setFilters,
        setFiltersAndResetPagination: setFiltersAndResetPagination,
        data: data,
        setData: setData,
        resetFilters: resetFilters,
        parseFiltersForQuery: parseFiltersForQuery,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
/**
 * Displays the component
 */
const useFilters = (props) => {
  return useContext(FilterContext);
};

useFilters.propTypes = propTypes;
useFilters.defaultProps = defaultProps;

export default useFilters;
export {
  useFilters,
  FiltersProvider,
  parseReactNativeDropdownValue,
  getSelectedArrayValues,
  getArrayOfKeys,
  getValueAndLabelArray,
};
