/**
 * Displays an icon
 *
 * @see Icon.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import { routesLocalizePath } from "../Routes";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Icon.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Icon.lang.ro-ro";
import { hu_hu } from "./Icon.lang.hu-hu";
import { en_us } from "./Icon.lang.en-us";
import { de_de } from "./Icon.lang.de-de";

i18n.addResourceBundle("ro-RO", "Icon", ro_ro);
i18n.addResourceBundle("hu-HU", "Icon", hu_hu);
i18n.addResourceBundle("en-US", "Icon", en_us);
i18n.addResourceBundle("de-DE", "Icon", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  iconButton: {
    maxWidth: 64,
  },

  iconButtonNoPadding: {
    maxWidth: 64,
    padding: 0,
  },

  buttonWithoutLabel: {
    minWidth: "auto",
  },

  hr: {
    width: "100%",
  },
  button: (props) => ({
    opacity: props.highlighted ? 1 : null,
  }),
  textTransform: {
    textTransform: "none",
  },
}));

const createValidUrl = (url) => {
  const valid = url.startsWith("https://") || url.startsWith("http://");

  return valid ? url : `https://${url}`;
};

/**
 * Displays the component
 */
const Icon = (props) => {
  const {
    icon,
    name: originalName,
    url,
    path: originalPath,
    component,
    disabled,
    callback,
    divider,
    highlighted,
    displayName,
    doTranslateName,
    customVariant,
    customTheme,
    transKey,
    noPadding,
    doNotTransformText,
  } = props;

  const {
    textTransform,
    iconButton,
    iconButtonNoPadding,
    hr,
    buttonWithoutLabel,
    button,
  } = useStyles(props);
  const { t } = useTranslation("Icon");
  const { t: t2 } = useTranslation("Routes");
  const currentTheme = useTheme();

  /**
   * Translates the name if necessary
   */
  const name = doTranslateName
    ? transKey
      ? t(transKey)
      : t(originalName)
    : originalName;
  const path = doTranslateName
    ? routesLocalizePath({ path: originalPath, t: t2 })
    : originalPath;

  /**
   * Checks if the icon name should be displayed
   */
  let iconName = displayName && (
    <span className={doNotTransformText ? `${textTransform}` : "ButtonLabel"}>
      {name}
    </span>
  );

  if (displayName && name && name.split(/\n/).length > 1) {
    iconName = (
      <Grid container>
        {name.split(/\n/).map((item) => (
          <Grid container item xs={12}>
            <span className="ButtonLabel">{item}</span>
          </Grid>
        ))}
      </Grid>
    );
  }

  /**
   * Ads className to Button
   */
  const buttonKlass = displayName ? null : buttonWithoutLabel;
  /**
   * Highlights an icon
   */
  const color = highlighted ? "primary" : "default";

  /**
   * Displays a divider
   */
  if (divider) {
    return <Divider {...divider} className={clsx(hr)} />;
  }

  const theme = customTheme ?? currentTheme;

  /**
   * Uses a callback and path
   */
  if (callback && path) {
    return (
      <Button
        startIcon={icon}
        to={path}
        component={RouterLink}
        disabled={disabled}
        onClick={() => callback()}
        color={color}
        style={{ color: "white" }}
        className={clsx(button, buttonKlass)}
      >
        {iconName}
      </Button>
    );
  }

  /**
   * Uses a callback
   */
  if (callback) {
    return (
      <Button
        startIcon={icon}
        disabled={disabled}
        onClick={() => callback()}
        color={color}
        className={clsx(button, buttonKlass)}
      >
        {iconName}
      </Button>
    );
  }

  /**
   * Displays the component
   */
  if (component) {
    return icon ? (
      <ThemeProvider theme={theme}>
        <Button startIcon={icon} className={clsx(button, buttonKlass)}>
          <ThemeProvider theme={currentTheme}>{component}</ThemeProvider>
        </Button>
      </ThemeProvider>
    ) : (
      component
    );
  }

  /**
   * Displays a button with icon
   */
  if (name) {
    return (
      <ThemeProvider theme={theme}>
        {url ? (
          <Button
            startIcon={icon}
            href={url}
            disabled={disabled}
            color={color}
            className={clsx(button, buttonKlass)}
          >
            {iconName}
          </Button>
        ) : path ? (
          <Button
            startIcon={icon}
            to={path}
            variant={customVariant || "text"}
            component={RouterLink}
            disabled={disabled}
            color={color}
            disableElevation
            className={clsx(button, buttonKlass)}
          >
            {iconName}
          </Button>
        ) : (
          <Button
            startIcon={icon}
            disabled={disabled}
            color={color}
            className={clsx(button, buttonKlass)}
          >
            {iconName}
          </Button>
        )}
      </ThemeProvider>
    );
  }

  /**
   * Displays an IconButton
   */

  return (
    <>
      {url ? (
        <ThemeProvider theme={theme}>
          <IconButton
            href={createValidUrl(url)}
            target="_blank"
            component={Link}
            className={clsx(noPadding ? iconButtonNoPadding : iconButton)}
            disabled={disabled}
            color={color}
          >
            {icon}
          </IconButton>
        </ThemeProvider>
      ) : path ? (
        <ThemeProvider>
          <IconButton
            to={path}
            component={RouterLink}
            className={clsx(iconButton)}
            disabled={disabled}
            color={color}
          >
            {icon}
          </IconButton>
        </ThemeProvider>
      ) : null}
    </>
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
export { propTypes as IconPropTypes, defaultProps as IconDefaultProps };
