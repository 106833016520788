/**
 * Defines the data requirements for the component
 *
 * @see Order.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { OrderItemPropTypes } from "../OrderItem";

/**
 * Defines the prop PropTypes
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getMarketOrderByID
 */
const propTypes = {
  id: PropTypes.string,
  seller: PropTypes.string,
  orderDate: PropTypes.string,
  orderItems: PropTypes.number,
  totalPrice: PropTypes.string,
  status: PropTypes.string,
  statusDescription: PropTypes.string,
  refusalReason: PropTypes.string,
  personalName: PropTypes.string,
  personalAddress: PropTypes.string,
  personalPhone: PropTypes.string,
  deliveryName: PropTypes.string,
  deliveryAddress: PropTypes.string,
  deliveryPhone: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(OrderItemPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [],
};

export { propTypes, defaultProps };
