const hu_hu = {
  admin: "Admin",
  order_history: "Rendelési előzmények",
  your_account: "Fiókja",
  booking_history: "Foglalási előzmények",
  settings: "Beállítások",
  request_role: "Kérjen szerepet",
  profile_role: "Profil szerepe",
  role: "Szerep",
  seller: "Eladó",
  host: "Házigazda",
  description: "Számla létrehozása",
};

export { hu_hu };
