/**
 * Defines the data requirements for the component
 *
 * @see ForgotPassDialog.md for details
 */
import PropTypes from "prop-types";
import {
  ForgotPassFormDefaultProps,
  ForgotPassFormPropTypes
} from "../ForgotPassForm";
import { Dialog2PropTypes, Dialog2DefaultProps } from "../../Dialog2";

/**
 * Defines the prop types
 */
const propTypes = {
  forgotPassForm: PropTypes.shape(ForgotPassFormPropTypes),
  dialog: PropTypes.shape(Dialog2PropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  forgotPassForm: ForgotPassFormDefaultProps,
  dialog: Dialog2DefaultProps
};

export { propTypes, defaultProps };
