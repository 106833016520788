const en_us = {
  sold_by: "Items sold and delivered by",
  checkout: "Proceed to checkout",
  pending: "Pending",
  total_price: "Total price",
  delivery_price: "Delivery price",
  items_price: "Items price",
};

export { en_us };
