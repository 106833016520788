const de_de = {
  email: "E-Mail-Addresse",
  destination: "Ziel",
  message: "Botschaft",
  submit: "Nachricht senden",
  name: "Dein Name",
  terms: "Geschäftsbedingungen",
  select_destination: "Ziel aussuchen",
  agree_text: "Ich stimme dem zu",
};

export { de_de };
