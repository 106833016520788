/**
 * Component short description
 *
 * @see FilterDestination.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../FilterListItem";
import { useFilters } from "../../hooks";
import { getSelectedArrayValues, getArrayOfKeys } from "../../hooks/useFilters";
import FilterChips from "../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterDestination.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterDestination.lang.ro-ro";
import { hu_hu } from "./FilterDestination.lang.hu-hu";
import { en_us } from "./FilterDestination.lang.en-us";
import { de_de } from "./FilterDestination.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterDestination", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterDestination", hu_hu);
i18n.addResourceBundle("en-US", "FilterDestination", en_us);
i18n.addResourceBundle("de-DE", "FilterDestination", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterDestination = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterDestination");

  /**
   * Handles the filters
   */
  const { filters, data, setFiltersAndResetPagination } = useFilters();
  const { destinations } = data;

  const selected = getSelectedArrayValues({
    key: "id",
    selected: filters.destination?.filterValue || defaultValue,
    items: destinations,
  });

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;

    setFiltersAndResetPagination({
      destination: {
        filterValue: getArrayOfKeys({ key: "id", items: value }),
        label: getArrayOfKeys({ key: "name", items: value }),
      },
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="destination"
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {destinations.map((d) => {
          return (
            <MenuItem key={d.id || d} value={d}>
              <ListItemText primary={d.name || d} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};

FilterDestination.propTypes = propTypes;
FilterDestination.defaultProps = defaultProps;

export default FilterDestination;
export {
  propTypes as FilterDestinationPropTypes,
  defaultProps as FilterDestinationDefaultProps,
};
