const ro_ro = {
  personal_address: "Adresă",
  delivery_address: "Adresă",
  request_sent_successfully: "Cerinta trimisă",
  delivery_info: "Informaţii de livrare",
  personal_info: "Informaţii personale",
  items_sold_and_delivered: "Items sold and delivered by",
  is_same_as_personal_info: "La fel ca si date personale",
  personal_phone: "Număr telefon",
  delivery_phone: "Număr telefon",
  personal_name: "Nume",
  delivery_name: "Nume",
  create_order_failed: "A apărut o eroare la trimiterea comenzii dvs.",
  send_request: "Trimite cererea",
  checkout: "Verifică",
  create_order_success: "Comanda dvs. a fost trimisă cu succes.",
};

export { ro_ro };
