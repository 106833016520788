const hu_hu = {
  add_to_cart: "Kosárba",
  description: "Leírás",
  unit: "Mértékegység",
  units: "Untis",
  sing_in: "Jelentkezzen be a vásárláshoz",
  product_addded_to_cart: "A terméket sikeresen felvették a kosárba",
};

export { hu_hu };
