/**
 * Component short description
 *
 * @see PrivacyPolicy.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import { PageHeader } from "../../Theme";
import PrivacyPolicyHTML from '../../../assets/staticPages/privacyPolicy.htm';

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./PrivacyPolicy.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./PrivacyPolicy.lang.ro-ro";
import { hu_hu } from "./PrivacyPolicy.lang.hu-hu";
import { en_us } from "./PrivacyPolicy.lang.en-us";
import { de_de } from "./PrivacyPolicy.lang.de-de";

i18n.addResourceBundle("ro-RO", "PrivacyPolicy", ro_ro);
i18n.addResourceBundle("hu-HU", "PrivacyPolicy", hu_hu);
i18n.addResourceBundle("en-US", "PrivacyPolicy", en_us);
i18n.addResourceBundle("de-DE", "PrivacyPolicy", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
  iframeContainer: {
    width: '100%',
    padding: '10px',
    height: '100%'
  },
  iframe: {
    width: '100%',
    border: 0
  }
}));

/**
 * Displays the component
 */
const PrivacyPolicy = (props) => {
  const adjustIFrameHeight = () => {
    const iframeDOMNode = document.getElementById('myIFrame');
    if (iframeDOMNode) {
      iframeDOMNode.height = iframeDOMNode.contentWindow.document.body.scrollHeight || 'auto'
    }
  }

  const { container, iframeContainer, iframe } = useStyles(props);
  const { t } = useTranslation("PrivacyPolicy");

  useEffect(() => {
    window.addEventListener('resize', adjustIFrameHeight)
  })

  return (
    <Layout footer={{ currentPage: "Privacy Policy" }}>
      <Grid container spacing={1} className={clsx(container, "PrivacyPolicy")}>
        <Grid item xs={12}>
          <PageHeader title={t("title")} />
        </Grid>
        <Grid container spacing={0} className={clsx(container)}>
          <Grid item xs={12}>
            <div className={clsx(iframeContainer)} onFocus={adjustIFrameHeight}>
              <iframe
                title="privacy-policy"
                id="myIFrame"
                scrolling="no"
                src={PrivacyPolicyHTML}
                onLoad={adjustIFrameHeight}
                className={clsx(iframe)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;
export {
  propTypes as PrivacyPolicyPropTypes,
  defaultProps as PrivacyPolicyDefaultProps,
};
