const en_us = {
  headline: "Welcome to Pineyard.ro",
  afir_description: "Realised with the support of AFIR",
  collage: "G10 locations collage",
  voslabeni: "Voslabeni",
  remetea: "Remetea",
  ciumani: "Ciumani",
  suseni: "Gyergyóújfalu",
  joseni: "Joseni",
  ditro: "Ditró",
  borsec: "Borsec",
  corbu: "Corbu",
  lazarea: "Lăzarea",
  tulghes: "Tulgheș",
};

export { en_us };
