const en_us = {
  admin: "Admin",
  order_history: "Order history",
  your_account: "Your account",
  booking_history: "Booking history",
  settings: "Settings",
  request_role: "Request Role",
  profile_role: "Profile Role",
  role: "Role",
  seller: "Seller",
  host: "Host",
  description: "Account creation",
};

export { en_us };
