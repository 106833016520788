/**
 * Defines the data requirements for the component
 *
 * @see CategoriesAsThumbs.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { CategoryAsThumbPropTypes } from "../CategoryAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryAsThumbPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  categories: [],
};

export { propTypes, defaultProps };
