/**
 * Defines the data requirements for the component
 *
 * @see Hero.md for details
 */
import PropTypes from "prop-types";

import collage1280x720 from "./collage/collage-1280-720.jpeg";
import collage640x360 from "./collage/collage-640-360.jpeg";
import collage320x180 from "./collage/collage-320-180.jpeg";

import ciumani1280x720 from "./ciumani/ciumani-1280-720.jpeg";
import ciumani640x360 from "./ciumani/ciumani-640-360.jpeg";
import ciumani320x180 from "./ciumani/ciumani-320-180.jpeg";

import voslabeni1280x720 from "./voslabeni/voslabeni-1280-720.jpeg";
import voslabeni640x360 from "./voslabeni/voslabeni-640-360.jpeg";
import voslabeni320x180 from "./voslabeni/voslabeni-320-180.jpeg";

import remetea1280x720 from "./remetea/remetea-1280-720.jpeg";
import remetea640x360 from "./remetea/remetea-640-360.jpeg";
import remetea320x180 from "./remetea/remetea-320-180.jpeg";

import tulghes1280x720 from "./tulghes/tulghes-1280-720.jpeg";
import tulghes640x360 from "./tulghes/tulghes-640-360.jpeg";
import tulghes320x180 from "./tulghes/tulghes-320-180.jpeg";

import lazarea1280x720 from "./lazarea/lazarea-1280-720.jpeg";
import lazarea640x360 from "./lazarea/lazarea-640-360.jpeg";
import lazarea320x180 from "./lazarea/lazarea-320-180.jpeg";

import corbu1280x720 from "./corbu/corbu-1280-720.jpeg";
import corbu640x360 from "./corbu/corbu-640-360.jpeg";
import corbu320x180 from "./corbu/corbu-320-180.jpeg";

import borsec1280x720 from "./borsec/borsec-1280-720.jpeg";
import borsec640x360 from "./borsec/borsec-640-360.jpeg";
import borsec320x180 from "./borsec/borsec-320-180.jpeg";

import joseni1280x720 from "./joseni/joseni-1280-720.jpeg";
import joseni640x360 from "./joseni/joseni-640-360.jpeg";
import joseni320x180 from "./joseni/joseni-320-180.jpeg";

import ditro1280x720 from "./ditro/ditro-1280-720.jpeg";
import ditro640x360 from "./ditro/ditro-640-360.jpeg";
import ditro320x180 from "./ditro/ditro-320-180.jpeg";
/**
 * Imports prop types from other components
 */
import {
  SearchFormDefaultProps,
  SearchFormPropTypes,
} from "../../_search/SearchForm";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/About%20us/getAboutUs
 */
const propTypes = {
  images: PropTypes.array,
  searchForm: PropTypes.shape(SearchFormPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  images: [
    {
      id: 1,
      nameKey: "collage",
      author: "Reprezentanții G10",
      resolutions: [
        {
          id: 1,
          path: collage1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: collage640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: collage320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 2,
      nameKey: "ciumani",
      author: "Kereztes média",
      resolutions: [
        {
          id: 1,
          path: ciumani1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: ciumani640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: ciumani320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 3,
      nameKey: "voslabeni",
      author: "www.triff.ro",
      resolutions: [
        {
          id: 1,
          path: voslabeni1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: voslabeni640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: voslabeni320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 4,
      nameKey: "remetea",
      author: "Mincsor Szabolcs",
      resolutions: [
        {
          id: 1,
          path: remetea1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: remetea640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: remetea320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 5,
      nameKey: "tulghes",
      author: "",
      resolutions: [
        {
          id: 1,
          path: tulghes1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: tulghes640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: tulghes320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 6,
      nameKey: "lazarea",
      author: "",
      resolutions: [
        {
          id: 1,
          path: lazarea1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: lazarea640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: lazarea320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 7,
      nameKey: "corbu",
      author: "",
      resolutions: [
        {
          id: 1,
          path: corbu1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: corbu640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: corbu320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 8,
      nameKey: "borsec",
      author: "",
      resolutions: [
        {
          id: 1,
          path: borsec1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: borsec640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: borsec320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 9,
      nameKey: "joseni",
      author: "",
      resolutions: [
        {
          id: 1,
          path: joseni1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: joseni640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: joseni320x180,
          width: 320,
          height: 180,
        },
      ],
    },
    {
      id: 10,
      nameKey: "ditro",
      author: "Erős Zoltán",
      resolutions: [
        {
          id: 1,
          path: ditro1280x720,
          width: 1280,
          height: 720,
        },
        {
          id: 2,
          path: ditro640x360,
          width: 640,
          height: 360,
        },
        {
          id: 3,
          path: ditro320x180,
          width: 320,
          height: 180,
        },
      ],
    },
  ],
  searchForm: SearchFormDefaultProps,
};

export { propTypes, defaultProps };
