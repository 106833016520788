/**
 * The default authentication strategy
 *
 * @see useOAuth.md for details
 */
import { useState } from "react";

import useLocalStorage from "../../../useLocalStorage";

/**
 * Defines the default return values
 */
const defaultReturns = {
  isAuthenticated: false,
  user: {},
  token: null,
  message: "The finster auth strategy",
  strategy: "borsec",
  localStorageKeyAuth: "localStorageKeyAuth",
  localStorageKeyToken: "localStorageKeyToken",
  localStorageKeyUser: "userData",
  redirectRoute: "/",
};

/**
 * Displays the component
 */
const useOAuth = (props) => {
  const {
    localStorageKeyAuth,
    localStorageKeyToken,
    localStorageKeyUser,
    token: tokenFromProps,
    message: messageFromProps,
    strategy,
    redirectRoute,
  } = defaultReturns;

  /**
   * Checks local storage if the user is authenticated already
   */
  const [
    isAuthenticatedLocalStorage,
    setIsAuthenticatedLocalStorage,
  ] = useLocalStorage(localStorageKeyAuth, false);

  /**
   * Checks local storage if the token is saved
   */
  const [tokenLocalStorage, setTokenLocalStorage] = useLocalStorage(
    localStorageKeyToken,
    tokenFromProps
  );

  /**
   * Checks local storage if the token is saved
   */
  const [userLocalStorage, setUserLocalStorage] = useLocalStorage(
    localStorageKeyUser,
    {}
  );

  const [user, setUser] = useState(userLocalStorage);

  /**
   * Manages auth state
   *
   * - First it is populated with the value from the local storage
   */
  const [isAuthenticated, setIsAuthenticated] = useState(
    isAuthenticatedLocalStorage
  );

  /**
   * Manages the token
   *
   * - First it is populated with the value from the local storage
   */
  const [token, setToken] = useState(tokenLocalStorage);

  /**
   * Manages the status message
   *
   * - First it is populated with the value from the props
   */
  const [message, setMessage] = useState(messageFromProps);

  /**
   * Defines the login function
   *
   * - `data` is the result of the login API call
   */
  const login = (data) => {
    const newToken = data?.token;
    if (newToken) {
      setUserLocalStorage(data?.user);
      setTokenLocalStorage(newToken);
      setToken(newToken);
      setUser(data?.user);
      setIsAuthenticatedLocalStorage(true);
      setIsAuthenticated(true);
      setMessage("Successful login");
    } else {
      setMessage("Unsuccessful login");
    }
  };

  /**
   * Defines the logout function
   */
  const logout = () => {
    setToken(tokenFromProps);
    setIsAuthenticated(false);
    setIsAuthenticatedLocalStorage(false);
    setMessage("Successful logout");
    localStorage.removeItem(localStorageKeyUser);
    localStorage.removeItem(localStorageKeyToken);

    setUser({});
  };

  const updateUser = (user) => {
    setUser(user);
    setUserLocalStorage(user);
  };

  const updateToken = (token) => {
    setTokenLocalStorage(token);
  };
  /**
   * Redirects when authentication fails
   *
   * - `useHistory` can't be used here: https://github.com/ReactTraining/react-router/issues/6939
   */
  const redirectTo = (route) => {
    const newRoute = route ? route : redirectRoute;
    window.location = newRoute;
  };

  return {
    isAuthenticated,
    token,
    message,
    login,
    logout,
    strategy,
    user,
    redirectTo,
    updateUser,
    updateToken,
  };
};

export { useOAuth };
