/**
 * Displays destination as a Hero
 *
 * @see DestinationAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStyles, Card2SingleRowStylesInverted, } from "../../Card2";
import CategoriesAsThumbs from "../../_category/CategoriesAsThumbs";
import Carousel from "../../Carousel";
import { TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./DestinationAsHero.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./DestinationAsHero.lang.ro-ro";
import { hu_hu } from "./DestinationAsHero.lang.hu-hu";
import { en_us } from "./DestinationAsHero.lang.en-us";
import { de_de } from "./DestinationAsHero.lang.de-de";
import ImageResponsive from "../../ImageResponsive";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";
import { getDestinationUrl } from "../../../helpers/urlHelper";

i18n.addResourceBundle("ro-RO", "DestinationAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "DestinationAsHero", hu_hu);
i18n.addResourceBundle("en-US", "DestinationAsHero", en_us);
i18n.addResourceBundle("de-DE", "DestinationAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const DestinationAsHero = (props) => {
  const {
    id,
    name,
    slug,
    alternateName,
    description,
    imageWidth,
    images,
    categories,
    index,
    showCategories
  } = props;

  const { container } = useStyles(props);
  const { t } = useTranslation("DestinationAsHero");

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextSection
      title={name}
      subtitle={alternateName}
      description={description}
    />
  );

  const cardActions = (
    <Button
      variant="contained"
      color="primary"
      size="large"
      component={RouterLink}
      to={getDestinationUrl(url, slug, id, t('Routes:destinations'))}
    >
      {t("explore")}
    </Button>
  );

  const styles =
    index % 2 === 0 ? Card2SingleRowStylesInverted : Card2SingleRowStyles;

  const carouselSteps = images.map((image) => {
    const { title } = image;

    return {
      id: shortid.generate(),
      title: title,
      content: <ImageResponsive {...{ ...image, imageWidth } } />,
    };
  });

  const cardWithMedia = {
    image: null,
    carousel: !!images.length && (
      <Carousel
        steps={carouselSteps}
        isMobileStepperAlignedRight={index % 2 === 0}
        customStyles={{ breakpoint: "sm", spacingTop: 2 }}
      />
    ),
    content: cardContent,
    actions: cardActions,
    customStyles: styles({ ...props, breakpoint: "md", imageWidth: 640 }),
  };

  return (
    <Grid container className={clsx(container, "DestinationAsHero")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia} />
      </Grid>
      {
        showCategories &&
        <Grid item xs={12} className={clsx(container, "Categories")}>
          <CategoriesAsThumbs
            destinationSlug={getSlugForCurrentLang(slug)}
            categories={categories}
          />
        </Grid>
      }
    </Grid>
  );
};

DestinationAsHero.propTypes = propTypes;
DestinationAsHero.defaultProps = defaultProps;

export default DestinationAsHero;
export {
  propTypes as DestinationAsHeroPropTypes,
  defaultProps as DestinationAsHeroDefaultProps,
};
