/**
 * Displays a menu
 *
 * @see Menu.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import MenuDrawer from "../MenuDrawer";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Menu.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Menu.lang.ro-ro";
import { hu_hu } from "./Menu.lang.hu-hu";
import { en_us } from "./Menu.lang.en-us";
import { de_de } from "./Menu.lang.de-de";

i18n.addResourceBundle("ro-RO", "Menu", ro_ro);
i18n.addResourceBundle("hu-HU", "Menu", hu_hu);
i18n.addResourceBundle("en-US", "Menu", en_us);
i18n.addResourceBundle("de-DE", "Menu", de_de);

/**
 * Converts a menu name to a route
 */
const convertMenuNameToRoute = (menuName) => {
  return menuName === "Home" ? "/" : `/${menuName}`;
};

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const Menu = (props) => {
  const { children, orientation } = props;

  return orientation === "vertical" ? (
    <MenuDrawer>{children}</MenuDrawer>
  ) : (
    children
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
export {
  propTypes as MenuPropTypes,
  defaultProps as MenuDefaultProps,
  convertMenuNameToRoute,
};
