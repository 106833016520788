/**
 * Component short description
 *
 * @see About.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStyles } from "../../Card2";
import { TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./About.data";
/**
 * Imports Material UI components
 */
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./About.lang.ro-ro";
import { hu_hu } from "./About.lang.hu-hu";
import { en_us } from "./About.lang.en-us";
import { de_de } from "./About.lang.de-de";
import SearchForm from "../../_search/SearchForm";

i18n.addResourceBundle("ro-RO", "About", ro_ro);
i18n.addResourceBundle("hu-HU", "About", hu_hu);
i18n.addResourceBundle("en-US", "About", en_us);
i18n.addResourceBundle("de-DE", "About", de_de);

/**
 * Displays the component
 */
const About = (props) => {
  const { description } = props;
  const { t } = useTranslation("About");

  /**
   * Displays the card
   */
  const cardContent1 = (
    <TextSection title={t("title")} description={description} />
  );

  const cardWithMedia1 = {
    image: null,
    klassName: "About",
    content: cardContent1,
    actions: <SearchForm />,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
    cardContainerProps: { alignItems: "center" },
  };

  return <Card2 {...cardWithMedia1} />;
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;
export { propTypes as AboutPropTypes, defaultProps as AboutDefaultProps };
