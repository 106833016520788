/**
 * Defines the data requirements for the component
 *
 * @see OngInfo.md for details
 */
import PropTypes from "prop-types";
/**
 * Imports prop types from other components
 */
import { ImageResponsivePropTypes } from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/ > PartnerInfo, Partner
 */
const propTypes = {
  description: PropTypes.string,
  quote: PropTypes.string,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.shape(ImageResponsivePropTypes),
      url: PropTypes.string,
    })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  info: {
    description: "",
    quote: "",
  },
  partners: [],
};

export { propTypes, defaultProps };
