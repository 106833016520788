import * as moment from "moment";

export const getArrayItemsForMinAndMax = (min, max) => {
  const minQuantity = min || 1;
  const maxQuantity = max || min;

  const totalItems = [];
  Array(maxQuantity + 1).fill(1).forEach((value, key) => {
    if (minQuantity <= key) {
      totalItems.push(key);
    }
  })

  return totalItems;
}

export const getImageResolutions = (resolutions, imageWidth) => {
  let newResolutions = [];
  resolutions.forEach((res) => {
    if (res.width <= imageWidth) {
      newResolutions.push(res);
    }
  })

  return newResolutions;
}

export const areDatesValid = (checkIn, checkOut, setError, message) => {
  if (moment(checkIn).isValid() && moment(checkOut).isValid()) {
    return true;
  }

  if (!moment(checkIn).isValid()) {
    setError('checkIn', 'notMatch', message);
  }

  if (!moment(checkOut).isValid()) {
    setError('checkOut', 'notMatch', message);
  }

  return false;
}
