const de_de = {
  title: "Veranstaltungen",
  no_events:
    "Derzeit gibt es im ausgewählten Monat keine Veranstaltungen für diesen Ort",
  month: "Monat",
  select_destination: "Wähle ein Reiseziel",
  select_month: "Wähle einen Monat",
  destination: "Ziel",
  all: "Alles",
};

export { de_de };
