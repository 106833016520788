/**
 * Defines the data requirements for the component
 *
 * @see FilterListItem.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  primary: PropTypes.string,
  children: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  primary: "Filter title",
  children: null,
};

export { propTypes, defaultProps };
