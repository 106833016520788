const ro_ro = {
  home: "Acasă",
  search: "Căutare",
  stay: "Cazare",
  contact: "Contact",
  cart: "Cumpărături",
  destinations: "Destinaţii",
  events: "Evenimente",
  sign_in: "Logare",
  local_market: "Shop - produse locale",
  news: "Ştiri",
};

export { ro_ro };
