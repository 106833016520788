/**
 * Displays a responsive image
 * - Supports the resolution switching mechanism with `srcset` and `sizes`
 * - For art direction the `<Picture/>` component should be used
 *
 * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */

import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import Image, { ImagePropTypes, ImageDefaultProps } from "../Image";
import i18n from "../../i18n";
import { getImageResolutions } from "../../helpers/baseHelper";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The basic image props
   */
  ...ImagePropTypes,
  /**
   * A set of image sources
   */
  srcSet: PropTypes.string,
  /**
   * A set of source sizes
   */
  sizes: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...ImageDefaultProps,
  srcSet: null,
  sizes: null,
};

/**
 * Pre-defines various placeholders
 */
const imagePlaceholderCom169 = {
  id: shortid.generate(),
  srcSet: "",
  alt: "Placeholder image (16:9)",
  aspectRatio: 9 / 16,
};

const imagePlaceholderCom11 = {
  id: shortid.generate(),
  srcSet: "",
  alt: "Placeholder image (1:1)",
  aspectRatio: 1 / 1,
};

/**
 * Converts an API specific image format to the component's standard image format
 */
const convertImageFormat = (props) => {
  const { alternateName, resolutions: imageResolutions, imageWidth, author } = props;
  if (!imageResolutions) return props;

  /**
   * If something goes wrong further with `resolutions` we'll return a placeholder image
   */
  let isSrcSetOk = true;

  /**
   * Aspect Ratio will be calculated from the last `resolution`
   */
  let aspectRatio = 1;

  // TODO: Fix the API, then remove the prefix

  let resolutions = imageWidth ? getImageResolutions(imageResolutions, imageWidth) : imageResolutions;
  const srcSet = resolutions.map((item) => {
    const { width, height, path, url } = item;
    const clearPath = path || url;

    if (!width || !clearPath || !height) isSrcSetOk = false;

    aspectRatio = height / width;
    return `${clearPath} ${width}w`;
  });

  if (!isSrcSetOk) return imagePlaceholderCom169;

  const image = {
    srcSet: srcSet.join(","),
    alt: `${alternateName} ${i18n.t("Image:by")} ${author}`,
    aspectratio: aspectRatio,
  };

  return image;
};

/**
 * Displays the component
 *
 * - This is a lighweight component.
 * - A responsive image is nothing more than an `<Image />` with `srcSet` and `sizes` set
 */
const ImageResponsive = (props) => {
  const image = convertImageFormat(props);

  return <Image {...image} />;
};

ImageResponsive.propTypes = propTypes;
ImageResponsive.defaultProps = defaultProps;

export default ImageResponsive;
export {
  propTypes as ImageResponsivePropTypes,
  defaultProps as ImageResponsiveDefaultProps,
  imagePlaceholderCom169 as ImageResponsivePlaceholderCom169,
  imagePlaceholderCom11 as ImageResponsivePlaceholderCom11,
};
