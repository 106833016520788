/**
 * Defines the data requirements for the component
 *
 * @see Destination.md for details
 */
import React from "react";
import PropTypes from "prop-types";
/**
 * Imports prop types from other components
 */
import { ImageResponsivePropTypes } from "../../ImageResponsive";
import {
  CategoriesAsThumbsDefaultProps,
  CategoriesAsThumbsPropTypes,
} from "../../_category/CategoriesAsThumbs";
import {
  LocalMarketAsHeroDefaultProps,
  LocalMarketAsHeroPropTypes,
} from "../../_localMarket/LocalMarketAsHero";
import {
  StayAsHeroDefaultProps,
  StayAsHeroPropTypes,
} from "../../_stay/StayAsHero";
import { IconPropTypes } from "../../Icon";
import { GpsDefaultProps, GpsPropTypes } from "../../Gps";

import YouTubeIcon from "@material-ui/icons/YouTube";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import CameraIcon from "@material-ui/icons/Camera";
import { VideoAsThumbPropTypes } from "../../_videos/VideoAsThumb";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestinations
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestination
 */
const propTypes = {
  /**
   * From API
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  slug: PropTypes.object,
  name: PropTypes.string,
  alternateName: PropTypes.string,
  description: PropTypes.string,
  longDescription: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  videos: PropTypes.arrayOf(PropTypes.shape(VideoAsThumbPropTypes)),
  categoriesDescription: PropTypes.string,
  ...CategoriesAsThumbsPropTypes,
  ...LocalMarketAsHeroPropTypes,
  ...StayAsHeroPropTypes,
  /**
   * Other props
   */
  gps: PropTypes.shape(GpsPropTypes),
  videoIcon: PropTypes.shape(IconPropTypes),
  pdfIcon: PropTypes.shape(IconPropTypes),
  transportationIcon: PropTypes.shape(IconPropTypes),
  webcamIcon: PropTypes.shape(IconPropTypes),
  widget: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  slug: {},
  name: "",
  alternateName: "",
  description: "",
  longDescription: "",
  latitude: null,
  longitude: null,
  images: [],
  videos: [],
  categoriesDescription: "",
  staysDescription: "",
  marketDescription: "",
  ...CategoriesAsThumbsDefaultProps,
  ...LocalMarketAsHeroDefaultProps,
  ...StayAsHeroDefaultProps,
  gps: GpsDefaultProps,
  videoIcon: {
    icon: <YouTubeIcon />,
  },
  pdfIcon: {
    icon: <PictureAsPdfIcon />,
  },
  transportationIcon: {
    icon: <DepartureBoardIcon />,
  },
  webcamIcon: {
    icon: <CameraIcon />,
  },
  widget: null,
};

export { propTypes, defaultProps };
