/**
 * Component short description
 *
 * @see RequestStay.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./RequestStay.data";
/**
 * Imports Material UI components
 */
import { makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RequestStay.lang.ro-ro";
import { hu_hu } from "./RequestStay.lang.hu-hu";
import { en_us } from "./RequestStay.lang.en-us";
import { de_de } from "./RequestStay.lang.de-de";
import RequestStayForm from "../RequestStayForm/RequestStayForm";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "RequestStay", ro_ro);
i18n.addResourceBundle("hu-HU", "RequestStay", hu_hu);
i18n.addResourceBundle("en-US", "RequestStay", en_us);
i18n.addResourceBundle("de-DE", "RequestStay", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

/**
 * Displays the component
 */
const RequestStay = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("RequestStay");
  const {
    extras,
    price,
    description,
    subTitle,
    accommodationId,
    setIsModalOpen,
  } = props;
  const { name, email } = JSON.parse(localStorage.getItem("userData") || "{}");

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{subTitle}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption">{description}</Typography>
      </Grid>
      <Grid item xs={12} className={clsx(container, "RequestStayForm")}>
        <Grid container spacing={4}>
          <Grid container item spacing={3}>
            {extras}
          </Grid>
          <Grid container item spacing={3}>
            {price}
          </Grid>
          <Grid container item spacing={1}>
            {name && (
              <Grid item xs={12}>
                {`${t("name")}: ${name}`}
              </Grid>
            )}
            {email && (
              <Grid item xs={12}>
                {`${t("email")}: ${email}`}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <RequestStayForm {...{ accommodationId, setIsModalOpen }} />
    </Grid>
  );
};

RequestStay.propTypes = propTypes;
RequestStay.defaultProps = defaultProps;

export default RequestStay;
export {
  propTypes as RequestStayPropTypes,
  defaultProps as RequestStayDefaultProps,
};
