export const DESTINATION_WEATHER_CODES = {
  borsec: '46d9725d56',
  lazarea: '46d7425d53',
  ciumani: '46d6825d52',
  joseni: '46d7025d50',
  corbu: '44d4128d66',
  suseni: '44d7024d95',
  ditrau: '46d8125d50',
  remetea: '46d8025d44',
  voslabeni: '46d6525d62',
  tulghes: '46d9525d76'
};
