const ro_ro = {
  headline: "Bine aţi venit la Pineyard.ro",
  afir_description: "Realizat cu sprijinul AFIR",
  collage: "Colaj locatii G10",
  voslabeni: "Voșlăbeni",
  remetea: "Remetea",
  ciumani: "Ciumani",
  suseni: "Suseni",
  joseni: "Joseni",
  ditro: "Ditrău",
  borsec: "Borsec",
  corbu: "Corbu",
  lazarea: "Lăzarea",
  tulghes: "Tulgheș",
};

export { ro_ro };
