/**
 * Defines the data requirements for the component
 *
 * @see DestinationsList.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { DestinationAsHeroPropTypes } from "../../_destination/DestinationAsHero";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationAsHeroPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: [],
};

export { propTypes, defaultProps };
