const de_de = {
  items_count_and_price: "{{orderItems}} items / {{totalPrice}} RON",
  confirm_cancel: "Bestellung stornieren",
  title: "Auftragsstornierung",
  order_cancelled_successfully: "Bestellung erfolgreich storniert",
  order_date: "Bestelldatum: {{Wert}}",
  back_to_order: "Zurück zur Bestellung",
  order_id: "Bestellnummer: {{Wert}}",
  seller: "Verkäufer: {{value}}",
};

export { de_de };
