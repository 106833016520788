/**
 * Component short description
 *
 * @see SortBy.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";

/**
 * Imports other components and hooks
 */
import { useFilters } from "../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./SortBy.data";

/**
 * Imports Material UI components
 */
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from "@material-ui/icons/Sort";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./SortBy.lang.ro-ro";
import { hu_hu } from "./SortBy.lang.hu-hu";
import { en_us } from "./SortBy.lang.en-us";
import { de_de } from "./SortBy.lang.de-de";

i18n.addResourceBundle("ro-RO", "SortBy", ro_ro);
i18n.addResourceBundle("hu-HU", "SortBy", hu_hu);
i18n.addResourceBundle("en-US", "SortBy", en_us);
i18n.addResourceBundle("de-DE", "SortBy", de_de);

/**
 * Styles the component
 */

/**

 */
const SortBy = (props) => {
  const { options, defaultValue } = props;
  const { t } = useTranslation("SortBy");

  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState(defaultValue);

  const { setFiltersAndResetPagination } = useFilters();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    const { key, value } = item;
    setSelection(key);
    setFiltersAndResetPagination({
      ...value,
    });

    handleClose();
  };

  const listItems = options.map((item) => {
    const { key } = item;
    return (
      <MenuItem
        key={key}
        selected={key === selection}
        onClick={() => handleSelect(item)}
      >
        {t(key)}
      </MenuItem>
    );
  });
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        startIcon={<SortIcon />}
        onClick={handleClick}
      >
        {t("sort_by")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {listItems}
      </Menu>
    </>
  );
};

SortBy.propTypes = propTypes;
SortBy.defaultProps = defaultProps;

export default SortBy;
export { propTypes as SortByPropTypes, defaultProps as SortByDefaultProps };
