import useRequests from "../../../hooks/useRequests/useRequests";
import useSWR from "swr";

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsArticleSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
export const useAboutInfo = () => {
  const { get } = useRequests();

  return useSWR("about/info", get);
};
