const ro_ro = {
  address: "Adresa",
  cancel_order: "Anulează comanda",
  order: "Cumpărător",
  order_date: "Data comenzii",
  info: "Informaţii de livrare",
  personal_info: "Informaţii personale",
  name: "Nume",
  total_price: "Preţ final",
  order_status: "Statusul comenzii",
  phone: "Telefon",
  seller: "Vânzător",
  cancelled: "Anulat",
  confirmed: "Confirmat",
  paid: "Plătit",
  shipped: "Expediat",
  refused: "Refuzat",
  return_requested: "Retur solicitat",
  returned: "Întors",
  pending: "In asteptarea",
  order_number: "Numar de ordine",
  items: "Elemente",
  item: "Articol",
  order_cancellation: "Anulare comandă",
  confirm_cancel: "Confirmați Anulare",
  back_to_order: "Înapoi la comandă",
  order_cancelled: "Comanda dvs. a fost anulată",
  cancel_order_success: "Comanda dvs. a fost anulată",
  cancel_order_failed: "Problema la anularea comenzii.",
  get_order_failed: "Ceva nu este în regulă. Vă rugăm să încercați din nou.",
  payment_success: "Sikeres fizetés, most visszatérhet az alkalmazáshoz",
  complete_payment: "Plata completă",
  order_detail: "Detalii comandă",
  return_order: "Retur comandă",
  order_returned_successfully: "Solicitarea de returnare a fost trimisă",
};

export { ro_ro };
