/**
 * Component short description
 *
 * @see ProductAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ProductAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./ProductAsThumb.lang.ro-ro";
import { hu_hu } from "./ProductAsThumb.lang.hu-hu";
import { en_us } from "./ProductAsThumb.lang.en-us";
import { de_de } from "./ProductAsThumb.lang.de-de";
import { useTranslation } from "react-i18next";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "ProductAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "ProductAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "ProductAsThumb", en_us);
i18n.addResourceBundle("de-DE", "ProductAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const ProductAsThumb = (props) => {
  const { name, slug, image, price, unit, destinationName } = props;
  const { t } = useTranslation("ProductAsThumb");

  const cardContent1 = (
    <TextThumbnail
      overline={destinationName}
      to={`/${t("Routes:local-market")}/${getSlugForCurrentLang(slug)}`}
      title={name}
      price={`${price} RON / ${unit}`}
    />
  );

  const cardWithMedia1 = {
    image,
    images: null,
    carousel: null,
    content: cardContent1,
    actions: null,
    customStyles: useStyles(props),
  };

  return <Card2 {...cardWithMedia1} />;
};

ProductAsThumb.propTypes = propTypes;
ProductAsThumb.defaultProps = defaultProps;

export default ProductAsThumb;
export {
  propTypes as ProductAsThumbPropTypes,
  defaultProps as ProductAsThumbDefaultProps,
};
