const hu_hu = {
  title: "Hozzájárulás kezelése",
  description:
    "A Pineyard.ro a következő sütiket használja, további információkért kérjük, olvassa el Adatvédelmi irányelveinket és Általános Szerződési Feltételeinket.",
  analytics_consent: "Engedélyezze a Google Analytics szolgáltatást",
  weather_consent: "Engedélyezze az Időjárás widgetet",
  accept_selected: "A kiválasztott elfogadása",
  accept_all: "Mindent elfogad",
};

export { hu_hu };
