/**
 * Defines the data requirements for the component
 *
 * @see Search.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  SearchResultsPropTypes,
  SearchResultsDefaultProps
} from "../SearchResults";
import { SearchFormPropTypes, SearchFormDefaultProps } from "../SearchForm";

/**
 * Defines the prop types
 */
const propTypes = {
  form: PropTypes.shape(SearchFormPropTypes),
  results: PropTypes.shape(SearchResultsPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  form: SearchFormDefaultProps,
  results: SearchResultsDefaultProps
};

export { propTypes, defaultProps };
