/**
 * Defines the data requirements for the component
 *
 * @see RegisterForm.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  openDialog: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  },
  onSubmit: () => {},
  error: null,
  setError: () => {},
  openDialog: () => {},
};

export { propTypes, defaultProps };
