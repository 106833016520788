/**
 * Component short description
 *
 * @see RequestRoleForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
// NOTE: It should be refactored to use `<List2> and <TextSection>`

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./RequestRoleForm.data";

/**
 * Imports Material UI components
 */
import { makeStyles, FormHelperText } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RequestRoleForm.lang.ro-ro";
import { hu_hu } from "./RequestRoleForm.lang.hu-hu";
import { en_us } from "./RequestRoleForm.lang.en-us";
import { de_de } from "./RequestRoleForm.lang.de-de";
import { useAuth } from "../../../hooks";
import { useForm, Controller } from "react-hook-form";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";

i18n.addResourceBundle("ro-RO", "RequestRoleForm", ro_ro);
i18n.addResourceBundle("hu-HU", "RequestRoleForm", hu_hu);
i18n.addResourceBundle("en-US", "RequestRoleForm", en_us);
i18n.addResourceBundle("de-DE", "RequestRoleForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  select: {
    width: "100%",
    //maxWidth: 320
  },

  grid: {
    "& .MuiGrid-item": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },

  gridHorizontal: {
    "& .MuiGrid-item": {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 0,
      },
    },
    "& :last-child": {
      [theme.breakpoints.up("sm")]: {
        paddingRight: 0,
      },
    },
  },
  text: {
    maxWidth: theme.custom.typography.maxTextWidth,
  },
}));

/**
 * Displays the component
 */

const RequestRoleForm = (props) => {
  const { role: defaultRole, onSubmit, destinations } = props;

  const { user } = useAuth();
  const { name, email } = user;

  const { container, select, grid, gridHorizontal, text } = useStyles(props);
  const { t } = useTranslation("RequestRoleForm");

  const roles = [
    { label: t("seller"), value: "seller" },
    { label: t("host"), value: "host" },
  ];

  const { getRequestRoleFormSchema } = useValidationSchemas();

  const { errors, handleSubmit, formState, control } = useForm({
    validationSchema: getRequestRoleFormSchema(),
  });

  const { isSubmitted, isValid } = formState;

  /**
   * Manages the Role radio input
   */
  const [role, setRole] = useState(defaultRole);

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  /**
   * Handles the terms checkbox
   */
  const [accept, setAccept] = useState(false);

  const handleChangeCheckbox = (event) => {
    setAccept(event.target.checked);
  };

  /**
   * Displays the roles list
   */
  const rolesList = roles.map((item) => {
    return (
      <FormControlLabel
        value={item.value}
        control={<Radio />}
        label={item.label}
      />
    );
  });

  /**
   * Displays the destinations list
   */
  const destinationsList = destinations.map((item) => {
    const { id, name } = item;

    return (
      <MenuItem value={id} key={id}>
        {name}
      </MenuItem>
    );
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        className={clsx(container, grid, "RequestRoleForm")}
      >
        <Grid item xs={12}>
          <List dense disablePadding>
            <ListItem dense disableGutters>
              <ListItemText dense primary={`${t("name")}: ${name}`} />
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemText dense primary={`${t("email")}: ${email}`} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" error={Boolean(errors?.role)}>
            <FormLabel component="legend">{t("role")}</FormLabel>
            <Controller
              control={control}
              name="role"
              defaultValue={role}
              as={
                <RadioGroup row aria-label="role" onChange={handleChangeRole}>
                  {rolesList}
                </RadioGroup>
              }
            />
            {errors?.role && (
              <FormHelperText>{errors?.role?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          container
          spacing={2}
          className={clsx(container, grid, gridHorizontal)}
        >
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              error={Boolean(errors?.destination)}
              className={clsx(select)}
            >
              <InputLabel id="roles-destination-select-label">
                {t("select_destination")}
              </InputLabel>
              <Controller
                control={control}
                name="destination"
                as={
                  <Select
                    label={t("select_destination")}
                    labelId="roles-destination-select-label"
                    id="roles-destination-select"
                  >
                    {destinationsList}
                  </Select>
                }
              />
              {errors?.destination && (
                <FormHelperText>{errors?.destination?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={clsx(select)}>
              <Controller
                control={control}
                name="phone"
                as={
                  <TextField label={t("phone")} variant="outlined" id="phone" />
                }
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={clsx(select)}>
            <Controller
              control={control}
              name="message"
              as={
                <TextField
                  multiline
                  rows={4}
                  label={t("message")}
                  variant="outlined"
                  id="message"
                  error={Boolean(errors.message)}
                  helperText={errors.message?.message}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={Boolean(errors.terms)}>
            <FormControlLabel
              className={clsx(text)}
              control={
                <Controller
                  control={control}
                  name="terms"
                  defaultValue={false}
                  as={
                    <Checkbox
                      checked={accept}
                      onChange={handleChangeCheckbox}
                    />
                  }
                />
              }
              label={t("terms")}
            />
            {errors.terms && (
              <FormHelperText>{errors.terms.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} className={clsx(container)}>
            <Grid item>
              <Button>{t("cancel")}</Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={isSubmitted && !isValid}
                type="submit"
                variant="contained"
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

RequestRoleForm.propTypes = propTypes;
RequestRoleForm.defaultProps = defaultProps;

export default RequestRoleForm;
export {
  propTypes as RequestRoleFormPropTypes,
  defaultProps as RequestRoleFormDefaultProps,
};
