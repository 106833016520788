/**
 * Defines the data requirements for the component
 *
 * @see SortBy.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.anu,
    })
  ),
  defaultValue: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  options: [
    {
      key: "name_asc",
      value: {
        order: "asc",
        sort: "name",
      },
    },
    {
      key: "name_desc",
      value: {
        order: "desc",
        sort: "name",
      },
    },

    {
      key: "price_desc",
      value: {
        order: "desc",
        sort: "price",
      },
    },
    {
      key: "price_asc",
      value: {
        order: "asc",
        sort: "price",
      },
    },
  ],
  defaultValue: "name_asc",
};

export { propTypes, defaultProps };
