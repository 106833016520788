/**
 * Defines the data requirements for the component
 *
 * @see SearchResults.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { DestinationAsThumbPropTypes } from "../../_destination/DestinationAsThumb";
import { ProductAsThumbPropTypes } from "../../_product/ProductAsThumb";
import { AccommodationAsThumbPropTypes } from "../../_accommodation/AccommodationAsThumb";
import { AttractionAsThumbPropTypes } from "../../_attraction/AttractionAsThumb";
import { ArticleAsThumbPropTypes } from "../../_article/ArticleAsThumb";
import { EventAsThumbPropTypes } from "../../_event/EventAsThumb";
import { TabPanelPropTypes, TabPanelDefaultProps } from "../../TabPanel";
import i18n from "../../../i18n";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationAsThumbPropTypes)),
  products: PropTypes.arrayOf(PropTypes.shape(ProductAsThumbPropTypes)),
  accommodations: PropTypes.arrayOf(
    PropTypes.shape(AccommodationAsThumbPropTypes)
  ),
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionAsThumbPropTypes)),
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleAsThumbPropTypes)),
  events: PropTypes.arrayOf(PropTypes.shape(EventAsThumbPropTypes)),
  tabPanel: PropTypes.shape(TabPanelPropTypes),
  tabLabels: PropTypes.arrayOf(PropTypes.object),
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: [],
  attractions: [],
  products: [],
  accommodations: [],
  news: [],
  events: [],
  tabPanel: TabPanelDefaultProps,
  tabLabels: [
    { id: 1, name: i18n.t("SearchResults:all") },
    { id: 2, name: i18n.t("Destinations:title") },
    { id: 3, name: i18n.t("Attractions:title") },
    { id: 4, name: i18n.t("LocalMarket:title") },
    { id: 5, name: i18n.t("Stay:title") },
    { id: 6, name: i18n.t("News:title") },
    { id: 7, name: i18n.t("Events:title") },
  ],
};

export { propTypes, defaultProps };
