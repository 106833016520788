/**
 * Displays an Article as a thumb
 *
 * @see ArticleAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./ArticleAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./ArticleAsThumb.lang.ro-ro";
import { hu_hu } from "./ArticleAsThumb.lang.hu-hu";
import { en_us } from "./ArticleAsThumb.lang.en-us";
import { de_de } from "./ArticleAsThumb.lang.de-de";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "ArticleAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "ArticleAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "ArticleAsThumb", en_us);
i18n.addResourceBundle("de-DE", "ArticleAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const ArticleAsThumb = props => {
  const { name, slug, image, destinationName, newsSlug } = props;
  const to = `${newsSlug || ''}/${getSlugForCurrentLang(slug)}`;

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextThumbnail
      overline={destinationName}
      to={to.replace('//', '/')}
      title={name}
    />
  );

  const cardWithMedia = {
    image: {...image, imageWidth: 320},
    images: null,
    content: cardContent,
    customStyles: useStyles(props)
  };

  return <Card2 {...cardWithMedia} />;
};

ArticleAsThumb.propTypes = propTypes;
ArticleAsThumb.defaultProps = defaultProps;

export default ArticleAsThumb;
export {
  propTypes as ArticleAsThumbPropTypes,
  defaultProps as ArticleAsThumbDefaultProps
};
