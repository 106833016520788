/**
 * Defines the data requirements for the component
 *
 * @see RequestRoleForm.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { AccountPropTypes, AccountDefaultProps } from "../Account";
import { DestinationPropTypes } from "../../_destination/Destination";

/**
 * Defines the role types
 */

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From the API
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Account/createRequestRole
   */
  role: PropTypes.any,
  destinationId: PropTypes.number,
  phone: PropTypes.string,
  message: PropTypes.string,
  /**
   * Other props
   */
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  account: PropTypes.shape(AccountPropTypes),
  terms: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  role: "seller",
  destinationId: null,
  phone: null,
  message: null,
  destinations: [],
  account: AccountDefaultProps,
  terms: "",
};

export { propTypes, defaultProps };
