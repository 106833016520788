/**
 * Component short description
 *
 * @see NewsAsHero.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { TextSection } from "../../Theme";
/**
 * Imports other components and hooks
 */
import Card2, { Card2LocalMarketAsHeroStyles } from "../../Card2";
import Carousel from "../../Carousel";
import ArticleAsThumb from "../../_article/ArticleAsThumb";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./NewsAsHero.data";
/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./NewsAsHero.lang.ro-ro";
import { hu_hu } from "./NewsAsHero.lang.hu-hu";
import { en_us } from "./NewsAsHero.lang.en-us";
import { de_de } from "./NewsAsHero.lang.de-de";
import useNewsSwr, { useNewsInfo } from "../News/News.logic";
import { Link as RouterLink } from "react-router-dom";
import { useUpdateEffect } from "../../../hooks";

i18n.addResourceBundle("ro-RO", "NewsAsHero", ro_ro);
i18n.addResourceBundle("hu-HU", "NewsAsHero", hu_hu);
i18n.addResourceBundle("en-US", "NewsAsHero", en_us);
i18n.addResourceBundle("de-DE", "NewsAsHero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const NewsAsHero = (props) => {
  const { description: defaultDescription, articles: defaultArticles } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("NewsAsHero");

  const { data: newsData, mutate: mutateNews } = useNewsSwr();
  const { data: newsInfoData, mutate: mutateNewsInfo } = useNewsInfo();

  const { language } = i18n;
  useUpdateEffect(() => {
    mutateNews();
    mutateNewsInfo();
  }, [language]);

  const articles = newsData ? newsData.data : defaultArticles;
  const description = newsInfoData ? newsInfoData.description : defaultDescription;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextSection title={t("News:title")} description={description} />
  );

  const newsLink = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={{ pathname: `/${t('Routes:news')}` }}
      {...props}
    />
  ));

  const cardActions = (
    <Button variant="outlined" component={newsLink}>
      {t("view_more")}
    </Button>
  );

  const carouselSteps = isMobile
    ? articles.map((item, index) => {
      const newsSlug = item.startDate ? `/${t('Routes:events')}` : `/${t('Routes:news')}`;

      return {
        id: shortid.generate(),
        content: <ArticleAsThumb {...item} newsSlug={newsSlug} index={index} />,
      };
    })
    : isTablet
      ? articles
        .map((item, index) => {
          const newsSlug = item.startDate ? `/${t('Routes:events')}` : `/${t('Routes:news')}`;

          return index % 2 === 0
            ? {
              id: shortid.generate(),
              content: (
                <Grid
                  container
                  className={clsx(container)}
                  wrap="nowrap"
                  justify="space-around"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <ArticleAsThumb {...item} newsSlug={newsSlug} index={index} />
                  </Grid>
                  {articles[index + 1] && (
                    <Grid item xs={6}>
                      <ArticleAsThumb
                        {...articles[index + 1]}
                        index={index + 3}
                        newsSlug={newsSlug}
                      />
                    </Grid>
                  )}
                </Grid>
              ),
            }
            : null;
        })
        .filter((item) => item)
      : articles
        .map((item, index) => {
          const newsSlug = item.startDate ? `/${t('Routes:events')}` : `/${t('Routes:news')}`;

          return index % 3 === 0
            ? {
              id: shortid.generate(),
              content: (
                <Grid
                  container
                  className={clsx(container)}
                  wrap="nowrap"
                  justify="space-around"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <ArticleAsThumb newsSlug={newsSlug} {...item} index={index} />
                  </Grid>
                  {articles[index + 1] && (
                    <Grid item xs={6}>
                      <ArticleAsThumb
                        newsSlug={newsSlug}
                        {...articles[index + 1]}
                        index={index + 3}
                      />
                    </Grid>
                  )}
                </Grid>
              ),
            }
            : null;
        })
        .filter((item) => item);

  const cardWithMedia = {
    image: null,
    carousel: (
      <Carousel steps={carouselSteps} isMobileStepperAlignedRight={true} />
    ),
    content: cardContent,
    actions: cardActions,
    customStyles: Card2LocalMarketAsHeroStyles({
      ...props,
      breakpoint: "md",
    }),
  };

  return <Card2 {...cardWithMedia} />;
};

NewsAsHero.propTypes = propTypes;
NewsAsHero.defaultProps = defaultProps;

export default NewsAsHero;
export {
  propTypes as NewsAsHeroPropTypes,
  defaultProps as NewsAsHeroDefaultProps,
};
