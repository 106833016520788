const en_us = {
  title: "Events",
  no_events:
    "Currently there are no events for this location in the selected month",
  month: "Month",
  select_destination: "Select a destination",
  select_month: "Select a month",
  destination: "Destination",
  all: "All",
};

export { en_us };
