/**
 * Defines the data requirements for the component
 *
 * @see LocalMarketAsHero.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */

import {
  ProductAsThumbPropTypes,
  ProductAsThumbDefaultProps,
} from "../../_product/ProductAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape(ProductAsThumbPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "Description",
  products: Array(5)
    .fill(ProductAsThumbDefaultProps)
    .map((item, index) => {
      return { ...item, name: `Product ${index}` };
    }),
};

export { propTypes, defaultProps };
