/**
 * Defines the data requirements for the component
 *
 * @see EventAsThumb.md for details
 */

/**
 * Imports prop types from other components
 */
import { EventPropTypes, EventDefaultProps } from "../Event";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...EventPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...EventDefaultProps,
  image: {
    ...ImageResponsivePlaceholderCom169,
  },
};

export { propTypes, defaultProps };
