/**
 * Defines the data requirements for the component
 *
 * @see Footer.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { LogoPropTypes, LogoDefaultProps } from "../Logo";
import { MenuPropTypes } from "../Menu";
import { IconsPropTypes } from "../Icons";

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

/**
 * Defines the prop types
 */
const propTypes = {
  logo: PropTypes.shape(LogoPropTypes),
  menu: PropTypes.shape(MenuPropTypes),
  icons: PropTypes.shape(IconsPropTypes),
  currentPage: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  logo: LogoDefaultProps,
  menu: {
    items: [
      { id: shortid.generate(), name: "FAQ", key: "faq", path: "faq" },
      {
        id: shortid.generate(),
        name: "News",
        key: "media",
        path: "news",
      },
      {
        id: shortid.generate(),
        name: "Contact",
        key: "support",
        path: "contact",
      },
      {
        id: shortid.generate(),
        name: "Privacy Policy",
        key: "privacy",
        path: "privacy-policy",
      },
      {
        id: shortid.generate(),
        name: "Terms & Conditions",
        key: "terms",
        path: "terms-and-conditions",
      },
      {
        id: shortid.generate(),
        name: "ANPC",
        key: "anpc",
        path: "https://anpc.ro",
        external: true,
      },
      {
        id: shortid.generate(),
        name: "ANSVSA",
        key: "ansvsa",
        path: "http://www.ansvsa.ro",
        external: true,
      },
      {
        id: shortid.generate(),
        name: "Sitemap",
        key: "sitemap",
        path: "sitemap",
      },
    ],
    activeItem: "",
  },
  icons: {
    items: [
      {
        id: shortid.generate(),
        icon: <FacebookIcon />,
        url: "https://www.facebook.com/Pineyard-106357414567630",
      },
      {
        id: shortid.generate(),
        icon: <InstagramIcon />,
        url: "https://www.instagram.com/pineyard_g10",
      },
      {
        id: shortid.generate(),
        icon: <YouTubeIcon />,
        url: "https://youtube.com/channel/UCwqqdIeTLILC4jfvwo4nUGA",
      },
    ],
  },
  currentPage: "",
};

export { propTypes, defaultProps };
