const en_us = {
  add_to_cart: "Add to cart",
  description: "Description",
  unit: "Unit",
  units: "Untis",
  sing_in: "Sign in to buy",
  product_addded_to_cart: "Product was successfully added to cart",
};

export { en_us };
