const de_de = {
  add_to_cart: "In den Warenkorb legen",
  description: "Beschreibung",
  unit: "Einheit",
  units: "Untis",
  sing_in: "Zum Kaufen anmelden",
  product_addded_to_cart: "Produkt wurde erfolgreich in den Warenkorb gelegt",
};

export { de_de };
