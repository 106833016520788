/**
 * Defines the data requirements for the component
 *
 * @see StaySearch.md for details
 */
import PropTypes from "prop-types";
import AccommodationAsThumb from "../../_accommodation/AccommodationAsThumb";

/**
 * Defines the prop types
 */
const propTypes = {
  filters: PropTypes.any,
  stays: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape(AccommodationAsThumb)),
    limit: PropTypes.number,
  }),
  data: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  filters: {
    destination: null,
    accommodationType: null,
    roomType: null,
    extras: null,
    meals: null,
    limit: null,
    offset: null,
    order: null,
    sort: null,
    page: 1,
  },
  stays: {
    total: 0,
    data: [],
    limit: 0,
  },
  data: {
    destinations: [],
    accommodationTypes: [],
    roomTypes: [],
    extras: [],
    meals: [],
  },
};

export { propTypes, defaultProps };
