const ro_ro = {
  admin: "Admin",
  order_history: "alt istoric",
  your_account: "Contul meu",
  booking_history: "Istoric rezervare cazare",
  settings: "Setări",
  request_role: "Cerere rol",
  profile_role: "Rolul profilului",
  role: "Rol",
  seller: "Vânzător",
  host: "Gazdă",
  description: "Crearea contului",
};

export { ro_ro };
