/**
 * Defines the data requirements for the component
 *
 * @see ProductAsThumb.md for details
 */

/**
 * Imports prop types from other components
 */
import { ProductPropTypes, ProductDefaultProps } from "../Product";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...ProductPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...ProductDefaultProps,
  image: {
    ...ImageResponsivePlaceholderCom169,
  },
};

export { propTypes, defaultProps };
