/**
 * Defines the data requirements for the component
 *
 * @see useUnsplash.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The search query
   * @see https://unsplash.com/documentation#search-photos
   */
  keyword: PropTypes.string,
  /**
   * From whcih page to retrieve the photo
   * - usefull to get a set of random photos
   */
  page: PropTypes.number,
  /**
   * How many photos to retrieve
   */
  photos: PropTypes.number,
  /**
   * Other params
   * @see https://github.com/unsplash/unsplash-js#search
   */
  params: PropTypes.object,
  /**
   * The original image params
   */
  originalImageProps: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  keyword: "dogs",
  page: 1,
  photos: 1,
  params: null,
  originalImageProps: null
};

export { propTypes, defaultProps };
