/**
 * Defines the data requirements for the component
 *
 * @see TabPanel.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The index of the active tab
   */
  activeTabIndex: PropTypes.number,
  /**
   * The index of the current tab
   */
  currentTabIndex: PropTypes.number,
  /**
   * The content of the tab panel
   */
  children: PropTypes.any
};

/**
 * Defines the default props
 */
const defaultProps = {
  activeTabIndex: 0,
  currentTabIndex: 0,
  children: "Tab panel value"
};

export { propTypes, defaultProps };
