const ro_ro = {
  select_destination: "Alege destinaţia",
  cancel: "Anulare",
  email: "Email",
  host: "Gazdă",
  message: "Mesaj",
  phone: "Număr de telefon",
  name: "Nume",
  role: "Rol",
  terms: "Termeni şi condiţii",
  submit: "Trimite cererea",
  seller: "Vânzător",
};

export { ro_ro };
