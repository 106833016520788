/**
 * A Material UI Carousel (Mobile Stepper) component
 *
 * @see Carousel.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Carousel.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Carousel.lang.ro-ro";
import { hu_hu } from "./Carousel.lang.hu-hu";
import { en_us } from "./Carousel.lang.en-us";
import { de_de } from "./Carousel.lang.de-de";

i18n.addResourceBundle("ro-RO", "Carousel", ro_ro);
i18n.addResourceBundle("hu-HU", "Carousel", hu_hu);
i18n.addResourceBundle("en-US", "Carousel", en_us);
i18n.addResourceBundle("de-DE", "Carousel", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {},

  /**
   * Adjusting the design to the specifics of the project
   */
  stepper: (props) => ({
    justifyContent: "center",

    /**
     * Styling the background
     *
     * @see http://www.patternify.com/
     */
    paddingBottom: 0,
    paddingTop: 1,
    marginTop: 2,
    background:
      "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFElEQVQYlWNgGE4gcVPcf2LooQAA3zsMMS4TDHQAAAAASUVORK5CYII=)",
    backgroundRepeat: "repeat-x",
    backgroundPositionY: "center",
    "& .MuiButton-contained.Mui-disabled": {
      backgroundColor: theme.palette.grey[400],
    },

    /**
     * Configurable top margin
     * - when increased nav buttons look smaller
     * - tried to make nav buttons responsive, but they suck
     * - so this is a workaround
     */
    [theme.breakpoints.up(props.breakpoint)]: {
      marginTop: props.spacingTop ? theme.spacing(props.spacingTop) : "auto",
    },

    /**
     * Removing navigation dots
     */
    "& .MuiMobileStepper-dots": {
      display: "none",
    },
  }),

  button: {
    borderRadius: 0,
    boxShadow: "none",
    padding: theme.spacing(2),
  },
  carouselStep: {
    overflow: "hidden",
  },
  carouselSteps: {
    position: "relative",
  },
}));

/**
 * Defines an autoplay carousel
 */
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

/**
 * Displays the component
 */
const Carousel = (props) => {
  const {
    steps,
    mobileStepper,
    isMobileStepperDisplayed,
    isAutoPlay,
    customStyles,
  } = props;
  const { variant, nextButtonText, backButtonText } = mobileStepper;

  const { container, stepper, button, carouselSteps, carouselStep } = useStyles(
    customStyles
  );

  /**
   * Manages the stepper state
   */
  const maxSteps = steps.length;
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  /**
   * Manages the stepper clicks
   */
  const handleNext = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStepIndex(step);
  };

  /**
   * Displays the mobile stepper
   */
  const DisplayMobileStepper = () => (
    <MobileStepper
      steps={maxSteps}
      position="static"
      variant={variant}
      activeStep={activeStepIndex}
      className={clsx(stepper, "CarouselMobileStepper")}
      nextButton={
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStepIndex === maxSteps - 1 || !steps.length}
          className={clsx(button, "CarouselMobileStepperButton")}
        >
          {nextButtonText}
          <ArrowForwardIcon />
        </Button>
      }
      backButton={
        <Button
          variant="contained"
          color="primary"
          onClick={handleBack}
          disabled={activeStepIndex === 0 || !steps.length}
          className={clsx(button, "CarouselMobileStepperButton")}
        >
          <ArrowBackIcon />
          {backButtonText}
        </Button>
      }
    />
  );

  return (
    <Grid container className={clsx(container, "Carousel")}>
      <Grid item xs={12}>
        {isAutoPlay && (
          <AutoPlaySwipeableViews
            index={activeStepIndex}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            className={clsx(carouselSteps, "CarouselSteps")}
          >
            {steps &&
              steps.map((step, index) => {
                const { id, content } = step;
                // NOTE: Fix lazy loading
                return (
                  content && (
                    <div
                      className={clsx(carouselStep, "CarouselStep")}
                      key={id}
                    >
                      {Math.abs(activeStepIndex - index) <= 2 ? content : null}
                    </div>
                  )
                );
              })}
          </AutoPlaySwipeableViews>
        )}
        {!isAutoPlay && (
          <SwipeableViews
            index={activeStepIndex}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            className={clsx(carouselSteps, "CarouselSteps")}
          >
            {steps &&
              steps.map((step, index) => {
                const { id, content } = step;
                // NOTE: Fix lazy loading
                return (
                  content && (
                    <div
                      className={clsx(carouselStep, "CarouselStep")}
                      key={id}
                    >
                      {Math.abs(activeStepIndex - index) <= 2 ? content : null}
                    </div>
                  )
                );
              })}
          </SwipeableViews>
        )}
        {isMobileStepperDisplayed && <DisplayMobileStepper />}
      </Grid>
    </Grid>
  );
};

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
export { propTypes as CarouselPropTypes, defaultProps as CarouselDefaultProps };
