/**
 * Defines the data requirements for the component
 *
 * @see Questions.md for details
 */
import PropTypes from "prop-types";

import { QuestionPropTypes } from "../Question/Question";

/**
 * Defines the prop types
 */
const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(QuestionPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  list: [],
};

export { propTypes, defaultProps };
