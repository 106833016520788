const hu_hu = {
  email: "Email cím",
  destination: "Rendeltetési hely",
  message: "Üzenet",
  submit: "Üzenet küldése",
  name: "A neved",
  terms: "Felhasználási feltételek",
  select_destination: "Válassza ki a rendeltetési helyet",
  agree_text: "egyet értek a",
};

export { hu_hu };
