/**
 * Component short description
 *
 * @see VideoAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import ReactPlayer from "react-player";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./VideoAsThumb.data";

/**
 * Imports Material UI components
 */
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./VideoAsThumb.lang.ro-ro";
import { hu_hu } from "./VideoAsThumb.lang.hu-hu";
import { en_us } from "./VideoAsThumb.lang.en-us";
import { de_de } from "./VideoAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "VideoAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "VideoAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "VideoAsThumb", en_us);
i18n.addResourceBundle("de-DE", "VideoAsThumb", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const VideoAsThumb = (props) => {
  const { name, url } = props;

  /**
   * Displays thumb as a card
   */
  const cardContent = (
    <Typography variant="body1">
      <ReactPlayer url={url} controls width="100%" height="auto" />

      <Link href={url}>{name}</Link>
    </Typography>
  );

  const cardWithMedia = {
    image: null,
    images: null,
    content: cardContent,
  };

  return <Card2 {...cardWithMedia} breakpoint="sm" />;
};

VideoAsThumb.propTypes = propTypes;
VideoAsThumb.defaultProps = defaultProps;

export default VideoAsThumb;
export {
  propTypes as VideoAsThumbPropTypes,
  defaultProps as VideoAsThumbDefaultProps,
};
