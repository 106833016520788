/**
 * Displays a Stay
 *
 * @see Stay.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { Route, Switch, useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Accommodation from "../../_accommodation/Accommodation";
import StaySearch from "../StaySearch/StaySearch";
import { FiltersProvider, useUpdateEffect } from "../../../hooks";
import { useStayInfoSwr } from "./Stay.logic";
import { PageHeader, TextSection } from "../../Theme";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Stay.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Stay.lang.ro-ro";
import { hu_hu } from "./Stay.lang.hu-hu";
import { en_us } from "./Stay.lang.en-us";
import { de_de } from "./Stay.lang.de-de";

i18n.addResourceBundle("ro-RO", "Stay", ro_ro);
i18n.addResourceBundle("hu-HU", "Stay", hu_hu);
i18n.addResourceBundle("en-US", "Stay", en_us);
i18n.addResourceBundle("de-DE", "Stay", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Stay = (props) => {
  const { staySearch } = props;
  const { filters, data } = staySearch;

  const { container } = useStyles(props);
  const { setSlugs } = useSlugs();

  const { description: metaDescription, title, keywords } = useMeta({
    type: "route",
    path: "stay",
  });

  const { t, i18n } = useTranslation("Stay");
  const { language } = i18n;

  const { data: stayInfoData, mutate: mutateStayInfo } = useStayInfoSwr();

  const stayInfo = stayInfoData ?? props;
  const { description, image } = stayInfo;

  useUpdateEffect(() => {
    mutateStayInfo();
  }, [language]);

  useEffect(() => {
    setSlugs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Defines the routes for accommodations
   */
  const match = useRouteMatch();
  const { path } = match;

  /**
   * Displays the hero card
   */
  const cardContent1 = <TextSection description={description} />;

  const cardWithMedia1 = {
    image,
    content: cardContent1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Accommodation destinationName="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Head title={title} description={metaDescription} keywords={keywords} />
        <Layout header={{ currentPage: "Stay" }}>
          <Grid container spacing={1} className={clsx(container, "Stay")}>
            <Grid item xs={12}>
              <PageHeader title={t("title")} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia1} />
            </Grid>
            <Grid item xs={12}>
              <FiltersProvider filters={filters} data={data}>
                <StaySearch />
              </FiltersProvider>
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Stay.propTypes = propTypes;
Stay.defaultProps = defaultProps;

export default Stay;
export { propTypes as StayPropTypes, defaultProps as StayDefaultProps };
