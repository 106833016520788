/**
 * Defines the data requirements for the component
 *
 * @see News.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { ArticleAsThumbPropTypes } from "../../_article/ArticleAsThumb";
import { DestinationPropTypes } from "../../_destination/Destination";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > NewsInfo
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/News/getNews
 */
const propTypes = {
  /**
   * Props from the API
   */
  /**
   * Short description (for the frontpage)
   */
  description: PropTypes.string,
  /**
   * Internal props
   *
   * // NOTE: It seems not only articles are listed here but also events. In this case please be inspired by SearchResults
   */
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleAsThumbPropTypes)),
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  filters: {
    destination: " ", // This has to be " " in order to properly work with the backend.
    month: null,
    limit: null,
    perPage: 10,
    page: 1,
  },
  description: "",
  articles: [],
  destinations: [],
};

export { propTypes, defaultProps };
