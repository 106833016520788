/**
 * Defines the data requirements for the component
 *
 * @see FilterAccomodationType.md for details
 */
import PropTypes from "prop-types";
import {
  FilterListItemPropTypes,
  FilterListItemDefaultProps,
} from "../../FilterListItem";

/**
 * Defines the prop types
 */
const propTypes = {
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
  defaultValue: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  filterListItem: FilterListItemDefaultProps,
  defaultValue: [],
};

export { propTypes, defaultProps };
