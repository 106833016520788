const hu_hu = {
  request_role: "Szerep kérése",
  name: "Név",
  email: "Email",
  seller: "Eladó",
  host: "Házigazda",
  destination: "Rendeltetési hely",
  location: "Elhelyezkedés",
  phone_number: "Telefonszám",
  message: "Üzenet",
  send_request: "Felkérést küld",
  send_request_error: "Valami elromlott. Kérlek próbáld újra.",
  agree_text: "egyet értek a",
  terms_and_conditions: "Felhasználási feltételek",
  description: "Leírás",
};

export { hu_hu };
