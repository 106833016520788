const hu_hu = {
  home_title: 'Turisztikai attrakciók Gyergyói-havasok környékén| Pineyard.ro',
  home_description:
    'A Gyergyói-havasok környéke magában foglalja a borszéki üdülőhelyet és a következő községeket: Gyergyótölgyes, Gyergyóholló, Ditró, Gyergyóremete, Gyergyószárhegy, Gyergyóalfalu, Gyergyócsomafalva, Gyergyóújfalu és Vasláb | Többet megtudhatsz a pineyard.ro oldalon',
  home_keywords: 'Pineyard,Giurgeu,regiunea Giurgeu,atractii turistice Giurgeu,Harghita,Borsec,cazare munte',
  destinations_title: 'A Gyergyói-havasok környéke legfontosabb turisztikai látványosságai | Pineyard.ro',
  destinations_description:
    'A kilenc településből álló régió rengeteg turisztikai látnivalóval rendelkezik amelyeket látni és értékelni kell | Pineyard.ro',
  destinations_keywords: 'obiective turistice Giurgeu,destinatii turistice Harghita,Borsec,Corbu,Ciumani',
  ditrau_title: 'Ditró a Gyergyói-medence középső részén található | Pineyard.ro',
  ditrau_description:
    'A Ditrói alkáli masszívumnál írták le először a nefelinnel és szodalittal rendelkező szienitet, amelyet Ditróitnak neveznek | Pineyard.ro',
  ditrau_keywords: 'Ditrau,comuna Ditrau,cazare Ditrau,vremea Ditrau',
  corbu_title: 'Gyergyóholló község a Besztercei-havasok és a Gyergyói-havasok között helyezkedik el | Pineyard.ro',
  corbu_description:
    'Gyergyóholló község számos szállás lehetőséggel és gyönyörű hegyi tájakkal fogad, amelyek nyáron bejárhatók 12 túraútvonalon | Pineyard.ro',
  corbu_keywords: 'Corbu,cazare Corbu,primaria Corbu, vremea Corbu,pensiuni Corbu',
  ciumani_title: 'Csomafalva egy etnokulturális régióban található Kelet-Erdélyben| Pineyard.ro',
  ciumani_description:
    'Azok, akik ellátogatnak Gyergyócsomafalvára, a sípálya panorámájában gyönyörködhetnek és sós vízzel telt cseberből csodálhatják a csillagokat. | Pineyard.ro',
  ciumani_keywords: 'Ciumani,vremea Ciumani,cazare Ciumani,pensiuni Ciumani',
  remetea_title: 'Gyergyóremete Hargita megye nagy települései egyike | Pineyard.ro',
  remetea_description:
    'Gyergyóremete idilli helyen fekszik, Ditróval szemben, a Maros folyó bal oldalán, a Kőpatak völgyében. | Pineyard.ro',
  remetea_keywords: 'Remetea,vremea Remetea,primaria Remetea,comuna Remetea,cazare Remetea',
  joseni_title: 'Gyergyóalfalu egy község, amely Hargita megyében, Romániában található | Pineyard.ro',
  joseni_description:
    'Gyergyóalfalu jelentése "Alsó falu", és egy Erdély keleti részén találhatő három faluból álló etnokulturális központ. | Pineyard.ro',
  joseni_keywords: 'Joseni,vremea Joseni,Joseni Harghita,cazare Joseni',
  lazarea_title: 'Szárhegy község a Gyergyói-medence egyik legrégebbi települése | Pineyard.ro',
  lazarea_description:
    'Szárhegy község legelső írásos adatai 1200-as évekből származnak, és négy fontos műemlékkel rendelkezik, amelyek más-más történelmi korszakot képvisel | Pineyard.ro',
  lazarea_keywords: 'Lazarea,comuna Lazarea,Lazarea Harghita,cazare Lazarea Harghita',
  tulghes_title: 'Gyergyótölgyes nagyrészt román település Hargita megye északkeleti részén | Pineyard.ro',
  tulghes_description:
    'Gyergyótölgyes természeti és kulturális turisztikai látnivalókat, szórakoztató és szabadidős szolgáltatásokat, és nem utolsósorban szálláshely-szolgáltatásokat kínál| Pineyard.ro',
  tulghes_keywords: 'Tulghes,vremea Tulghes,cazare Tulghes,Tulghes Toplita,primaria Tulghes',
  borsec_title: 'Borszék a Gyergyói, Besztercei és Kelemen-havasok interferenciáján található | Pineyard.ro',
  borsec_description:
    'Borszék legnagyobb gazdagsága a számos ásványvízforrás, a különböző nemzetközi vásárokon elnyert díjakat az Ásványvizek Múzeumában állítják ki. | Pineyard.ro',
  borsec_keywords: 'Borsec,cazare Borsec,partia Borsec,statiunea Borsec,vremea Borsec,webcam Borsec',
  suseni_title: 'Gyergyóújfalu település alapítása 1330 körül becsülhető meg | Pineyard.ro',
  suseni_description:
    'A Gyergyóújfalu község külterületén található Katorzsa nevű helység a Gyergyói-medence egyik legrégebbi települése. | Pineyard.ro',
  suseni_keywords: 'Suseni,Suseni Harghita,cazare in Suseni,atractii turistice Suseni Harghita',
  voslabeni_title: 'Vasláb községhez Vasláb és Marosfő falvak tartoznak| Pineyard.ro',
  voslabeni_description:
    'A Marosfőtől északra található Vasláb nevű település egy tipikus hegyi falu, ahol békés, dolgos emberek laknak. | Pineyard.ro',
  voslabeni_keywords: 'Voslabeni,vremea in Voslabeni,Voslabeni Harghita,primaria Voslabeni Harghita',
  local_market_title: 'Ez a része a weboldalnak lehetőséget ad helyi termékek online vásárlására | Pineyard.ro',
  local_market_description:
    'Ezen a weboldalon keresztül megismerheti a Gyergyói-medence helyi termelők által eladásra kínált termékeket. | Pineyard.ro',
  local_market_keywords:
    'Produse locale jud Harghita,produse locale Giurgeu,cumpara produse locale,producatori locali Giurgeu',
  stay_title:
    'A Gyergyói-medence környéke minőségi szállásszolgáltatásokat kínál két, három és négy csillagos kategóriában | Pineyard.ro',
  stay_description:
    'Az oldalunkon keresztül választhatsz szállást a Gyergyói-medencében, szállodában, panzióban, villában, hostelben, kulcsosházban vagy kempingben | Pineyard.ro',
  stay_keywords: 'Cazare Giurgeu,cazare jud Harghita,cazare munte,pensiune cu piscina Harghita,cazare munte piscina',
  news_title: 'Hírek és újdonságok a Gyergyói-medence környékéből| Pineyard.ro',
  news_description:
    'Ismerje meg a környék legfrissebb híreit, legyen szó hegyi szállásokról vagy a Gyergyói-medence legújabb helyi termékeiről. | Pineyard.ro',
  news_keywords: 'Stiri judetul Harghita,cazare Harghita,stiri Giurgeu,primaria Giurgeu',
  contact_title: 'Forduljon hozzánk bizalommal, csapatunk az Ön rendelkezésére áll a nap 24 órájában| Pineyard.ro',
  contact_description:
    'Ha bármilyen kérdése van az oldalunkon megosztott információkkal kapcsolatosan, forduljon hozzánk bizalommal| Pineyard.ro',
  contact_keywords: 'Trimite mesaj,contact Pineyard',
  events_title: 'A Gyergyói-medence és környéke legfontosabb eseményei | Pineyard.ro',
  events_description:
    'Összegyűjtöttük a Gyergyói-medence legfontosabb eseményeit, bemutatókat, vásárokat, stb.| Pineyard.ro',
  events_keywords: 'Evenimente Harghita,evenimente jud Harghita,evenimente Giurgeu,concerte Harghita',
};

export { hu_hu };
