const de_de = {
  address: "Adresse",
  cancel_order: "Bestellung stornieren",
  order: "Auftrag",
  order_date: "Auftragsdatum",
  info: "Lieferinformationen",
  personal_info: "Persönliche Informationen",
  name: "Name",
  total_price: "Gesamtpreis",
  order_status: "Bestellstatus",
  phone: "Telefon",
  seller: "Verkäufer",
  cancelled: "Abgesagt",
  confirmed: "Bestätigt",
  paid: "Bezahlt",
  shipped: "Versand",
  refused: "Verweigert",
  return_requested: "Rücksendung angefordert",
  returned: "Ist zurückgekommen",
  pending: "steht aus",
  order_number: "Bestellnummer",
  items: "Artikel",
  item: "Artikel",
  order_cancellation: "Auftragsstornierung",
  confirm_cancel: "Bestätigen Abbrechen",
  back_to_order: "Zurück zur Bestellung",
  order_cancelled: "Ihre Bestellung wurde storniert",
  cancel_order_success: "Ihre Bestellung wurde storniert",
  cancel_order_failed:
    "Bei der Stornierung Ihrer Bestellung ist ein Fehler aufgetreten.",
  get_order_failed: "Etwas ist schief gelaufen. Bitte versuche es erneut.",
  payment_success:
    "Nach erfolgreicher Zahlung können Sie jetzt zur App zurückkehren",
  complete_payment: "Zur vollständigen Bezahlung",
  order_detail: "Bestelldetails",
  return_order: "Rückgabeauftrag",
  order_returned_successfully: "Bestellung erfolgreich zurückgegeben",
};

export { de_de };
