/**
 * Defines the data requirements for the component
 *
 * @see MarketFilters.md for details
 */
import PropTypes from "prop-types";
import {
  FilterDestinationPropTypes,
  FilterDestinationDefaultProps,
} from "../../FilterDestination";
import {
  FilterSellerPropTypes,
  FilterSellerDefaultProps,
} from "../FilterSeller/FilterSeller";
import {
  FilterCategoryPropTypes,
  FilterCategoryDefaultProps,
} from "../FilterCategory/FilterCategory";

/**
 * Defines the prop types
 */
const propTypes = {
  filterDestination: PropTypes.shape(FilterDestinationPropTypes),
  filterSeller: PropTypes.shape(FilterSellerPropTypes),
  filterCategory: PropTypes.shape(FilterCategoryPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  filterDestination: FilterDestinationDefaultProps,
  filterSeller: FilterSellerDefaultProps,
  filterCategory: FilterCategoryDefaultProps,
};

export { propTypes, defaultProps };
