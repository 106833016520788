const ro_ro = {
  current_email: "Adresa de email actuală",
  new_email_confirm: "Confirmă adresa nouă de email",
  new_password_confirm: "Confrimă parola nouă",
  new_email: "Emial nou",
  double_confirmation: "Este necesară a doua confirmare",
  title_general: "Generale",
  current_name: "Nume actual",
  new_name: "Nume nou",
  title_current_password: "Parola actuală",
  current_password: "Parola actuală",
  new_password: "Parola nouă",
  submit: "Salvare",
  title_change_email: "Schimbă adresa de email",
  title_change_password: "Schimbă parola",
  settings: "Setări",
  update_profile_success: "Contul dvs. a fost actualizat cu succes.",
  update_profile_failed: "Ceva n-a mers bine. Vă rugăm să încercați din nou.",
};

export { ro_ro };
