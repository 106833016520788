/**
 * Component short description
 *
 * @see Afir.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Afir.data";
/**
 * Imports Material UI components
 */
import { Link, makeStyles } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Afir.lang.ro-ro";
import { hu_hu } from "./Afir.lang.hu-hu";
import { en_us } from "./Afir.lang.en-us";
import { de_de } from "./Afir.lang.de-de";
/**
 * Imports other components and hooks
 */
import { TextDefault } from "../../Theme/Theme";
import Modal from "../../Modal/Modal";
import Grid from "@material-ui/core/Grid";

i18n.addResourceBundle("ro-RO", "Afir", ro_ro);
i18n.addResourceBundle("hu-HU", "Afir", hu_hu);
i18n.addResourceBundle("en-US", "Afir", en_us);
i18n.addResourceBundle("de-DE", "Afir", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  AFIRLogoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
    color: '#fff',
  },
  AFIRLogoSection: {
    textAlign: 'right',
  },
  AFIRLogoStyle: {
    width: '120px',
  },
  AFIRBannerStyle: {
    width: '100%'
  }
}));

/**
 * Displays the component
 */
const Afir = (props) => {
  const { t } = useTranslation("Afir");
  const { AFIRLogoStyle, AFIRLogoSection, AFIRLogoContainer, AFIRBannerStyle } = useStyles(props);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [AFIRBanner, setAFIRBanner] = useState(null);

  const AFIRLogo = require('../../../assets/images/AFIR_logo.jpg');
  const handleAFIRClick = () => {
    const AFIRBannerImage = require('../../../assets/images/AFIR_banner.jpg');

    setAFIRBanner(AFIRBannerImage);
    setIsModalOpen(true);
  };

  return (
    <>
      <Grid container item xs={12} className={clsx(AFIRLogoContainer, "Afir")}>
        <Grid item xs={12} sm={6} md={6} className={clsx(AFIRLogoSection, "Afir")}>
          <img
            src={AFIRLogo}
            alt="AFIR logo"
            className={clsx(AFIRLogoStyle, "Afir")}
            onClick={handleAFIRClick}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextDefault text={t("title")} />
        </Grid>
      </Grid>
      <Modal
        title={t("title")}
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        {
          AFIRBanner && (
            <Grid item xs={12}>
              <img
                src={AFIRBanner}
                alt="AFIR banner"
                className={clsx(AFIRBannerStyle, 'Afir')}
              />
            </Grid>
          )
        }
        <Link href="https://www.afir.info">{t('website')}</Link>
      </Modal>
    </>
  );
};

Afir.propTypes = propTypes;
Afir.defaultProps = defaultProps;

export default Afir;
export { propTypes as AfirPropTypes, defaultProps as AfirDefaultProps };
