/**
 * A (missing) TabPanel component for MUI.
 *
 * @see TabPanel.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./TabPanel.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./TabPanel.lang.ro-ro";
import { hu_hu } from "./TabPanel.lang.hu-hu";
import { en_us } from "./TabPanel.lang.en-us";
import { de_de } from "./TabPanel.lang.de-de";

i18n.addResourceBundle("ro-RO", "TabPanel", ro_ro);
i18n.addResourceBundle("hu-HU", "TabPanel", hu_hu);
i18n.addResourceBundle("en-US", "TabPanel", en_us);
i18n.addResourceBundle("de-DE", "TabPanel", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const TabPanel = (props) => {
  const { activeTabIndex, currentTabIndex, children } = props;

  const visible = activeTabIndex === currentTabIndex;

  return (
    <div hidden={!visible} className={clsx("TabPanel")}>
      {visible && children}
    </div>
  );
};

TabPanel.propTypes = propTypes;
TabPanel.defaultProps = defaultProps;

export default TabPanel;
export { propTypes as TabPanelPropTypes, defaultProps as TabPanelDefaultProps };
