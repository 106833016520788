/**
 * Imports React and third party packages
 */
import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

/**
 * Imports other components and hooks
 */
import { AuthProvider } from "./hooks";
import Routes from "./components/Routes";
import theme from "./theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SWRConfig } from "swr";
import { SlugProvider } from "./hooks/useSlugs/useSlugs";
import { ConsentProvider } from "./hooks/useConsent/useConsent";
import GoogleAnalytics from "./components/GoogleAnalytics/GoogleAnalytics";
import { CartItemsProvider } from "./hooks/useCartItems/useCartItems";

/**
 * Displays the app
 */
const App = () => {
  const notistackRef = useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ConsentProvider>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                  <CloseIcon />
                </IconButton>
              )}
            >
              <AuthProvider strategy="oauth">
                <SWRConfig
                  value={{
                    revalidateOnFocus: false,
                    shouldRetryOnError: false,
                    revalidateOnMount: true,
                    errorRetryCount: 0,
                  }}
                >
                  <SlugProvider>
                    <CartItemsProvider>
                      <Routes />
                    </CartItemsProvider>
                    <GoogleAnalytics />
                  </SlugProvider>
                </SWRConfig>
              </AuthProvider>
            </SnackbarProvider>
          </ConsentProvider>
        </MuiPickersUtilsProvider>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
