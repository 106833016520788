/**
 * Displays categories using a Tabs component
 *
 * @see CategoriesAsTabs.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

/**
 * Imports other components and hooks
 */
import CategoryAsHero from "../CategoryAsHero";
import TabPanel from "../../TabPanel";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CategoriesAsTabs.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./CategoriesAsTabs.lang.ro-ro";
import { hu_hu } from "./CategoriesAsTabs.lang.hu-hu";
import { en_us } from "./CategoriesAsTabs.lang.en-us";
import { de_de } from "./CategoriesAsTabs.lang.de-de";

i18n.addResourceBundle("ro-RO", "CategoriesAsTabs", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoriesAsTabs", hu_hu);
i18n.addResourceBundle("en-US", "CategoriesAsTabs", en_us);
i18n.addResourceBundle("de-DE", "CategoriesAsTabs", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 * Displays the component
 */
const CategoriesAsTabs = (props) => {
  const { categories, onTabSelect, selectedTab } = props;
  const { container, tabs: tabsClass, panels: panelsClass } = useStyles(props);
  const { t } = useTranslation("CategoriesAsTabs");

  /**
   * Manages the state of the active tab
   */
  const [activeTabIndex, setActiveTabIndex] = useState(selectedTab);

  /**
   * Manages to click on tab labels
   */
  const handleTabClick = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  /**
   * Displays the tab labels
   */
  const labels = categories.map((category) => {
    const { name, id } = category;
    return (
      <Tab
        label={t(name)}
        value={id}
        key={id}
        onClick={() => onTabSelect(id)}
      />
    );
  });

  /**
   * Displays the tab panels
   */
  const panels = categories.map((category, index) => {
    const { id } = category;
    return (
      <TabPanel
        index={index}
        activeTabIndex={activeTabIndex}
        currentTabIndex={id}
        key={id}
      >
        <CategoryAsHero {...category} index={index} />
      </TabPanel>
    );
  });

  return (
    <Grid container className={clsx(container, "CategoriesAsTabs")}>
      <Grid item xs={12}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabClick}
          variant="scrollable"
          scrollButtons="auto"
          className={clsx(tabsClass, "Tabs")}
        >
          {labels}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <div className={clsx(panelsClass, "Panels")}>{panels}</div>
      </Grid>
    </Grid>
  );
};

CategoriesAsTabs.propTypes = propTypes;
CategoriesAsTabs.defaultProps = defaultProps;

export default CategoriesAsTabs;
export {
  propTypes as CategoriesAsTabsPropTypes,
  defaultProps as CategoriesAsTabsDefaultProps,
};
