/**
 * Defines the data requirements for the component
 *
 * @see Icons.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports other components and hooks
 */
import { IconPropTypes, IconDefaultProps } from "../Icon";

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(IconPropTypes)),
  activeItem: PropTypes.string,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [
    {
      IconDefaultProps,
    },
  ],
  activeItem: null,
  orientation: "horizontal",
};

export { propTypes, defaultProps };
