const de_de = {
  register: "Begleiten Sie uns",
  description: "Treten Sie uns bei, indem Sie die folgenden Details eingeben",
  register_success:
    "Erfolgreiche Registrierung. Sie können sich jetzt anmelden.",
  login: "Einloggen!",
  account: "Sie haben bereits ein Konto?",
};

export { de_de };
