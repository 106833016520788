/**
 * Component short description
 *
 * @see NewsSearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./NewsSearch.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./NewsSearch.lang.ro-ro";
import { hu_hu } from "./NewsSearch.lang.hu-hu";
import { en_us } from "./NewsSearch.lang.en-us";
import { de_de } from "./NewsSearch.lang.de-de";
/**
 * Imports other components and hooks
 */
import Pagination from "../../Pagination";
import { useFilters, useUpdateEffect } from "../../../hooks";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import useNewsSwr from "../News/News.logic";
import ArticleAsThumb from "../ArticleAsThumb";
import SelectFromListForm from "../../Forms/SelectFromListForm";
import EventAsThumb from "../../_event/EventAsThumb/EventAsThumb";

i18n.addResourceBundle("ro-RO", "NewsSearch", ro_ro);
i18n.addResourceBundle("hu-HU", "NewsSearch", hu_hu);
i18n.addResourceBundle("en-US", "NewsSearch", en_us);
i18n.addResourceBundle("de-DE", "NewsSearch", de_de);

const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  select: {
    width: "100%",
    maxWidth: 320,
  },
}));

const NewsSearch = (props) => {
  const { container } = useStyles(props);
  const { i18n, t } = useTranslation("NewsSearch");
  const { language } = i18n;

  const { articles: defaultArticles, data: defaultData } = props;
  const { destinations: defaultDestinations } = defaultData;

  const {
    filters,
    setFilters,
    parseFiltersForQuery,
    setFiltersAndResetPagination,
  } = useFilters();
  const { destination, page } = filters;

  const [articles, setArticles] = useState(defaultArticles);
  const { total, perPage, data: articlesData } = articles;

  const {
    data: destinations = defaultDestinations,
    mutate: mutateDestinations,
  } = useDestinationsSimpleSwr();
  const { data: newsData, mutate: mutateEvents } = useNewsSwr({
    ...parseFiltersForQuery(filters),
  });

  useEffect(() => {
    if (newsData) {
      setArticles(newsData);
    }
  }, [newsData]);

  /**
   * Refetch data if language changes
   */
  useUpdateEffect(() => {
    mutateEvents();
    mutateDestinations();
  }, [language]);

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    setFiltersAndResetPagination({
      destination: value,
    });
  };

  const handlePagination = (_event, value) => {
    setFilters((prevState) => ({
      ...prevState,
      page: value,
      perPage: perPage,
    }));
  };

  const articlesList = (list) => {
    return list.map((item, index) => {
      const { startDate } = item;
      const type = startDate ? "event" : "article";
      return (
        <Grid item xs={12} sm={6} md={4} key={item.id + type}>
          {startDate ? (
            <EventAsThumb {...item} index={index} />
          ) : (
            <ArticleAsThumb {...item} index={index} />
          )}
        </Grid>
      );
    });
  };

  /**
   * Displays a placeholder skeleton while data is loading
   * // NOTE: When defined in `EventAsThumb` it throws an error
   */
  const skeletonsList = Array(12)
    .fill(0)
    .map((item, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <>
          <Skeleton width="60%" />
          <Skeleton variant="rect" width={320} height={180} />
          <Skeleton />
        </>
      </Grid>
    ));

  const articlesListOrSkeleton = newsData
    ? articlesList(articlesData)
    : skeletonsList;

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className={clsx(container)}
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={7}>
          <SelectFromListForm
            {...{
              items: destinations,
              value: destination,
              label: t("News:select_destination"),
              onChangeEvent: handleDestinationChange,
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={clsx(container)} spacing={1} item xs={12}>
        {articlesListOrSkeleton}
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <Pagination
          total={total}
          pageSize={Number(perPage)}
          page={page}
          onChange={handlePagination}
        />
      </Grid>
    </>
  );
};

NewsSearch.propTypes = propTypes;
NewsSearch.defaultProps = defaultProps;

export default NewsSearch;
export {
  propTypes as NewsSearchPropTypes,
  defaultProps as NewsSearchDefaultProps,
};
