import { getSlugForCurrentLang } from "../components/Routes/Routes.logic";
import shortid from "shortid";
import Icon from "../components/Icon";
import React from "react";

export const getSearchParams = (text) => {
  return text ? `?text=${text}` : '';
}

export const getDestinationUrl = (url, slug, id, route) => {
  let newUrl = `${url}/${getSlugForCurrentLang(slug)}`;
  if (url.indexOf(route) === -1) {
    newUrl = {
      state: { categoryId: id },
      pathname:`${url}/${route}`.replace('//', '/')
    };
  }

  return newUrl;
}

export const getUrlItems = (urlActions) => {
  const urlItems = [];
  urlActions.forEach((action) =>{
    const { url, icon } = action;
    if (!url) {
      return;
    }

    urlItems.push({
      id: shortid.generate(),
      action: <Icon {...icon} url={url} noPadding/>,
    });
  });

  return urlItems;
}
