/**
 * Component short description
 *
 * @see Product.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import { useUpdateEffect } from "../../../hooks";
import {
  Actions,
  PageHeader,
  Template,
  TextPrice,
  TextSection,
} from "../../Theme";
import Breadcrumbs from "../../Breadcrumbs";
import UnitsForm from "../../_localMarket/UnitsForm/UnitsForm";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import { TextDefault } from "../../Theme/Theme";
import FacebookShare from "../../FacebookShare/FacebookShare";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Product.data";
import {
  getProductSlugFromPath,
  useMarketProductSwr,
} from "../../_localMarket/LocalMarket/LocalMarket.logic";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Product.lang.ro-ro";
import { hu_hu } from "./Product.lang.hu-hu";
import { en_us } from "./Product.lang.en-us";
import { de_de } from "./Product.lang.de-de";

i18n.addResourceBundle("ro-RO", "Product", ro_ro);
i18n.addResourceBundle("hu-HU", "Product", hu_hu);
i18n.addResourceBundle("en-US", "Product", en_us);
i18n.addResourceBundle("de-DE", "Product", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Product = (props) => {
  const { container } = useStyles(props);
  const location = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation("Product");

  const { addSlug } = useSlugs();

  const [product, setProduct] = useState(props);

  const { data: productData, mutate: mutateProduct } = useMarketProductSwr({
    id: getProductSlugFromPath(location.pathname),
  });

  const {
    name,
    images,
    description,
    price,
    sectionDescription1,
    destinationName,
    max,
    min,
    unit,
    nrOfViews,
    id,
  } = product;

  useEffect(() => {
    if (productData) {
      if (!isEqual(productData, product)) {
        setProduct(productData);
        addSlug(productData.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  useUpdateEffect(() => {
    mutateProduct();
  }, [language, mutateProduct]);

  /**
   * Displays the hero card
   */
  const carouselSteps1 = images.map((image, index) => {
    const { title } = image;

    return {
      id: String(index),
      title: title,
      content: <ImageResponsive {...image} />,
    };
  });

  const cardContent1 = <TextSection description={description} />;
  const price1 = <TextPrice text={`${price} RON ${unit}`} />;

  const cardActions1 = (
    <Actions
      items={[
        { id: 1, action: price1 },
        {
          id: 2,
          action: <UnitsForm {...{ max, min, unit, productId: id }} />,
        },
        {
          id: 3,
          action: <FacebookShare />,
        },
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  /**
   * Displays the template
   */
  const cardWithMedia2 = {
    image: null,
    images: null,
    content: <TextDefault text={sectionDescription1} html={true}/>,
    actions: null,
    customStyles: {},
  };

  const visitorsCounterCard = nrOfViews && {
    image: null,
    content: (
      <TextDefault text={`${t("Footer:visitors_counter")}: ${nrOfViews}`} />
    ),
  };

  return (
    <Layout header={{ currentPage: "Local Market" }}>
      <Grid container className={clsx(container, "Product")} spacing={1}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...visitorsCounterCard} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template items={[<Card2 {...cardWithMedia2} />]} />
        </Grid>
      </Grid>
    </Layout>
  );
};

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

export default Product;
export { propTypes as ProductPropTypes, defaultProps as ProductDefaultProps };
