const hu_hu = {
  text: "{{price}} RON / éjszaka ártól",
  number_of_guests: "Vendégek: {{guests}}",
  number_of_rooms: "Szobák: {{rooms}}",
  view_message: "Üzenet megtekintése",
  message_modal_title: "Üzenet",
  show_more: "Mutass többet",
  show_less: "Mutass kevesebbet",
};

export { hu_hu };
