/**
 * Component short description
 *
 * @see Routes.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Routes.data";
/**
 * Imports logic
 */
import { localizePath } from "./Routes.logic";
import { getLocale } from "../LanguageSelector/LanguageSelector";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Routes.lang.ro-ro";
import { hu_hu } from "./Routes.lang.hu-hu";
import { en_us } from "./Routes.lang.en-us";
import { de_de } from "./Routes.lang.de-de";

i18n.addResourceBundle("ro-RO", "Routes", ro_ro);
i18n.addResourceBundle("hu-HU", "Routes", hu_hu);
i18n.addResourceBundle("en-US", "Routes", en_us);
i18n.addResourceBundle("de-DE", "Routes", de_de);

/**
 * Displays the component
 */

const getLngFromLocale = (locale) => {
  switch (locale) {
    case "en":
      return "en-US";
    case "ro":
      return "ro-RO";
    case "hu":
      return "hu-HU";
    case "de":
      return "de-DE";
    default:
      return "ro-RO";
  }
};

const LocalizedRouter = ({ children, RouterComponent }) => (
  <RouterComponent>
    <Route path="/:locale([a-z]{2})">
      {({ match, location }) => {
        /**
         * Get current language
         * Set default locale to en if base path is used without a language
         */
        const params = match ? match.params : {};
        const currentLocale = getLocale();
        const { locale = currentLocale || "ro" } = params;
        const currentLang = i18n.language;
        const lang = getLngFromLocale(locale);

        // i18n.changeLanguage(getLngFromLocale(lang));

        /**
         * If language is not in route path, redirect to language root
         */
        const { pathname, search } = location;

        if (
          !pathname.includes(`/${locale}/`) &&
          (locale !== "ro" || (locale === "ro" && pathname !== "/"))
        ) {
          const redirectRoute = `/${locale}/${pathname}${search}`.replace(
            "//",
            "/"
          );

          return <Redirect to={redirectRoute} />;
        }

        if (currentLang !== lang) {
          i18n.changeLanguage(lang);
        }
        /**
         * Return Intl provider with default language set
         */
        return <>{children}</>;
      }}
    </Route>
  </RouterComponent>
);

export const LocalizedSwitch = ({ children }) => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const { t } = useTranslation("Routes");
  const locale = getLocale();

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <>
      <ScrollToTop />
      <Switch>
        {React.Children.map(children, (child) => {
          return React.isValidElement(child)
            ? React.cloneElement(child, {
                ...child.props,
                path: localizeRoutePath(child.props.path),
              })
            : child;
        })}
        <Redirect to="/errorPage" />
      </Switch>
    </>
  );

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path) {
    let translatedPath = "";

    switch (typeof path) {
      case "undefined":
        translatedPath = undefined;
        break;
      case "object": {
        translatedPath = path.map((key) => `/${locale}` + t(key));
        break;
      }
      default:
        const isFallbackRoute =
          path === "*" || (path === "/" && locale === "ro");
        translatedPath = isFallbackRoute
          ? path
          : `/${locale}/` + t(path.substr(1));
        break;
    }

    return translatedPath;
  }
};

const Routes = (props) => {
  const { routes } = props;

  return (
    <LocalizedRouter RouterComponent={Router}>
      <LocalizedSwitch>
        {routes.map((route, index) => (
          <Route id={route.path} {...route} key={route.path + index} />
        ))}
      </LocalizedSwitch>
    </LocalizedRouter>
  );
};

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
export {
  propTypes as RoutesPropTypes,
  defaultProps as RoutesDefaultProps,
  localizePath as routesLocalizePath,
};
