const de_de = {
  title: "Zustimmungsmanagement",
  description:
    "Pineyard.ro verwendet die folgenden Cookies. Weitere Informationen finden Sie in unseren Datenschutzbestimmungen und Allgemeinen Geschäftsbedingungen.",
  analytics_consent: "Aktivieren Sie Google Analytics",
  weather_consent: "Wetter-Widget aktivieren",
  accept_selected: "Akzeptieren Sie ausgewählt",
  accept_all: "Akzeptiere alle",
};

export { de_de };
