const de_de = {
  personal_address: "Adresse",
  delivery_address: "Adresse",
  request_sent_successfully: "Anfrage geschickt",
  delivery_info: "Lieferinformationen",
  personal_info: "Persönliche Informationen",
  items_sold_and_delivered: "Artikel verkauft und geliefert von",
  is_same_as_personal_info: "Gleich wie persönliche Daten",
  personal_phone: "Telefon",
  delivery_phone: "Telefon",
  personal_name: "Name",
  delivery_name: "Name",
  create_order_failed:
    "Beim Senden Ihrer Bestellung ist ein Fehler aufgetreten.",
  send_request: "Anfrage senden",
  checkout: "Auschecken",
  create_order_success: "Ihre Bestellung wurde erfolgreich gesendet.",
};

export { de_de };
