/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";
import { stringifyParams } from "../../../hooks/useRequests/useRequests";
import { getLocale } from "../../LanguageSelector/LanguageSelector";

/**
 * Imports data
 */

const getDestinationSlugFromPath = (path) => {
  const arr = path.split("/");
  return arr[3];
};

const useDestinationsSwr = (props) => {
  const { filters = {}, condition = true, options = {} } = props;
  const { get } = useRequests();

  return useSWR(
    condition ? `destinations?${stringifyParams(filters)}` : null,
    get,
    options
  );
};

const useDestinationsSimpleSwr = () => {
  const { get } = useRequests();
  return useSWR("destinations/simple", get);
};

const useDestinationsCategoriesSwr = () => {
  const { get } = useRequests();
  return useSWR("destinations/categories", get);
};

const useDestinationsCategoryInfo = () => {
  const { get } = useRequests();
  return useSWR("destinations/categories/info", get);
};

const useDestinationSwr = (props) => {
  const { id, condition = true } = props;
  const { get } = useRequests();
  return useSWR(condition ? `destinations/${id}?${getLocale()}` : null, get);
};

const useDestinationCategorySwr = (props) => {
  const { destinationId, categoryId, condition = true } = props;
  const { get } = useRequests();
  return useSWR(
    condition ? `destinations/${destinationId}/categories/${categoryId}` : null,
    get
  );
};

const useAttractionSwr = (props) => {
  const { id } = props;
  const { get } = useRequests();
  return useSWR(`destinations/attractions/${id}`, get);
};

const useDestination = (props) => {
  return { useDestinationSwr };
};

export {
  useDestination,
  useDestinationCategorySwr,
  useAttractionSwr,
  useDestinationSwr,
  useDestinationsCategoriesSwr,
  useDestinationsCategoryInfo,
  useDestinationsSimpleSwr,
  useDestinationsSwr,
  getDestinationSlugFromPath,
};
