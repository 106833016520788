/**
 * Component short description
 *
 * @see OrderHistory.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import OrderForAccount from "../OrderForAccount";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./OrderHistory.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./OrderHistory.lang.ro-ro";
import { hu_hu } from "./OrderHistory.lang.hu-hu";
import { en_us } from "./OrderHistory.lang.en-us";
import { de_de } from "./OrderHistory.lang.de-de";
import { useOrder } from "../Order/Order.logic";

i18n.addResourceBundle("ro-RO", "OrderHistory", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderHistory", hu_hu);
i18n.addResourceBundle("en-US", "OrderHistory", en_us);
i18n.addResourceBundle("de-DE", "OrderHistory", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const OrderHistory = (props) => {
  const { container } = useStyles(props);
  const { language } = i18n;

  const { useGetOrdersSwr } = useOrder();
  const { data, mutate: mutatedOrders } = useGetOrdersSwr();

  useEffect(() => {
    mutatedOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  const orders = data || [];

  const ordersList =
    orders &&
    orders.map((item) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderForAccount {...item} />
        </Grid>
      );
    });

  return (
    <Grid container spacing={1} className={clsx(container, "OrderHistory")}>
      {ordersList}
    </Grid>
  );
};

OrderHistory.propTypes = propTypes;
OrderHistory.defaultProps = defaultProps;

export default OrderHistory;
export {
  propTypes as OrderHistoryPropTypes,
  defaultProps as OrderHistoryDefaultProps,
};
