const hu_hu = {
  title: "Események",
  no_events:
    "Jelenleg nincsenek események erre a helyre a kiválasztott hónapban",
  month: "Hónap",
  select_destination: "Válasszon úti célt",
  select_month: "Válasszon egy hónapot",
  destination: "Rendeltetési hely",
  all: "Összes",
};

export { hu_hu };
