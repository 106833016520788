/**
 * Displays breadrcumbs using the router
 *
 * @see Breadcrumbs.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useMemo } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Link as RouterLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Breadcrumbs.data";
/**
 * Imports Material UI components
 */
import { Breadcrumbs as MUIBreadcrumbs, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Breadcrumbs.lang.ro-ro";
import { hu_hu } from "./Breadcrumbs.lang.hu-hu";
import { en_us } from "./Breadcrumbs.lang.en-us";
import { de_de } from "./Breadcrumbs.lang.de-de";
import { LanguageSelectorDefaultProps } from "../LanguageSelector";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "Breadcrumbs", ro_ro);
i18n.addResourceBundle("hu-HU", "Breadcrumbs", hu_hu);
i18n.addResourceBundle("en-US", "Breadcrumbs", en_us);
i18n.addResourceBundle("de-DE", "Breadcrumbs", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {},
}));

/**
 * Displays breadcrumbs as chips
 */
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[500],
      cursor: "pointer",
      color: "white",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[500], 0.12),
      cursor: "pointer",
      color: "white",
    },
  },
}))(Chip);

/**
 * Displays the component
 */

const { languages } = LanguageSelectorDefaultProps;

const Breadcrumbs = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Breadcrumbs");

  const locales = useMemo(() => {
    return languages.map((lng) => lng.alternateName.split("-")[0]);
  }, []);
  /**
   * Integrates with the router
   */
  const breadcrumbs = useBreadcrumbs();

  /**
   * Displays less on small screens
   */
  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down("md"));
  const maxItems = isPortrait ? 2 : 8;

  let links = breadcrumbs.filter(({ breadcrumb }) => {
    const { key } = breadcrumb;
    const linkWithoutSlash = key.substring(1);

    return !locales.includes(linkWithoutSlash);
  })
    .filter(({ breadcrumb }, index) => {
      // -1 because the index starts at 0
      // -2 because of the language slug that adds tot he array length

      return index !== breadcrumbs.length - 2;
    })
    .map(({ breadcrumb }, index) => {
      const { key, props: { children } } = breadcrumb;
      const label = 'Home' === children ? t('Header:home') : children;

      return (
        <StyledBreadcrumb
          label={label}
          to={key}
          component={RouterLink}
          key={shortid.generate()}
        />
      );
    });

  return (
    <MUIBreadcrumbs
      maxItems={maxItems}
      className={clsx(container, "Breadcrumbs")}
    >
      {links}
    </MUIBreadcrumbs>
  );
};

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
export {
  propTypes as BreadcrumbsPropTypes,
  defaultProps as BreadcrumbsDefaultProps,
};
