/**
 * Component short description
 *
 * @see Terms.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Terms.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Terms.lang.ro-ro";
import { hu_hu } from "./Terms.lang.hu-hu";
import { en_us } from "./Terms.lang.en-us";
import { de_de } from "./Terms.lang.de-de";
import TermsAndConditionsHTML from "../../../assets/staticPages/termsAndConditions.htm";

i18n.addResourceBundle("ro-RO", "Terms", ro_ro);
i18n.addResourceBundle("hu-HU", "Terms", hu_hu);
i18n.addResourceBundle("en-US", "Terms", en_us);
i18n.addResourceBundle("de-DE", "Terms", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
  iframeContainer: {
    width: '100%',
    padding: '10px',
    height: '100%'
  },
  iframe: {
    width: '100%',
    border: 0
  }
}));

/**
 * Displays the component
 */
const Terms = (props) => {
  const { container, iframeContainer, iframe } = useStyles(props);
  const { t } = useTranslation("Terms");

  const adjustIFrameHeight = () => {
    const iframeDOMNode = document.getElementById('myIFrame');
    if (iframeDOMNode) {
      iframeDOMNode.height = iframeDOMNode.contentWindow.document.body.scrollHeight || 'auto'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', adjustIFrameHeight)
  })

  return (
    <Layout footer={{ currentPage: "Terms & Conditions" }}>
      <Grid container spacing={1} className={clsx(container, "Terms")}>
        <Grid item xs={12}>
          <PageHeader title={t("title")} />
        </Grid>
        <Grid container spacing={0} className={clsx(container)}>
          <Grid item xs={12}>
            <div className={clsx(iframeContainer)} onFocus={adjustIFrameHeight}>
              <iframe
                title="terms-and-conditions"
                id="myIFrame"
                scrolling="no"
                src={TermsAndConditionsHTML}
                onLoad={adjustIFrameHeight}
                className={clsx(iframe)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;
export { propTypes as TermsPropTypes, defaultProps as TermsDefaultProps };
