const ro_ro = {
  search: "cautare",
  stay: "cazare",
  order: "comanda",
  "privacy-policy": "confidentialitate",
  account: "cont",
  contact: "contact",
  cart: "cos-cumparaturi",
  destinations: "destinatii",
  events: "evenimente",
  faq: "faq-intrebari-frecvente",
  checkout: "finalizare-comanda",
  sitemap: "harta-site",
  "order-history": "istoric-comenzi",
  "booking-history": "istoric-rezervari-cazare",
  "media-and-press": "media",
  settings: "setari",
  "local-market": "shop-produse-locale",
  news: "stiri",
  support: "suport",
  "terms-and-conditions": "termeni-si-conditii",
  videos: "videouri",
  "paypal-mobile": "paypal-mobile",
};

export { ro_ro };
