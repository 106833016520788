/**
 * Component short description
 *
 * @see List2.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./List2.data";

/**
 * Imports Material UI components
 */
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./List2.lang.ro-ro";
import { hu_hu } from "./List2.lang.hu-hu";
import { en_us } from "./List2.lang.en-us";
import { de_de } from "./List2.lang.de-de";

i18n.addResourceBundle("ro-RO", "List2", ro_ro);
i18n.addResourceBundle("hu-HU", "List2", hu_hu);
i18n.addResourceBundle("en-US", "List2", en_us);
i18n.addResourceBundle("de-DE", "List2", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const List2 = (props) => {
  const { items } = props;

  const listItems =
    items &&
    items.map((item) => {
      const { id, listItem } = item;

      if (!listItem) {
        return null;
      }
      return (
        <ListItem key={id} disableGutters divider>
          {listItem}
        </ListItem>
      );
    });

  return listItems && <List>{listItems}</List>;
};

List2.propTypes = propTypes;
List2.defaultProps = defaultProps;

export default List2;
export { propTypes as List2PropTypes, defaultProps as List2DefaultProps };
