const hu_hu = {
  search: "kereses",
  stay: "szallas",
  order: "rendeles",
  "privacy-policy": "adatvedelmi-iranyelvek",
  account: "szamla",
  contact: "kapcsolatba-lepni",
  cart: "szeker",
  destinations: "uti-celok",
  events: "esemenyek",
  faq: "gyik",
  checkout: "penztar",
  sitemap: "webhelyterkep",
  "order-history": "rendelestortenet",
  "booking-history": "foglalasi-elozmenyek",
  "media-and-press": "media-es-sajto",
  settings: "beallitasok",
  "local-market": "helyi-piac",
  news: "hirek",
  support: "tamogatas",
  "terms-and-conditions": "felhasznalasi-feltetelek",
  videos: "videok",
  "paypal-mobile": "paypal-mobile",
};

export { hu_hu };
