/**
 * Displays the site header in an App bar
 *
 * @see Header.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Logo from "../Logo";
import Menu from "../Menu";
import MenuItems from "../MenuItems";
import Icons from "../Icons";
import { useAuth } from "../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Header.data";
import { headerTheme } from "../../theme/theme";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import { ThemeProvider } from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Header.lang.ro-ro";
import { hu_hu } from "./Header.lang.hu-hu";
import { en_us } from "./Header.lang.en-us";
import { de_de } from "./Header.lang.de-de";

i18n.addResourceBundle("ro-RO", "Header", ro_ro);
i18n.addResourceBundle("hu-HU", "Header", hu_hu);
i18n.addResourceBundle("en-US", "Header", en_us);
i18n.addResourceBundle("de-DE", "Header", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  menuContainer: {
    /**
     * Setting menu width on small screens
     */
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: 300,
    },

    /**
     * Remove horizontal spacing
     */
    ...theme.custom.grid.removeHorizontalPadding,
  },
  menuIconsStyle: {
    "& .MuiGrid-root": {
      alignItems: "center",
    },
  },
  header: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(8),
    },
  },
}));

/**
 * Displays the component
 */
const translateMenuItems = (menuItems, t) => {
  const { items = [] } = menuItems;
  return {
    ...menuItems,
    items: items.map((item) => {
      const { key } = item;
      return {
        ...item,
        translatedName: t(key),
      };
    }),
  };
};

const Header = (props) => {
  const { logo, menuItems, menuIcons, currentPage } = props;
  const { container, menuContainer, menuIconsStyle, header } = useStyles(props);
  const { t } = useTranslation("Header");

  const { isAuthenticated } = useAuth();

  /**
   * Prepares responsiveness
   */
  const theme = useTheme();
  const isHamburgerMenu = useMediaQuery(theme.breakpoints.down("md"));
  const canIconNameBeDisplayed = useMediaQuery(theme.breakpoints.up("lg"));
  const orientation = isHamburgerMenu ? "vertical" : "horizontal";
  const direction = isHamburgerMenu ? "column" : "row";
  const justifyMenu = isHamburgerMenu
    ? null
    : canIconNameBeDisplayed
    ? "flex-end"
    : "space-around";
  const alignItemsMenu = isHamburgerMenu ? null : "center";

  const responsiveIconNames =
    menuIcons &&
    menuIcons.items &&
    menuIcons.items.map((item) => {
      return {
        ...item,
        transKey: t(item.transKey),
        displayName: item.displayName || isHamburgerMenu,
      };
    });

  const iconsBasedOnAuth = responsiveIconNames.filter((item) => {
    const { requiresAuth = false } = item;
    return isAuthenticated === requiresAuth || !requiresAuth;
  });

  const responsiveMenuIcons = { items: iconsBasedOnAuth };

  /**
   * Prepares the menu sections
   */
  const items = (
    <MenuItems
      {...translateMenuItems(menuItems, t)}
      activeItem={currentPage}
      orientation={orientation}
    />
  );

  /**
   * // NOTE: Menu items are tabs, icons are grid items, they differ in height
   */
  const icons = (
    <Icons
      {...responsiveMenuIcons}
      activeItem={currentPage}
      orientation={orientation}
      customVariant="text"
      customTheme={headerTheme}
    />
  );

  return (
    <HideOnScroll>
      <AppBar
        className={clsx(header, "Header")}
        elevation={0}
        color="primary"
        position="fixed"
      >
        <Toolbar disableGutters>
          <Grid
            container
            className={clsx(container, "ToolbarContainer")}
            spacing={1}
            alignItems="center"
            justify="flex-start"
            wrap="nowrap"
          >
            <Grid
              item
              xs={9}
              lg={2}
              className={clsx(container, "LogoContainer")}
            >
              <Logo {...logo} isWhite />
            </Grid>
            <Grid
              container
              item
              xs={3}
              lg={10}
              className={clsx(container, "MenuContainer")}
              spacing={0}
              alignItems="center"
            >
              <Menu orientation={orientation} className={clsx("Menu")}>
                <Grid
                  container
                  className={clsx(container, menuContainer, "MenuContainer2")}
                  spacing={0}
                  alignItems={alignItemsMenu}
                  justify={justifyMenu}
                  direction={direction}
                  wrap="nowrap"
                >
                  <Grid item>
                    <ThemeProvider theme={headerTheme}>{items}</ThemeProvider>
                    <Hidden lgUp>
                      <Divider />
                    </Hidden>
                  </Grid>
                  <Grid item className={clsx(menuIconsStyle, "IconsContainer")}>
                    {icons}
                  </Grid>
                </Grid>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
export { propTypes as HeaderPropTypes, defaultProps as HeaderDefaultProps };
