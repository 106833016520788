/**
 * Defines the data requirements for the component
 *
 * @see UnitsForm.md for details
 */

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {
  max: 1,
  productId: null,
  defaultFormValues: {
    units: 1,
  },
};

export { propTypes, defaultProps };
