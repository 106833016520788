/**
 * Defines the data requirements for the component
 *
 * @see NewsSearch.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  filters: PropTypes.any,
  news: PropTypes.shape({
    total: PropTypes.number,
    data: PropTypes.array,
    limit: PropTypes.number,
  }),
  data: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  filters: {
    destination: " ", // This has to be " " in order to properly work with the backend.
    month: null,
    limit: null,
    perPage: null,
    page: 1,
  },
  articles: {
    total: 1,
    data: [],
    perPage: 10,
  },
  data: {
    destinations: [],
    month: null,
  }
};

export { propTypes, defaultProps };
