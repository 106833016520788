const ro_ro = {
  items_count_and_price: "{{orderItems}} produse / {{totalPrice}} RON",
  confirm_cancel: "Anulare comandă",
  title: "Anularea comenzii",
  order_cancelled_successfully: "Comandă anulată cu succes",
  order_date: "Data comenzii: {{value}}",
  back_to_order: "Înapoi la comandă",
  order_id: "Număr comandă: {{value}}",
  seller: "Vânzător: {{value}}",
};

export { ro_ro };
