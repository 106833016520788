/**
 * Defines the data requirements for the component
 *
 * @see ContactForm.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import { DestinationPropTypes } from "../../_destination/Destination";

/**
 * Defines the prop types
 */
const propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape(DestinationPropTypes)),
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  destinations: [],
  defaultValues: {
    destination: "",
    name: "",
    email: "",
    message: "",
    terms: React.View ? false : "unchecked",
  },
  onSubmit: () => {},
};

export { propTypes, defaultProps };
