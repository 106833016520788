const hu_hu = {
  sold_by: "Az által értékesített és szállított termékek",
  checkout: "Tovább a kijelentkezéshez",
  pending: "Függőben levő",
  total_price: "Teljes ár",
  delivery_price: "Szállítási ár",
  items_price: "Tételek ára",
};

export { hu_hu };
