const ro_ro = {
  1: "În această localitate nu sunt Atracții turistice naturale.",
  2: "În această localitate nu sunt Atracții turistice culturale.",
  3: "În această localitate nu sunt Izvoare cu apă minerală.",
  4: "În această localitate nu sunt servicii de Tratament balneare.",
  5: "În această localitate nu sunt Servicii de agrement.",
  6: "În această localitate nu sunt Trasee turistice.",
  7: "În această localitate nu sunt Muzee",
  8: "În această localitate nu sunt informații turistice de tip Toate.",
  9: "În această localitate nu sunt informații turistice de tip Alte.",
  10: "În această localitate nu sunt Restaurante și terase.",
};

export { ro_ro };
