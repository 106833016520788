/**
 * Component short description
 *
 * @see Account.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Redirect, useLocation } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import { useAuth, useUnsplash, useUpdateEffect } from "../../../hooks";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Settings from "../Settings";
import OrderHistory from "../OrderHistory";
import BookingHistory from "../BookingHistory";
import TabPanel from "../../TabPanel";
import AccountRoles from "../AccountRoles";
import RequestRole from "../RequestRole";
import { TextSection, Actions } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Account.data";

/**
 * Imports logic
 */
import { useAccountInfo, useProfileSwr } from "./Account.logic";

/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Account.lang.ro-ro";
import { hu_hu } from "./Account.lang.hu-hu";
import { en_us } from "./Account.lang.en-us";
import { de_de } from "./Account.lang.de-de";

i18n.addResourceBundle("ro-RO", "Account", ro_ro);
i18n.addResourceBundle("hu-HU", "Account", hu_hu);
i18n.addResourceBundle("en-US", "Account", en_us);
i18n.addResourceBundle("de-DE", "Account", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Account = (props) => {
  const { image, activeTab } = props;

  const { isAuthenticated, user = {}, updateUser } = useAuth();
  const { isSeller, isHost } = user;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { container } = useStyles(props);
  const {
    t,
    i18n: { language },
  } = useTranslation("Account");

  /**
   * Loads the active tab query param
   * - useParams was not suitable for this ....
   */
  const { search } = useLocation();

  const unsplashImage = useUnsplash({
    keyword: "portrait",
    photos: 1,
    params: { orientation: "squarish" },
  });

  const query = new URLSearchParams(search);
  const activeTabFromQuery = Number(query.get("activeTab"));
  const initialTab = activeTabFromQuery ? activeTabFromQuery : activeTab;

  /**
   * Defines states before the `if`
   */
  const [tab, setTab] = useState(initialTab);

  useEffect(() => {
    setTab(activeTabFromQuery);
  }, [activeTabFromQuery]);

  const { data: accountInfo, mutate: mutateAccountInfo } = useAccountInfo();

  const { data: profileData, mutate: mutateProfileData } = useProfileSwr();

  useEffect(() => {
    if (profileData) {
      updateUser(profileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useUpdateEffect(() => {
    mutateAccountInfo();
  }, [language]);
  /**
   * Redirects if the user is not logged in
   */

  /**
   * Displays the description
   */
  const cardContent1 = <TextSection description={accountInfo?.description} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  /**
   * Displays the user info
   */
  const cardContent2 = <TextSection subtitle={user?.name} />;

  const cardActions2 = (
    <Actions
      items={[
        { id: shortid.generate(), action: <AccountRoles {...props} /> },
        {
          id: shortid.generate(),
          action:
            !isHost || !isSeller ? (
              <RequestRole account={props} onClose={mutateProfileData} />
            ) : null,
        },
      ]}
      orientation="vertical"
    />
  );

  const image2 = unsplashImage ? unsplashImage[0] : image;

  const cardWithMedia2 = {
    image: { ...image2, displayCaption: false, height: "auto" },
    content: cardContent2,
    actions: cardActions2,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "sm",
      imageResponsiveWidth: isMobile ? 150 : 200,
    }),
  };

  /**
   * Displays the tabs
   */
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const tabLabels = (
    <Tabs
      value={tab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label={t("settings")} />
      <Tab label={t("booking_history")} />
      <Tab label={t("order_history")} />
    </Tabs>
  );

  const tabPanels = (
    <>
      <TabPanel currentTabIndex={0} activeTabIndex={tab} key="1">
        <Settings />
      </TabPanel>
      <TabPanel currentTabIndex={1} activeTabIndex={tab} key="2">
        <BookingHistory />
      </TabPanel>
      <TabPanel currentTabIndex={2} activeTabIndex={tab} key="3">
        <OrderHistory />
      </TabPanel>
    </>
  );

  if (!isAuthenticated) return <Redirect to="/" />;

  return (
    <Layout>
      <Grid container spacing={1} className={clsx(container, "Account")}>
        <Grid item xs={12}>
          <Typography variant="h4">{t("your_account")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia2} />
        </Grid>
        <Grid item xs={12}>
          {tabLabels}
        </Grid>
        <Grid item xs={12}>
          {tabPanels}
        </Grid>
      </Grid>
    </Layout>
  );
};

Account.propTypes = propTypes;
Account.defaultProps = defaultProps;

export default Account;
export { propTypes as AccountPropTypes, defaultProps as AccountDefaultProps };
