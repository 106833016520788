/**
 * Component short description
 *
 * @see Layout.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Header from "../Header";
import Footer from "../Footer";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Layout.data";

/**
 * Imports Material UI components
 */
import { makeStyles, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Layout.lang.ro-ro";
import { hu_hu } from "./Layout.lang.hu-hu";
import { en_us } from "./Layout.lang.en-us";
import { de_de } from "./Layout.lang.de-de";

i18n.addResourceBundle("ro-RO", "Layout", ro_ro);
i18n.addResourceBundle("hu-HU", "Layout", hu_hu);
i18n.addResourceBundle("en-US", "Layout", en_us);
i18n.addResourceBundle("de-DE", "Layout", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
}));

/**
 * Displays the component
 */

const Layout = (props) => {
  const { header, footer, children, containerMaxWidth, hideHeader } = props;
  const { container } = useStyles(props);

  return (
    <Grid container className={clsx(container, "Layout")}>
      {!hideHeader && (
        <Grid item xs={12}>
          <Header {...header} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Container disableGutters={true} maxWidth={containerMaxWidth}>
          <Box mt={hideHeader ? 0 : 9}>{children}</Box>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Footer {...footer} />
      </Grid>
    </Grid>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
export { propTypes as LayoutPropTypes, defaultProps as LayoutDefaultProps };
