/**
 * Defines the data requirements for the component
 *
 * @see Videos.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { VideoAsThumbPropTypes } from "../VideoAsThumb";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestination
 */
const propTypes = {
  /**
   * From API
   */
  items: PropTypes.arrayOf(PropTypes.shape(VideoAsThumbPropTypes)),
  /**
   * From parents
   */
  destinationName: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [],
  destinationName: "",
};

export { propTypes, defaultProps };
