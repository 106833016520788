const de_de = {
  current_email: "Aktuelle E-Mail:",
  new_email_confirm: "Neue E-Mail bestätigen",
  new_password_confirm: "Bestätige neues Passwort",
  new_email: "Neue e-mail",
  double_confirmation: "Eine doppelte Bestätigung ist erforderlich",
  title_general: "Allgemeines",
  current_name: "Aktueller Name:",
  new_name: "Neuer Name",
  title_current_password: "Derzeitiges Passwort",
  current_password: "Derzeitiges Passwort",
  new_password: "Neues Kennwort",
  submit: "sparen",
  title_change_email: "Ändern Sie die E-Mail",
  title_change_password: "Ändere das Passwort",
  settings: "die Einstellungen",
  update_profile_success: "Ihr Konto wurde erfolgreich aktualisiert.",
  update_profile_failed: "Etwas ist schief gelaufen. Bitte versuche es erneut.",
};

export { de_de };
