/**
 * Component short description
 *
 * @see RequestStayForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import * as moment from "moment";
import { useSnackbar } from "notistack";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./RequestStayForm.data";
import useStay from "../Stay/Stay.logic";

/**
 * Imports Material UI components
 */
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RequestStayForm.lang.ro-ro";
import { hu_hu } from "./RequestStayForm.lang.hu-hu";
import { en_us } from "./RequestStayForm.lang.en-us";
import { de_de } from "./RequestStayForm.lang.de-de";
import { areDatesValid } from "../../../helpers/baseHelper";

i18n.addResourceBundle("ro-RO", "RequestStayForm", ro_ro);
i18n.addResourceBundle("hu-HU", "RequestStayForm", hu_hu);
i18n.addResourceBundle("en-US", "RequestStayForm", en_us);
i18n.addResourceBundle("de-DE", "RequestStayForm", de_de);

/**
 * Displays the component
 */
const RequestStayForm = (props) => {
  const { t } = useTranslation("RequestStayForm");
  const { getRequestStayFormSchema } = useValidationSchemas();
  const { accommodationId, defaultValues, setIsModalOpen } = props;

  const {
    register,
    errors,
    setValue,
    handleSubmit,
    formState,
    control,
    getValues,
    reset,
    watch,
    setError
  } = useForm({
    defaultValues,
    validationSchema: getRequestStayFormSchema(),
    reValidateMode: "onChange",
  });

  const { isValid, isSubmitted, isSubmitting } = formState;
  const { createStayRequest } = useStay();
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkOutMinDate, setCheckOutMinDate] = useState(
    defaultValues.checkOut
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleFormSubmit = (data) => {
    const { checkIn, checkOut } = data;
    const areValid = areDatesValid(checkIn, checkOut, setError, t('invalid_date'));
    if (!areValid) {
      return;
    }

    const parsedData = {
      ...data,
      checkIn: moment(checkIn).format(process.env.REACT_APP_DATE_FORMAT),
      checkOut: moment(checkOut).format(process.env.REACT_APP_DATE_FORMAT),
    };

    createStayRequest(parsedData).then((resp) => {
      const { message } = resp;

      enqueueSnackbar(message, { variant: "success" });
      setIsModalOpen(false);
      reset(defaultValues);
    });
  };

  const handleFormCancel = () => {
    setIsModalOpen(false);
  };

  const checkInDate = watch("checkIn");
  useEffect(() => {
    const { checkOut } = getValues();
    if (checkInDate >= checkOut) {
      const newCheckoutMinDate = moment(checkInDate).add(1, "days");

      setCheckOutMinDate(newCheckoutMinDate);
      setValue("checkOut", newCheckoutMinDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInDate]);

  const handleTermsAndConditions = (event) => {
    setAreTermsAccepted(event.target.checked);
  };

  const isNumberKey = (event) => {
    if (!isNaN(event.target.value)) {
      setPhoneNumber(event.target.value);
    }
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              InputProps={{ inputProps: { maxLength: 20 } }}
              onChange={isNumberKey}
              value={phoneNumber}
              type="text"
              inputRef={register}
              fullWidth
              name="phone"
              variant="outlined"
              label={t("phone")}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
            />
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name="checkIn"
                control={control}
                as={
                  <KeyboardDatePicker
                    autoOk
                    label={t("check_in")}
                    inputVariant="outlined"
                    variant="inline"
                    disablePast
                    fullWidth
                    format="DD-MM-YYYY"
                    mask={'__-__-____'}
                    InputAdornmentProps={{ position: "start" }}
                    error={Boolean(errors.checkIn)}
                    helperText={errors.checkIn?.message}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="checkOut"
                control={control}
                as={
                  <KeyboardDatePicker
                    autoOk
                    label={t("check_out")}
                    inputVariant="outlined"
                    variant="inline"
                    disablePast
                    minDate={checkOutMinDate}
                    format="DD-MM-YYYY"
                    mask={'__-__-____'}
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    error={Boolean(errors.checkOut)}
                    helperText={errors.checkOut?.message}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                InputProps={{ inputProps: { min: 1, max: 500 } }}
                inputRef={register}
                fullWidth
                name="rooms"
                variant="outlined"
                label={t("rooms")}
                error={Boolean(errors.rooms)}
                helperText={errors.rooms?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                inputRef={register}
                InputProps={{ inputProps: { min: 1, max: 500 } }}
                min={1}
                fullWidth
                name="guests"
                variant="outlined"
                label={t("guests")}
                error={Boolean(errors.guests)}
                helperText={errors.guests?.message}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                inputRef={register}
                fullWidth
                name="message"
                variant="outlined"
                multiline={true}
                rows={6}
                label={t("message")}
                error={Boolean(errors.message)}
                helperText={errors.message?.message}
              />
              <TextField
                type="hidden"
                inputRef={register}
                value={accommodationId}
                name="accommodationId"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="termsAndConditions"
                    onChange={handleTermsAndConditions}
                  />
                }
                label={t("terms")}
              />
            </FormControl>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                onClick={handleFormCancel}
                fullWidth
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                disabled={(!isValid && isSubmitted) || !areTermsAccepted}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

RequestStayForm.propTypes = propTypes;
RequestStayForm.defaultProps = defaultProps;

export default RequestStayForm;
export {
  propTypes as RequestStayFormPropTypes,
  defaultProps as RequestStayFormDefaultProps,
};
