import * as yup from "yup";
import useValidationMessages from "../useValidationMessages";

const useValidationFields = () => {
  const { trans } = useValidationMessages();

  return {
    email: yup
      .string()
      .required(trans("required"))
      .email(trans("email_invalid")),
    password: yup
      .string()
      .required(trans("required"))
      .min(6, trans("password_min_length")),
    requiredString: yup.string().required(trans("required")),
  };
};

const useValidationSchemas = () => {
  const { trans } = useValidationMessages();
  const { email, password, requiredString } = useValidationFields();

  const getForgotPasswordFormSchema = () => {
    return yup.object().shape({
      email,
    });
  };

  const getNewsletterFormSchema = () => {
    return yup.object().shape({
      email,
    });
  };

  const getLoginFormSchema = () => {
    return yup.object().shape({
      email,
      password,
    });
  };

  const getOrderReturnFormSchema = () => {
    return yup.object().shape({
      returnReason: requiredString.min(3, trans("min_characters")),
    });
  };

  const getSearchFormSchema = () => {
    return yup.object().shape({
      search: requiredString.min(3, trans("min_characters")),
    });
  };

  const getContactFormSchema = () => {
    return yup.object().shape({
      email,
      destination_id: requiredString,
      name: requiredString,
      message: requiredString,
      terms: yup.string().oneOf(["checked"], trans("required")),
    });
  };

  const getRequestStayFormSchema = () => {
    return yup.object().shape({
      phone: requiredString,
      checkIn: requiredString.nullable(),
      checkOut: requiredString.nullable(),
      rooms: requiredString,
      message: yup.string(),
      guests: requiredString,
      accommodationId: requiredString,
    });
  };

  const getCheckoutFormSchema = (isSameAsPersonalInfo) => {
    const validation = isSameAsPersonalInfo ? yup.string() : requiredString;

    return yup.object().shape({
      personalName: requiredString,
      personalAddress: requiredString,
      personalPhone: requiredString,
      deliveryName: yup.lazy(() => validation),
      deliveryAddress: yup.lazy(() => validation),
      deliveryPhone: yup.lazy(() => validation),
      sellerId: requiredString,
    });
  };

  const getRegisterFormSchema = () => {
    return yup.object().shape({
      email,
      password,
      name: requiredString,
      password_confirmation: yup
        .string()
        .required(trans("required"))
        .test("passwords-match", trans("passwords_not_match"), function(value) {
          return this.parent.password === value;
        }),
    });
  };

  const getRequestRoleFormSchema = () => {
    return yup.object().shape({
      role: yup.string().oneOf(["seller", "host"], trans("required")),
      destination: requiredString,
      message: requiredString,
      phone: requiredString,
      terms: yup.bool().oneOf([true], trans("required")),
    });
  };

  const getSettingsFormSchema = (currentEmail) => {
    return yup.object().shape({
      newName: yup.string(),
      currentPassword: requiredString,
      newPassword: yup
        .string()
        .test("passwords-match", trans("password_min_length"), function(value) {
          return !value || value.length >= 6;
        }),
      newPasswordConfirm: yup
        .string()
        .test("passwords-match", trans("passwords_not_match"), function(value) {
          return this.parent.newPassword === value;
        }),
      newEmail: yup
        .string()
        .email(trans("email_invalid"))
        .test("emails-match", trans("emails_match"), function(value) {
          return currentEmail !== value;
        }),
      newEmailConfirm: yup
        .string()
        .email(trans("email_invalid"))
        .test("emails-match", trans("emails_do_not_match"), function(value) {
          return this.parent.newEmail === value;
        }),
    });
  };

  return {
    getLoginFormSchema,
    getSearchFormSchema,
    getContactFormSchema,
    getCheckoutFormSchema,
    getRegisterFormSchema,
    getSettingsFormSchema,
    getNewsletterFormSchema,
    getRequestStayFormSchema,
    getOrderReturnFormSchema,
    getForgotPasswordFormSchema,
    getRequestRoleFormSchema,
  };
};

export default useValidationSchemas;
