const de_de = {
  search: "suche",
  stay: "unterkunft",
  order: "auftrag",
  "privacy-policy": "datenschutz-bestimmungen",
  account: "konto",
  contact: "kontakt",
  cart: "wagen",
  destinations: "ziele",
  events: "veranstaltungen",
  faq: "faq",
  checkout: "auschecken",
  sitemap: "seitenverzeichnis",
  "order-history": "bestellverlauf",
  "booking-history": "buchungshistorie",
  "media-and-press": "medien-und-presse",
  settings: "die-einstellungen",
  "local-market": "lokaler-markt",
  news: "nachrichten",
  support: "unterstutzung",
  "terms-and-conditions": "geschaftsbedingungen",
  videos: "videos",
  "paypal-mobile": "paypal-mobile",
};

export { de_de };
