const de_de = {
  home_title: 'Touristenattraktionen in der Region Giurgeu | Pineyard.ro',
  home_description:
    'Die Region Giurgeu umfasst den Ferienort Borsec und die Gemeinden Tulgheş, Corbu, Ditrău, Remetea, Lăzarea, Joseni, Ciumani, Suseni und Voşlăbeni | Erfahren Sie mehr auf pineyard.ro',
  home_keywords: 'Pineyard,Giurgeu,regiunea Giurgeu,atractii turistice Giurgeu,Harghita,Borsec,cazare munte',
  destinations_title: 'Die wichtigsten Touristenattraktionen rund um Giurgeu | Pineyard.ro',
  destinations_description:
    'Die Region der neun ländlichen Siedlungen und eines Resorts bietet eine endlose Quelle an natürlichen Touristenattraktionen, die es wert sind, gesehen und geschätzt zu werden. | Pineyard.ro',
  destinations_keywords: 'obiective turistice Giurgeu,destinatii turistice Harghita,Borsec,Corbu,Ciumani',
  ditrau_title: 'Ditrău befindet sich im zentralen Teil des Giurgeu-Beckens Pineyard.ro',
  ditrau_description:
    'Der Syenit mit Nephelin und Sodalith, auch DITROIT genannt, wurde erstmals im alkalischen Massiv von Ditrău | beschrieben Pineyard.ro',
  ditrau_keywords: 'Ditrau,comuna Ditrau,cazare Ditrau,vremea Ditrau',
  corbu_title: 'Das Dorf Corbu liegt zwischen den Bergen Bistrița und Giurgeu Pineyard.ro',
  corbu_description:
    'Die Gemeinde Corbu begrüßt Sie mit mehreren Unterkünften und einer wunderschönen Berglandschaft, die Sie im Sommer auf den 12 Wanderwegen erkunden können Pineyard.ro',
  corbu_keywords: 'Corbu,cazare Corbu,primaria Corbu, vremea Corbu,pensiuni Corbu',
  ciumani_title: 'Ciumani liegt in einer ethnokulturellen Region im Osten Siebenbürgens Pineyard.ro',
  ciumani_description:
    'Diejenigen, die die Gemeinde Ciumani besuchen, sind eingeladen, das Panorama von der Spitze der Skipiste aus zu bewundern und in einem Salzwasser-Whirlpool unter dem Sternenhimmel zu entspannen. | Pineyard.ro',
  ciumani_keywords: 'Ciumani,vremea Ciumani,cazare Ciumani,pensiuni Ciumani',
  remetea_title: 'Remetea ist eine der großen Gemeinden des Landkreises Harghita Pineyard.ro',
  remetea_description:
    'Remetea liegt idyllisch gegenüber von Ditrău am oberen linken Ufer des Flusses Mureş im Tal des Flusses Kőpatak Pineyard.ro',
  remetea_keywords: 'Remetea,vremea Remetea,primaria Remetea,comuna Remetea,cazare Remetea',
  joseni_title: 'Joseni ist ein Dorf im Landkreis Harghita, România | Pineyard.ro',
  joseni_description:
    'Joseni bedeutet "Unteres Dorf" und ist eine ethnokulturelle Region im Osten Siebenbürgens, die aus drei Dörfern besteht Pineyard.ro',
  joseni_keywords: 'Joseni,vremea Joseni,Joseni Harghita,cazare Joseni',
  lazarea_title: 'Die Gemeinde Lăzarea ist eine der ältesten Siedlungen des Gurghiu-Tals Pineyard.ro',
  lazarea_description:
    'Die ersten dokumentierten Aufzeichnungen über die Gemeinde Lăzarea stammen aus dem Jahr 1200 und enthalten vier wichtige Denkmäler, die jeweils eine andere historische Epoche darstellen Pineyard.ro',
  lazarea_keywords: 'Lazarea,comuna Lazarea,Lazarea Harghita,cazare Lazarea Harghita',
  tulghes_title:
    'Tulgheș ist eine überwiegend rumänische Siedlung im nordöstlichen Teil des Landkreises Harghita Pineyard.ro',
  tulghes_description:
    'Tulgheș bietet seinen Besuchern natürliche und kulturelle Touristenattraktionen, Unterhaltungs- und Freizeitdienstleistungen und nicht zuletzt Unterkunftsdienste Pineyard.ro',
  tulghes_keywords: 'Tulghes,vremea Tulghes,cazare Tulghes,Tulghes Toplita,primaria Tulghes',
  borsec_title: 'Borsec liegt am Treffpunkt der Berge Giurgeu, Bistrița und Călimani | Pineyard.ro',
  borsec_description:
    'Der größte Reichtum des Borsec Resorts sind die unzähligen Mineralwasserquellen, während die auf verschiedenen internationalen Messen erzielten Preise im Museum of Mineral Waters ausgestellt werden Pineyard.ro',
  borsec_keywords: 'Borsec,cazare Borsec,partia Borsec,statiunea Borsec,vremea Borsec,webcam Borsec',
  suseni_title: 'Die Gründung von Suseni als Siedlung kann um das Jahr 1330 geschätzt werden Pineyard.ro',
  suseni_description:
    'Die Siedlung Katorzsa am Rande der Gemeinde Suseni kann als eine der ältesten Siedlungen in der Region Giurgeu angesehen werden Pineyard.ro',
  suseni_keywords: 'Suseni,Suseni Harghita,cazare in Suseni,atractii turistice Suseni Harghita',
  voslabeni_title: 'Die Gemeinde Voşlăbeni besteht aus den Dörfern Voşlăbeni und Izvoru Mureşului Pineyard.ro',
  voslabeni_description:
    'Das Dorf Voşlăbeni nördlich von Izvoru Mureșului ist eine typische Bergsiedlung mit friedlichen und fleißigen Menschen Pineyard.ro',
  voslabeni_keywords: 'Voslabeni,vremea in Voslabeni,Voslabeni Harghita,primaria Voslabeni Harghita',
  local_market_title:
    'In diesem Bereich der Website haben Sie die Möglichkeit, lokale Produkte online zu kaufen Pineyard.ro',
  local_market_description:
    'Auf dieser Webseite werden Ihnen die Produkte präsentiert, die von lokalen Herstellern in der Region Giurgeu zum Verkauf angeboten werden. | Pineyard.ro',
  local_market_keywords:
    'Produse locale jud Harghita,produse locale Giurgeu,cumpara produse locale,producatori locali Giurgeu',
  stay_title:
    'Die Region Giurgeu bietet qualitativ hochwertige Unterkünfte mit zwei, drei und vier Sternen Pineyard.ro',
  stay_description:
    'Über unsere Seite können Sie eine Unterkunft in den Bergen, im Hotel, in der Pension, in der Villa, im Hostel, im Cottage oder auf dem Campingplatz in der Region Giurgeu auswählen. | Pineyard.ro',
  stay_keywords: 'Cazare Giurgeu,cazare jud Harghita,cazare munte,pensiune cu piscina Harghita,cazare munte piscina',
  news_title: 'Neuigkeiten und Updates aus der Region Giurgeu | Pineyard.ro',
  news_description:
    'Informieren Sie sich über die neuesten Nachrichten in der Region, ob es sich um Bergunterkünfte oder die neuesten lokalen Produkte aus der Region Giurgeu handelt Pineyard.ro',
  news_keywords: 'Stiri judetul Harghita,cazare Harghita,stiri Giurgeu,primaria Giurgeu',
  contact_title:
    'Kontaktieren Sie uns mit Zuversicht, unser Team steht Ihnen rund um die Uhr zur Verfügung Pineyard.ro',
  contact_description:
    'Wenn Sie Fragen zu den auf unserer Website veröffentlichten Informationen haben, zögern Sie bitte nicht, uns zu kontaktieren. | Pineyard.ro',
  contact_keywords: 'Trimite mesaj,contact Pineyard',
  events_title: 'Die wichtigsten Ereignisse aus der Region Giurgeu | Pineyard.ro',
  events_description:
    'Wir haben die wichtigsten Ereignisse in der Region Giurgeu, Shows, Messen, Einweihungen usw. zusammengestellt Pineyard.ro',
  events_keywords: 'Evenimente Harghita,evenimente jud Harghita,evenimente Giurgeu,concerte Harghita',
};

export { de_de };
