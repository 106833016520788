/**
 * Defines the data requirements for the component
 *
 * @see Menu.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: null,
  orientation: "vertical",
};

export { propTypes, defaultProps };
