import { useRequests } from "../../../hooks";
import useSWR from "swr";

export const useCheckout = () => {
  const { get } = useRequests();

  const useGetCheckoutSwr = () => {
    return useSWR('market/checkout/info', get);
  };

  return {
    useGetCheckoutSwr,
  }
};
