const ro_ro = {
  required: "Câmp obligatoriu",
  max_number: "Cantitate nevalidă",
  emails_do_not_match: "Email-urile nu se potrivesc",
  emails_match: "Noua adresă de email nu poate fi adresa veche",
  password_min_length: "Parola trebuie să aibă cel puțin 6 caractere",
  passwords_not_match: "Parolele nu se potrivesc",
  min_characters: "Sunt necesare cel puțin 3 caractere",
  positive_number: "Trebuie să fie un număr pozitiv",
  email_invalid: "Vă rugăm să introduceţi o adresă de email validă",
  phone_invalid: "Va rugam sa introduceti un numar de telefon valid",
};

export { ro_ro };
