const hu_hu = {
  home: "itthon",
  admin: "Rendszergazda",
  search: "Keresés",
  stay: "szállás",
  privacy: "Adatvédelmi irányelvek",
  account: "Fiók",
  contact: "Kapcsolatba lépni",
  cart: "Kosár",
  destinations: "Úticélok",
  events: "Események",
  faq: "GYIK",
  sign_out: "Kilépés",
  order_history: "Megrendelések",
  sign_in: "Bejelentkezés",
  news: "hírek",
  local_market: "Helyi piac",
  terms: "Felhasználási feltételek",
  language: "Nyelv",
};

export { hu_hu };
