const ro_ro = {
  Stay: "Cazare",
  "Privacy Policy": "Confidenţialitate",
  Contact: "Contact",
  Destinations: "Destinaţii",
  FAQ: "Faq - Întrebări frecvente",
  Sitemap: "Harta site-ului",
  "Media & Press": "Media",
  Home: "Pagina principală",
  "Local Market": "Piata locala",
  News: "Ştiri",
  Support: "Suport",
  "Terms & Conditions": "Termeni şi condiţii",
};

export { ro_ro };
