const en_us = {
  Stay: "Stay",
  "Privacy Policy": "Privacy Policy",
  Contact: "Contact",
  Destinations: "Destinations",
  FAQ: "FAQ",
  Sitemap: "Sitemap",
  "Media & Press": "Media & Press",
  Home: "Home",
  "Local Market": "Local Market",
  News: "News",
  Support: "Support",
  "Terms & Conditions": "Terms & Conditions",
};

export { en_us };
