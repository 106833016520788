/**
 * Defines the data requirements for the component
 *
 * @see AttractionAsThumb.md for details
 */

/**
 * Imports prop types from other components
 */
import { AttractionPropTypes, AttractionDefaultProps } from "../Attraction";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...AttractionPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...AttractionDefaultProps,
  image: { ...ImageResponsivePlaceholderCom169, sizes: "640px" },
};

export { propTypes, defaultProps };
