/**
 * Component short description
 *
 * @see LocalMarketIntro.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStyles } from "../../Card2";
import { TextSection } from "../../Theme";
import { useUpdateEffect } from "../../../hooks";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./LocalMarketIntro.data";
import { useMarketInfoSwr } from "../LocalMarket/LocalMarket.logic";

/**
 * Imports Material UI components
 */
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LocalMarketIntro.lang.ro-ro";
import { hu_hu } from "./LocalMarketIntro.lang.hu-hu";
import { en_us } from "./LocalMarketIntro.lang.en-us";
import { de_de } from "./LocalMarketIntro.lang.de-de";

i18n.addResourceBundle("ro-RO", "LocalMarketIntro", ro_ro);
i18n.addResourceBundle("hu-HU", "LocalMarketIntro", hu_hu);
i18n.addResourceBundle("en-US", "LocalMarketIntro", en_us);
i18n.addResourceBundle("de-DE", "LocalMarketIntro", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const LocalMarketIntro = (props) => {
  const { t } = useTranslation("LocalMarketIntro");
  const { data: responseData, mutate: mutateMarketInfo } = useMarketInfoSwr();
  const { description, image } = responseData || props;

  const { language } = i18n;
  useUpdateEffect(() => {
    mutateMarketInfo();
  }, [language]);

  /**
   * Displays the hero card
   */
  const cardContent1 = (
    <TextSection title={t("LocalMarket:title")} description={description} />
  );

  const localMarketLink = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={{ pathname: `/${t("Routes:local-market")}` }}
      {...props}
    />
  ));

  const cardActions1 = (
    <Button
      variant="contained"
      color="primary"
      size="large"
      component={localMarketLink}
    >
      {t("marketplace")}
    </Button>
  );

  const cardWithMedia1 = {
    image: {...image, imageWidth: 640},
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  return <Card2 {...cardWithMedia1} />;
};

LocalMarketIntro.propTypes = propTypes;
LocalMarketIntro.defaultProps = defaultProps;

export default LocalMarketIntro;
export {
  propTypes as LocalMarketIntroPropTypes,
  defaultProps as LocalMarketIntroDefaultProps,
};
