/**
 * Defines the data requirements for the component
 *
 * @see Pagination.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  pageSize: PropTypes.number,
  onChange: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  total: 0,
  current: 0,
  pageSize: 8,
  onChange: () => {},
};

export { propTypes, defaultProps };
