/**
 * Component short description
 *
 * @see FilterListItem.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterListItem.data";

/**
 * Imports Material UI components
 */
import {
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./FilterListItem.lang.ro-ro";
import { hu_hu } from "./FilterListItem.lang.hu-hu";
import { en_us } from "./FilterListItem.lang.en-us";
import { de_de } from "./FilterListItem.lang.de-de";
import shortid from "shortid";

i18n.addResourceBundle("ro-RO", "FilterListItem", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterListItem", hu_hu);
i18n.addResourceBundle("en-US", "FilterListItem", en_us);
i18n.addResourceBundle("de-DE", "FilterListItem", de_de);

/**
 * Displays the component
 */
const FilterListItem = (props) => {
  const { primary, children } = props;

  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));

  /**
   * isTablet on inital load is always false
   * When the resolution is changed we expand/collapse the filter accordingly
   */
  useEffect(() => {
    setOpen(isTablet);
  }, [isTablet]);

  const [open, setOpen] = useState(isTablet);

  const handleChange = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <ListItem disableGutters={!isTablet} className={clsx("FilterListItem")}>
      <Grid container direction="column" className="filterContainer">
        <Accordion expanded={open} onChange={handleChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={shortid.generate()}
          >
            <ListItemText primary={primary} />
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </Grid>
    </ListItem>
  );
};

FilterListItem.propTypes = propTypes;
FilterListItem.defaultProps = defaultProps;

export default FilterListItem;
export {
  propTypes as FilterListItemPropTypes,
  defaultProps as FilterListItemDefaultProps,
};
