/**
 * Component short description
 *
 * @see Newsletter.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesInverted } from "../Card2";
import NewsletterForm from "../NewsletterForm";
import { TextSection } from "../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Newsletter.data";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Newsletter.lang.ro-ro";
import { hu_hu } from "./Newsletter.lang.hu-hu";
import { en_us } from "./Newsletter.lang.en-us";
import { de_de } from "./Newsletter.lang.de-de";
import { useNewsletterInfo } from "./Newsletter.logic";
import { useUpdateEffect } from "../../hooks";

i18n.addResourceBundle("ro-RO", "Newsletter", ro_ro);
i18n.addResourceBundle("hu-HU", "Newsletter", hu_hu);
i18n.addResourceBundle("en-US", "Newsletter", en_us);
i18n.addResourceBundle("de-DE", "Newsletter", de_de);

/**
 * Displays the component
 */
const Newsletter = (props) => {
  const { t } = useTranslation("Newsletter");
  const { language } = i18n;

  const { data, mutate } = useNewsletterInfo();
  const { description, enabled, image } = data || props;
  useUpdateEffect(() => {
    mutate();
  }, [language]);

  /**
   * Displays the card
   */
  const cardContent1 = (
    <TextSection subtitle={t("title")} description={description} />
  );

  const cardActions1 = <NewsletterForm />;

  const cardWithMedia1 = {
    image: {...image, imageWidth: 640},
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "md" }),
    cardContainerProps: { alignItems: "center" },
  };

  return !!enabled && <Card2 {...cardWithMedia1} />;
};

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;
export {
  propTypes as NewsletterPropTypes,
  defaultProps as NewsletterDefaultProps,
};
