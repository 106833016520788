/**
 * Reusable business logic
 * - For the web and the React Native apps
 */

/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import useRequests from "../../../hooks/useRequests";
import { stringifyParams } from "../../../hooks/useRequests/useRequests";

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
const useNewsSwr = (props) => {
  const key = props ? `news?${stringifyParams(props)}` : "news";
  const { get } = useRequests();

  return useSWR(key, get);
};

export default useNewsSwr;

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsArticleSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
export const useNewsArticleSwr = (slug) => {
  const key = slug ? `news/${slug}` : "news";
  const { get } = useRequests();

  return useSWR(key, get);
};

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsArticleSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
export const useNewsInfo = () => {
  const { get } = useRequests();

  return useSWR("news/info", get);
};
