const hu_hu = {
  home: "Főoldal",
  search: "Keresés",
  stay: "szállás",
  contact: "Kapcsolatba lépni",
  cart: "Kosár",
  destinations: "Úticélok",
  events: "Események",
  sign_in: "Bejelentkezés",
  local_market: "Helyi piac",
  news: "Hírek",
};

export { hu_hu };
