/**
 * Component short description
 *
 * @see PaypalMobileView.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { useLocation } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
import queryString from "query-string";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2 from "../../Card2";
import List2 from "../../List2";
import { Actions, TextSection } from "../../Theme";
import OrderItem from "../OrderItem";
import { useAuth } from "../../../hooks";
import OrderCancel from "../OrderCancel/OrderCancel";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./PaypalMobileView.data";
import { STATUS_CONFIRMED, STATUS_PAID } from "../../../constants/status";
import { useOrder, useExchangeRate } from "../Order/Order.logic";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./PaypalMobileView.lang.ro-ro";
import { hu_hu } from "./PaypalMobileView.lang.hu-hu";
import { en_us } from "./PaypalMobileView.lang.en-us";
import { de_de } from "./PaypalMobileView.lang.de-de";
import { useSnackbar } from "notistack";

i18n.addResourceBundle("ro-RO", "PaypalMobileView", ro_ro);
i18n.addResourceBundle("hu-HU", "PaypalMobileView", hu_hu);
i18n.addResourceBundle("en-US", "PaypalMobileView", en_us);
i18n.addResourceBundle("de-DE", "PaypalMobileView", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const PaypalMobileView = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Order");
  const { language } = i18n;
  const { updateToken, token: localStorageToken } = useAuth();
  const { search = {} } = useLocation();

  const params = queryString.parse(search);

  const { orderId, token } = params;

  const { enqueueSnackbar } = useSnackbar();

  const { useGetOrderByIdSwr, updateOrderById } = useOrder();
  const { data, mutate: mutateOrder } = useGetOrderByIdSwr(
    orderId,
    Boolean(orderId && Boolean(localStorageToken))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: exchangeRateData } = useExchangeRate();

  const exchangeRate = exchangeRateData ? exchangeRateData.eur : null;

  useEffect(() => {
    mutateOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (token) {
      updateToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data || !localStorageToken) {
    return null;
  }

  const {
    id,
    seller,
    orderDate,
    orderItems,
    totalPrice,
    status,
    statusDescription,
    personalName,
    personalAddress,
    personalPhone,
    deliveryName,
    deliveryAddress,
    deliveryPhone,
    items,
    sellerPaypal,
  } = data;

  const totalPriceEuro = exchangeRate
    ? (totalPrice / exchangeRate).toFixed(2)
    : null;

  /**
   * Displays the order header
   */
  const title = `${t("order")}: ${id}`;

  const details1 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("seller")}: ${seller}` },
        {
          id: shortid.generate(),
          listItem: `${t("order_date")}: ${orderDate}`,
        },
        {
          id: shortid.generate(),
          listItem: `${t(
            "total_price"
          )}: ${totalPrice} RON ~ ${totalPriceEuro ?? 0} Euro`,
        },
      ]}
    />
  );

  const details2 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${statusDescription}` },
        {
          id: shortid.generate(),
          listItem:
            status === STATUS_CONFIRMED ? `1 Euro = ${exchangeRate} RON` : null,
        },
      ]}
    />
  );

  const paypalButton = status === STATUS_CONFIRMED && totalPriceEuro && (
    <PayPalButton
      amount={totalPriceEuro}
      shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
      onSuccess={(details, data) => {
        enqueueSnackbar(t("payment_success"), { variant: "success" });

        return updateOrderById(id, {
          status: STATUS_PAID,
          transactionId: data.orderID,
        }).then(() => {
          mutateOrder();
        });
      }}
      style={{
        height: 40,
        label: "pay",
      }}
      options={{
        locale: "en_RO",
        currency: "EUR",
        clientId: sellerPaypal,
        disableFunding: "credit,card",
      }}
    />
  );
  const cardContent1 = <TextSection subtitle={title} description={details1} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  const cardContent2 = (
    <TextSection subtitle={t("order_status")} description={details2} />
  );

  const cardActions2 = (
    <Actions
      alignItems="flex-start"
      items={[{ id: shortid.generate(), action: paypalButton }]}
    />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2,
    actions: cardActions2,
  };

  /**
   * Displays personal information
   */
  const personalInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("name")}: ${personalName}` },
        {
          id: shortid.generate(),
          listItem: `${t("Address")}: ${personalAddress}`,
        },
        { id: shortid.generate(), listItem: `${t("phone")}: ${personalPhone}` },
      ]}
    />
  );

  const cardContent3 = (
    <TextSection subtitle={t("personal_info")} description={personalInfo} />
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3,
  };

  /**
   * Displays delivery information
   */
  const deliveryInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("name")}: ${deliveryName}` },
        {
          id: shortid.generate(),
          listItem: `${t("address")}: ${deliveryAddress}`,
        },
        { id: shortid.generate(), listItem: `${t("phone")}: ${deliveryPhone}` },
      ]}
    />
  );

  const cardContent4 = (
    <TextSection subtitle={t("info")} description={deliveryInfo} />
  );

  const cardWithMedia4 = {
    image: null,
    content: cardContent4,
  };

  /**
   * Displays the items
   */
  const itemsList =
    items &&
    items.map((item, index) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderItem {...item} index={index} quantity={orderItems} />
        </Grid>
      );
    });

  return (
    <Layout hideHeader header={{ currentPage: "Account" }}>
      <Grid container spacing={1} className={clsx(container, "Order")}>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia2} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia3} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia4} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} className={clsx(container, "OrderItems")}>
            {itemsList}
          </Grid>

          <OrderCancel
            {...{
              seller,
              orderId: id,
              orderDate,
              orderItems,
              totalPrice,
              isModalOpen,
              description: statusDescription,
              handleClose: () => setIsModalOpen(false),
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

PaypalMobileView.propTypes = propTypes;
PaypalMobileView.defaultProps = defaultProps;

export default PaypalMobileView;
export {
  propTypes as PaypalMobileViewPropTypes,
  defaultProps as PaypalMobileViewDefaultProps,
};
