/**
 * Component short description
 *
 * @see Track.md for details
 */

/**
 * Imports React and third party packages
 */
import { useEffect } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Track.data";

/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Track.lang.ro-ro";
import { hu_hu } from "./Track.lang.hu-hu";
import { en_us } from "./Track.lang.en-us";
import { de_de } from "./Track.lang.de-de";
import { getGoogleMapsFormattedData } from "../../helpers/googleMapsHelper";

i18n.addResourceBundle("ro-RO", "Track", ro_ro);
i18n.addResourceBundle("hu-HU", "Track", hu_hu);
i18n.addResourceBundle("en-US", "Track", en_us);
i18n.addResourceBundle("de-DE", "Track", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */

function processPoints(geometry, callback, thisArg) {
  if (geometry instanceof window.google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof window.google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function(g) {
      processPoints(g, callback, thisArg);
    });
  }
}

const Track = (props) => {
  const { map, track } = props;

  const getGeoJson = (track, map) => {
    fetch(track)
      .then((resp) => resp.json())
      .then((data) => {
        const newData = getGoogleMapsFormattedData(data);

        const extendedJson = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              id: "",
              properties: {},
              geometry: newData,
            },
          ],
        };

        map.data.addGeoJson(extendedJson);
      });
  };

  useEffect(() => {
    if (!map || !track) {
      return;
    }
    const google = window.google;

    const bounds = new google.maps.LatLngBounds();

    map.data.addListener("addfeature", function(e) {
      processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      map.fitBounds(bounds);
    });

    // zoom to the clicked feature
    map.data.addListener("click", function(e) {
      var bounds = new google.maps.LatLngBounds();
      processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      map.fitBounds(bounds);
    });
    getGeoJson(track, map);

    map.data.setStyle({
      strokeColor: "red",
      strokeWeight: 1,
    });
  }, [track, map]);

  if (!map || !track) {
    return null;
  }

  return null;
};

Track.propTypes = propTypes;
Track.defaultProps = defaultProps;

export default Track;
export { propTypes as TrackPropTypes, defaultProps as TrackDefaultProps };
