/**
 * Defines the data requirements for the component
 *
 * @see HideOnScroll.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.element.isRequired,
};

/**
 * Defines the default props
 */
const defaultProps = {};

export { propTypes, defaultProps };
