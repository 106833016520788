/**
 * Defines the data requirements for the component
 *
 * @see Faq.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { QuestionsPropTypes, QuestionsDefaultProps } from "../Questions";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  questions: PropTypes.shape(QuestionsPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  questions: QuestionsDefaultProps,
};

export { propTypes, defaultProps };
