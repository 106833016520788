const de_de = {
  request_role: "Rolle anfordern",
  name: "Name",
  email: "Email",
  seller: "Verkäufer",
  host: "Gastgeber",
  destination: "Ziel",
  location: "Ort",
  phone_number: "Telefonnummer",
  message: "Botschaft",
  send_request: "Anfrage senden",
  send_request_error: "Etwas ist schief gelaufen. Bitte versuche es erneut.",
  agree_text: "Ich stimme dem zu",
  terms_and_conditions: "Geschäftsbedingungen",
  description: "Beschreibung",
};

export { de_de };
