const hu_hu = {
  select_destination: "Válassza ki a rendeltetési helyet",
  cancel: "Megszünteti",
  email: "Email",
  host: "Házigazda",
  message: "Üzenet",
  phone: "Telefonszám",
  name: "Név",
  role: "Szerep",
  terms: "Felhasználási feltételek",
  submit: "Felkérést küld",
  seller: "Eladó",
};

export { hu_hu };
