const hu_hu = {
  attractions: "Látnivalók",
  accommodations: "Szálláshelyek",
  destinations: "Úticélok",
  events: "Események",
  no_results: "Nincs találat. Kérjük, próbálkozzon másik kereséssel.",
  products: "Termékek",
  news: "hírek",
  all: "Összes",
};

export { hu_hu };
