/**
 * Defines the data requirements for the component
 *
 * @see Event.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Events/getEvent
 */
const propTypes = {
  /**
   * Props from the API
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  slug: PropTypes.object,
  name: PropTypes.string,
  destination: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  sectionDescription1: PropTypes.string,
  image1: PropTypes.shape(ImageResponsivePropTypes),
  image1Description: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription2: PropTypes.string,
  sectionListing: PropTypes.string,
  image2: PropTypes.shape(ImageResponsivePropTypes),
  image2Description: PropTypes.string,
  sectionDescription3: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  slug: {},
  name: "",
  destinationName: "",
  image: ImageResponsivePlaceholderCom169,
  startDate: null,
  endDate: null,
  description: "",
  sectionDescription1: "",
  image1: ImageResponsivePlaceholderCom169,
  image1Description: "",
  sectionTitle: "",
  sectionDescription2: "",
  sectionListing: "",
  image2: ImageResponsivePlaceholderCom169,
  image2Description: "",
  sectionDescription3: "",
};

export { propTypes, defaultProps };
