/**
 * Displays attractions in a list
 *
 * @see AttractionsList.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import AttractionAsThumb from "../AttractionAsThumb";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AttractionsList.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./AttractionsList.lang.ro-ro";
import { hu_hu } from "./AttractionsList.lang.hu-hu";
import { en_us } from "./AttractionsList.lang.en-us";
import { de_de } from "./AttractionsList.lang.de-de";
import { TextLarge } from "../../Theme/Theme";

i18n.addResourceBundle("ro-RO", "AttractionsList", ro_ro);
i18n.addResourceBundle("hu-HU", "AttractionsList", hu_hu);
i18n.addResourceBundle("en-US", "AttractionsList", en_us);
i18n.addResourceBundle("de-DE", "AttractionsList", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  noAttractionContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "50vh",
    flexDirection: "column"
  }
}));

/**
 * Displays the component
 */
const AttractionsList = (props) => {
  const { attractions, categoryId } = props;
  const { container, noAttractionContainer } = useStyles(props);
  const { t } = useTranslation("AttractionsList");

  const noAttractions = (
    <Grid container className={clsx(noAttractionContainer, "AttractionsList")} spacing={3}>
      <TextLarge text={t(categoryId)}/>
    </Grid>
  );

  const list = attractions.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <AttractionAsThumb {...item} index={index} />
      </Grid>
    );
  });

  return (
    <Grid container className={clsx(container, "AttractionsList")} spacing={1}>
      {list.length ? list : noAttractions}
    </Grid>
  );
};

AttractionsList.propTypes = propTypes;
AttractionsList.defaultProps = defaultProps;

export default AttractionsList;
export {
  propTypes as AttractionsListPropTypes,
  defaultProps as AttractionsListDefaultProps,
};
