/**
 * Defines the data requirements for the component
 *
 * @see Product.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { ImageResponsivePropTypes } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > MarketProduct
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  description: PropTypes.string,
  price: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.tring,
    })
  ),
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  sectionDescription2: PropTypes.string,
  /**
   * From parent
   */
  destinationName: PropTypes.string,
  /**
   * Other props
   *
   */
  slug: PropTypes.object,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: null,
  name: "",
  slug: {},
  images: [],
  // Around 250 chars
  description: "",
  price: null,
  units: [],
  sectionDescription1: "",
  sectionListing: "",
  sectionDescription2: "",
  destinationName: "",
};

export { propTypes, defaultProps };
