/**
 * Component short description
 *
 * @see LoginForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";

/**
 * Imports other components and hooks
 */
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./LoginForm.data";

/**
 * Imports Material UI components
 */
import {
  makeStyles,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LoginForm.lang.ro-ro";
import { hu_hu } from "./LoginForm.lang.hu-hu";
import { en_us } from "./LoginForm.lang.en-us";
import { de_de } from "./LoginForm.lang.de-de";

i18n.addResourceBundle("ro-RO", "LoginForm", ro_ro);
i18n.addResourceBundle("hu-HU", "LoginForm", hu_hu);
i18n.addResourceBundle("en-US", "LoginForm", en_us);
i18n.addResourceBundle("de-DE", "LoginForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  alignRight: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
}));

/**
 * Displays the component
 */
const LoginForm = (props) => {
  const { defaultValues, onSubmit, openDialog } = props;
  const { container, alignRight } = useStyles();
  const { t } = useTranslation("LoginForm");
  const { getLoginFormSchema } = useValidationSchemas();

  const { register, errors, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: getLoginFormSchema(),
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (...args) => {
    onSubmit(...args);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Grid container spacing={2} className={clsx(container)}>
        <Grid item xs={12}>
          <TextField
            type="email"
            inputRef={register}
            fullWidth
            variant="outlined"
            name="email"
            label={t("email")}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            inputRef={register}
            variant="outlined"
            name="password"
            label={t("password")}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={clsx(container)}
          >
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormControlLabel
                  control={<Checkbox name="rememberMe" inputRef={register} />}
                  label={t("remember_me")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={clsx(alignRight)}>
              <Link
                component="button"
                variant="body2"
                type="button"
                onClick={() => openDialog("forgotPass")}
              >
                {t("forgot_pass", "Forgot password?")}
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
          >
            {t("login")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={clsx(container)}
          >
            <Grid item xs={12} sm={6}>
              <Typography display="inline" variant="body2">
                {t("register_text")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={clsx(alignRight)}>
              <Link
                component="button"
                variant="body2"
                onClick={() => openDialog("register")}
              >
                {t("register")}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
export {
  propTypes as LoginFormPropTypes,
  defaultProps as LoginFormDefaultProps,
};
