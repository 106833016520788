/**
 * The custom card component
 * - This is the most complex and expensive component in this project
 * - This happens when design time (Figma) no design system is used; a custom component has to be created
 * - The problem is the MUI Grid component which is totally flawed spacing-wise, and the button mis-alignment
 * - Lots of edge cases has to be compressed into this component
 *
 * @see Card2.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
/**
 * Imports other components and hooks
 */
import ImageResponsive from "../ImageResponsive";
import Image from "../Image";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Card2.data";
/**
 * Imports styles
 */
import {
  defaultStyles,
  localMarketAsHeroStyles,
  localMarketAsHeroStylesInverted,
  noImagePaddingStyle,
  singleRowStyles,
  singleRowStylesInverted,
  singleRowStylesWithActions,
} from "./Card2.styles";
/**
 * Imports Material UI components
 */
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Card2.lang.ro-ro";
import { hu_hu } from "./Card2.lang.hu-hu";
import { en_us } from "./Card2.lang.en-us";
import { de_de } from "./Card2.lang.de-de";

i18n.addResourceBundle("ro-RO", "Card2", ro_ro);
i18n.addResourceBundle("hu-HU", "Card2", hu_hu);
i18n.addResourceBundle("en-US", "Card2", en_us);
i18n.addResourceBundle("de-DE", "Card2", de_de);

/**
 * Displays the component
 */
const Card2 = (props) => {
  const {
    image,
    images,
    carousel,
    content,
    actions,
    customStyles,
    cardProps,
    contentContainerProps,
    imageContainerProps,
    cardContainerProps,
    klassName,
    widget,
    elevation,
    variant,
  } = props;
  /**
   * Loads a predefined style
   */
  const {
    card,
    cardContainer,
    imageContainer,
    cardMedia,
    contentContainer,
    cardContent,
    cardActions,
  } = defaultStyles();

  /**
   * Loads a cutom style from props
   */
  const {
    card: card2,
    cardContainer: cardContainer2,
    imageContainer: imageContainer2,
    cardMedia: cardMedia2,
    contentContainer: contentContainer2,
    cardContent: cardContent2,
    cardActions: cardActions2,
  } = customStyles;

  /**
   * Displays a single image
   *
   * // NOTE: `<CardMedia>` can't be used with responsive images ...
   */
  const displayImage = () => {
    return (
      <Grid
        item
        xs={12}
        {...imageContainerProps}
        className={clsx("Card2ImageContainer", imageContainer, imageContainer2)}
      >
        <div className={clsx("CardMedia", cardMedia, cardMedia2)}>
          {typeof image === "string" ? (
            <Image url={image} hasCaption={false} />
          ) : (
            <ImageResponsive {...image} />
          )}
        </div>
      </Grid>
    );
  };

  /**
   * Displays multiple images
   */
  const displayImages = () => (
    <Grid
      item
      xs={12}
      {...imageContainerProps}
      className={clsx("Card2ImageContainer", imageContainer, imageContainer2)}
    >
      {images &&
        images.map((image) => (
          <div
            className={clsx("CardMedia", cardMedia, cardMedia2)}
            key={shortid.generate()}
          >
            <ImageResponsive {...image} />
          </div>
        ))}
    </Grid>
  );

  /**
   * Displays a carousel
   */
  const displayCarousel = () => (
    <Grid
      item
      xs={12}
      {...imageContainerProps}
      className={clsx("Card2ImageContainer", imageContainer, imageContainer2)}
    >
      {carousel}
    </Grid>
  );

  /**
   * Displays a widget
   */
  const displayWidget = () => (
    <Grid
      item
      xs={12}
      {...imageContainerProps}
      className={clsx("Card2ImageContainer", imageContainer, imageContainer2)}
    >
      <div className={clsx("CardMedia", cardMedia, cardMedia2)}>{widget}</div>
    </Grid>
  );

  /**
   * Displays the content
   */
  const displayContent = () => (
    <Grid
      item
      xs={12}
      {...contentContainerProps}
      className={clsx(
        "Card2ContentContainer",
        contentContainer,
        contentContainer2
      )}
    >
      <CardContent className={clsx("CardContent", cardContent, cardContent2)}>
        {content}
      </CardContent>
      {actions && (
        <CardActions className={clsx("CardActions", cardActions, cardActions2)}>
          {actions}
        </CardActions>
      )}
    </Grid>
  );

  return (
    <Card
      className={clsx("Card2", card, card2)}
      elevation={elevation}
      variant={variant}
      square
      {...cardProps}
    >
      <Grid
        container
        {...cardContainerProps}
        className={clsx(
          "Card2CardContainer",
          klassName,
          cardContainer,
          cardContainer2
        )}
      >
        {widget && displayWidget()}
        {image && displayImage()}
        {images && displayImages()}
        {carousel && displayCarousel()}
        {content && displayContent()}
      </Grid>
    </Card>
  );
};

Card2.propTypes = propTypes;
Card2.defaultProps = defaultProps;

export default Card2;
export {
  propTypes as Card2PropTypes,
  defaultProps as Card2DefaultProps,
  noImagePaddingStyle as Card2NoImagePadding,
  singleRowStyles as Card2SingleRowStyles,
  singleRowStylesInverted as Card2SingleRowStylesInverted,
  localMarketAsHeroStyles as Card2LocalMarketAsHeroStyles,
  localMarketAsHeroStylesInverted as Card2LocalMarketAsHeroStylesInverted,
  singleRowStylesWithActions as Card2SingleRowStylesWithActions,
};
