const en_us = {
  email: "Email address",
  submit: "Join us",
  have_an_account: "Already have an account?",
  conditions_part_2: "Conditions of Use & Sale.",
  sign_in: "Sign in",
  name: "Your name",
  password: "Password",
  conditions_part_1: "By creating an account you agree to Pineyard's",
  confirm_password: "Re-enter password",
};

export { en_us };
