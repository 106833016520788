const ro_ro = {
  location_categories: "Categorii locale",
  pdf: "Descarcă PDF",
  gps: "GPS",
  transportation: "Transport în comun",
  show_more: "Vezi mai mult",
  show_less: "Vezi mai puţin",
  videos: "Video",
  weather: "Vremea",
  webcam: "WebCam",
};

export { ro_ro };
