const ro_ro = {
  category: "Categorie",
  destination: "Destinaţie",
  extras: "În plus",
  clear_all: "Şterge tot",
  room_type: "Tip de cameră",
  accomodation_type: "Tip de cazare",
  seller: "Vânzător",
};

export { ro_ro };
