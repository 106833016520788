/**
 * Defines the data requirements for the component
 *
 * @see List2.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, listItem: PropTypes.string })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [],
};

export { propTypes, defaultProps };
