/**
 * Defines the data requirements for the component
 *
 * @see AccommodationAsThumb.md for details
 */

/**
 * Imports prop types from other components
 */
import {
  AccommodationPropTypes,
  AccommodationDefaultProps,
} from "../Accommodation";
import { ImageResponsivePlaceholderCom169 } from "../../ImageResponsive";

/**
 * Defines the prop types
 */
const propTypes = {
  ...AccommodationPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...AccommodationDefaultProps,
  image: {
    ...ImageResponsivePlaceholderCom169,
  },
};

export { propTypes, defaultProps };
