/**
 * Component short description
 *
 * @see Hero.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports other components and hooks
 */
import ImageResponsive from "../../ImageResponsive";
import { TextHeadline, TextSection } from "../../Theme/Theme";
import Afir from "../Afir";
import About from "../About";
import { useUpdateEffect } from "../../../hooks";
import Carousel from "../../Carousel";

/**
 * Imports Material UI components
 */
import { Box, Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Hero.lang.ro-ro";
import { hu_hu } from "./Hero.lang.hu-hu";
import { en_us } from "./Hero.lang.en-us";
import { de_de } from "./Hero.lang.de-de";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Hero.data";
import SearchForm from "../../_search/SearchForm";
import { useAboutInfo } from "../About/About.logic";

i18n.addResourceBundle("ro-RO", "Hero", ro_ro);
i18n.addResourceBundle("hu-HU", "Hero", hu_hu);
i18n.addResourceBundle("en-US", "Hero", en_us);
i18n.addResourceBundle("de-DE", "Hero", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  afirContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 2,
  },
  searchContainer: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "50%",
      transform: "translate(0%,-50%)",
      zIndex: 2,
      width: "100%",
      overflow: "hidden",
    },
  },
  text: {
    [theme.breakpoints.up("md")]: { color: "white" },
    overflowWrap: "break-word",
  },
  imageContainer: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      "& .CarouselSteps::before": {
        position: "absolute",
        content: '""',
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 2,
      },
    },
  },
}));

/**
 * Displays the component
 */
const Hero = (props) => {
  const { images } = props;
  const { t } = useTranslation("Hero");
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { afirContainer, searchContainer, text, imageContainer } = useStyles(
    props
  );
  const { language } = i18n;

  const { data: aboutData, mutate } = useAboutInfo();
  useUpdateEffect(() => {
    mutate();
  }, [language]);

  const data = aboutData || null;
  if (!data) {
    return null;
  }

  const { searchForm, description } = data;

  const searchFoLgScreen = (
    <>
      <TextSection descriptionClass={text} description={description} />
      <Grid item xs={12}>
        <SearchForm {...searchForm} isWhite />
      </Grid>
    </>
  );

  const content = isSmall ? (
    <About description={description} />
  ) : (
    searchFoLgScreen
  );

  const carouselSteps = images.map((image) => {
    const { nameKey, id, ...rest } = image;
    return {
      id: String(id),
      content: <ImageResponsive {...rest} alternateName={t(nameKey)} />,
    };
  });

  return (
    <Box position="relative">
      <Box className={imageContainer}>
        <Carousel isAutoPlay steps={carouselSteps} />
        <Box className={afirContainer}>
          <Afir />
        </Box>
        <Box className={searchContainer}>
          <Grid container spacing={4} justify="center">
            <Grid container xs={12} item md={8}>
              {!isSmall && (
                <Grid item xs={12} md={6}>
                  <TextHeadline className={text} text={t("headline")} />
                </Grid>
              )}
              <Grid item container xs={12} md={6}>
                {content}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
export { propTypes as HeroPropTypes, defaultProps as HeroDefaultProps };
