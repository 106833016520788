const en_us = {
  category: "Category",
  destination: "Destination",
  extras: "Extras",
  clear_all: "Clear all",
  room_type: "Room type",
  accomodation_type: "Accomodation type",
  seller: "Seller",
};

export { en_us };
