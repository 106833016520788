/**
 * Component short description
 *
 * @see Order.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { Redirect, useLocation } from "react-router-dom";
import { PayPalButton } from "react-paypal-button-v2";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2 from "../../Card2";
import List2 from "../../List2";
import { Actions, PageHeader, TextSection } from "../../Theme";
import OrderItem from "../OrderItem";
import { useAuth } from "../../../hooks";
import OrderCancel from "../OrderCancel/OrderCancel";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Order.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Order.lang.ro-ro";
import { hu_hu } from "./Order.lang.hu-hu";
import { en_us } from "./Order.lang.en-us";
import { de_de } from "./Order.lang.de-de";
import {
  STATUS_CANCELLED,
  STATUS_CONFIRMED,
  STATUS_PAID,
  STATUS_RETURNED,
  STATUS_SHIPPED,
} from "../../../constants/status";
import { useExchangeRate, useOrder } from "./Order.logic";
import OrderReturn from "../OrderReturn/OrderReturn";
import { useSnackbar } from "notistack";

i18n.addResourceBundle("ro-RO", "Order", ro_ro);
i18n.addResourceBundle("hu-HU", "Order", hu_hu);
i18n.addResourceBundle("en-US", "Order", en_us);
i18n.addResourceBundle("de-DE", "Order", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Order = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Order");
  const { language } = i18n;
  const { isAuthenticated } = useAuth();
  const { search } = useLocation();
  const orderId = search.split("=").pop();

  const { useGetOrderByIdSwr, updateOrderById } = useOrder();
  const { data, mutate: mutateOrder } = useGetOrderByIdSwr(orderId);
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const [isReturnOrderModalOpen, setIsReturnOrderModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: exchangeRateData } = useExchangeRate();

  const exchangeRate = exchangeRateData ? exchangeRateData.eur : null;

  useEffect(() => {
    mutateOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  if (!data) {
    return null;
  }

  const {
    id,
    seller,
    orderDate,
    orderItems,
    totalPrice,
    status,
    statusDescription,
    personalName,
    personalAddress,
    personalPhone,
    deliveryName,
    deliveryAddress,
    deliveryPhone,
    items,
    sellerPaypal,
  } = data;

  const totalPriceEuro = exchangeRate
    ? (totalPrice / exchangeRate).toFixed(2)
    : null;

  if (!isAuthenticated) return <Redirect to="/" />;

  const handleAction = (payload, successMessage, setIsModalOpen) => {
    updateOrderById(orderId, payload).then(() => {
      enqueueSnackbar(successMessage, {
        variant: "success",
      });

      mutateOrder().then(() => setIsModalOpen(false));
    });
  };

  const handleOrderReturn = (formData) => {
    const { returnReason } = formData;
    const payload = { status: STATUS_RETURNED, returnReason };

    handleAction(
      payload,
      t("order_returned_successfully"),
      setIsReturnOrderModalOpen
    );
  };

  const handleOrderCancel = () => {
    const payload = { status: STATUS_CANCELLED };

    handleAction(
      payload,
      t("OrderCancel:order_cancelled_successfully"),
      setIsCancelOrderModalOpen
    );
  };

  /**
   * Displays the order header
   */
  const title = `${t("order")}: ${id}`;

  const details1 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("seller")}: ${seller}` },
        {
          id: shortid.generate(),
          listItem: `${t("order_date")}: ${orderDate}`,
        },
        {
          id: shortid.generate(),
          listItem: `${t(
            "total_price"
          )}: ${totalPrice} RON ~ ${totalPriceEuro ?? 0} Euro`,
        },
      ]}
    />
  );

  const details2 = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${statusDescription}` },
        {
          id: shortid.generate(),
          listItem:
            status === STATUS_CONFIRMED ? `1 Euro = ${exchangeRate} RON` : null,
        },
      ]}
    />
  );

  const cancelButton = status !== STATUS_CANCELLED && (
    <Button variant="outlined" onClick={() => setIsCancelOrderModalOpen(true)}>
      {t("cancel_order")}
    </Button>
  );

  const returnOrderButton = status === STATUS_SHIPPED && (
    <Button variant="outlined" onClick={() => setIsReturnOrderModalOpen(true)}>
      {t("return_order")}
    </Button>
  );

  const paypalButton = status === STATUS_CONFIRMED &&
    totalPriceEuro &&
    sellerPaypal && (
      <PayPalButton
        amount={totalPriceEuro}
        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          return updateOrderById(id, {
            status: STATUS_PAID,
            transactionId: data.orderID,
          }).then(() => {
            mutateOrder();
          });
        }}
        style={{
          height: 40,
          label: "pay",
        }}
        options={{
          locale: "en_RO",
          currency: "EUR",
          clientId: sellerPaypal,
          disableFunding: "credit,card",
        }}
      />
    );
  const cardContent1 = <TextSection subtitle={title} children={details1} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  const cardContent2 = (
    <TextSection subtitle={t("order_status")} children={details2} />
  );

  const cardActions2 = (
    <Actions
      alignItems="flex-start"
      items={[
        { id: shortid.generate(), action: cancelButton },
        { id: shortid.generate(), action: returnOrderButton },
        { id: shortid.generate(), action: paypalButton },
      ]}
    />
  );

  const cardWithMedia2 = {
    image: null,
    content: cardContent2,
    actions: cardActions2,
  };

  /**
   * Displays personal information
   */
  const personalInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("name")}: ${personalName}` },
        {
          id: shortid.generate(),
          listItem: `${t("Address")}: ${personalAddress}`,
        },
        { id: shortid.generate(), listItem: `${t("phone")}: ${personalPhone}` },
      ]}
    />
  );

  const cardContent3 = (
    <TextSection subtitle={t("personal_info")} children={personalInfo} />
  );

  const cardWithMedia3 = {
    image: null,
    content: cardContent3,
  };

  /**
   * Displays delivery information
   */
  const deliveryInfo = (
    <List2
      items={[
        { id: shortid.generate(), listItem: `${t("name")}: ${deliveryName}` },
        {
          id: shortid.generate(),
          listItem: `${t("address")}: ${deliveryAddress}`,
        },
        { id: shortid.generate(), listItem: `${t("phone")}: ${deliveryPhone}` },
      ]}
    />
  );

  const cardContent4 = (
    <TextSection subtitle={t("info")} children={deliveryInfo} />
  );

  const cardWithMedia4 = {
    image: null,
    content: cardContent4,
  };

  /**
   * Displays the items
   */
  const itemsList =
    items &&
    items.map((item, index) => {
      const { id } = item;

      return (
        <Grid item key={id} xs={12}>
          <OrderItem {...item} index={index} quantity={orderItems} />
        </Grid>
      );
    });

  return (
    <Layout header={{ currentPage: "Account" }}>
      <Grid container spacing={1} className={clsx(container, "Order")}>
        <Grid item xs={12}>
          <PageHeader breadcrumbs={<Breadcrumbs />} title={t("order")} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia2} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia3} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card2 {...cardWithMedia4} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} className={clsx(container, "OrderItems")}>
            {itemsList}
          </Grid>

          <OrderCancel
            {...{
              seller,
              orderId: id,
              orderDate,
              orderItems,
              totalPrice,
              isModalOpen: isCancelOrderModalOpen,
              description: statusDescription,
              handleClose: () => setIsCancelOrderModalOpen(false),
              handleOrderCancel,
            }}
          />

          <OrderReturn
            {...{
              handleOrderReturn,
              isModalOpen: isReturnOrderModalOpen,
              handleClose: () => setIsReturnOrderModalOpen(false),
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Order.propTypes = propTypes;
Order.defaultProps = defaultProps;

export default Order;
export { propTypes as OrderPropTypes, defaultProps as OrderDefaultProps };
