const hu_hu = {
  quantity: "Mennyiség",
  item: "Tétel",
  items: "Tételek",
  order_date: "Rendelés dátuma",
  order_number: "Rendelésszám",
  order_status: "Rendelési státusz",
  seller: "Eladó",
  pending: "Függőben levő",
  refused: "Törölve",
  confirmed: "Fizetésre vár",
  cancelled: "Törölve",
  paid: "Fizetett",
  shipped: "Kiszállított",
  returned: "Visszatért",
  return_requested: "Visszaadás kérve",
};

export { hu_hu };
