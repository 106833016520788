const de_de = {
  title: "Passwort vergessen",
  reset_success: "E-Mail zur Passwortwiederherstellung gesendet",
  login: "Einloggen",
  return: "Oder zurück zu",
  description:
    "Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.",
};

export { de_de };
