const ro_ro = {
  add_to_cart: "Adaugă în coş",
  description: "Descriere",
  unit: "Unitate",
  units: "Unităţi",
  sing_in: "Conectați-vă pentru a cumpăra",
  product_addded_to_cart: "Produsul a fost adăugat cu succes în coș",
};

export { ro_ro };
