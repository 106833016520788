/**
 * Component short description
 *
 * @see useUpdateEffect.md for details
 */

/**
 * Imports React and third party packages
 */
import { useEffect, useRef } from "react";
/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useUpdateEffect.data";

/**
 * Runs only when a dependency is changed, component mount is ignored
 * Use case: when language changes the data has to be re-fetched
 * With useEffect the data is also refetched when the component mounts,
 * we want to avoid this
 */
const useUpdateEffect = (func, deps = []) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

useUpdateEffect.propTypes = propTypes;
useUpdateEffect.defaultProps = defaultProps;

export default useUpdateEffect;
export {
  propTypes as useUpdateEffectPropTypes,
  defaultProps as useUpdateEffectDefaultProps,
};
