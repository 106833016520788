const ro_ro = {
  sold_by: "Articole vândute şi livrate de",
  checkout: "Finalizează cumpărăturile",
  pending: "În aşteptare",
  total_price: "preţ Final",
  delivery_price: "Preţ livrare",
  items_price: "Preţul produsului",
};

export { ro_ro };
