/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";

/**
 * Imports data
 */

const useContactInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("contact/info", get);
};

/**
 * Displays the component
 */
const useContact = (props) => {
  const { post } = useRequests();

  const createContact = (contact) => {
    return post("contact", contact);
  };

  return { createContact, useContactInfoSwr };
};

export default useContact;
export { useContactInfoSwr };
