/**
 * Defines the data requirements for the component
 *
 * @see AttractionsList.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { AttractionPropTypes } from "../Attraction";

/**
 * Defines the prop types
 */
const propTypes = {
  attractions: PropTypes.arrayOf(PropTypes.shape(AttractionPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  attractions: [],
};

export { propTypes, defaultProps };
