const en_us = {
  1: "There are no Natural turist attractions in this locality.",
  2: "There are no Cultural turist attractions in this locality.",
  3: "There are no Natural mineral water springs in this locality.",
  4: "There are no Spa Treatment services in this locality.",
  5: "There are no Recreational services in this locality.",
  6: "There are no Turist routes in this locality.",
  7: "There are no Museums in this locality.",
  8: "There is no information of type All in this locality.",
  9: "There is no information of type Other in this locality.",
  10: "There are no Restaurants and terraces.",
};

export { en_us };
