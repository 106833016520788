const en_us = {
  address: "Address",
  cancel_order: "Cancel order",
  order: "Order",
  order_date: "Order date",
  info: "Delivery information",
  personal_info: "Personal information",
  name: "Name",
  total_price: "Total price",
  order_status: "Order status",
  phone: "Phone",
  seller: "Seller",
  cancelled: "Cancelled",
  confirmed: "Confirmed",
  paid: "Paid",
  shipped: "Shipped",
  refused: "Refused",
  return_requested: "Return requested",
  returned: "Returned",
  pending: "Pending",
  order_number: "Order Number",
  items: "Items",
  item: "Item",
  order_cancellation: "Order Cancellation",
  confirm_cancel: "Confirm Cancel",
  back_to_order: "Back to Order",
  order_cancelled: "Your Order has been cancelled",
  cancel_order_success: "Your Order has been cancelled",
  cancel_order_failed: "Something went wrong when cancelling your order.",
  get_order_failed: "Something went wrong. Please try again.",
  payment_success: "Successful payment, you can now return to the app",
  complete_payment: "Complete payment",
  order_detail: "Order Detail",
  return_order: "Return order",
  order_returned_successfully: "Order returned sucessfully",
};

export { en_us };
