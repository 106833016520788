import { useRequests } from "../../../hooks";

export const useAuthRequests = () => {
  const { post, deleteReq } = useRequests();

  const login = data => {
    return post("auth/token", data);
  };

  const register = data => {
    return post("auth/register", data);
  };

  const forgotPass = data => {
    return post("auth/forgot-password", data);
  };

  const changePass = data => {
    return post("auth/change-password", data);
  };

  const logout = () => {
    return deleteReq("auth/logout");
  };

  return { login, register, forgotPass, changePass, logout };
};
