const en_us = {
  search: "search",
  stay: "stay",
  order: "order",
  "privacy-policy": "privacy-policy",
  account: "account",
  contact: "contact",
  cart: "cart",
  destinations: "destinations",
  events: "events",
  faq: "faq",
  checkout: "checkout",
  sitemap: "sitemap",
  "order-history": "order-history",
  "booking-history": "booking-history",
  "media-and-press": "media-and-press",
  settings: "settings",
  "local-market": "local-market",
  news: "news",
  support: "support",
  "terms-and-conditions": "terms-and-conditions",
  videos: "videos",
  "paypal-mobile": "paypal-mobile",
};

export { en_us };
