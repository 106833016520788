/**
 * Component short description
 *
 * @see CookiesDialog.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import Dialog2 from "../Dialog2";
import { Controller, useForm } from "react-hook-form";
import useConsent from "../../hooks/useConsent/useConsent";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CookiesDialog.data";

/**
 * Imports Material UI components
 */
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./CookiesDialog.lang.ro-ro";
import { hu_hu } from "./CookiesDialog.lang.hu-hu";
import { en_us } from "./CookiesDialog.lang.en-us";
import { de_de } from "./CookiesDialog.lang.de-de";

i18n.addResourceBundle("ro-RO", "CookiesDialog", ro_ro);
i18n.addResourceBundle("hu-HU", "CookiesDialog", hu_hu);
i18n.addResourceBundle("en-US", "CookiesDialog", en_us);
i18n.addResourceBundle("de-DE", "CookiesDialog", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const CookiesDialog = (props) => {
  const { checkboxes, open } = props;
  const { t } = useTranslation("CookiesDialog");

  const { setConsent, closeDialog } = useConsent();

  const { handleSubmit, control } = useForm();

  const handleFormSubmit = (form) => {
    setConsent(form);
    closeDialog();
  };

  const handleFormSubmitAll = (form) => {
    Object.keys(form).forEach((key) => {
      form[key] = true;
    });

    setConsent(form);
    closeDialog();
  };

  const content = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{t("description")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormGroup>
            {checkboxes.map((checkbox) => {
              return (
                <FormControlLabel
                  key={checkbox.name}
                  control={
                    <Controller
                      as={Checkbox}
                      control={control}
                      disabled={checkbox.disabled}
                      onChange={([e]) => {
                        return e.target.checked;
                      }}
                      name={checkbox.name}
                      defaultValue={checkbox.defaultChecked}
                    />
                  }
                  label={t(checkbox.trans)}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  const actions = (
    <>
      <Button onClick={handleSubmit(handleFormSubmit)} color="primary">
        {t("accept_selected")}
      </Button>
      <Button
        onClick={handleSubmit(handleFormSubmitAll)}
        variant="contained"
        color="primary"
        autoFocus
      >
        {t("accept_all")}
      </Button>
    </>
  );

  return (
    <Dialog2
      maxWidth="md"
      title={t("title")}
      height="auto"
      open={open}
      content={content}
      actions={actions}
      disableContentPading={false}
    />
  );
};

CookiesDialog.propTypes = propTypes;
CookiesDialog.defaultProps = defaultProps;

export default CookiesDialog;
export {
  propTypes as CookiesDialogPropTypes,
  defaultProps as CookiesDialogDefaultProps,
};
