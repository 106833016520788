/**
 * Displays a list of icons
 *
 * @see Icons.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Icon from "../Icon";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Icons.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Icons.lang.ro-ro";
import { hu_hu } from "./Icons.lang.hu-hu";
import { en_us } from "./Icons.lang.en-us";
import { de_de } from "./Icons.lang.de-de";

i18n.addResourceBundle("ro-RO", "Icons", ro_ro);
i18n.addResourceBundle("hu-HU", "Icons", hu_hu);
i18n.addResourceBundle("en-US", "Icons", en_us);
i18n.addResourceBundle("de-DE", "Icons", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Icons = forwardRef((props, ref) => {
  const { items, orientation, activeItem, ...rest } = props;
  const { container } = useStyles(props);

  const icons = items.map((icon) => {
    const { id, name } = icon;
    const highlighted = name === activeItem;

    return (
      <Grid item key={id}>
        <Icon {...icon} highlighted={highlighted} {...rest} />
      </Grid>
    );
  });

  const direction = orientation === "vertical" ? "column" : "row";

  return (
    <Grid
      container
      direction={direction}
      alignItems={direction === "row" ? "center" : null}
      wrap="nowrap"
      className={clsx(container, "Icons")}
      spacing={2}
    >
      {icons}
    </Grid>
  );
});

Icons.propTypes = propTypes;
Icons.defaultProps = defaultProps;

export default Icons;
export { propTypes as IconsPropTypes, defaultProps as IconsDefaultProps };
