/**
 * A general component for typography, deco elements specific to this project
 *
 * @see Theme.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import shortid from "shortid";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Theme.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Theme.lang.ro-ro";
import { hu_hu } from "./Theme.lang.hu-hu";
import { en_us } from "./Theme.lang.en-us";
import { de_de } from "./Theme.lang.de-de";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "Theme", ro_ro);
i18n.addResourceBundle("hu-HU", "Theme", hu_hu);
i18n.addResourceBundle("en-US", "Theme", en_us);
i18n.addResourceBundle("de-DE", "Theme", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
  textDefaultHTML: {
    whiteSpace: 'pre-wrap'
  },
  templateKlass: {
    "& .MuiGrid-item": {
      padding: 0,

      "& .Card2": {
        paddingBottom: 0,
        paddingTop: 0,

        "& .Card2ContentContainer": {
          paddingBottom: 0,

          "& .CardContent": {
            paddingBottom: 0,

            "& .TextSection": {
              "& .MuiGrid-item": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          },
        },
      },

      "&:first-of-type": {
        paddingTop: theme.spacing(2),

        "& .Card2": {
          paddingTop: theme.spacing(2),
        },
      },

      "&:last-of-type": {
        paddingBottom: theme.spacing(2),

        "& .Card2": {
          paddingBottom: theme.spacing(2),
        },
      },
    },
  },

  textOverlineKlass: {
    lineHeight: 1,
  },

  titleKlass: {
    lineHeight: 1.25,
  },

  quoteKlass: {
    fontStyle: "italic",
    fontSize: "larger",
  },

  pageHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up("lg")]: {
      /**
       * Aligning to the menu above
       */
      paddingLeft: theme.spacing(1),
    },
  },

  pageHeaderBreadcrumbs: {
    marginBottom: theme.spacing(1),
  },

  pageHeaderTitle: {
    //marginTop: theme.spacing(4)
  },

  pageHeaderOverline: {
    /**
     * Align to the title above
     */
    paddingLeft: 2,
  },

  headlineKlass: {
    lineHeight: 1.1,
  },
  textListing: {
    display: "flex",
    paddingLeft: '20px !important'
  },
  textListingBullet: {
    paddingRight: '5px',
  }
}));

/**
 * Defines theme constants
 */
const textSpacing = 2;

/**
 * Displays an overline text
 */
const TextOverline = (props) => {
  const { text } = props;
  const { textOverlineKlass } = useStyles(props);

  return (
    <Typography variant="overline" className={clsx(textOverlineKlass)}>
      {text}
    </Typography>
  );
};

/**
 * Displays a large text
 */
const TextLarge = (props) => {
  const { text, className = null } = props;
  const { textDefaultHTML } = useStyles(props);

  return (
    <Typography
      className={`${className} ${textDefaultHTML}`}
      variant="body1"
      dangerouslySetInnerHTML={{__html: text}}
    />
  );
};

/**
 * Displays a default text
 */
const TextDefault = (props) => {
  const { text, customClass } = props;
  const { textDefaultHTML } = useStyles(props);

  return (
    <Typography
      variant="body1"
      dangerouslySetInnerHTML={{ __html: text }}
      className={`${customClass} ${textDefaultHTML}`}
    />
  );
};

/**
 * Displays a quote text
 */
const TextQuote = (props) => {
  const { text } = props;
  const { quoteKlass, textDefaultHTML } = useStyles(props);

  return (
    <Typography
      variant="body1"
      dangerouslySetInnerHTML={{ __html: text }}
      className={`${textDefaultHTML} ${clsx(quoteKlass)}`}/>
  );
};

/**
 * Displays a headline
 */
const TextHeadline = (props) => {
  const { text, className = null } = props;
  const { headlineKlass } = useStyles(props);

  return (
    <Typography
      variant="h3"
      className={clsx(className, headlineKlass, "Headline")}
    >
      {text}
    </Typography>
  );
};

/**
 * Displays page title
 */
const TextPageTitle = (props) => {
  const { text } = props;
  const { titleKlass } = useStyles(props);

  return (
    <Typography variant="h3" className={clsx(titleKlass, "PageTitle")}>
      {text}
    </Typography>
  );
};

/**
 * Displays a section title
 */
const TextSectionTitle = (props) => {
  const { text } = props;
  const { titleKlass } = useStyles(props);

  return (
    <Typography variant="h4" className={clsx(titleKlass, "Title")}>
      {text}
    </Typography>
  );
};

/**
 * Displays a subsection title
 */
const TextSubSectionTitle = (props) => {
  const { text } = props;
  const { titleKlass } = useStyles(props);

  return (
    <Typography variant="h5" className={clsx(titleKlass, "Subtitle")}>
      {text}
    </Typography>
  );
};

/**
 * Displays price
 */
const TextPrice = (props) => {
  return <TextSubSectionTitle {...props} />;
};

/**
 * Displays a section
 *
 * // NOTE: This is a dirty hack, it can be done better
 */
const TextSection = (props) => {
  const {
    title,
    subtitle,
    description,
    quote,
    listing,
    description2,
    overline,
    price,
    button,
    children,
    descriptionClass = null,
  } = props;
  const { container, textListing, textListingBullet } = useStyles(props);

  let sectionListing = () => {
    if (!listing) {
      return null;
    }

    if (typeof listing !== "string" || listing.split(/\n/).length <= 1) {
      return (
        <Grid item xs={12}>
          <TextLarge text={listing} />
        </Grid>
      );
    }

    return listing.split(/\n/).map((item, index) => (
      <Grid item xs={12} key={String(index)} className={textListing}>
        <FiberManualRecordIcon className={textListingBullet} />
        <TextLarge text={item} />
      </Grid>
    ));
  };

  return (
    <Grid
      container
      spacing={textSpacing}
      className={clsx(container, "TextSection")}
    >
      {title && (
        <Grid item xs={12}>
          <TextSectionTitle text={title} />
        </Grid>
      )}
      {subtitle && (
        <Grid item xs={12}>
          <TextSubSectionTitle text={subtitle} />
        </Grid>
      )}
      {description && (
        <Grid item xs={12}>
          <TextLarge className={descriptionClass} text={description} isHTML={true} />
        </Grid>
      )}
      {quote && (
        <Grid item xs={12}>
          <TextQuote text={quote} />
        </Grid>
      )}
      {sectionListing()}
      {description2 && (
        <Grid item xs={12}>
          <TextLarge text={description2} isHTML={true} />
        </Grid>
      )}
      {overline && (
        <Grid item xs={12}>
          <TextOverline text={overline} />
        </Grid>
      )}
      {price && (
        <Grid item xs={12}>
          <TextPrice text={price} />
        </Grid>
      )}
      {button && (
        <Grid item xs={12}>
          {button}
        </Grid>
      )}
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Displays breadcrumbs, page title, and overline
 *
 * Title only:
 * - On smaller screens when no image follows the title it doesn't looks so nice
 *
 * Breadcrumbs:
 * - On large screens they're aligned left (good) while the menu is centered(bad) => Menu shouldn't be centered
 */
const PageHeader = (props) => {
  const { breadcrumbs, overline, title } = props;
  const {
    container,
    pageHeader,
    pageHeaderOverline,
    pageHeaderTitle,
    pageHeaderBreadcrumbs,
  } = useStyles(props);

  return (
    <Grid
      container
      spacing={0}
      className={clsx(container, pageHeader, "PageHeader")}
    >
      {breadcrumbs && (
        <Grid item xs={12} className={clsx(pageHeaderBreadcrumbs)}>
          {breadcrumbs}
        </Grid>
      )}
      {title && (
        <Grid item xs={12} className={clsx(pageHeaderTitle)}>
          <TextPageTitle text={title} />
        </Grid>
      )}
      {overline && (
        <Grid item xs={12} className={clsx(pageHeaderOverline)}>
          <TextOverline text={overline} />
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Displays a thumbnail title with link
 */
const ThumbnailTitleWithLink = (props) => {
  const { text, to } = props;
  const { titleKlass } = useStyles(props);

  return (
    <Link component={RouterLink} to={to}>
      <Typography variant="body1" className={clsx(titleKlass, "TitleWithLink")}>
        {text}
      </Typography>
    </Link>
  );
};

/**
 * Displays price in a thumbnail
 */
const ThumbnailPrice = (props) => {
  const { price } = props;
  return <Typography variant="subtitle1">{price}</Typography>;
};

/**
 * Displays a text for thumbnail with overline, link to title, and price
 */
const TextThumbnail = (props) => {
  const { overline, to, title, description, price } = props;
  const { container } = useStyles(props);

  return (
    <Grid container spacing={0} className={clsx(container, "TextThumbnail")}>
      {overline && (
        <Grid item xs={12}>
          <TextOverline text={overline} />
        </Grid>
      )}
      {to && title && (
        <Grid item xs={12}>
          <ThumbnailTitleWithLink to={to} text={title} />
        </Grid>
      )}
      {description && (
        <Grid item xs={12}>
          <TextDefault text={description} html={true} />
        </Grid>
      )}
      {price && (
        <Grid item xs={12}>
          <ThumbnailPrice price={price} />
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Displays a template
 */
const Template = (props) => {
  const { items } = props;
  const { container, templateKlass } = useStyles(props);

  const itemsList =
    items &&
    items.map((item, index) => {
      return (
        <Grid item xs={12} key={shortid.generate()}>
          {item}
        </Grid>
      );
    });

  return (
    <Grid
      container
      spacing={textSpacing}
      className={clsx(container, templateKlass, "Template")}
    >
      {itemsList}
    </Grid>
  );
};

/**
 * Displays a list of card actions
 */
const Actions = (props) => {
  const {
    items,
    orientation,
    justify,
    alignItems: alignItemsProp,
    fullGridItems,
  } = props;
  const { container } = useStyles(props);

  const gridSize = fullGridItems ? 12 : "auto";

  const itemsList =
    items &&
    items.map((item) => {
      const { id, action } = item;

      return (
        <Grid item key={id} xs={gridSize}>
          {action}
        </Grid>
      );
    });

  const direction = orientation
    ? orientation === "vertical"
      ? "column"
      : "row"
    : "row";

  const alignItems = alignItemsProp
    ? alignItemsProp
    : direction === "row"
      ? "center"
      : null;
  return (
    <Grid
      container
      spacing={1}
      direction={direction}
      alignItems={alignItems}
      justify={justify}
      className={clsx(container, "Actions")}
    >
      {itemsList}
    </Grid>
  );
};

/**
 * Displays the component
 */
const Theme = (props) => {
  return null;
};

Theme.propTypes = propTypes;
Theme.defaultProps = defaultProps;

export default Theme;
export { propTypes as ThemePropTypes, defaultProps as ThemeDefaultProps };
export {
  TextOverline,
  TextLarge,
  TextDefault,
  TextQuote,
  TextSectionTitle,
  TextSubSectionTitle,
  TextSection,
  TextPrice,
  TextPageTitle,
  TextHeadline,
  ThumbnailTitleWithLink,
  ThumbnailPrice,
  TextThumbnail,
  Template,
  Actions,
  PageHeader,
};
