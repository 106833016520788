/**
 * Component short description
 *
 * @see LanguageSelector.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useRef } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { upperCase } from "lodash";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import { yup, useValidation, useUpdateEffect, useAuth } from "../../hooks";
import useSlugs from "../../hooks/useSlugs/useSlugs";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./LanguageSelector.data";
import { updateURL } from "../Routes/Routes.logic";
import { RoutesDefaultProps } from "../Routes";
import { headerTheme } from "../../theme/theme";
import { useAccount } from "../_account/Account/Account.logic";

/**
 * Imports Material UI components
 */
import { makeStyles, ThemeProvider } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LanguageSelector.lang.ro-ro";
import { hu_hu } from "./LanguageSelector.lang.hu-hu";
import { en_us } from "./LanguageSelector.lang.en-us";
import { de_de } from "./LanguageSelector.lang.de-de";

i18n.addResourceBundle("ro-RO", "LanguageSelector", ro_ro);
i18n.addResourceBundle("hu-HU", "LanguageSelector", hu_hu);
i18n.addResourceBundle("en-US", "LanguageSelector", en_us);
i18n.addResourceBundle("de-DE", "LanguageSelector", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(() => ({
  container: {},
  textField: {
    /**
     * Remove the underline
     */
    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },

    /**
     * Aligning the dropdown icon
     */
    "& .MuiSelect-icon": {
      top: "auto",
    },
  },
}));

const getLocale = (lng = null) => {
  const language = lng ?? i18n.language;

  const [locale] = language.split("-");
  return locale;
};

const getCurrentLang = () => {
  return i18n.language;
};
/**
 * Displays the component
 */
const LanguageSelector = (props) => {
  const { languages, defaultLanguage } = props;
  const { alternateName } = defaultLanguage;

  const { updateLanguage } = useAccount();
  const { isAuthenticated } = useAuth();

  const { container, textField } = useStyles(props);
  const { t, i18n } = useTranslation("LanguageSelector");
  const currentLanguage = i18n.language || alternateName;
  const oldLang = useRef(currentLanguage);

  const { slugs } = useSlugs();
  /**
   * Loads breadcrumbs:
   * 1. The default language will be determined from URL with breadcrumbs
   * 2. On language change the URL will be updated by the help of breadcrumbs
   * 2.a useLocation is not enough for translations
   */
  const breadcrumbs = useBreadcrumbs();

  /**
   * Loads routes
   * - They will be used to update the URL on language change
   * - For this task breadcrumbs alone are not enough
   */
  const { routes } = RoutesDefaultProps;

  /**
   * Manages the state of the select box
   */
  const [selected, setSelected] = useState(currentLanguage);

  /**
   * Manages the select box change
   */
  const selectHandler = (event) => {
    setSelected(event.target.value);
  };

  /**
   * Validates the language selected
   */
  const [isError, errorMessages, validateSchema] = useValidation({
    language: yup
      .string()
      .required()
      .typeError(t("It should be a string")),
  });

  /**
   * Sets the new language
   */
  const history = useHistory();
  const location = useLocation();

  useUpdateEffect(() => {
    validateSchema({ language: selected });

    i18n.changeLanguage(selected);

    if (isAuthenticated && oldLang.current !== selected) {
      updateLanguage(getLocale(selected));
    }

    const { url } = updateURL({
      breadcrumbs: breadcrumbs,
      i18n: i18n,
      routes: routes,
      oldLanguage: oldLang.current,
      slugs: slugs,
      location: location,
    });

    oldLang.current = selected;
    history.replace(url);
  }, [selected]);

  /**
   * Prepares the select box items
   */
  const items = languages.map((item) => {
    const { displayName, alternateName } = item;

    return (
      <MenuItem key={shortid.generate()} value={alternateName}>
        {/* // NOTE: <Typography> makes font size the same as the other items in the menu*/}
        <Typography variant="body2">{upperCase(displayName)}</Typography>
      </MenuItem>
    );
  });

  return (
    <FormControl
      className={clsx(container, "LanguageSelector")}
      error={isError}
    >
      <ThemeProvider theme={headerTheme}>
        <TextField
          name="language"
          className={clsx(textField, "TextField")}
          select
          value={selected}
          onChange={selectHandler}
        >
          {items}
        </TextField>
      </ThemeProvider>
      {isError && <FormHelperText>{errorMessages}</FormHelperText>}
    </FormControl>
  );
};

LanguageSelector.propTypes = propTypes;
LanguageSelector.defaultProps = defaultProps;

export default LanguageSelector;
export {
  propTypes as LanguageSelectorPropTypes,
  defaultProps as LanguageSelectorDefaultProps,
  getLocale,
  getCurrentLang,
};
