const de_de = {
  home: "Hauptseite",
  search: "Suche",
  stay: "Unterkunft",
  contact: "Kontakt",
  cart: "Einkaufswagen",
  destinations: "Ziele",
  events: "Veranstaltungen",
  sign_in: "Einloggen",
  local_market: "Lokaler Markt",
  news: "Nachrichten",
};

export { de_de };
