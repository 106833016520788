/**
 * Displays a category as a thumbnail
 *
 * @see CategoryAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./CategoryAsThumb.data";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";
/**
 * Imports Material UI components
 */
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./CategoryAsThumb.lang.ro-ro";
import { hu_hu } from "./CategoryAsThumb.lang.hu-hu";
import { en_us } from "./CategoryAsThumb.lang.en-us";
import { de_de } from "./CategoryAsThumb.lang.de-de";
import { Card2NoImagePadding } from "../../Card2/Card2";

i18n.addResourceBundle("ro-RO", "CategoryAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoryAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "CategoryAsThumb", en_us);
i18n.addResourceBundle("de-DE", "CategoryAsThumb", de_de);

/**
 * Displays the component
 */
const CategoryAsThumb = (props) => {
  const { name, slug, count, icon, destinationSlug } = props;

  /**
   * Loads the current route
   */
  const match = useRouteMatch();
  const { url } = match;

  const to = destinationSlug
    ? `${url}/${destinationSlug}/${getSlugForCurrentLang(slug)}`
    : `${url}/${getSlugForCurrentLang(slug)}`;
  /**
   * Prepares a card
   */
  const cardContent = <TextThumbnail to={to} title={`${name} (${count})`} />;

  const cardWithMedia = {
    image: icon,
    images: null,
    content: cardContent,
    customStyles: Card2NoImagePadding(),
  };

  return <Card2 {...cardWithMedia} breakpoint="sm" />;
};

CategoryAsThumb.propTypes = propTypes;
CategoryAsThumb.defaultProps = defaultProps;

export default CategoryAsThumb;
export {
  propTypes as CategoryAsThumbPropTypes,
  defaultProps as CategoryAsThumbDefaultProps,
};
