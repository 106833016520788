/**
 * Defines the data requirements for the component
 *
 * @see Accommodation.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports prop types from other components
 */
import { ImageResponsivePropTypes } from "../../ImageResponsive";

import { IconPropTypes } from "../../Icon";
import { GpsPropTypes, GpsDefaultProps } from "../../Gps";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import SpaIcon from "@material-ui/icons/Spa";
import RestaurantIcon from "@material-ui/icons/Restaurant";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * From API
   *
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/
   * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Stay/getStayAccommodationIdByID
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  slug: PropTypes.object,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  destinationName: PropTypes.string,
  price: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  accommodationType: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  extras: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  sectionTitle: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  sectionDescription2: PropTypes.string,
  /**
   * Other props
   */
  gps: PropTypes.shape(GpsPropTypes),
  accommodationTypeIcon: PropTypes.shape(IconPropTypes),
  roomTypesIcon: PropTypes.shape(IconPropTypes),
  extrasIcon: PropTypes.shape(IconPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: null,
  name: "",
  slug: {},
  description: "", // ~250 chars
  images: [],
  destinationName: "",
  price: null,
  latitude: null,
  longitude: null,
  accommodationType: {},
  roomTypes: [],
  extras: [],
  sectionTitle: "", // ~128 chars
  sectionDescription1: "", // ~500 chars
  sectionListing: "",
  sectionDescription2: "", // ~500 chars
  gps: GpsDefaultProps,
  accommodationTypeIcon: {
    id: shortid.generate(),
    icon: <HomeWorkIcon />,
  },
  roomTypesIcon: {
    id: shortid.generate(),
    icon: <MeetingRoomIcon />,
  },
  mealIcon: {
    icon: <RestaurantIcon />,
  },
  extrasIcon: {
    id: shortid.generate(),
    icon: <SpaIcon />,
  },
};

export { propTypes, defaultProps };
