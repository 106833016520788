/**
 * Defines the data requirements for the component
 *
 * @see VideoAsThumb.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see http://api.borsecturismregional.web-staging.eu/apidoc/#/Destinations/getDestination
 */
const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: 1,
  name: "Video name",
  url: "https://www.youtube.com/watch?v=CD-E-LDc384",
  image: ImageResponsivePlaceholderCom169,
};

export { propTypes, defaultProps };
