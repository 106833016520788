const en_us = {
  items_count_and_price: "{{orderItems}} items / {{totalPrice}} RON",
  confirm_cancel: "Cancel Order",
  title: "Order Cancellation",
  order_cancelled_successfully: "Order cancelled successfully",
  order_date: "Order Date: {{value}}",
  back_to_order: "Back to order",
  order_id: "Order number: {{value}}",
  seller: "Seller: {{value}}",
};

export { en_us };
