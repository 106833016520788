/**
 * Defines the data requirements for the component
 *
 * @see Attraction.md for details
 */
import React from "react";
import PropTypes from "prop-types";
/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePlaceholderCom169,
  ImageResponsivePropTypes,
} from "../../ImageResponsive";
import { IconPropTypes } from "../../Icon";
import { GpsDefaultProps, GpsPropTypes } from "../../Gps";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import MapIcon from "@material-ui/icons/Map";

/**
 * Defines the prop types
 *
 * @see https://schema.org/TouristAttraction
 */
const propTypes = {
  /**
   * Props from the API
   */
  name: PropTypes.string,
  slug: PropTypes.object,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape(ImageResponsivePropTypes)),
  audio: PropTypes.string,
  address: PropTypes.string,
  track: PropTypes.string,
  openingHours: PropTypes.string,
  prices: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  url: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionListing: PropTypes.string,
  image1: PropTypes.shape(ImageResponsivePropTypes),
  image1Description: PropTypes.string,
  sectionDescription2: PropTypes.string,
  image2: PropTypes.shape(ImageResponsivePropTypes),
  image2Description: PropTypes.string,
  /**
   * Props from the parent
   */
  destinationName: PropTypes.string,
  /**
   * Other props
   */
  ...GpsPropTypes,
  openingHoursIcon: PropTypes.shape(IconPropTypes),
  pricesIcon: PropTypes.shape(IconPropTypes),
  facebookIcon: PropTypes.shape(IconPropTypes),
  instagramIcon: PropTypes.shape(IconPropTypes),
  webIcon: PropTypes.shape(IconPropTypes),
  routeIcon: PropTypes.shape(IconPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  name: "",
  slug: {},
  description: "",
  images: [],
  address: "",
  audio: "",
  track: "",
  openingHours: "",
  prices: "",
  facebook: "",
  instagram: "",
  url: "",
  sectionTitle: "",
  sectionDescription1: "",
  sectionListing: "",
  image1: {
    ...ImageResponsivePlaceholderCom169,
    /**
     * For some reson the image on mobile is not displayed.
     * - This is a fix
     */
    boxStyle: {
      width: "100%",
      minWidth: 320,
      "@media (min-width: 767px)": {
        minWidth: "auto",
      },
    },
  },
  image1Description: "",
  sectionDescription2: "",
  image2: {
    ...ImageResponsivePlaceholderCom169,
    /**
     * For some reson the image on mobile is not displayed.
     * - This is a fix
     */
    boxStyle: {
      width: "100%",
      minWidth: 320,
      "@media (min-width: 767px)": {
        minWidth: "auto",
      },
    },
  },
  image2Description: "",
  destinationName: "",
  ...GpsDefaultProps,

  audioIcon: {
    icon: <AudiotrackIcon />,
  },
  openingHoursIcon: {
    icon: <WatchLaterIcon />,
  },
  pricesIcon: {
    icon: <LocalOfferIcon />,
  },
  facebookIcon: {
    icon: <FacebookIcon />,
  },
  instagramIcon: {
    icon: <InstagramIcon />,
  },
  webIcon: {
    icon: <LanguageIcon />,
  },
  routeIcon: {
    icon: <MapIcon />,
  },
};

export { propTypes, defaultProps };
