const de_de = {
  Events: "",
  Search: "",
  Cart: "",
  Settings: "",
  "Booking History": "",
  "Order History": "",
  "Sign out": "",
};

export { de_de };
