const de_de = {
  cancel: "Stornieren",
  check_out: "Auschecken",
  check_in: "Check-In",
  message: "Botschaft",
  phone: "Telefonnummer",
  rooms: "Zimmernummer",
  guests: "Gastnummer",
  terms: "Geschäftsbedingungen",
  submit: "Anfrage senden",
  wrong_date: "Falsches Datum",
  checkin_early: "Das Eincheckdatum ist falsch",
  wrong_checkout: "Check-out Datum ist falsch",
  agree_text: "Ich stimme dem zu",
  invalid_date:
    "Bitte überprüfen Sie das Format passend zu TT-MM-JJJJ -> 21-12-2020",
};

export { de_de };
