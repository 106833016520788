/**
 * Defines the data requirements for the component
 *
 * @see Layout.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { HeaderPropTypes, HeaderDefaultProps } from "../Header";
import { FooterPropTypes, FooterDefaultProps } from "../Footer";

/**
 * Defines the prop types
 */
const propTypes = {
  header: PropTypes.shape(HeaderPropTypes),
  footer: PropTypes.shape(FooterPropTypes),
  children: PropTypes.any,
  headerTitle: PropTypes.string,
  currentScreen: PropTypes.string,
  containerMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideHeader: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  header: HeaderDefaultProps,
  footer: FooterDefaultProps,
  children: null,
  headerTitle: null,
  currentScreen: null,
  containerMaxWidth: "lg",
  hideHeader: false,
};

export { propTypes, defaultProps };
