const ro_ro = {
  home: "Acasă",
  admin: "Admin",
  search: "Căutare",
  stay: "Cazare",
  privacy: "Confidenţialitate",
  account: "Cont",
  contact: "Contact",
  cart: "Cumpărături",
  destinations: "Destinaţii",
  events: "Evenimente",
  faq: "Faq - Întrebări frecvente",
  sign_out: "Ieşire din cont",
  order_history: "Istoric comenzi",
  sign_in: "Logare",
  news: "Noutăţi",
  local_market: "Shop - produse locale",
  terms: "Termeni şi condiţii",
  language: "Limbă",
};

export { ro_ro };
