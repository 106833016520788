const de_de = {
  1: "In dieser Gegend gibt es keine natürlichen Touristenattraktionen.",
  2: "In dieser Gegend gibt es keine kulturellen Touristenattraktionen.",
  3: "In dieser Gegend gibt es keine Mineralwasserquellen.",
  4: "In dieser Gegend gibt es keine Spa-Behandlungen.",
  5: "In dieser Gegend gibt es keine Freizeitdienste.",
  6: "In dieser Gegend gibt es keine turistischen Routen.",
  7: "In dieser Gegend gibt es keine Museen.",
  8: "An dieser Stelle gibt es keine Informationen vom Typ Alle.",
  9:
    "In dieser Gegend gibt es keine touristischen Informationen vom Typ Andere.",
  10: "In dieser Gegend gibt es keine Restaurants und Terrassen.",
};

export { de_de };
