/**
 * Component short description
 *
 * @see AccountAuth.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccountAuth.data";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./AccountAuth.lang.ro-ro";
import { hu_hu } from "./AccountAuth.lang.hu-hu";
import { en_us } from "./AccountAuth.lang.en-us";
import { de_de } from "./AccountAuth.lang.de-de";
import { useAuth } from "../../../hooks";
import AccountMenu from "../AccountMenu/AccountMenu";
import Auth from "../Auth/Auth";

i18n.addResourceBundle("ro-RO", "AccountAuth", ro_ro);
i18n.addResourceBundle("hu-HU", "AccountAuth", hu_hu);
i18n.addResourceBundle("en-US", "AccountAuth", en_us);
i18n.addResourceBundle("de-DE", "AccountAuth", de_de);

/**
 * Displays the component
 */
const AccountAuth = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <AccountMenu /> : <Auth />;
};

AccountAuth.propTypes = propTypes;
AccountAuth.defaultProps = defaultProps;

export default AccountAuth;
export {
  propTypes as AccountAuthPropTypes,
  defaultProps as AccountAuthDefaultProps,
};
