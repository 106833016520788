const de_de = {
  attractions: "Sehenswürdigkeiten",
  accommodations: "Unterkünfte",
  destinations: "Ziele",
  events: "Veranstaltungen",
  no_results:
    "Keine Ergebnisse gefunden. Bitte versuchen Sie es mit einer anderen Suche.",
  products: "Produkte",
  news: "Nachrichten",
  all: "Alles",
};

export { de_de };
