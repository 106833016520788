/**
 * Component short description
 *
 * @see FilterAccomodationType.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterAccomodationType.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterAccomodationType.lang.ro-ro";
import { hu_hu } from "./FilterAccomodationType.lang.hu-hu";
import { en_us } from "./FilterAccomodationType.lang.en-us";
import { de_de } from "./FilterAccomodationType.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterAccomodationType", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterAccomodationType", hu_hu);
i18n.addResourceBundle("en-US", "FilterAccomodationType", en_us);
i18n.addResourceBundle("de-DE", "FilterAccomodationType", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterAccomodationType = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterAccomodationType");

  /**
   * Handles the filters
   */
  const { filters, setFiltersAndResetPagination, data } = useFilters();
  const { accommodationTypes } = data;

  const selected = getSelectedArrayValues({
    key: "id",
    selected: filters.accommodationType?.filterValue || defaultValue,
    items: accommodationTypes,
  });

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;

    setFiltersAndResetPagination({
      accommodationType: {
        filterValue: getArrayOfKeys({ key: "id", items: value }),
        label: getArrayOfKeys({ key: "name", items: value }),
      },
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="accomodationType"
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {accommodationTypes.map((a) => {
          return (
            <MenuItem key={a.id} value={a}>
              <ListItemText primary={a.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};

FilterAccomodationType.propTypes = propTypes;
FilterAccomodationType.defaultProps = defaultProps;

export default FilterAccomodationType;
export {
  propTypes as FilterAccomodationTypePropTypes,
  defaultProps as FilterAccomodationTypeDefaultProps,
};
