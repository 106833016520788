/**
 * Component short description
 *
 * @see Valuta.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import postscribe from "postscribe";
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesInverted } from "../../Card2";
import { TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Valuta.data";
/**
 * Imports Material UI components
 */
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Valuta.lang.ro-ro";
import { hu_hu } from "./Valuta.lang.hu-hu";
import { en_us } from "./Valuta.lang.en-us";
import { de_de } from "./Valuta.lang.de-de";
import { BNR_EXCHANGE_URL } from "../../../constants/envVars";

i18n.addResourceBundle("ro-RO", "Valuta", ro_ro);
i18n.addResourceBundle("hu-HU", "Valuta", hu_hu);
i18n.addResourceBundle("en-US", "Valuta", en_us);
i18n.addResourceBundle("de-DE", "Valuta", de_de);

/**
 * Displays the component
 */
const Valuta = (props) => {
  const { t } = useTranslation("Valuta");
  const [bnrExchangeLoaded, setBnrExchangeLoaded] = useState(false);

  /**
   * Displays the card
   */
  const cardContent1 = (
    <>
      <TextSection subtitle={t("title")} />
      <div id="bnr-exchange" />
    </>
  );

  useEffect(() => {
    const bnrExchange = document.getElementById("bnr-exchange");
    const bnrScriptString = `<script src=${`${BNR_EXCHANGE_URL}&t=${new Date().getTime()}`}></script>`;

    if (bnrExchange && !bnrExchangeLoaded) {
      postscribe("#bnr-exchange", bnrScriptString);
      setBnrExchangeLoaded(true);
    }
  }, [bnrExchangeLoaded]);

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "sm" }),
    cardContainerProps: { alignItems: "center" },
  };

  return <Card2 {...cardWithMedia1} />;
};

Valuta.propTypes = propTypes;
Valuta.defaultProps = defaultProps;

export default Valuta;
export { propTypes as ValutaPropTypes, defaultProps as ValutaDefaultProps };
