const de_de = {
  faq: "FAQ",
  media: "Nachrichten",
  sitemap: "Seitenverzeichnis",
  support: "Kontakt",
  terms: "Geschäftsbedingungen",
  visitors_counter: "Besucherzahl",
  consent: "Zustimmungs",
};

export { de_de };
