/**
 * Displays a destination
 *
 * @see Destination.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Carousel from "../../Carousel";
import ImageResponsive from "../../ImageResponsive";
import Category from "../../_category/Category";
import CategoriesAsThumbs from "../../_category/CategoriesAsThumbs";
import Videos from "../../_videos/Videos";
import Gps from "../../Gps";
import Modal from "../../Modal/Modal";
import {
  useDestinationsCategoryInfo,
  useDestinationSwr,
} from "./Destination.logic";
import {
  useMarketInfoSwr,
  useMarketProductsSwr,
} from "../../_localMarket/LocalMarket/LocalMarket.logic";
import { useStayInfoSwr, useStaysSwr } from "../../_stay/Stay/Stay.logic";
import { Actions, PageHeader, TextSection } from "../../Theme";
import StayAsHero from "../../_stay/StayAsHero/StayAsHero";
import LocalMarketAsHero from "../../_localMarket/LocalMarketAsHero/LocalMarketAsHero";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import FacebookShare from "../../FacebookShare/FacebookShare";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Destination.data";
import { DESTINATION_WEATHER_CODES } from "../../../constants/destinations";
import { weatherPlugin } from "../../../helpers/weather";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Destination.lang.ro-ro";
import { hu_hu } from "./Destination.lang.hu-hu";
import { en_us } from "./Destination.lang.en-us";
import { de_de } from "./Destination.lang.de-de";
import { TextDefault } from "../../Theme/Theme";

i18n.addResourceBundle("ro-RO", "Destination", ro_ro);
i18n.addResourceBundle("hu-HU", "Destination", hu_hu);
i18n.addResourceBundle("en-US", "Destination", en_us);
i18n.addResourceBundle("de-DE", "Destination", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Destination = (props) => {
  const {
    categoriesDescription: categoriesDefaultDescription,
    staysDescription: staysDefaultDescription,
    marketDescription: marketDefaultDescription,
    stays,
    products,
    videoIcon,
    pdfIcon,
    transportationIcon,
    webcamIcon,
  } = props;

  const { container } = useStyles(props);
  const {
    t,
    i18n: { language },
  } = useTranslation("Destination");

  /**
   * Routes to a destination category
   */
  const match = useRouteMatch();
  const { path, params } = match;
  const { slug } = params;

  const { description: metaDescription, title, keywords } = useMeta({
    type: "slug",
    path: slug,
  });

  const [destination, setDestination] = useState(props);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { addSlug } = useSlugs();

  const {
    id,
    videos,
    images,
    longitude,
    latitude,
    name,
    pdf,
    webcam,
    transportation,
    description,
    longDescription,
    categories,
    weatherDestination,
  } = destination;

  const shortLanguage = language.substr(0, 2);
  const url = `https://forecast7.com/${shortLanguage}/${DESTINATION_WEATHER_CODES[weatherDestination]}/${weatherDestination}/`;

  const {
    data: destinationData,
    mutate: mutateDestinationData,
  } = useDestinationSwr({ id: slug });
  const {
    data: categoriesInfo,
    mutate: mutateCategoriesInfo,
  } = useDestinationsCategoryInfo();
  const { data: marketInfo, mutate: mutateMarketInfo } = useMarketInfoSwr();
  const { data: stayInfo, mutate: mutateStayInfo } = useStayInfoSwr();
  const {
    data: productsData,
    mutate: mutateProductsData,
  } = useMarketProductsSwr({
    filters: {
      destination: id,
    },
    condition: Boolean(id),
  });
  const { data: staysData, mutate: mutateStaysData } = useStaysSwr({
    filters: {
      destination: id,
    },
    condition: Boolean(id),
  });

  const videosList = destinationData ? videos : videos.items;

  useEffect(() => {
    mutateDestinationData();
    mutateCategoriesInfo();
    mutateMarketInfo();
    mutateStayInfo();
    mutateProductsData();
    mutateStaysData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (destinationData) {
      if (!isEqual(destinationData, destination)) {
        setDestination(destinationData);
        addSlug(destinationData.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationData]);

  /**
   * Defines links to routes
   */
  const LinkToVideos = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={{ pathname: `${slug}/${t("Routes:videos")}`, videos: videos }}
      {...props}
    />
  ));

  /**
   * Displays the hero card
   */
  const carouselSteps1 = images.map((image) => {
    const { title } = image;

    return {
      id: shortid.generate(),
      title: title,
      content: <ImageResponsive {...image} />,
    };
  });

  const cardContent1 = (
    <>
      <TextSection description={description} />
      {longDescription && (
        <>
          <Button color="primary" onClick={() => setIsModalOpen(true)}>
            {t("show_more")}
          </Button>
          <Modal
            title={t("description")}
            isModalOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          >
            <TextDefault text={longDescription} />
          </Modal>
        </>
      )}
    </>
  );

  const videosLinkButton = (
    <Button
      size="large"
      variant="outlined"
      color="secondary"
      startIcon={videoIcon.icon}
      component={LinkToVideos}
    >
      {t("videos")}
    </Button>
  );

  const pdfDownloadButton = pdf && (
    <Button
      size="large"
      variant="outlined"
      href={pdf}
      startIcon={pdfIcon.icon}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t("pdf")}
    </Button>
  );

  const transportationButton = transportation && (
    <Button
      size="large"
      variant="outlined"
      href={transportation}
      startIcon={transportationIcon.icon}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t("transportation")}
    </Button>
  );

  const webcamButton = webcam && (
    <Button
      size="large"
      variant="outlined"
      href={webcam}
      startIcon={webcamIcon.icon}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t("webcam")}
    </Button>
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: 1, action: transportationButton },
        { id: 2, action: videosLinkButton },
        {
          id: 3,
          action: <Gps longitude={longitude} latitude={latitude} />,
        },
        { id: 4, action: pdfDownloadButton },
        { id: 5, action: webcamButton },
        {
          id: 6,
          action: <FacebookShare />,
        },
      ]}
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps1} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({
      ...props,
      breakpoint: "xxl",
    }),
  };

  const widget2 = (
    <>
      <a
        className="weatherwidget-io"
        href={url}
        data-label_1={t(name)}
        data-label_2=""
        data-theme="pure"
      >
        {t(name)} {t("Weather")}
      </a>
      {weatherPlugin()}
    </>
  );

  const cardWithMedia3 = {
    image: null,
    widget: widget2,
  };

  /**
   * Displays categories
   */

  const categoriesDescription = categoriesInfo
    ? categoriesInfo.description
    : categoriesDefaultDescription;
  const cardContent2 = (
    <TextSection
      subtitle={t("location_categories")}
      description={categoriesDescription}
    />
  );

  const cardWithMedia2 = {
    image: null,
    images: null,
    carousel: null,
    content: cardContent2,
    actions: <CategoriesAsThumbs categories={categories} />,
    customStyles: {},
  };

  const productsList = productsData ? productsData.data : products;
  const staysList = staysData ? staysData.data : stays;
  const staysDescription = stayInfo
    ? stayInfo.description
    : staysDefaultDescription;
  const marketDescription = marketInfo
    ? marketInfo.description
    : marketDefaultDescription;

  return (
    <Switch>
      <Route path={`${path}/${t("Routes:videos")}`}>
        <Videos destinationName={name} items={videosList} />
      </Route>
      <Route path={`${path}/:slug`}>
        <Category destinationName={name} />
      </Route>
      <Route path={path}>
        <Head title={title} description={metaDescription} keywords={keywords} />
        <Layout header={{ currentPage: "Destinations" }}>
          <Grid
            container
            spacing={1}
            className={clsx(container, "Destination")}
          >
            <Grid item xs={12}>
              <PageHeader breadcrumbs={<Breadcrumbs />} title={name} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia1} />
            </Grid>
            <Grid item xs={12}>
              <Card2 {...cardWithMedia3} />
            </Grid>

            <Grid item xs={12}>
              <Card2 {...cardWithMedia2} />
            </Grid>

            {!!productsList.length && (
              <Grid item xs={12}>
                <LocalMarketAsHero
                  description={marketDescription}
                  products={productsList}
                />
              </Grid>
            )}
            {!!staysList.length && (
              <Grid item xs={12}>
                <StayAsHero description={staysDescription} stays={staysList} />
              </Grid>
            )}
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

Destination.propTypes = propTypes;
Destination.defaultProps = defaultProps;

export default Destination;
export {
  propTypes as DestinationPropTypes,
  defaultProps as DestinationDefaultProps,
};
