/**
 * Defines the data requirements for the component
 *
 * @see Terms.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 *
 * No API for this, yet
 */
const propTypes = {
  description: PropTypes.string,
  sectionTitle1: PropTypes.string,
  sectionDescription1: PropTypes.string,
  sectionDescription1a: PropTypes.string,
  sectionListing1: PropTypes.string,
  sectionTitle2: PropTypes.string,
  sectionDescription2: PropTypes.string,
  sectionDescription2a: PropTypes.string,
  sectionListing2: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  sectionTitle1: "",
  sectionDescription1: "",
  sectionDescription1a: "",
  sectionListing1: "",
  sectionTitle2: "",
  sectionDescription2: "",
  sectionDescription2a: "",
  sectionListing2: "",
};

export { propTypes, defaultProps };
