const hu_hu = {
  cancel: "Megszünteti",
  check_out: "Kijelentkezés",
  check_in: "Bejelentkezik",
  message: "Üzenet",
  phone: "Telefonszám",
  rooms: "Szoba szám",
  guests: "Vendégszám",
  terms: "Felhasználási feltételek",
  submit: "Felkérést küld",
  wrong_date: "Rossz dátum",
  checkin_early: "A bejelentkezés dátuma hibás",
  wrong_checkout: "A kijelentkezés dátuma nem megfelelő",
  agree_text: "egyet értek a",
  invalid_date:
    "Kérjük, ellenőrizze a formátumot, hogy megfeleljen NN-HH-ÉÉÉÉ -> 21-12-2020",
};

export { hu_hu };
