const hu_hu = {
  faq: "GYIK",
  media: "Hírek",
  sitemap: "Webhelytérkép",
  support: "Kapcsolatba lépni",
  terms: "Felhasználási feltételek",
  visitors_counter: "Látogatók száma",
  consent: "Hozzájárulás",
};

export { hu_hu };
