/**
 * Defines the data requirements for the component
 *
 * @see useFilters.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.oneOf(["market", "stay"]),
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: "market",
};

export { propTypes, defaultProps };
