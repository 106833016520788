/**
 * Defines the data requirements for the component
 *
 * @see RequestRole.md for details
 */

/**
 * Imports prop types from other components
 */
import {
  RequestRoleFormPropTypes,
  RequestRoleFormDefaultProps,
} from "../RequestRoleForm";

/**
 * Defines the prop types
 */
const propTypes = {
  ...RequestRoleFormPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...RequestRoleFormDefaultProps,
};

export { propTypes, defaultProps };
