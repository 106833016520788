const en_us = {
  email: "Email address",
  destination: "Destination",
  message: "Message",
  submit: "Send message",
  name: "Your name",
  terms: "Terms and Conditions",
  select_destination: "Select destination",
  agree_text: "I agree to the",
};

export { en_us };
