/**
 * Defines the data requirements for the component
 *
 * @see Routes.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports other components and hooks
 */
import Home from "../_home/Home";
import Destinations from "../_destinations/Destinations";
import LocalMarket from "../_localMarket/LocalMarket";
import Stay from "../_stay/Stay";
import Faq from "../_faq/Faq/Faq";
import Contact from "../_contact/Contact/Contact";
import News from "../_news/News";
import Events from "../_events/Events";
import Terms from "../_terms/Terms";
import Account from "../_account/Account";
import Settings from "../_account/Settings";
import BookingHistory from "../_account/BookingHistory";
import OrderHistory from "../_account/OrderHistory";
import Search from "../_search/Search";
import Cart from "../_cart/Cart";
import Order from "../_account/Order";
import Checkout from "../_checkout/Checkout";
import MediaAndPress from "../_mediaPress/MediaAndPress";
import Support from "../_support/Support";
import PrivacyPolicy from "../_privacyPolicy/PrivacyPolicy";
import Sitemap from "../_sitemap/Sitemap";
import Logout from "../_account/Logout";
import PaypalMobileView from "../_account/PaypalMobileView/PaypalMobileView";
import ErrorPage from "../_errorPage/ErrorPage";

/**
 * Defines the prop types
 */
const propTypes = {
  routes: PropTypes.array,
};

/**
 * Defines the default props
 */
const defaultProps = {
  routes: [
    { id: "destinations", path: "/destinations", component: Destinations },
    { id: "local_market", path: "/local-market", component: LocalMarket },
    { id: "stay", path: "/stay", component: Stay },
    { id: "faq", path: "/faq", component: Faq },
    { id: "contact", path: "/contact", component: Contact },
    { id: "news", path: "/news", component: News },
    { id: "events", path: "/events", component: Events },
    {
      id: "terms_and_conditions",
      path: "/terms-and-conditions",
      component: Terms,
    },
    { id: "account", path: "/account", component: Account },
    { id: "settings", path: "/settings", component: Settings },
    { id: "order-history", path: "/order-history", component: OrderHistory },
    {
      id: "booking_history",
      path: "/booking-history",
      component: BookingHistory,
    },
    { id: "search", path: "/search", component: Search },
    { id: "cart", path: "/cart", component: Cart },
    { id: "order", path: "/order", component: Order },
    { id: "checkout", path: "/checkout", component: Checkout },
    {
      id: "media_and_press",
      path: "/media-and-press",
      component: MediaAndPress,
    },
    { id: "support", path: "/support", component: Support },
    { id: "privacy_policy", path: "/privacy-policy", component: PrivacyPolicy },
    { id: "sitemap", path: "/sitemap", component: Sitemap },
    { id: "logout", path: "/logout", component: Logout },
    {
      id: "paypal_mobile",
      path: "/paypal-mobile",
      component: PaypalMobileView,
    },
    { id: "home", path: "/", component: Home, exact: true },
    { id: "error", path: "/errorPage", component: ErrorPage },
  ],
};

export { propTypes, defaultProps };
