/**
 * Component short description
 *
 * @see SearchForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./SearchForm.data";
/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./SearchForm.lang.ro-ro";
import { hu_hu } from "./SearchForm.lang.hu-hu";
import { en_us } from "./SearchForm.lang.en-us";
import { de_de } from "./SearchForm.lang.de-de";
import { useForm } from "react-hook-form";
import { getSearchParams } from "../../../helpers/urlHelper";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";
import { useSearch } from "../Search/Search.logic";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "SearchForm", ro_ro);
i18n.addResourceBundle("hu-HU", "SearchForm", hu_hu);
i18n.addResourceBundle("en-US", "SearchForm", en_us);
i18n.addResourceBundle("de-DE", "SearchForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  select: {
    width: "100%",
  },
}));

const customThemeColor = "#ffffff";
const customTheme = {
  palette: {
    primary: { main: customThemeColor },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: customThemeColor,
      },
    },
    MuiFormLabel: {
      root: { color: customThemeColor },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: `${customThemeColor} !important`,
      },
    },
  },
};

/**
 * Displays the component
 */
const SearchForm = (props) => {
  const { text, isWhite, handleResults } = props;
  const { container, select } = useStyles(props);
  const { t } = useTranslation("SearchForm");
  const theme = useTheme();
  const { getSearchFormSchema } = useValidationSchemas();
  const { getSearch } = useSearch();
  const history = useHistory();
  const searchTheme = createMuiTheme(customTheme);
  const location = useLocation();
  const { search: searchUrl } = location;
  const schema = getSearchFormSchema();
  const { register, errors, handleSubmit, formState } = useForm({
    validationSchema: schema,
    reValidateMode: "onChange",
  });
  const { isValid, isSubmitted } = formState;
  useState(() => {
    if (searchUrl) {
      getSearch(searchUrl).then((responseData) => handleResults(responseData));
    }
  }, [searchUrl]);

  const handleFormSubmit = (data) => {
    if (handleResults) {
      getSearch(getSearchParams(data.search)).then((responseData) =>
        handleResults(responseData)
      );

      return;
    }

    history.push({
      pathname: `/${t("Routes:search")}`,
      search: getSearchParams(data.search),
    });
  };

  /**
   * Displays the text field
   */
  const textField = (
    <TextField
      defaultValue={text}
      type="text"
      fullWidth
      label={t("search_placeholder")}
      inputRef={register}
      variant="outlined"
      name="search"
      className={clsx(select)}
      error={Boolean(errors.search)}
      helperText={errors.search?.message}
    />
  );

  /**
   * The grid is aligned to the Home/About section above this component
   */
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Grid
        container
        spacing={1}
        className={clsx(container, "SearchForm")}
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={6}>
          <ThemeProvider theme={isWhite ? searchTheme : theme}>
            {textField}
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            disabled={!isValid && isSubmitted}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("Search:search")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

export default SearchForm;
export {
  propTypes as SearchFormPropTypes,
  defaultProps as SearchFormDefaultProps,
};
