const ro_ro = {
  email: "Adresa de email",
  destination: "Destinaţie",
  message: "Mesaj",
  submit: "Mesaj trimis",
  name: "Numele dumneavoastră",
  terms: "Termeni şi condiţii",
  select_destination: "Selectați destinația",
  agree_text: "Sunt de acord cu",
};

export { ro_ro };
