/**
 * Component short description
 *
 * @see AccommodationAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccommodationAsThumb.data";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./AccommodationAsThumb.lang.ro-ro";
import { hu_hu } from "./AccommodationAsThumb.lang.hu-hu";
import { en_us } from "./AccommodationAsThumb.lang.en-us";
import { de_de } from "./AccommodationAsThumb.lang.de-de";

i18n.addResourceBundle("ro-RO", "AccommodationAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "AccommodationAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "AccommodationAsThumb", en_us);
i18n.addResourceBundle("de-DE", "AccommodationAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const AccommodationAsThumb = (props) => {
  const { image, name, slug, price, destinationName } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("AccommodationAsThumb");
  /**
   * Loads the current route
   */

  /**
   * Displays the accommodation as card
   */
  const cardContent1 = (
    <TextThumbnail
      overline={destinationName}
      to={`/${t("Routes:stay")}/${getSlugForCurrentLang(slug)}`}
      title={name}
      price={t("Booking:text", { price })}
    />
  );

  const cardWithMedia1 = {
    image,
    images: null,
    carousel: null,
    content: cardContent1,
    actions: null,
    customStyles: useStyles(props),
  };

  return (
    <Grid container className={clsx(container, "AccommodationAsThumb")}>
      <Grid item xs={12}>
        <Card2 {...cardWithMedia1} />
      </Grid>
    </Grid>
  );
};

AccommodationAsThumb.propTypes = propTypes;
AccommodationAsThumb.defaultProps = defaultProps;

export default AccommodationAsThumb;
export {
  propTypes as AccommodationAsThumbPropTypes,
  defaultProps as AccommodationAsThumbDefaultProps,
};
