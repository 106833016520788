/**
 * Component short description
 *
 * @see EventAsThumb.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import Card2 from "../../Card2";
import { TextThumbnail } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./EventAsThumb.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./EventAsThumb.lang.ro-ro";
import { hu_hu } from "./EventAsThumb.lang.hu-hu";
import { en_us } from "./EventAsThumb.lang.en-us";
import { de_de } from "./EventAsThumb.lang.de-de";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "EventAsThumb", ro_ro);
i18n.addResourceBundle("hu-HU", "EventAsThumb", hu_hu);
i18n.addResourceBundle("en-US", "EventAsThumb", en_us);
i18n.addResourceBundle("de-DE", "EventAsThumb", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const EventAsThumb = (props) => {
  const { name, slug, image, destination, startDate } = props;
  const { t } = useTranslation("EventAsThumb");

  /**
   * Prepares the card
   */
  const cardContent = (
    <TextThumbnail
      overline={destination}
      to={`/${t("Routes:events")}/${getSlugForCurrentLang(slug)}`}
      title={name}
      price={startDate}
    />
  );

  const cardWithMedia = {
    image: image,
    content: cardContent,
    customStyles: useStyles(props),
  };

  return <Card2 {...cardWithMedia} />;
};

EventAsThumb.propTypes = propTypes;
EventAsThumb.defaultProps = defaultProps;

export default EventAsThumb;
export {
  propTypes as EventAsThumbPropTypes,
  defaultProps as EventAsThumbDefaultProps,
};
