/**
 * Component short description
 *
 * @see StayIntro.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
/**
 * Imports other components and hooks
 */
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesInverted, } from "../../Card2";
import { TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./StayIntro.data";
/**
 * Imports Material UI components
 */
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StayIntro.lang.ro-ro";
import { hu_hu } from "./StayIntro.lang.hu-hu";
import { en_us } from "./StayIntro.lang.en-us";
import { de_de } from "./StayIntro.lang.de-de";
import { useStayInfoSwr } from "../Stay/Stay.logic";
import { Link as RouterLink } from "react-router-dom";
import { useUpdateEffect } from "../../../hooks";

i18n.addResourceBundle("ro-RO", "StayIntro", ro_ro);
i18n.addResourceBundle("hu-HU", "StayIntro", hu_hu);
i18n.addResourceBundle("en-US", "StayIntro", en_us);
i18n.addResourceBundle("de-DE", "StayIntro", de_de);

/**
 * Displays the component
 */
const StayIntro = (props) => {
  const { t } = useTranslation("StayIntro");
  const { data: responseData, mutate: mutateStayInfo } = useStayInfoSwr();
  const { description, image } = responseData || props;

  const { language } = i18n;
  useUpdateEffect(() => {
    mutateStayInfo();
  }, [language]);

  /**
   * Displays the hero card
   */
  const cardContent1 = (
    <TextSection title={t("Stay:title")} description={description} />
  );

  const stayLink = forwardRef((props, ref) => (
    <RouterLink
      ref={ref}
      to={{ pathname: `/${t('Routes:stay')}` }}
      {...props}
    />
  ));

  const cardActions1 = (
    <Button variant="contained" color="primary" size="large" component={stayLink}>
      {t("accommodation")}
    </Button>
  );

  const cardWithMedia1 = {
    image: {...image, imageWidth: 640},
    images: null,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesInverted({ ...props, breakpoint: "md" }),
  };

  return <Card2 {...cardWithMedia1} />;
};

StayIntro.propTypes = propTypes;
StayIntro.defaultProps = defaultProps;

export default StayIntro;
export {
  propTypes as StayIntroPropTypes,
  defaultProps as StayIntroDefaultProps,
};
