/**
 * Component short description
 *
 * @see RequestRole.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import RequestRoleForm from "../RequestRoleForm";
import { TextSection } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./RequestRole.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./RequestRole.lang.ro-ro";
import { hu_hu } from "./RequestRole.lang.hu-hu";
import { en_us } from "./RequestRole.lang.en-us";
import { de_de } from "./RequestRole.lang.de-de";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import { useRequestRoleInfo, useAccount } from "../Account/Account.logic";
import { useSnackbar } from "notistack";

i18n.addResourceBundle("ro-RO", "RequestRole", ro_ro);
i18n.addResourceBundle("hu-HU", "RequestRole", hu_hu);
i18n.addResourceBundle("en-US", "RequestRole", en_us);
i18n.addResourceBundle("de-DE", "RequestRole", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  // TODO: Refactor modal dialog styles
  container: { ...theme.custom.grid.removeNegativeMargin },

  title: {
    "& .MuiTypography-root": {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  /**
   * Stretching the dialog content
   * - `<Dialog maxWidth="lg" ...` is not enough
   */
  content: {
    width: "99vw",
    height: "99vh",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  /**
   * Styling the card
   */
  cardContent: {
    padding: 0,
  },
}));

/**
 * Displays the component
 */
const RequestRole = (props) => {
  const { container, title, content: contentKlass } = useStyles(props);
  const { t } = useTranslation("RequestRole");
  const { onClose } = props;

  const { enqueueSnackbar } = useSnackbar();
  /**
   * Manages the modal dialog
   */
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  const { requestRole } = useAccount();
  const { data: destinationsData } = useDestinationsSimpleSwr();

  const { data: requestRoleInfo } = useRequestRoleInfo();

  const handleSubmit = (form) => {
    const { role, destination, phone, message } = form;

    requestRole({
      roleType: role,
      destinationId: destination,
      phone,
      message,
    }).then((resp) => {
      const { message } = resp;
      enqueueSnackbar(message, { variant: "success" });
      handleClose();
    });
  };
  /**
   * Displays the content
   */

  const content = (
    <Grid container spacing={1} className={clsx(container)}>
      <Grid item xs={12}>
        <TextSection description={requestRoleInfo?.description} />
      </Grid>
      <Grid item xs={12}>
        <RequestRoleForm
          {...props}
          onSubmit={handleSubmit}
          destinations={destinationsData ?? []}
        />
      </Grid>
    </Grid>
  );

  return (
    <div className={clsx(container, "RequestRole")}>
      <Button variant="outlined" onClick={handleOpen}>
        {t("request_role")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="account-request-role-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle
          id="account-request-role-dialog-title"
          className={clsx(title)}
        >
          {t("request_role")}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={clsx(contentKlass)}>{content}</DialogContent>
      </Dialog>
    </div>
  );
};

RequestRole.propTypes = propTypes;
RequestRole.defaultProps = defaultProps;

export default RequestRole;
export {
  propTypes as RequestRolePropTypes,
  defaultProps as RequestRoleDefaultProps,
};
