/**
 * Defines the data requirements for the component
 *
 * @see StayIntro.md for details
 */

/**
 * Imports prop types from other components
 */
import { StayPropTypes, StayDefaultProps } from "../Stay";

/**
 * Defines the prop types
 */
const propTypes = {
  ...StayPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...StayDefaultProps,
};

export { propTypes, defaultProps };
