/**
 * Defines the data requirements for the component
 *
 * @see NewsAsHero.md for details
 */

/**
 * Imports prop types from other components
 */
import { NewsPropTypes, NewsDefaultProps } from "../News";

/**
 * Defines the prop types
 */
const propTypes = {
  ...NewsPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...NewsDefaultProps,
};

export { propTypes, defaultProps };
