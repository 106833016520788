/**
 * Defines the data requirements for the component
 *
 * @see Track.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  track: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  track: null,
};

export { propTypes, defaultProps };
