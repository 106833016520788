const hu_hu = {
  items_count_and_price: "{{orderItems}} elem / {{totalPrice}} RON",
  confirm_cancel: "Megrendelés törlése",
  title: "Rendelés lemondása",
  order_cancelled_successfully: "A megrendelés törlése sikeresen megtörtént",
  order_date: "Rendelés dátuma: {{value}}",
  back_to_order: "Vissza a megrendeléshez",
  order_id: "Rendelési szám: {{value}}",
  seller: "Eladó: {{value}}",
};

export { hu_hu };
