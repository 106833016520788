/**
 * Component short description
 *
 * @see HideOnScroll.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./HideOnScroll.data";

/**
 * Imports Material UI components
 */
import { useScrollTrigger, Slide } from "@material-ui/core";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./HideOnScroll.lang.ro-ro";
import { hu_hu } from "./HideOnScroll.lang.hu-hu";
import { en_us } from "./HideOnScroll.lang.en-us";
import { de_de } from "./HideOnScroll.lang.de-de";

i18n.addResourceBundle("ro-RO", "HideOnScroll", ro_ro);
i18n.addResourceBundle("hu-HU", "HideOnScroll", hu_hu);
i18n.addResourceBundle("en-US", "HideOnScroll", en_us);
i18n.addResourceBundle("de-DE", "HideOnScroll", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = propTypes;
HideOnScroll.defaultProps = defaultProps;

export default HideOnScroll;
export {
  propTypes as HideOnScrollPropTypes,
  defaultProps as HideOnScrollDefaultProps,
};
