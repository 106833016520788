const en_us = {
  cancel: "Cancel",
  check_out: "Check-Out",
  check_in: "Check-In",
  message: "Message",
  phone: "Phone number",
  rooms: "Room number",
  guests: "Guest number",
  terms: "Terms and Conditions",
  submit: "Send request",
  wrong_date: "Wrong date",
  checkin_early: "Check-in date is wrong",
  wrong_checkout: "Check-out date is wrong",
  agree_text: "I agree to the",
  invalid_date: "Please verify date format: DD-MM-YYYY -> 21-12-2020",
};

export { en_us };
