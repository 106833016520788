/**
 * Defines the data requirements for the component
 *
 * @see CookiesDialog.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  checkboxes: PropTypes.array,
  open: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  checkboxes: [
    {
      trans: "weather_consent",
      disabled: true,
      defaultChecked: true,
      name: "weather",
    },
    {
      trans: "analytics_consent",
      disabled: false,
      defaultChecked: false,
      name: "analytics",
    },
  ],
  open: false,
};

export { propTypes, defaultProps };
