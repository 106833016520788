import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

/**
 * - Sets up default styles
 * - Only basic stuff for now, the extra stuff comes later.
 *
 * @see https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=89a527&secondary.color=2e8232&primary.text.color=ffffff&secondary.text.color=000000
 * // NOTE: White text is usually not legible
 */
let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#bcd659",
      main: "#89a527",
      dark: "#587600",
      contrastText: "#fff",
    },
    secondary: {
      light: "#61b25e",
      main: "#2e8232",
      dark: "#005404",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    fontSize: 16,
  },
});

/**
 * Adds responsive typography 'magic'
 */
theme = responsiveFontSizes(theme);

/**
 * What's not covered by typography magic it can be added below
 * - Also note: `theme` can be used now for set up the theme ...
 *
 * H3: 3,438 rem
 * H4: 2,4291
 * H5: 1,6867
 * H6: 1,4063
 * subtitle1, body1: 1,2
 */
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: "larger !important",
  fontWeight: "bold",
};

theme.typography.body1 = {
  ...theme.typography.body1,
};

/**
 * Adding Finster specific settings
 */
theme = {
  ...theme,
  custom: {
    typography: {
      /**
       * Only 45-60 chars per row for long texts
       * @see https://material.io/design/typography/understanding-typography.html#readability
       *
       * Currently set to fit iPad landscape.
       * // NOTE: When typography changes this should be updated.
       */
      maxTextWidth: "100%",
      captionOpacity: ".8",
    },

    grid: {
      /**
       * A common mixin reused almost with every Grid container
       * - See https://material-ui.com/components/grid/#negative-margin
       */
      removeNegativeMargin: {
        width: "100%",
        margin: 0,
      },

      /**
       * Remove horizontal padding when container spacing is set
       */
      removeHorizontalPadding: {
        "& .MuiGrid-item": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },

      /**
       * Remove vertical padding when container spacing is set
       */
      removeVerticalPadding: {
        "& .MuiGrid-item": {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },

      removeHorizontalPaddingFromSides: {
        "& .MuiGrid-item": {
          "&:first-of-type": {
            paddingLeft: 0,
          },
        },
        "& .MuiGrid-item:last-of-type()": {
          paddingRight: 0,
        },
      },
    },

    mui: {
      /**
       * Wrapping long, multiline text inside a chip
       */
      multiLineChip: {
        marginTop: 2,
        marginBottom: 2,
        paddingTop: 4,
        paddingBottom: 4,
        height: "auto",

        "& .MuiChip-label": {
          overflowWrap: "break-word",
          whiteSpace: "normal",
          lineHeight: "1",
        },
      },
    },
  },
};

const headerTheme = createMuiTheme({
  // ...theme,
  palette: { primary: { main: "#fff" } },
  overrides: {
    MuiButton: {
      root: {
        [theme.breakpoints.up("lg")]: {
          color: "#fff",
          opacity: 0.7,
        },
      },
    },
    MuiTabs: {
      flexContainerVertical: {
        alignItems: 'center'
      }
    },
    MuiInputBase: {
      root: {
        [theme.breakpoints.up("lg")]: {
          color: "#fff",
        },
      },
    },
  },
});

export default theme;
export { headerTheme };
