/**
 * Component short description
 *
 * @see useMeta.md for details
 */

/**
 * Imports React and third party packages
 */
import { useEffect, useState } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps, DESTINATION_KEYS } from "./useMeta.data";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./useMeta.lang.ro-ro";
import { hu_hu } from "./useMeta.lang.hu-hu";
import { en_us } from "./useMeta.lang.en-us";
import { de_de } from "./useMeta.lang.de-de";

i18n.addResourceBundle("ro-RO", "useMeta", ro_ro);
i18n.addResourceBundle("hu-HU", "useMeta", hu_hu);
i18n.addResourceBundle("en-US", "useMeta", en_us);
i18n.addResourceBundle("de-DE", "useMeta", de_de);

/**
 * Displays the component
 */

const getTranslationKey = (path, type) => {
  switch (type) {
    case "route":
    default:
      return path;
    case "slug":
      return DESTINATION_KEYS[path];
  }
};

const useMeta = (props) => {
  const { path, type = "route" } = props;
  const {
    t,
    i18n: { language },
  } = useTranslation("useMeta");

  const [state, setState] = useState({});

  useEffect(() => {
    const key = getTranslationKey(path, type);
    setState({
      description: t(`${key}_description`),
      title: t(`${key}_title`),
      keywords: t(`${key}_keywords`),
    });
  }, [path, language, type, t]);

  return {
    ...state,
  };
};

useMeta.propTypes = propTypes;
useMeta.defaultProps = defaultProps;

export default useMeta;
export { propTypes as useMetaPropTypes, defaultProps as useMetaDefaultProps };
