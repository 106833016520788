/**
 * Component short description
 *
 * @see OrderItemForCart.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
import shortid from "shortid";
/**
 * Imports other components and hooks
 */
import Card2, { Card2SingleRowStylesWithActions } from "../../Card2";
import { Actions, TextSection } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./OrderItemForCart.data";
import { getArrayItemsForMinAndMax } from "../../../helpers/baseHelper";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderItemForCart.lang.ro-ro";
import { hu_hu } from "./OrderItemForCart.lang.hu-hu";
import { en_us } from "./OrderItemForCart.lang.en-us";
import { de_de } from "./OrderItemForCart.lang.de-de";

i18n.addResourceBundle("ro-RO", "OrderItemForCart", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderItemForCart", hu_hu);
i18n.addResourceBundle("en-US", "OrderItemForCart", en_us);
i18n.addResourceBundle("de-DE", "OrderItemForCart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
  select: {
    width: "100%",
  },
  menuPaper: {
    maxHeight: '200px'
  }
}));

/**
 * Displays the component
 */
const OrderItemForCart = (props) => {
  const {
    id,
    min,
    max,
    unit,
    name,
    price,
    image,
    quantity,
    destinationName,
    handleItemDelete,
    handleUpdateItem
  } = props;

  const { select, menuPaper } = useStyles(props);
  const { t } = useTranslation("OrderItemForCart");

  const totalItems = getArrayItemsForMinAndMax(min, max);
  const quantityList = totalItems.map((value) => {
    return (
      <MenuItem value={value} key={shortid.generate()}>
        {`${value} ${unit}`}
      </MenuItem>
    );
  });

  /**
   * Displays the select box
   */
  const quantitySelect = (
    <FormControl variant="outlined" className={clsx(select)}>
      <InputLabel id="cart-order-quantity-select-label">
        {t("select_quantity")}
      </InputLabel>
      <Select
        MenuProps={{ classes: { paper: menuPaper } }}
        label={t("select_quantity")}
        labelId="cart-order-quantity-select-label"
        id="cart-order-quantity-select"
        value={Math.floor(Number(quantity))}
        onChange={(e) => handleUpdateItem(e.target.value, id)}
      >
        {quantityList}
      </Select>
    </FormControl>
  );

  /**
   * Displays the order item
   */
  const cardContent1 = (
    <TextSection
      description={name}
      overline={destinationName}
      price={`${price} RON`}
    />
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: quantitySelect },
        {
          id: shortid.generate(),
          action: <Button variant="outlined" onClick={() => handleItemDelete(id)}>{t("delete")}</Button>,
        },
      ]}
      orientation="horizontal"
      fullGridItems
    />
  );

  const cardWithMedia1 = {
    image,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStylesWithActions({
      ...props,
      alignItems: "center",
    }),
  };

  return <Card2 {...cardWithMedia1} />;
};

OrderItemForCart.propTypes = propTypes;
OrderItemForCart.defaultProps = defaultProps;

export default OrderItemForCart;
export {
  propTypes as OrderItemForCartPropTypes,
  defaultProps as OrderItemForCartDefaultProps,
};
