const de_de = {
  home: "Zuhause",
  admin: "Administrator",
  search: "Suche",
  stay: "Unterkunft",
  privacy: "Datenschutz-Bestimmungen",
  account: "Konto",
  contact: "Kontakt",
  cart: "Wagen",
  destinations: "Ziele",
  events: "Veranstaltungen",
  faq: "FAQ",
  sign_out: "Ausloggen",
  order_history: "Bestellverlauf",
  sign_in: "Einloggen",
  news: "Nachrichten",
  local_market: "Lokaler Markt",
  terms: "Geschäftsbedingungen",
  language: "Sprache",
};

export { de_de };
