const en_us = {
  home: "Home",
  search: "Search",
  stay: "Stay",
  contact: "Contact",
  cart: "Cart",
  destinations: "Destinations",
  events: "Events",
  sign_in: "Sign in",
  local_market: "Local Market",
  news: "News",
};

export { en_us };
