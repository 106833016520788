/**
 * Component short description
 *
 * @see useConsent.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { createContext, useContext, useState } from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./useConsent.data";
import useLocalStorage from "../useLocalStorage";
import CookiesDialog, {
  CookiesDialogDefaultProps,
} from "../../components/CookiesDialog/CookiesDialog";

const ConsentContext = createContext();

const ConsentProvider = (props) => {
  const { children } = props;

  const [localConsent, setLocalConstent] = useLocalStorage("consent");
  const [consent, setConsent] = useState(localConsent);
  const [open, setOpen] = useState(!Boolean(consent));
  const [checkboxes, setCheckboxes] = useState(
    CookiesDialogDefaultProps.checkboxes
  );

  const updateConsent = (data) => {
    setLocalConstent(data);
    setConsent(data);
  };

  const setCurrentValues = () => {
    const newValues = checkboxes.map((cb) => {
      const { name } = cb;
      return {
        ...cb,
        defaultChecked: Boolean(consent && consent[name]),
      };
    });
    setCheckboxes(newValues);
  };

  const openDialog = () => {
    setCurrentValues();
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <ConsentContext.Provider
      value={{
        consent,
        setConsent: updateConsent,
        openDialog,
        closeDialog,
      }}
    >
      <CookiesDialog open={open} checkboxes={checkboxes} />
      {children}
    </ConsentContext.Provider>
  );
};

/**
 * Displays the component
 */
const useConsent = (props) => {
  return useContext(ConsentContext);
};

useConsent.propTypes = propTypes;
useConsent.defaultProps = defaultProps;

export default useConsent;
export {
  propTypes as useConsentPropTypes,
  defaultProps as useConsentDefaultProps,
  ConsentProvider,
};
