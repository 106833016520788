/**
 * Defines the data requirements for the component
 *
 * @see Destinations.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  CategoriesAsTabsPropTypes,
  CategoriesAsTabsDefaultProps
} from "../../_category/CategoriesAsTabs";

import {
  DestinationsListPropTypes,
  DestinationsListDefaultProps
} from "../DestinationsList";

/**
 * Defines the prop types
 */
const propTypes = {
  categoriesAsTabs: PropTypes.shape(CategoriesAsTabsPropTypes),
  destinationsList: PropTypes.shape(DestinationsListPropTypes)
};

/**
 * Defines the default props
 */
const defaultProps = {
  categoriesAsTabs: CategoriesAsTabsDefaultProps,
  destinationsList: DestinationsListDefaultProps
};

export { propTypes, defaultProps };
