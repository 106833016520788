const ro_ro = {
  text: "De la {{price}} RON / Noapte",
  number_of_guests: "Oaspeți: {{guests}}",
  number_of_rooms: "Camere: {{rooms}}",
  view_message: "Vizualizați mesajul",
  message_modal_title: "Mesaj",
  show_more: "Vezi mai mult",
  show_less: "Vezi mai puţin",
};

export { ro_ro };
