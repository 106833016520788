const ro_ro = {
  title: "Managementul consimțământului",
  description:
    "Pineyard.ro folosește următoarele cookie-uri, pentru mai multe informații vă rugăm să consultați Politica noastră de confidențialitate și Termenii și condițiile.",
  analytics_consent: "Activați Google Analytics",
  weather_consent: "Activați widgetul Meteo",
  accept_selected: "Acceptați selectat",
  accept_all: "Accepta toate",
};

export { ro_ro };
