/**
 * Component short description
 *
 * @see MenuDrawer.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MenuDrawer.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./MenuDrawer.lang.ro-ro";
import { hu_hu } from "./MenuDrawer.lang.hu-hu";
import { en_us } from "./MenuDrawer.lang.en-us";
import { de_de } from "./MenuDrawer.lang.de-de";

i18n.addResourceBundle("ro-RO", "MenuDrawer", ro_ro);
i18n.addResourceBundle("hu-HU", "MenuDrawer", hu_hu);
i18n.addResourceBundle("en-US", "MenuDrawer", en_us);
i18n.addResourceBundle("de-DE", "MenuDrawer", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {},

  drawerContainer: (props) => ({
    /**
     * // NOTE: This value is taken from the `theme` variable.
     * - It changes from theme to theme, and is set up by MUI
     * - It must be manually adjusted
     */
    minHeight: theme.mixins.toolbar["@media (min-width:600px)"].minHeight,
    paddingRight: theme.spacing(1),
  }),
}));

/**
 * Displays the component
 */
const MenuDrawer = (props) => {
  const { children } = props;
  const { container, drawerContainer } = useStyles(props);

  /**
   * Manages the menu opener
   */
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      justify="flex-end"
      className={clsx(container, "MenuDrawer")}
    >
      <IconButton onClick={handleDrawerOpen}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className={clsx(drawerContainer, "MenuDrawerContainer")}
        >
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {children}
      </Drawer>
    </Grid>
  );
};

MenuDrawer.propTypes = propTypes;
MenuDrawer.defaultProps = defaultProps;

export default MenuDrawer;
export {
  propTypes as MenuDrawerPropTypes,
  defaultProps as MenuDrawerDefaultProps,
};
