/**
 * Component short description
 *
 * @see Event.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import { useEventSwr } from "../../_events/Events/Events.logic";
import { TextSection, PageHeader, Actions, Template } from "../../Theme";
import { useUpdateEffect } from "../../../hooks";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import FacebookShare from "../../FacebookShare/FacebookShare";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Event.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Event.lang.ro-ro";
import { hu_hu } from "./Event.lang.hu-hu";
import { en_us } from "./Event.lang.en-us";
import { de_de } from "./Event.lang.de-de";
import GeneralDescription from "../../GeneralDescription/GeneralDescription";

i18n.addResourceBundle("ro-RO", "Event", ro_ro);
i18n.addResourceBundle("hu-HU", "Event", hu_hu);
i18n.addResourceBundle("en-US", "Event", en_us);
i18n.addResourceBundle("de-DE", "Event", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Event = (props) => {
  const { container } = useStyles(props);
  const {
    i18n: { language },
  } = useTranslation("Event");

  const { addSlug } = useSlugs();

  /**
   * Routes to a destination category
   */
  const match = useRouteMatch();
  const { params } = match;
  const { slug } = params;

  const [event, setEvent] = useState(props);

  const {
    id,
    name,
    destinationName,
    image,
    startDate,
    endDate,
    description,
    sectionDescription1,
    image1,
  } = event;
  /**
   * Fetch the event data
   */

  const { data: eventData, mutate: mutateEvent } = useEventSwr({
    id: id ? id : slug,
  });

  useEffect(() => {
    if (eventData && !isEqual(eventData, event)) {
      setEvent(eventData);
      addSlug(eventData.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  /*
   *Refetch event when language
   */
  useUpdateEffect(() => {
    mutateEvent();
  }, [language]);

  /**
   * Displays the hero
   */
  const cardContent1 = <TextSection description={description} />;

  const cardActions = (
    <Actions
      items={[
        {
          id: 1,
          action: <FacebookShare />,
        },
      ]}
    />
  );

  const cardWithMedia1 = {
    image,
    content: cardContent1,
    actions: cardActions,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "md" }),
  };

  return (
    <Layout header={{ currentPage: "Events" }}>
      <Grid container spacing={1} className={clsx(container, "Event")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={`${destinationName} / ${startDate} - ${endDate}`}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template
            items={[<GeneralDescription image={image1} description={sectionDescription1}/>]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Event.propTypes = propTypes;
Event.defaultProps = defaultProps;

export default Event;
export { propTypes as EventPropTypes, defaultProps as EventDefaultProps };
