/**
 * Reusable business logic
 * - For the web and the React Native apps
 */

/**
 * Imports React and third party packages
 */
import useSWR from "swr";
/**
 * Imports other components and hooks
 */
import useRequests from "../../hooks/useRequests";

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useNewsArticleSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
export const useNewsletterInfo = () => {
  const { get } = useRequests();

  return useSWR("newsletter/info", get);
};

export const useNewsletter = () => {
  const { post } = useRequests();

  const createNewsletter = (data) => {
    return post("newsletter", data);
  };

  return {
    createNewsletter,
  };
};
