/**
 * Component short description
 *
 * @see Home.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Hero from "../Hero";
import CategoriesAsCarousel from "../../_category/CategoriesAsCarousel";
import OngInfo from "../OngInfo";
import LocalMarketIntro from "../../_localMarket/LocalMarketIntro";
import StayIntro from "../../_stay/StayIntro";
import NewsAsHero from "../../_news/NewsAsHero";
import Valuta from "../Valuta";
import Newsletter from "../../Newsletter";
import Head from "../../Head/Head";
import useMeta from "../../../hooks/useMeta/useMeta";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Home.data";
/**
 * Imports Material UI components
 */
import { Container, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./Home.lang.ro-ro";
import { hu_hu } from "./Home.lang.hu-hu";
import { en_us } from "./Home.lang.en-us";
import { de_de } from "./Home.lang.de-de";

i18n.addResourceBundle("ro-RO", "Home", ro_ro);
i18n.addResourceBundle("hu-HU", "Home", hu_hu);
i18n.addResourceBundle("en-US", "Home", en_us);
i18n.addResourceBundle("de-DE", "Home", de_de);

/**
 * Styles the component
 */
// TODO - find a better solution for `marginTop: '-12px'`
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    marginTop: "-12px",
  },
  heroGrid: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },
}));

/**
 * Displays the component
 */
const Home = (props) => {
  const { container, heroGrid } = useStyles(props);

  const { description, title, keywords } = useMeta({
    type: "route",
    path: "home",
  });

  return (
    <>
      <Head title={title} description={description} keywords={keywords} />
      <Layout
        header={{ currentPage: "Home", hasSearch: false }}
        containerMaxWidth={false}
      >
        <Grid container spacing={1} className={clsx(container, "Home")}>
          <Grid item xs={12} className={heroGrid}>
            <Hero />
          </Grid>
          <Grid container item xs={12}>
            <Container disableGutters={true} maxWidth="lg">
              <Grid item xs={12}>
                <CategoriesAsCarousel />
              </Grid>
              <Grid item xs={12}>
                <OngInfo />
              </Grid>
              <Grid item xs={12}>
                <LocalMarketIntro />
              </Grid>
              <Grid item xs={12}>
                <StayIntro />
              </Grid>
              <Grid item xs={12}>
                <NewsAsHero />
              </Grid>
              <Grid container spacing={1} className={clsx(container)}>
                <Grid item xs={12}>
                  <Newsletter />
                </Grid>
                <Grid item xs={12}>
                  <Valuta />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
export { propTypes as HomePropTypes, defaultProps as HomeDefaultProps };
