/**
 * Component short description
 *
 * @see Sitemap.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import { PageHeader } from "../../Theme";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Sitemap.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Sitemap.lang.ro-ro";
import { hu_hu } from "./Sitemap.lang.hu-hu";
import { en_us } from "./Sitemap.lang.en-us";
import { de_de } from "./Sitemap.lang.de-de";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { SITEMAP_URLS } from "../../../constants/sitemap";
import { useDestinationsSwr } from "../../_destination/Destination/Destination.logic";
import { getSlugForCurrentLang } from "../../Routes/Routes.logic";

i18n.addResourceBundle("ro-RO", "Sitemap", ro_ro);
i18n.addResourceBundle("hu-HU", "Sitemap", hu_hu);
i18n.addResourceBundle("en-US", "Sitemap", en_us);
i18n.addResourceBundle("de-DE", "Sitemap", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },

  subSection: {
    paddingLeft: "50px",
  },
}));

/**
 * Displays the component
 */
const Sitemap = (props) => {
  const { container, subSection } = useStyles(props);
  const { t } = useTranslation("Sitemap");
  const { language } = i18n;
  const { data, mutate } = useDestinationsSwr({ filters: {} });

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Layout footer={{ currentPage: "Sitemap" }}>
      <Grid container spacing={2} className={clsx(container, "Sitemap")}>
        <Grid item xs={12}>
          <PageHeader title={t("title")} />
        </Grid>
        <Grid container spacing={0} className={clsx(container)}>
          {SITEMAP_URLS.map((sitemapUrl) => {
            const translatedRoute = t(`Routes:${sitemapUrl}`);

            return (
              <Grid item xs={12} key={sitemapUrl}>
                <Button
                  color="secondary"
                  variant="text"
                  component={RouterLink}
                  to={translatedRoute}
                >
                  {translatedRoute}
                </Button>
                {sitemapUrl === "destinations" && data && (
                  <Grid
                    container
                    item
                    xs={12}
                    className={clsx(subSection, "Sitemap")}
                  >
                    {data.map((destination) => {
                      const { slug, name, id } = destination;

                      return (
                        <Grid item xs={12} key={id}>
                          <Button
                            color="secondary"
                            variant="text"
                            component={RouterLink}
                            to={`/${t(
                              "Routes:destinations"
                            )}/${getSlugForCurrentLang(slug)}`}
                          >
                            {name}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Layout>
  );
};

Sitemap.propTypes = propTypes;
Sitemap.defaultProps = defaultProps;

export default Sitemap;
export { propTypes as SitemapPropTypes, defaultProps as SitemapDefaultProps };
