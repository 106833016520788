/**
 * Component short description
 *
 * @see Settings.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Settings.data";
/**
 * Imports Material UI components
 */
/**
 * Imports other components and hooks
 */
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Settings.lang.ro-ro";
import { hu_hu } from "./Settings.lang.hu-hu";
import { en_us } from "./Settings.lang.en-us";
import { de_de } from "./Settings.lang.de-de";
import { useAccount } from "../Account/Account.logic";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";
import { useAuth } from "../../../hooks";
import { useSnackbar } from "notistack";

i18n.addResourceBundle("ro-RO", "Settings", ro_ro);
i18n.addResourceBundle("hu-HU", "Settings", hu_hu);
i18n.addResourceBundle("en-US", "Settings", en_us);
i18n.addResourceBundle("de-DE", "Settings", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Settings = (props) => {
  const { container } = useStyles(props);
  const { t } = useTranslation("Settings");

  const { user } = useAuth();
  const { defaultValues } = props;
  const { updateUserSettings } = useAccount();
  const { getSettingsFormSchema } = useValidationSchemas();
  const { enqueueSnackbar } = useSnackbar();
  const { name: currentName, email: currentEmail } = user;
  const [name, setName] = useState(currentName);
  const [email, setEmail] = useState(currentEmail);

  const { register, errors, handleSubmit, formState, reset } = useForm({
    defaultValues,
    validationSchema: getSettingsFormSchema(),
    reValidateMode: "onChange",
  });

  const { isValid, isSubmitted } = formState;

  const handleFormSubmit = (data) => {
    const keyMap = {
      name: "newName",
      currentPassword: "currentPassword",
      password: "newPassword",
      newEmail: "newEmail",
    };

    let payload = {};
    Object.keys(keyMap).forEach((key) => {
      if (data[keyMap[key]]) {
        payload[key] = data[keyMap[key]];
      }
    });

    reset(defaultValues);
    updateUserSettings(payload).then((responseData) => {
      const { name: responseName, email: responseEmail } = responseData;
      setName(responseName);
      setEmail(responseEmail);
      enqueueSnackbar(t("update_profile_success"), { variant: "success" });

      localStorage.setItem(
        "userData",
        JSON.stringify({ name: responseName, email: responseEmail })
      );
    });
  };

  return (
    <Grid container className={clsx(container, "Settings")}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container item spacing={3}>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("title_general")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{`${t("current_name")}: ${name}`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                inputRef={register}
                fullWidth
                name="newName"
                variant="outlined"
                label={t("new_name")}
                error={Boolean(errors.newName)}
                helperText={errors.newName?.message}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid container item spacing={2} xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t("title_change_password")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{t("double_confirmation")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  name="newPassword"
                  label={t("new_password")}
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  name="newPasswordConfirm"
                  label={t("new_password_confirm")}
                  error={Boolean(errors.newPasswordConfirm)}
                  helperText={errors.newPasswordConfirm?.message}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("title_change_email")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{`${t("current_email")}: ${email}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  name="newEmail"
                  label={t("new_email")}
                  error={Boolean(errors.newEmail)}
                  helperText={errors.newEmail?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  inputRef={register}
                  fullWidth
                  variant="outlined"
                  name="newEmailConfirm"
                  label={t("new_email_confirm")}
                  error={Boolean(errors.newEmailConfirm)}
                  helperText={errors.newEmailConfirm?.message}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t("title_current_password")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="password"
                autoComplete="new-password"
                inputRef={register}
                fullWidth
                variant="outlined"
                name="currentPassword"
                label={t("current_password")}
                error={Boolean(errors.currentPassword)}
                helperText={errors.currentPassword?.message}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled={!isValid && isSubmitted}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

Settings.propTypes = propTypes;
Settings.defaultProps = defaultProps;

export default Settings;
export { propTypes as SettingsPropTypes, defaultProps as SettingsDefaultProps };
