import {
  getCurrentLang,
  getLocale,
} from "../LanguageSelector/LanguageSelector";

/**
 * Translates a path
 *
 * Ex.: '/destinations' => '/destinatii'
 * Ex.: '/account?activeTab=0' => '/cont/activeTab=0'
 */
const localizePath = (props) => {
  const { path, t } = props;

  if (!path) return;

  const split = path.split("?");
  const queryParams = split[1] ? `?${split[1]}` : "";
  const pathWithoutQueryParams = split[0] ? split[0] : path;

  const pathWithoutSlash = pathWithoutQueryParams.substring(1);
  const pathWithoutSlashLocalized = t(pathWithoutSlash, "not-found");

  return pathWithoutSlashLocalized === "not-found"
    ? path
    : `/${pathWithoutSlashLocalized}${queryParams}`;
};

/**
 * Translates a set of route paths
 */
const localizeRoutes = (props) => {
  const { routes, t } = props;

  return (
    routes &&
    routes.map((item) => {
      const { path } = item;
      return { ...item, path: localizePath({ path: path, t: t }) };
    })
  );
};

/**
 * Generates routes for a language
 */
const routesForLanguage = (props) => {
  const { routes, language, i18n } = props;
  const { alternateName: languageCode } = language;

  return (
    routes &&
    routes.map((item) => {
      const { path: oldPath } = item;

      const langF = i18n.getFixedT(languageCode, "Routes");
      const slug = langF(oldPath.substring(1));

      return { ...item, path: `/${slug}` };
    })
  );
};

/**
 * Updates the URL on language change
 *
 * - It loops through breadcrumbs
 * - Looks up breadcrumb in old routes
 * - Finds new slug on new routes
 * - If the new slug is not found it means it must be translated via an API call
 * - Since we can't make an async API call here we will return the query to the component where it will be executed
 *
 * - Returns: { url: the translated url, queries: API query strings }
 * - Ex.: { url: '/articles/', queries: [] }
 * - Ex.: { url: '/articles/{query-1}', queries: ['/api/article/article-2-ro/en-US'] }
 */
const updateURL = (props) => {
  const { breadcrumbs, i18n, routes, oldLanguage, slugs, location } = props;

  const currentLanguage = getCurrentLang(i18n);

  const currentRoutes = routesForLanguage({
    routes: routes,
    language: { alternateName: currentLanguage },
    i18n: i18n,
    doPrefixLanguage: false,
  });

  const oldRoutes = routesForLanguage({
    routes: routes,
    language: { alternateName: oldLanguage },
    i18n: i18n,
    doPrefixLanguage: true,
  });

  const [oldLocale] = oldLanguage.split("-");
  const [currentLocale] = currentLanguage.split("-");

  const urlParts =
    breadcrumbs &&
    breadcrumbs
      .map((item) => {
        const { breadcrumb } = item;
        const { key } = breadcrumb;

        const parsedKey = key.split("/").reverse()[0];
        let newKey = "";
        const componentForKey = oldRoutes.find(
          (item) => item.path === `/${parsedKey}`
        );

        const slug = slugs.find((slug) => slug[oldLocale] === parsedKey);
        if (componentForKey) {
          const componentForNewKey = currentRoutes.find(
            (item) => item.component === componentForKey.component
          );

          if (componentForNewKey) {
            const { path } = componentForNewKey;
            newKey = path;
          }
        } else if (slug) {
          const newSlug = slug[currentLocale];

          return `/${newSlug}`;
        } else {
          const langF = i18n.getFixedT(currentLanguage, "Routes");
          const resource = i18n.getResource(oldLanguage, "Routes");
          const currentLngKey = Object.keys(resource).find((key) => {
            return resource[key] === parsedKey;
          });

          const newSlug = langF(currentLngKey);
          return `/${newSlug}`;
        }

        return newKey ? newKey : null;
      })
      .filter((item) => item !== null && item !== "/");

  const url = urlParts.join("").replace("//", "/");

  const { search } = location;
  const urlWithLang = `/${currentLocale}/${url}${search}`.replace("//", "/");

  const isHomeInRomanian = urlWithLang === "/ro/";
  return { url: isHomeInRomanian ? "/" : urlWithLang };
};

const getSlugForCurrentLang = (slug) => {
  const locale = getLocale();
  return slug[locale];
};
export {
  localizeRoutes,
  localizePath,
  routesForLanguage,
  updateURL,
  getSlugForCurrentLang,
};
