/**
 * Defines the data requirements for the component
 *
 * @see RequestStayForm.md for details
 */
import PropTypes from "prop-types";
import * as moment from "moment";

/**
 * Defines the prop types
 */
const propTypes = {
  accommodationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setIsModalOpen: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    checkIn: moment(),
    checkOut: moment().add(1, "days"),
    accommodationId: "",
    phone: "",
    rooms: 1,
    guests: 1,
    userMessage: "",
  },
};

export { propTypes, defaultProps };
