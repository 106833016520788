/**
 * Component short description
 *
 * @see AccountRoles.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import { useAuth } from "../../../hooks";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./AccountRoles.data";

/**
 * Imports logic
 */
import {
  getRoles,
  getExternalRoles,
  getInternalRole,
} from "./AccountRoles.logic";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./AccountRoles.lang.ro-ro";
import { hu_hu } from "./AccountRoles.lang.hu-hu";
import { en_us } from "./AccountRoles.lang.en-us";
import { de_de } from "./AccountRoles.lang.de-de";

i18n.addResourceBundle("ro-RO", "AccountRoles", ro_ro);
i18n.addResourceBundle("hu-HU", "AccountRoles", hu_hu);
i18n.addResourceBundle("en-US", "AccountRoles", en_us);
i18n.addResourceBundle("de-DE", "AccountRoles", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 320,
  },
  link: {
    width: "100%",
  },
}));

/**
 * Displays the component
 */
const AccountRoles = (props) => {
  const { container, link } = useStyles(props);
  const { user } = useAuth();
  const roles = getRoles(user);

  const rolesList =
    roles.length > 0 &&
    roles.map((item) => {
      const { name, url, id } = item;

      return (
        <MenuItem key={id} value={item}>
          <Link
            href={url}
            color="textPrimary"
            underline="none"
            className={clsx(link, "AccountRoles")}
          >
            {name}
          </Link>
        </MenuItem>
      );
    });

  return (
    rolesList && (
      <FormControl className={clsx(container, "AccountRoles")}>
        <Select
          labelId="account-roles-select-label"
          id="account-roles-select"
          value={roles[0]}
        >
          {rolesList}
        </Select>
      </FormControl>
    )
  );
};

AccountRoles.propTypes = propTypes;
AccountRoles.defaultProps = defaultProps;

export default AccountRoles;
export {
  propTypes as AccountRolesPropTypes,
  defaultProps as AccountRolesDefaultProps,
  getRoles,
  getInternalRole,
  getExternalRoles,
};
