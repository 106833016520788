/**
 * Defines the data requirements for the component
 *
 * @see Booking.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Stay/getBookings
 */
const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  destinationName: PropTypes.string,
  phone: PropTypes.string,
  price: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  image: ImageResponsivePlaceholderCom169,
};

export { propTypes, defaultProps };
