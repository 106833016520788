/**
 * Defines the data requirements for the component
 *
 * @see Newsletter.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../ImageResponsive";
import {
  NewsletterFormPropTypes,
  NewsletterFormDefaultProps,
} from "../NewsletterForm";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Newsletter/getNewsletterInfo
 */
const propTypes = {
  description: PropTypes.string,
  enabled: PropTypes.bool,
  image: PropTypes.shape(ImageResponsivePropTypes),
  form: PropTypes.shape(NewsletterFormPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  enabled: true,
  image: ImageResponsivePlaceholderCom169,
  form: NewsletterFormDefaultProps,
};

export { propTypes, defaultProps };
