/**
 * Defines the data requirements for the component
 *
 * @see About.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  SearchFormPropTypes,
  SearchFormDefaultProps,
} from "../../_search/SearchForm";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/About%20us/getAboutUs
 */
const propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  searchForm: PropTypes.shape(SearchFormPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  headline: "",
  description: "",
  searchForm: SearchFormDefaultProps,
};

export { propTypes, defaultProps };
