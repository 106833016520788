const en_us = {
  email: "Email address",
  forgot_pass: "Forgot password?",
  register: "Join now!",
  login: "Sign in",
  password: "Password",
  remember_me: "Remember me",
  register_text: "Please fill in the required data in order to register.",
};

export { en_us };
