const hu_hu = {
  location_categories: "Helykategóriák",
  pdf: "PDF letöltése",
  gps: "GPS",
  transportation: "Szállítás",
  show_more: "Mutass többet",
  show_less: "Mutass kevesebbet",
  videos: "Videók",
  weather: "Időjárás",
  webcam: "Webkamera",
};

export { hu_hu };
