/**
 * Component short description
 *
 * @see BookingHistory.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Booking from "../Booking";
import { useStayHistorySwr } from "../../_stay/Stay/Stay.logic";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./BookingHistory.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./BookingHistory.lang.ro-ro";
import { hu_hu } from "./BookingHistory.lang.hu-hu";
import { en_us } from "./BookingHistory.lang.en-us";
import { de_de } from "./BookingHistory.lang.de-de";

i18n.addResourceBundle("ro-RO", "BookingHistory", ro_ro);
i18n.addResourceBundle("hu-HU", "BookingHistory", hu_hu);
i18n.addResourceBundle("en-US", "BookingHistory", en_us);
i18n.addResourceBundle("de-DE", "BookingHistory", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const BookingHistory = (props) => {
  const { container } = useStyles(props);
  const { language } = i18n;
  const { data, mutate: mutatedBookingsHistory } = useStayHistorySwr();
  const bookings = data || [];

  useEffect(() => {
    mutatedBookingsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const bookingsList = bookings.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12}>
        <Booking {...item} index={index} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={1} className={clsx(container, "BookingHistory")}>
      {bookingsList}
    </Grid>
  );
};

BookingHistory.propTypes = propTypes;
BookingHistory.defaultProps = defaultProps;

export default BookingHistory;
export {
  propTypes as BookingHistoryPropTypes,
  defaultProps as BookingHistoryDefaultProps,
};
