const ro_ro = {
  email: "Adresa de email",
  submit: "Alătură-te",
  have_an_account: "Aveţi deja un cont?",
  conditions_part_2: "Condiţii de utilizare şi de vânzare",
  sign_in: "Logare",
  name: "Numele dumneavoastră",
  password: "Parola",
  conditions_part_1:
    "Prin crearea unui cont sunteţi de acord cu condiţiile Pineyard. ",
  confirm_password: "Reintrodu parola",
};

export { ro_ro };
