const de_de = {
  headline: "Willkommen bei Pineyard.ro",
  afir_description: "Realisiert mit der Unterstützung von AFIR",
  collage: "Collage der G10-Standorte",
  voslabeni: "Voslabeni",
  remetea: "Remetea",
  ciumani: "Ciumani",
  suseni: "Gyergyóújfalu",
  joseni: "Joseni",
  ditro: "Dittersdorf",
  borsec: "Bad Borseck",
  corbu: "Corbu",
  lazarea: "Lăzarea",
  tulghes: "Tulgheș",
};

export { de_de };
