const hu_hu = {
  1: "Ezen a helyen nincs természetes turisztikai látványosság.",
  2: "Ebben a helységben nincs kulturális turisztikai látványosság.",
  3: "Ezen a településen nem találhatóak Ásványvízforrások.",
  4: "Ezen a településen nem található Balneológiai kezelés.",
  5: "Ezen a településen nem található Szabadidős szolgáltatás.",
  6: "Ezen a helyen nincsenek turista útvonalak.",
  7: "Ezen a településen nem találhatóak Múzeumok.",
  8: "Ebben a helységben nincs All típusú információ.",
  9:
    "Ezen a településen nincs más típusú turisztikai információ az Egyebek kategóriában.",
  10: "Ezen a településen nem találhatóak Éttermek és teraszok.",
};

export { hu_hu };
