/**
 * Defines the data requirements for the component
 *
 * @see OrderItem.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getMarketOrderByID
 *
 * // NOTE: It would be expected OrderItem to be Product.
 * // NOTE: Cart and Order should have been sharing Product instead of OrderItem
 * // NOTE: In this API design these three resources have nothing in common
 */
const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  destinationName: PropTypes.string,
  price: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.shape(ImageResponsivePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: null,
  name: "",
  destinationName: "",
  price: null,
  quantity: null,
  image: ImageResponsivePlaceholderCom169,
};

export { propTypes, defaultProps };
