/**
 * Defines the data requirements for the component
 *
 * @see Cart.md for details
 */
import PropTypes from "prop-types";
/**
 * Imports prop types from other components
 */
import { OrdersForCartPropTypes } from "../../_account/OrdersForCart";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getMarketCartInfo
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/Market/getCartMarket
 *
 * // NOTE: The API is not in Sync with Figma (destinationName is missing). Going with Figma
 */
const propTypes = {
  description: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.shape(OrdersForCartPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  orders: [],
};

export { propTypes, defaultProps };
