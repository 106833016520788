/**
 * Defines the data requirements for the component
 *
 * @see Valuta.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  widget: PropTypes.any,
};

/**
 * Defines the default props
 *
 * // NOTE: When theme colors change widget v.3.0 must be updated too
 * // NOTE: Widget v.2.0 seems to be not working
 */
const defaultProps = {};

export { propTypes, defaultProps };
