const hu_hu = {
  personal_address: "Cím",
  delivery_address: "Cím",
  request_sent_successfully: "Kérés elküldve",
  delivery_info: "Kézbesítési információ",
  personal_info: "Személyes adat",
  items_sold_and_delivered:
    "Az értékesített és szállított termékeket forgalmazza",
  is_same_as_personal_info: "Ugyanaz, mint a személyes adatok",
  personal_phone: "telefon",
  delivery_phone: "telefon",
  personal_name: "Név",
  delivery_name: "Név",
  create_order_failed: "Hiba történt a megrendelés elküldése során.",
  send_request: "Felkérést küld",
  checkout: "Pénztár",
  create_order_success: "Megrendelését sikeresen elküldtük.",
};

export { hu_hu };
