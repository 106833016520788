import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";

import ImageResponsive from "../ImageResponsive";
import { TextDefault } from "../Theme/Theme";
import theme from "../../theme/theme";

const useStyles = makeStyles(() => ({
  cardContainer: {
    wordBreak: 'break-word',
    width: '100%',
    padding: '16px',
    color: 'primary',
    backgroundColor: '#fff',
    float: 'left'
  },
  imageContainer: {
    width: '50%',
    float: 'right',
    margin: '0 0 16px 16px'
  },
  imageContainerTablet: {
    width: '100%',
    marginBottom: '16px'
  }
}));

function GeneralDescription(props) {
  const { image, description } = props;
  const { cardContainer, imageContainer, imageContainerTablet } = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={cardContainer}>
      <div className={isTablet ? imageContainerTablet : imageContainer}>
        <ImageResponsive {...image} />
      </div>

      <TextDefault text={description} html={true}/>
    </div>
  )
}

export default GeneralDescription;
