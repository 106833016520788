/**
 * Defines the data requirements for the component
 *
 * @see OrderForCart.md for details
 */

/**
 * Imports prop types from other components
 */
import { OrderPropTypes, OrderDefaultProps } from "../Order";

/**
 * Defines the prop types
 */
const propTypes = {
  ...OrderPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...OrderDefaultProps,
};

export { propTypes, defaultProps };
