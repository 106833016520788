/**
 * Component short description
 *
 * @see Cart.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Card2 from "../../Card2";
import { PageHeader, TextSection } from "../../Theme";
import OrdersForCart from "../../_account/OrdersForCart";
import { useAuth } from "../../../hooks";

/**

 * Imports data
 */
import { defaultProps, propTypes } from "./Cart.data";
import useMarket from "../../_localMarket/LocalMarket/LocalMarket.logic";
import { useCart } from "./Cart.logic";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Cart.lang.ro-ro";
import { hu_hu } from "./Cart.lang.hu-hu";
import { en_us } from "./Cart.lang.en-us";
import { de_de } from "./Cart.lang.de-de";
import useCartItems from "../../../hooks/useCartItems/useCartItems";

i18n.addResourceBundle("ro-RO", "Cart", ro_ro);
i18n.addResourceBundle("hu-HU", "Cart", hu_hu);
i18n.addResourceBundle("en-US", "Cart", en_us);
i18n.addResourceBundle("de-DE", "Cart", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Cart = (props) => {
  const { description: defaultDescription } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("Cart");
  const { language } = i18n;

  const { useCartSwr, useCartInfoSwr } = useCart();
  const { updateCart } = useMarket();
  const { deleteCartItem } = useCart();
  const { enqueueSnackbar } = useSnackbar();
  const { updateCart: updateCartItems } = useCartItems();

  const { data: cartInfo, mutate: mutatedCartInfo } = useCartInfoSwr();
  const description = cartInfo ? cartInfo.description : defaultDescription;
  const { data, mutate: mutatedCart } = useCartSwr();
  const orders = data || [];

  const handleItemDelete = (id) => {
    deleteCartItem(id).then(() => {
      updateCartItems();
      mutatedCart();
      enqueueSnackbar(t("cart_item_deleted_successfully"), {
        variant: "success",
      });
    });
  };

  const handleUpdateItem = (value, productId) => {
    const cart = {
      productId,
      quantity: value,
    };

    updateCart(productId, cart).then(() => {
      mutatedCart();
      updateCartItems();
      enqueueSnackbar(t("items_added"), { variant: "success" });
    });
  };

  useEffect(() => {
    mutatedCart();
    mutatedCartInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return <Redirect to="/" />;

  /**
   * Displays the Hero card
   */
  const sectionTitle =
    !orders || !orders.length ? t("empty_cart") : description;
  const cardContent1 = <TextSection description={sectionTitle} />;

  const cardWithMedia1 = {
    image: null,
    content: cardContent1,
  };

  return (
    <Layout header={{ currentPage: "Cart" }}>
      <Grid container spacing={1} className={clsx(container, "Cart")}>
        <Grid item xs={12}>
          <PageHeader title={t("cart")} />
        </Grid>
        <Grid item xs={12}>
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <OrdersForCart
            orders={orders}
            handleItemDelete={handleItemDelete}
            handleUpdateItem={handleUpdateItem}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Cart.propTypes = propTypes;
Cart.defaultProps = defaultProps;

export default Cart;
export { propTypes as CartPropTypes, defaultProps as CartDefaultProps };
