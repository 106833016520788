/**
 * Defines the data requirements for the component
 *
 * @see Card2.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import { ImagePropTypes, ImageDefaultProps } from "../Image";
import { CarouselPropTypes } from "../Carousel";

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * A single image
   */
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(ImagePropTypes),
  ]),
  /**
   * A set of images
   */
  images: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)),
  /**
   * A Carousel component
   */
  carousel: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(CarouselPropTypes),
  ]),
  /**
   * The content of the card
   */
  content: PropTypes.element,
  /**
   * The actions of the card
   */
  actions: PropTypes.element,
  /**
   * A set of classes to overwrite the component's default style
   * - Customizing the card can be done either via styles and/or props
   */
  customStyles: PropTypes.any,
  /**
   * A set of props for the card
   */
  cardProps: PropTypes.object,
  /**
   * A set of props for the card container
   */
  cardContainerProps: PropTypes.object,
  /**
   * A set of props for the image container
   */
  imageContainerProps: PropTypes.object,
  /**
   * A set of props for the content container
   */
  contentContainerProps: PropTypes.object,
  /**
   * A custom className
   */
  klassName: PropTypes.string,
  /**
   * Widget. It will be displayed as / instead of an image
   */
  widget: PropTypes.any,
  /**
   * Standard Card props
   */
  elevation: PropTypes.number,
  variant: PropTypes.oneOf(["outlined", "elevation"]),
};

/**
 * Defines the default props
 */
const defaultProps = {
  image: ImageDefaultProps,
  images: null,
  carousel: null,
  content: null,
  actions: null,
  customStyles: "",
  cardProps: {},
  cardContainerProps: {},
  imageContainerProps: {},
  contentContainerProps: {},
  klassName: null,
  widget: null,
  elevation: 0,
  variant: "elevation",
};

export { propTypes, defaultProps };
