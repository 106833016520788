const ro_ro = {
  title: "Eveniment",
  no_events:
    "În prezent, nu există evenimente pentru această locație în luna selectată",
  month: "Luna",
  select_destination: "Selectează o destinaţie",
  select_month: "Selectează o lună",
  destination: "Destinaţie",
  all: "Toate",
};

export { ro_ro };
