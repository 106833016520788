/**
 * Component short description
 *
 * @see OrderCancel.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./OrderCancel.data";
/**
 * Imports Material UI components
 */
import { makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderCancel.lang.ro-ro";
import { hu_hu } from "./OrderCancel.lang.hu-hu";
import { en_us } from "./OrderCancel.lang.en-us";
import { de_de } from "./OrderCancel.lang.de-de";
/**
 * Imports other components and hooks
 */
import Modal from "../../Modal/Modal";

i18n.addResourceBundle("ro-RO", "OrderCancel", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderCancel", hu_hu);
i18n.addResourceBundle("en-US", "OrderCancel", en_us);
i18n.addResourceBundle("de-DE", "OrderCancel", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    wordBreak: "break-word",
  },
}));

/**
 * Displays the component
 */
const OrderCancel = (props) => {
  const {
    isModalOpen,
    handleClose,
    seller,
    orderId,
    orderDate,
    orderItems,
    totalPrice,
    description,
    handleOrderCancel,
  } = props;
  const { container } = useStyles(props);
  const { t } = useTranslation("OrderCancel");

  return (
    <Modal
      title={t("title")}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth="sm"
      isCustomStyle={true}
    >
      <Grid container spacing={3} className={clsx(container, "OrderCancel")}>
        <Grid container item>
          <Grid item>
            <Typography fullWidth variant="h6">
              {t("order_id", { value: orderId })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Typography>{t("seller", { value: seller })}</Typography>
          </Grid>
          <Grid item>
            <Typography>{t("order_date", { value: orderDate })}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t("items_count_and_price", { orderItems, totalPrice })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={4} justify="flex-end">
          <Grid item xs={4}>
            <Button variant="contained" onClick={handleClose} fullWidth>
              {t("back_to_order")}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleOrderCancel}
            >
              {t("confirm_cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

OrderCancel.propTypes = propTypes;
OrderCancel.defaultProps = defaultProps;

export default OrderCancel;
export {
  propTypes as OrderCancelPropTypes,
  defaultProps as OrderCancelDefaultProps,
};
