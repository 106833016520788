const en_us = {
  personal_address: "Address",
  delivery_address: "Address",
  request_sent_successfully: "Request Sent",
  delivery_info: "Delivery information",
  personal_info: "Personal information",
  items_sold_and_delivered: "Items sold and delivered by",
  is_same_as_personal_info: "Same as personal info",
  personal_phone: "Telephone",
  delivery_phone: "Telephone",
  personal_name: "Name",
  delivery_name: "Name",
  create_order_failed: "Something went wrong when sending your order.",
  send_request: "Send request",
  checkout: "Checkout",
  create_order_success: "Your order has been successfully sent.",
};

export { en_us };
