/**
 * Defines the data requirements for the component
 *
 * @see DestinationAsThumb.md for details
 */

/**
 * Imports prop types from other components
 */
import { DestinationPropTypes, DestinationDefaultProps } from "../Destination";

/**
 * Defines the prop types
 */
const propTypes = {
  ...DestinationPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...DestinationDefaultProps,
};

export { propTypes, defaultProps };
