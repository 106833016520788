/**
 * Defines the data requirements for the component
 *
 * @see Auth.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = { button: PropTypes.any, text: PropTypes.string };

/**
 * Defines the default props
 */
const defaultProps = {
  button: {},
  text: null,
};

export { propTypes, defaultProps };
