/**
 * Component short description
 *
 * @see UnitsForm.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import shortid from "shortid";
import { useSnackbar } from "notistack";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./UnitsForm.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./UnitsForm.lang.ro-ro";
import { hu_hu } from "./UnitsForm.lang.hu-hu";
import { en_us } from "./UnitsForm.lang.en-us";
import { de_de } from "./UnitsForm.lang.de-de";

/**
 * Imports other components and hooks
 */
import useMarket from "../LocalMarket/LocalMarket.logic";
import { useAuth } from "../../../hooks";
import Auth from "../../_account/Auth";
import { getArrayItemsForMinAndMax } from "../../../helpers/baseHelper";
import useCartItems from "../../../hooks/useCartItems/useCartItems";

i18n.addResourceBundle("ro-RO", "UnitsForm", ro_ro);
i18n.addResourceBundle("hu-HU", "UnitsForm", hu_hu);
i18n.addResourceBundle("en-US", "UnitsForm", en_us);
i18n.addResourceBundle("de-DE", "UnitsForm", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
  select: {
    width: "100%",
  },
  menuPaper: {
    maxHeight: "200px",
  },
}));

/**
 * Displays the component
 */
const UnitsForm = (props) => {
  const { container, select, menuPaper } = useStyles(props);
  const { t } = useTranslation("UnitsForm");
  const { min, max, unit, productId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { createCart } = useMarket();
  const { updateCart } = useCartItems();

  const { isAuthenticated } = useAuth();
  const minQuantity = min || 1;
  const totalItems = getArrayItemsForMinAndMax(minQuantity, max);

  /**
   * Handles the select box
   */
  const [selectedQuantity, setSelectedQuantity] = useState(minQuantity);
  useEffect(() => {
    setSelectedQuantity(minQuantity);
  }, [minQuantity]);

  const handleChangeQuantity = (event) => {
    setSelectedQuantity(event.target.value);
  };

  const handleAddToCart = () => {
    const cart = {
      productId,
      quantity: selectedQuantity,
    };

    createCart(cart).then(() => {
      updateCart();
      enqueueSnackbar(t("Cart:items_added"), { variant: "success" });
    });
  };

  return (
    <Grid item xs={12} className={clsx(container, "UnitsForm")}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={clsx(select)}>
            <InputLabel id="cart-order-quantity-select-label">
              {t("OrderItemForCart:select_quantity")}
            </InputLabel>
            <Select
              MenuProps={{ classes: { paper: menuPaper } }}
              label={t("OrderItemForCart:select_quantity")}
              labelId="cart-order-quantity-select-label"
              id="cart-order-quantity-select"
              value={selectedQuantity}
              onChange={handleChangeQuantity}
            >
              {totalItems.map((value) => (
                <MenuItem value={value} key={shortid.generate()}>
                  {`${value} ${unit}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {isAuthenticated ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAddToCart}
            >
              {t("add_to_cart")}
            </Button>
          ) : (
            <Auth
              button={{
                fullWidth: true,
                variant: "contained",
                color: "primary",
                size: "large",
                startIcon: null,
              }}
              text={t("sign_in")}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

UnitsForm.propTypes = propTypes;
UnitsForm.defaultProps = defaultProps;

export default UnitsForm;
export {
  propTypes as UnitsFormPropTypes,
  defaultProps as UnitsFormDefaultProps,
};
