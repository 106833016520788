/**
 * Defines the data requirements for the component
 *
 * @see Article.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";

/**
 * Defines the prop types
 *
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/ > NewsDetail
 * @see https://api.borsecturismregional.web-staging.eu/apidoc/#/News/getNewsByID
 */
const propTypes = {
  /**
   * Props from the API
   */
  id: PropTypes.number,
  slug: PropTypes.object,
  name: PropTypes.string,
  destination: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  description: PropTypes.string,
  sectionDescription1: PropTypes.string,
  image1: PropTypes.shape(ImageResponsivePropTypes),
  image1Description: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription2: PropTypes.string,
  sectionListing: PropTypes.string,
  image2: PropTypes.shape(ImageResponsivePropTypes),
  image2Description: PropTypes.string,
  sectionDescription3: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: null,
  slug: {},
  name: "",
  destination: "",
  image: ImageResponsivePlaceholderCom169,
  description: "",
  sectionDescription1: "",
  image1: ImageResponsivePlaceholderCom169,
  image1Description: "",
  sectionTitle: "",
  sectionDescription2: "",
  sectionListing: "",
  image2: ImageResponsivePlaceholderCom169,
  image2Description: "",
  sectionDescription3: "",
};

export { propTypes, defaultProps };
