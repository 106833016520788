/**
 * Component short description
 *
 * @see Footer.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";
/**
 * Imports other components and hooks
 */
import Logo from "../Logo";
import MenuItems from "../MenuItems";
import Icons from "../Icons";
import { TextLarge } from "../Theme/Theme";
import { useAboutInfo } from "../_home/About/About.logic";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Footer.data";
/**
 * Imports Material UI components
 */
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Footer.lang.ro-ro";
import { hu_hu } from "./Footer.lang.hu-hu";
import { en_us } from "./Footer.lang.en-us";
import { de_de } from "./Footer.lang.de-de";
import { logoGreen } from "../Logo/SvgIconPy";
import useConsent from "../../hooks/useConsent/useConsent";

i18n.addResourceBundle("ro-RO", "Footer", ro_ro);
i18n.addResourceBundle("hu-HU", "Footer", hu_hu);
i18n.addResourceBundle("en-US", "Footer", en_us);
i18n.addResourceBundle("de-DE", "Footer", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    justifyContent: "center",
  },

  logoContainer: (props) => ({
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1.5),
    },
  }),

  copyright: {
    marginTop: theme.spacing(1),
  },
  copyrightPortrait: {
    justifyContent: "center",
    display: "flex",
    marginLeft: theme.spacing(1.5),
  },
  iconsContainer: {
    "& .Icons": {
      justifyContent: "center",
      "& .MuiGrid-item": {
        "&:first-of-type": {
          paddingLeft: "0 !important",
        },
      },
    },
  },
  counter: {
    justifyContent: "center",
  },
}));

/**
 * Displays the component
 */
const Footer = (props) => {
  const { logo, menu, icons, currentPage } = props;
  const {
    container,
    logoContainer,
    iconsContainer,
    copyright,
    copyrightPortrait,
    counter,
  } = useStyles(props);
  const { t } = useTranslation("Footer");

  const theme = useTheme();
  const isPortrait = useMediaQuery(theme.breakpoints.down("md"));
  const orientation = isPortrait ? "vertical" : "horizontal";
  const { openDialog } = useConsent();

  const { data: aboutInfoData } = useAboutInfo();
  const visitorsCount = aboutInfoData ? aboutInfoData.visitorCounter : 1;

  const translateMenuItems = (menuItems, t) => {
    const { items = [] } = menuItems;

    const extendedItems = [
      ...items,
      {
        key: "consent",
        name: "Consent",
        id: "1",
        onClick: () => openDialog(),
      },
    ];

    return {
      ...menuItems,
      items: extendedItems.map((item) => {
        const { key } = item;
        return {
          ...item,
          translatedName: t(key),
        };
      }),
    };
  };

  const copy = (
    <Grid
      item
      className={clsx(
        copyright,
        isPortrait ? copyrightPortrait : null,
        "Copyright"
      )}
    >
      <Typography variant="caption">
        &copy; 2020 Pineyard. All rights reserved.
      </Typography>
    </Grid>
  );

  const id = (
    <>
      <Grid
        container
        className={clsx(container)}
        spacing={0}
        alignItems="center"
        justify="flex-start"
        wrap="wrap"
      >
        <Grid item className={clsx(logoContainer)}>
          <Logo {...logo} svg={logoGreen} />
        </Grid>
      </Grid>
      <Hidden xsDown>{copy}</Hidden>
    </>
  );

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "Footer")}
      alignItems="center"
      justify={isPortrait ? "flex-start" : "space-around"}
    >
      <Grid container item xs={12} className={clsx(counter)}>
        <TextLarge
          fullWidth
          text={`${t("Footer:visitors_counter")}: ${visitorsCount}`}
        />
      </Grid>
      <Grid
        item
        container
        className={clsx(container)}
        direction="column"
        xs={12}
        sm={6}
        lg={2}
      >
        {id}
      </Grid>
      {/* <Grid item container className={clsx(container)} xs={12} sm={6} lg={true}> */}
      <Grid item xs={12} lg={"auto"}>
        <MenuItems
          {...translateMenuItems(menu, t)}
          activeItem={currentPage}
          orientation={orientation}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={2}
        className={clsx(iconsContainer, "IconsContainer")}
      >
        <Icons {...icons} orientation="horizontal" />
      </Grid>
      {/* </Grid> */}
      <Hidden smUp>{copy}</Hidden>
    </Grid>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
export { propTypes as FooterPropTypes, defaultProps as FooterDefaultProps };
