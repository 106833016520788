/**
 * Component short description
 *
 * @see FilterSeller.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import { getArrayOfKeys, getSelectedArrayValues, } from "../../../hooks/useFilters";
/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterSeller.data";

/**
 * Imports Material UI components
 */
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterSeller.lang.ro-ro";
import { hu_hu } from "./FilterSeller.lang.hu-hu";
import { en_us } from "./FilterSeller.lang.en-us";
import { de_de } from "./FilterSeller.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterSeller", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterSeller", hu_hu);
i18n.addResourceBundle("en-US", "FilterSeller", en_us);
i18n.addResourceBundle("de-DE", "FilterSeller", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterSeller = (props) => {
  const { filterListItem, defaultValue } = props;
  const { t } = useTranslation("FilterSeller");

  /**
   * Handles the filters
   */
  const { filters, setFiltersAndResetPagination, data } = useFilters();
  const { sellers } = data;

  const selected = getSelectedArrayValues({
    key: "id",
    selected: filters.seller?.filterValue || defaultValue,
    items: sellers,
  });

  const selectHandler = (_event, value) => {
    setFiltersAndResetPagination({
      seller: {
        filterValue: getArrayOfKeys({ key: "id", items: value }),
        label: getArrayOfKeys({ key: "name", items: value }),
      },
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Autocomplete
        multiple
        name="seller"
        filterSelectedOptions
        getOptionLabel={(option) => option.name}
        options={sellers}
        closeIcon={null}
        value={selected}
        variant="outlined"
        onChange={selectHandler}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t("placeholder")} />
        )}
      />
    </FilterListItem>
  );
};

FilterSeller.propTypes = propTypes;
FilterSeller.defaultProps = defaultProps;

export default FilterSeller;
export {
  propTypes as FilterSellerPropTypes,
  defaultProps as FilterSellerDefaultProps,
};
