const de_de = {
  Stay: "Unterkunft",
  "Privacy Policy": "Datenschutz-Bestimmungen",
  Contact: "Kontakt",
  Destinations: "Ziele",
  FAQ: "FAQ",
  Sitemap: "Seitenverzeichnis",
  "Media & Press": "Medienpresse",
  Home: "Zuhause",
  "Local Market": "Lokaler Markt",
  News: "Nachrichten",
  Support: "Unterstützung",
  "Terms & Conditions": "Geschäftsbedingungen",
};

export { de_de };
