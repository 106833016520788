/**
 * Defines the data requirements for the component
 *
 * @see useMeta.md for details
 */

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

const DESTINATION_KEYS = {
  ditrau: "ditrau",
  ditro: "ditrau",
  dittersdorf: "ditrau",
  gyergyoditro: "ditrau",
  corbu: "corbu",
  gyergyohollo: "corbu",
  ciumani: "ciumani",
  remetea: "remetea",
  joseni: "joseni",
  lazarea: "lazarea",
  gyergyoszarhegy: "lazarea",
  tulghes: "tulghes",
  tolgyes: "tulghes",
  bor: "borsec",
  borsec: "borsec",
  suseni: "suseni",
  gyergyoujfalu: "suseni",
  voslabeni: "voslabeni",
  vaslab: "voslabeni",
};

export { propTypes, defaultProps, DESTINATION_KEYS };
