/**
 * Component short description
 *
 * @see Head.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import { Helmet } from "react-helmet";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Head.data";

/**
 * Displays the component
 */
const Head = (props) => {
  const { title, description, keywords } = props;
  return (
    <Helmet>
      {/** Primary meta tags */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/** Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;

export default Head;
export { propTypes as HeadPropTypes, defaultProps as HeadDefaultProps };
