/**
 * Defines the data requirements for the component
 *
 * @see FilterChips.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  selected: PropTypes.array,
  emptyText: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  selected: [],
  emptyText: "Select a value",
};

export { propTypes, defaultProps };
