const hu_hu = {
  headline: "Üdvözöljük a Pineyard.ro oldalán",
  afir_description: "Az AFIR támogatásával valósult meg",
  collage: "G10 helyszínek",
  voslabeni: "Vasláb",
  remetea: "Gyergyóremete",
  ciumani: "Ciumani",
  suseni: "Gyergyóújfalu",
  joseni: "Joseni",
  ditro: "Gyergyóditró",
  borsec: "Borszék",
  corbu: "Gyergyóholló",
  lazarea: "Gyergyószárhegy",
  tulghes: "Tölgyes",
};

export { hu_hu };
