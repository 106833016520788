/**
 * Displays GPS coordinates in a Modal dialog
 *
 * @see Gps.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState } from "react";
import clsx from "clsx";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { useErrorBoundary } from "use-error-boundary";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Gps.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Gps.lang.ro-ro";
import { hu_hu } from "./Gps.lang.hu-hu";
import { en_us } from "./Gps.lang.en-us";
import { de_de } from "./Gps.lang.de-de";
import Track from "../Track/Track";
import { GOOGLE_MAPS_API_KEY } from "../../constants/envVars";

i18n.addResourceBundle("ro-RO", "Gps", ro_ro);
i18n.addResourceBundle("hu-HU", "Gps", hu_hu);
i18n.addResourceBundle("en-US", "Gps", en_us);
i18n.addResourceBundle("de-DE", "Gps", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  title: {
    "& .MuiTypography-root": {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  /**
   * Stretching the dialog content
   * - `<Dialog maxWidth="lg" ...` is not enough
   */
  content: {
    width: "99vw",
    height: "99vh",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

/**
 * Displays the component
 */
const Gps = (props) => {
  const { iconData, latitude, longitude, track, google } = props;
  const { container, title, content } = useStyles(props);
  const { t } = useTranslation("Gps");
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const icon = track ? iconData.route : iconData.gps;

  /**
   * Manages the modal dialog
   */
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Display the track instead of the marker
   */
  const mapContent = track ? (
    <Track track={track} />
  ) : (
    <Marker position={{ lat: latitude, lng: longitude }} />
  );
  /**
   * Displays a Google Map
   */
  const map = didCatch ? (
    <p>Google Map error: {error.message}</p>
  ) : (
    <ErrorBoundary>
      <Map
        containerStyle={containerStyle}
        google={google}
        zoom={8}
        initialCenter={{ lat: latitude, lng: longitude }}
      >
        {mapContent}
      </Map>
    </ErrorBoundary>
  );

  return (
    <div className={clsx(container, "Gps")}>
      <Button
        variant="outlined"
        startIcon={icon.icon}
        size="large"
        onClick={handleOpen}
      >
        {t(`Attraction:${icon.name}`)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="gps-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="gps-dialog-title" className={clsx(title, "GpsTitle")}>
          {t("title")}
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={clsx(content, "GpsContent")}>
          {map}
        </DialogContent>
      </Dialog>
    </div>
  );
};

Gps.propTypes = propTypes;
Gps.defaultProps = defaultProps;

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
})(Gps);
export { propTypes as GpsPropTypes, defaultProps as GpsDefaultProps };
