/**
 * Reusable business logic
 * - For the web and the React Native apps
 */

/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import useRequests from "../../../hooks/useRequests";
import { isEmpty } from "lodash";
import { stringifyParams } from "../../../hooks/useRequests/useRequests";

/**
 * Loads data via SWR
 *
 * - Returns an SWR answer: {data, error, isValidating, mutate}
 *
 * Usage: `const { data } = useEventsSwr();`
 *
 * @see https://github.com/vercel/swr#return-values
 */
const useEventsSwr = (props) => {
  const params = isEmpty(props, true) ? "" : `?${stringifyParams(props)}`;
  // NOTE: This throws an infinte loop: const key = destinationId ? ["events", { destination: destinationId }] : "events";
  const { get } = useRequests();

  return useSWR(`events${params}`, get);
};

const useEventSwr = (props) => {
  const { id, condition = true } = props;
  const { get } = useRequests();

  return useSWR(condition ? `events/${id}` : null, get);
};

const useEvents = () => {
  const { get } = useRequests();

  const getEvents = (params) => {
    return get("events", params);
  };

  return [getEvents];
};

export default useEventsSwr;
export { useEvents, useEventSwr };
