/**
 * Defines the data requirements for the component
 *
 * @see RequestStay.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  extras: PropTypes.element,
  price: PropTypes.any,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  accommodationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setIsModalOpen: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {};

export { propTypes, defaultProps };
