/**
 * Component short description
 *
 * @see Pagination.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Pagination.data";

/**
 * Imports Material UI components
 */
import MuiPagination from "@material-ui/lab/Pagination";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { ro_ro } from "./Pagination.lang.ro-ro";
import { hu_hu } from "./Pagination.lang.hu-hu";
import { en_us } from "./Pagination.lang.en-us";
import { de_de } from "./Pagination.lang.de-de";

i18n.addResourceBundle("ro-RO", "Pagination", ro_ro);
i18n.addResourceBundle("hu-HU", "Pagination", hu_hu);
i18n.addResourceBundle("en-US", "Pagination", en_us);
i18n.addResourceBundle("de-DE", "Pagination", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const Pagination = (props) => {
  const { total, current, page, pageSize, onChange, ...rest } = props;

  const roundedTotal = total ? Math.ceil(total / Number(pageSize) || 10) : 0;
  const isVisible = roundedTotal > 1 && roundedTotal >= page;

  return (
    <>
      {isVisible && (
        <MuiPagination
          {...rest}
          showFirstButton={false}
          showLastButton={false}
          page={page}
          count={roundedTotal}
          onChange={onChange}
          variant="outlined"
          shape="rounded"
        />
      )}
    </>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
export {
  propTypes as PaginationPropTypes,
  defaultProps as PaginationDefaultProps,
};
