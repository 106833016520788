/**
 * Defines the data requirements for the component
 *
 * @see FacebookShare.md for details
 */
import PropTypes from "prop-types";

/**
 * Defines the prop types
 */
const propTypes = {
  url: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  url: null,
};

export { propTypes, defaultProps };
