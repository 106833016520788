/**
 * Component short description
 *
 * @see LoginDialog.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import Dialog2 from "../../Dialog2";
import LoginForm from "../LoginForm/LoginForm";
import { useAuthRequests } from "../Auth/Auth.logic";
import { useAuth } from "../../../hooks";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./LoginDialog.data";
/**
 * Imports Material UI components
 */

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./LoginDialog.lang.ro-ro";
import { hu_hu } from "./LoginDialog.lang.hu-hu";
import { en_us } from "./LoginDialog.lang.en-us";
import { de_de } from "./LoginDialog.lang.de-de";

i18n.addResourceBundle("ro-RO", "LoginDialog", ro_ro);
i18n.addResourceBundle("hu-HU", "LoginDialog", hu_hu);
i18n.addResourceBundle("en-US", "LoginDialog", en_us);
i18n.addResourceBundle("de-DE", "LoginDialog", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const LoginDialog = (props) => {
  const { dialog, loginForm, openDialog } = props;
  const { onClose, open } = dialog;

  const { t } = useTranslation("LoginDialog");

  const { login } = useAuthRequests();
  const { login: localLogin } = useAuth();

  const handleSubmit = async (data) => {
    const { email, password } = data;
    const reqData = { email, password };

    login(reqData).then((resp) => {
      localLogin(resp);
      onClose();
    });
  };

  return (
    <Dialog2
      open={open}
      onClose={onClose}
      id="login-dialog"
      maxWidth="sm"
      width="auto"
      height="auto"
      title={t("login")}
      content={
        <LoginForm
          {...loginForm}
          openDialog={openDialog}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};

LoginDialog.propTypes = propTypes;
LoginDialog.defaultProps = defaultProps;

export default LoginDialog;
export {
  propTypes as LoginDialogPropTypes,
  defaultProps as LoginDialogDefaultProps,
};
