/**
 * Component short description
 *
 * @see Videos.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Breadcrumbs from "../../Breadcrumbs";
import VideoAsThumb from "../VideoAsThumb";
import { PageHeader } from "../../Theme";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./Videos.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Videos.lang.ro-ro";
import { hu_hu } from "./Videos.lang.hu-hu";
import { en_us } from "./Videos.lang.en-us";
import { de_de } from "./Videos.lang.de-de";

i18n.addResourceBundle("ro-RO", "Videos", ro_ro);
i18n.addResourceBundle("hu-HU", "Videos", hu_hu);
i18n.addResourceBundle("en-US", "Videos", en_us);
i18n.addResourceBundle("de-DE", "Videos", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const Videos = (props) => {
  const { items = [], destinationName } = props;
  const { container } = useStyles(props);

  const { t } = useTranslation("Videos");

  const list = items.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <VideoAsThumb {...item} index={index} />
      </Grid>
    );
  });

  return (
    <Layout header={{ currentPage: "Destinations" }}>
      <Grid container spacing={1} className={clsx(container, "Videos")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={destinationName}
            title={t("title")}
          />
        </Grid>
        <Grid item container className={clsx(container)} spacing={1} xs={12}>
          {list}
        </Grid>
      </Grid>
    </Layout>
  );
};

Videos.propTypes = propTypes;
Videos.defaultProps = defaultProps;

export default Videos;
export { propTypes as VideosPropTypes, defaultProps as VideosDefaultProps };
