/**
 * Defines the data requirements for the component
 *
 * @see AccountRoles.md for details
 */
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Defines the prop types
 */
const propTypes = {
  id: PropTypes.string,
  /**
   * The name of the role
   */
  name: PropTypes.string,
  /**
   * Some roles will link to a local path
   * - like `/account`
   */
  path: PropTypes.string,
  /**
   * Some roles will link to an external url
   * - like `dashboard.domain.com`
   */
  url: PropTypes.string
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  name: "User role",
  path: null,
  url: null
};

export { propTypes, defaultProps };
