/**
 * Displays an Attraction
 *
 * @see Attraction.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";
import shortid from "shortid";
import { isEqual } from "lodash";

/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import ImageResponsive from "../../ImageResponsive";
import Card2, { Card2SingleRowStyles } from "../../Card2";
import Carousel from "../../Carousel";
import Icon from "../../Icon";
import Gps from "../../Gps";
import { useAttractionSwr } from "../../_destination/Destination/Destination.logic";
import { Actions, PageHeader, Template, TextSection } from "../../Theme";
import Breadcrumbs from "../../Breadcrumbs";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import FacebookShare from "../../FacebookShare/FacebookShare";
import { getUrlItems } from "../../../helpers/urlHelper";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./Attraction.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./Attraction.lang.ro-ro";
import { hu_hu } from "./Attraction.lang.hu-hu";
import { en_us } from "./Attraction.lang.en-us";
import { de_de } from "./Attraction.lang.de-de";
import GeneralDescription from "../../GeneralDescription/GeneralDescription";

i18n.addResourceBundle("ro-RO", "Attraction", ro_ro);
i18n.addResourceBundle("hu-HU", "Attraction", hu_hu);
i18n.addResourceBundle("en-US", "Attraction", en_us);
i18n.addResourceBundle("de-DE", "Attraction", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },

  text: {
    /**
     * Keep 45-60 chars per row for long text
     */
    maxWidth: theme.custom.typography.maxTextWidth,
  },

  aligned: {
    /**
     * Align opening hours etc icons to the GPS icon above
     * - horizontally the spacing does the job, here we adjust the vertical space
     */
    marginLeft: theme.spacing(2),
  },
}));

/**
 * Displays the component
 */
const Attraction = (props) => {
  const {
    openingHoursIcon,
    pricesIcon,
    facebookIcon,
    instagramIcon,
    webIcon,
    audioIcon,
    destinationName,
  } = props;

  const { container, aligned } = useStyles(props);

  const {
    t,
    i18n: { language },
  } = useTranslation("Attraction");

  const { addSlug } = useSlugs();

  const match = useRouteMatch();
  const { params } = match;
  const { slug } = params;

  const [attraction, setAttraction] = useState(props);

  const {
    audio,
    name,
    description,
    latitude,
    longitude,
    openingHours,
    prices,
    facebook,
    instagram,
    url,
    track,
    images,
    sectionDescription1,
    image1,
  } = attraction;

  /**
   * Fetch attraction
   */
  const { data: attractionData, mutate: mutateAttraction } = useAttractionSwr({
    id: slug,
  });

  useEffect(() => {
    if (attractionData && !isEqual(attractionData, attraction)) {
      setAttraction(attractionData);
      addSlug(attractionData.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attractionData]);

  useEffect(() => {
    mutateAttraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /**
   * Displays the hero
   */
  const carouselSteps = images
    .map((image, index) => {
      const { title } = image;

      return {
        id: String(index),
        title,
        content: <ImageResponsive {...image} />,
      };
    })
    .filter((item) => item);

  const cardContent1 = <TextSection description={description} />;

  const buttonAudio = audio && (
    <Button
      variant="outlined"
      size="large"
      href={audio}
      startIcon={audioIcon.icon}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t("audio")}
    </Button>
  );

  const actions1 = (
    <Actions
      items={[
        {
          id: shortid(),
          action: <Gps latitude={latitude} longitude={longitude} />
        },
        {
          id: shortid(),
          action: track && <Gps latitude={latitude} longitude={longitude} track={track} />
        },
        { id: shortid(), action: buttonAudio },
        { id: shortid(), action: <FacebookShare /> },
      ]}
    />
  );

  const actions2 = (openingHoursIcon || prices) && (
    <div className={clsx(aligned, "Aligned")}>
      <Actions
        className={clsx(aligned, "Aligned")}
        items={[
          {
            id: shortid.generate(),
            action: <Icon {...openingHoursIcon} name={openingHours} />,
          },
          {
            id: shortid.generate(),
            action: <Icon {...pricesIcon} name={prices} />,
          },
        ]}
        orientation="vertical"
      />
    </div>
  );
  const urlActions = [
    { url: facebook, icon: facebookIcon},
    { url: instagram, icon: instagramIcon},
    { url, icon: webIcon}
  ];

  const urlItems = getUrlItems(urlActions);

  const actions3 = (facebook || instagram || url) && (
    <div className={clsx(aligned, "Aligned")}>
      <Actions
        items={[
          {
            id: shortid.generate(),
            action: t("more_info"),
          },
          ...urlItems,
        ]}
        orientation="row"
      />
    </div>
  );

  const cardActions1 = (
    <Actions
      items={[
        { id: shortid.generate(), action: actions1 },
        { id: shortid.generate(), action: actions2 },
        { id: shortid.generate(), action: actions3 },
      ]}
      orientation="vertical"
    />
  );

  const cardWithMedia1 = {
    image: null,
    images: null,
    carousel: <Carousel steps={carouselSteps} />,
    content: cardContent1,
    actions: cardActions1,
    customStyles: Card2SingleRowStyles({ ...props, breakpoint: "xxl" }),
  };

  return (
    <Layout header={{ currentPage: "Destinations" }}>
      <Grid container spacing={1} className={clsx(container, "Attraction")}>
        <Grid item xs={12}>
          <PageHeader
            breadcrumbs={<Breadcrumbs />}
            overline={destinationName}
            title={name}
          />
        </Grid>
        <Grid item xs={12}>
          {/**/}
          <Card2 {...cardWithMedia1} />
        </Grid>
        <Grid item xs={12}>
          <Template
            items={[<GeneralDescription image={image1} description={sectionDescription1}/>]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

Attraction.propTypes = propTypes;
Attraction.defaultProps = defaultProps;

export default Attraction;
export {
  propTypes as AttractionPropTypes,
  defaultProps as AttractionDefaultProps,
};
