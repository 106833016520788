/**
 * Component short description
 *
 * @see OrderReturn.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
/**
 * Imports data
 */
import { defaultProps, propTypes } from "./OrderReturn.data";
/**
 * Imports Material UI components
 */
import { makeStyles, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./OrderReturn.lang.ro-ro";
import { hu_hu } from "./OrderReturn.lang.hu-hu";
import { en_us } from "./OrderReturn.lang.en-us";
import { de_de } from "./OrderReturn.lang.de-de";
import Modal from "../../Modal";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import useValidationSchemas from "../../../hooks/useValidation/useValidationSchemas";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */

i18n.addResourceBundle("ro-RO", "OrderReturn", ro_ro);
i18n.addResourceBundle("hu-HU", "OrderReturn", hu_hu);
i18n.addResourceBundle("en-US", "OrderReturn", en_us);
i18n.addResourceBundle("de-DE", "OrderReturn", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles(theme => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
    wordBreak: "break-word",
  },
  buttons: {
    justifyContent: 'flex-end'
  }
}));

/**
 * Displays the component
 */
const OrderReturn = props => {
  const { container, buttons } = useStyles(props);
  const { t } = useTranslation("OrderReturn");
  const { getOrderReturnFormSchema } = useValidationSchemas();
  const { isModalOpen, handleClose, handleOrderReturn } = props;

  const { register, errors, handleSubmit } = useForm({
    validationSchema: getOrderReturnFormSchema(),
    reValidateMode: "onChange",
  });

  return (
    <Modal
      title={t("title")}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth="sm"
      isCustomStyle={true}
    >
      <Grid container>
        <form onSubmit={handleSubmit(handleOrderReturn)} noValidate>
          <Grid container item spacing={3} className={clsx(container, 'OrderReturn')}>
            <Grid item xs={12}>
              <TextField
                type="text"
                inputRef={register}
                fullWidth
                name="returnReason"
                variant="outlined"
                multiline={true}
                rows={6}
                label={t("return_reason")}
                error={Boolean(errors.returnReason)}
                helperText={errors.returnReason?.message}
              />
            </Grid>
            <Grid container item spacing={3} className={clsx(buttons)}>
              <Grid item xs={4}>
                <Button variant="contained" onClick={handleClose} fullWidth>
                  {t('OrderCancel:back_to_order')}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  {t('confirm_return')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

OrderReturn.propTypes = propTypes;
OrderReturn.defaultProps = defaultProps;

export default OrderReturn;
export {
  propTypes as OrderReturnPropTypes,
  defaultProps as OrderReturnDefaultProps
};
