/**
 * Component short description
 *
 * @see News.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { Route, Switch, useRouteMatch } from "react-router-dom";
/**
 * Imports other components and hooks
 */
import Layout from "../../Layout";
import Article from "../../_article/Article";
import { PageHeader } from "../../Theme";
import { FiltersProvider } from "../../../hooks";
import NewsSearch from "../NewsSearch/NewsSearch";
import useSlugs from "../../../hooks/useSlugs/useSlugs";
import useMeta from "../../../hooks/useMeta/useMeta";
import Head from "../../Head/Head";

/**
 * Imports data
 */
import { defaultProps, propTypes } from "./News.data";
/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./News.lang.ro-ro";
import { hu_hu } from "./News.lang.hu-hu";
import { en_us } from "./News.lang.en-us";
import { de_de } from "./News.lang.de-de";

i18n.addResourceBundle("ro-RO", "News", ro_ro);
i18n.addResourceBundle("hu-HU", "News", hu_hu);
i18n.addResourceBundle("en-US", "News", en_us);
i18n.addResourceBundle("de-DE", "News", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: { ...theme.custom.grid.removeNegativeMargin },
}));

/**
 * Displays the component
 */
const News = (props) => {
  const { filters, data } = props;
  const { container } = useStyles(props);
  const { setSlugs } = useSlugs();

  const { t } = useTranslation("News");

  useEffect(() => {
    setSlugs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Prepares the path to an article
   */
  const match = useRouteMatch();
  const { path } = match;

  const { description: metaDescription, title, keywords } = useMeta({
    type: "route",
    path: "news",
  });

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Article destination="Destination Name XXX" />
      </Route>
      <Route path={path}>
        <Head title={title} description={metaDescription} keywords={keywords} />

        <Layout header={{ currentPage: "News" }}>
          <Grid container spacing={1} className={clsx(container, "News")}>
            <Grid item xs={12}>
              <PageHeader title={t("title")} />
            </Grid>
            <Grid item xs={12}>
              <FiltersProvider filters={filters} data={data}>
                <NewsSearch />
              </FiltersProvider>
            </Grid>
          </Grid>
        </Layout>
      </Route>
    </Switch>
  );
};

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
export { propTypes as NewsPropTypes, defaultProps as NewsDefaultProps };
