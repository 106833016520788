/**
 * Component short description
 *
 * @see CategoriesAsThumbs.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import CategoryAsThumb from "../CategoryAsThumb";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./CategoriesAsThumbs.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { ro_ro } from "./CategoriesAsThumbs.lang.ro-ro";
import { hu_hu } from "./CategoriesAsThumbs.lang.hu-hu";
import { en_us } from "./CategoriesAsThumbs.lang.en-us";
import { de_de } from "./CategoriesAsThumbs.lang.de-de";

i18n.addResourceBundle("ro-RO", "CategoriesAsThumbs", ro_ro);
i18n.addResourceBundle("hu-HU", "CategoriesAsThumbs", hu_hu);
i18n.addResourceBundle("en-US", "CategoriesAsThumbs", en_us);
i18n.addResourceBundle("de-DE", "CategoriesAsThumbs", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.custom.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const CategoriesAsThumbs = (props) => {
  const { categories, destinationSlug } = props;
  const { container } = useStyles(props);

  const list = categories.map((item, index) => {
    const { id } = item;

    return (
      <Grid item key={id} xs={6} sm={3} md={2}>
        <CategoryAsThumb
          {...item}
          key={id}
          index={index}
          destinationSlug={destinationSlug}
        />
      </Grid>
    );
  });

  return (
    <Grid
      container
      spacing={1}
      className={clsx(container, "CategoriesAsThumbs")}
    >
      {list}
    </Grid>
  );
};

CategoriesAsThumbs.propTypes = propTypes;
CategoriesAsThumbs.defaultProps = defaultProps;

export default CategoriesAsThumbs;
export {
  propTypes as CategoriesAsThumbsPropTypes,
  defaultProps as CategoriesAsThumbsDefaultProps,
};
