/**
 * Displays an image
 * - Usually inside an aspect ratio container to avoid page flicks
 *
 * @see https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import shortid from "shortid";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";
import isValid from "is-valid-path";

import AspectRatioBox, {
  AspectRatioBoxPropTypes,
  AspectRatioBoxDefaultProps,
  isAspectRatioDefined,
} from "../AspectRatioBox";

import Typography from "@material-ui/core/Typography";

/**
 * Defines the prop types
 *
 * @see https://schema.org/image for naming props
 */
const propTypes = {
  /**
   * When images are displayed in a list we need an unique id for each of them
   */
  id: PropTypes.string,
  /**
   * The URL of the image
   * - Use when the image is served from an URL
   * - Either `url` or `path` has to be set
   */
  url: PropTypes.string,
  /**
   * The path to the image
   * - use when the image is served from the filesystem
   * - Either `url` or `path` has to be set
   */
  path: PropTypes.string,
  /**
   * The image title
   */
  caption: PropTypes.string,
  /**
   * The image title display flag
   */
  displayCaption: PropTypes.bool,
  /**
   * The image title display position
   */
  displayCaptionPosition: PropTypes.oneOf(["on-top", "below"]),
  /**
   * The image dimensions
   */
  width: PropTypes.number,
  height: PropTypes.number,
  /**
   * The image's aspect ratio
   */
  ...AspectRatioBoxPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: shortid.generate(),
  url: "",
  path: null,
  caption: null,
  displayCaption: true,
  displayCaptionPosition: "on-top",
  width: null,
  height: null,
  ...AspectRatioBoxDefaultProps,
};

/**
 * Defines validation schema for the URL
 */
const schema = yup.object().shape({
  url: yup.string().url(),
});

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  image: (props) => ({
    /**
     * Do not overflow the parent container
     * @see https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
     */
    maxWidth: "100%",
    /**
     * Do not distort the image
     * - This introduces layout shifts even when `width` and `height` is set.
     * - Therefore the aspect ratio workaround has to be used all the time.
     * @see https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
     */
    height: props.height ? props.height : "100%",
  }),

  captionBelow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  captionOnTop: {
    position: "relative",

    "& .Caption": {
      position: "absolute",
      bottom: -2,
      right: 0,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  figcaption: {
    "& a": {
      color: theme.palette.text.primary,
    },
  },
}));

/**
 * Displays the component
 */
const Image = (props) => {
  const {
    alt,
    url,
    path,
    caption,
    displayCaption,
    displayCaptionPosition,
    width,
    height,
    srcSet,
  } = props;

  const { image, captionOnTop, captionBelow, figcaption } = useStyles(props);

  /**
   * Checks the source of the image.
   * - Either `url` or `path` should be used to define the source
   */
  const [firstImage] = srcSet ? srcSet.split(",") : [];
  const src = url || path || firstImage;

  /**
   * Returns early on an empty or invalid URL or path
   */
  if (!src && !srcSet) return null;
  if (src === url && !schema.isValidSync(props)) return null;
  if (src === path && !isValid(path)) return null;

  /**
   * Creates a default caption, if the caption is missing
   */
  const nonEmptyCaptionText = caption || "";

  const nonEmptyCaption = alt ?? nonEmptyCaptionText;

  /**
   * Styles the caption
   * - `dangerouslySetInnerHTML` cannot be styles only with `className`
   * - if the caption contains links please style the `<a>`
   */
  const styledCaption = (
    <Typography
      variant="caption"
      className={clsx(figcaption, "Caption")}
      dangerouslySetInnerHTML={{ __html: nonEmptyCaption }}
    />
  );

  /**
   * Applyes different styles for caption position
   */
  const captionPosition =
    displayCaptionPosition === "on-top" ? captionOnTop : captionBelow;

  /**
   * Displays the image
   */

  const img = (
    <img
      className={clsx(image, "Image")}
      src={src}
      alt={nonEmptyCaption}
      width={width}
      height={height}
      srcSet={srcSet}
    />
  );

  /**
   * Displays the figure
   * // NOTE: `<figure>` completely distorts the grid
   */
  const fig = (
    <div className={clsx(captionPosition, "Figure")}>
      {img}
      {displayCaption && styledCaption}
    </div>
  );

  const aspectRatioBoxProps = {
    ...props,
  };

  delete aspectRatioBoxProps.hasCaption;
  /**
   * Wraps the image into an aspect ratio container
   */
  const figWithAspectRatioContainer = (
    <div className={clsx(captionPosition, "Figure")}>
      <AspectRatioBox {...aspectRatioBoxProps}>{img}</AspectRatioBox>
      {displayCaption && styledCaption}
    </div>
  );

  /**
   * Returns either a simple image or one wrapped into an aspect ratio container
   */
  return isAspectRatioDefined(props) ? figWithAspectRatioContainer : fig;
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
export { propTypes as ImagePropTypes, defaultProps as ImageDefaultProps };
