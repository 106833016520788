/**
 * Component short description
 *
 * @see MenuItems.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { forwardRef } from "react";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

/**
 * Imports other components and hooks
 */
import { convertMenuNameToRoute } from "../Menu";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./MenuItems.data";

/**
 * Imports Material UI components
 */
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

/**
 * Imports translations
 */
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./MenuItems.lang.ro-ro";
import { hu_hu } from "./MenuItems.lang.hu-hu";
import { en_us } from "./MenuItems.lang.en-us";
import { de_de } from "./MenuItems.lang.de-de";
import { getLocale } from "../LanguageSelector/LanguageSelector";

i18n.addResourceBundle("ro-RO", "MenuItems", ro_ro);
i18n.addResourceBundle("hu-HU", "MenuItems", hu_hu);
i18n.addResourceBundle("en-US", "MenuItems", en_us);
i18n.addResourceBundle("de-DE", "MenuItems", de_de);

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiTabs-flexContainerVertical": {
      alignItems: "center",
    },
  },

  menuItem: {
    /**
     * Remove that default 160px
     */
    minWidth: "auto",
  },
}));

/**
 * Displays the component
 */
const MenuItems = (props) => {
  const { items, activeItem, orientation } = props;

  const { menu, menuItem } = useStyles(props);
  const { t } = useTranslation("MenuItems");

  /**
   * Creates a link to a route
   */
  const LinkToRoute = forwardRef((props, ref) => (
    <Link component={RouterLink} underline="none" ref={ref} {...props} />
  ));

  /**
   * Defines the menu items as tab labels
   */
  const labels = items.map((item) => {
    const { id, translatedName, path, external, onClick } = item;
    if (external) {
      return (
        <Tab
          key={id}
          href={path}
          label={translatedName}
          className={clsx(menuItem, "MenuItem")}
        />
      );
    }

    if (onClick) {
      return (
        <Tab
          key={id}
          onClick={onClick}
          label={translatedName}
          className={clsx(menuItem, "MenuItem")}
        />
      );
    }

    return (
      <Tab
        className={clsx(menuItem, "MenuItem")}
        key={id}
        label={translatedName}
        component={LinkToRoute}
        to={`/${getLocale()}${convertMenuNameToRoute(t(`Routes:${path}`))}`}
      />
    );
  });

  /**
   * Defines the current menu item
   */
  const currentMenuItem = items.findIndex((item) => item.name === activeItem);

  const value = currentMenuItem !== -1 ? currentMenuItem : false;

  return (
    <Tabs
      className={clsx(menu, "MenuItems")}
      value={value}
      orientation={orientation}
      TabIndicatorProps={{ style: { background: "white" } }}
    >
      {labels}
    </Tabs>
  );
};

MenuItems.propTypes = propTypes;
MenuItems.defaultProps = defaultProps;

export default MenuItems;
export {
  propTypes as MenuItemsPropTypes,
  defaultProps as MenuItemsDefaultProps,
};
