/**
 * Defines the data requirements for the component
 *
 * @see Header.md for details
 */
import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";

/**
 * Imports other components and hooks
 */
import LanguageSelector from "../LanguageSelector";

import DateRangeIcon from "@material-ui/icons/DateRange";
import LanguageIcon from "@material-ui/icons/Language";
import SearchIcon from "@material-ui/icons/Search";
import AccountAuth from "../_account/AccountAuth/AccountAuth";

/**
 * Imports prop types from other components
 */
import { LogoPropTypes, LogoDefaultProps } from "../Logo";
import { MenuPropTypes, MenuDefaultProps } from "../Menu";
import { MenuItemsPropTypes } from "../MenuItems";
import { IconsPropTypes } from "../Icons";
import CartIcon from "../CartIcon/CartIcon";

/**
 * Defines the prop types
 */
const propTypes = {
  logo: PropTypes.shape(LogoPropTypes),
  ...MenuPropTypes,
  menuItems: PropTypes.shape(MenuItemsPropTypes),
  menuIcons: PropTypes.shape(IconsPropTypes),
  currentPage: PropTypes.string,
  hasSearch: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  logo: LogoDefaultProps,
  ...MenuDefaultProps,
  menuItems: {
    items: [
      {
        id: shortid.generate(),
        name: "Destinations",
        key: "destinations",
        path: "destinations",
      },
      {
        id: shortid.generate(),
        name: "Local Market",
        key: "local_market",
        path: "local-market",
      },
      { id: shortid.generate(), name: "Stay", key: "stay", path: "stay" },
      { id: shortid.generate(), name: "News", key: "news", path: "news" },
      {
        id: shortid.generate(),
        name: "Contact",
        key: "contact",
        path: "contact",
      },
    ],
    activeItem: "",
  },
  menuIcons: {
    items: [
      {
        id: shortid.generate(),
        icon: <DateRangeIcon />,
        name: "Events",
        transKey: "events",
        path: "/events",
        displayName: true,
        doTranslateName: true,
      },
      {
        id: shortid.generate(),
        icon: <LanguageIcon />,
        component: <LanguageSelector />,
      },
      {
        id: shortid.generate(),
        icon: <SearchIcon />,
        name: "Search",
        path: "/search",
        transKey: "search",

        doTranslateName: true,
        displayName: false,
        requiresAuth: false,
      },
      {
        id: shortid.generate(),
        icon: <CartIcon />,
        name: "Cart",
        path: "/cart",
        transKey: "cart",
        doTranslateName: true,
        displayName: false,
        requiresAuth: true,
      },
      {
        // NOTE: This gives a props warning. Above the <LanguageSelector/> gives no warnings ate all
        id: shortid.generate(),
        component: <AccountAuth />,
      },
    ],
  },

  currentPage: "",
  hasSearch: true,
};

export { propTypes, defaultProps };
