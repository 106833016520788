const hu_hu = {
  address: "Cím",
  cancel_order: "Megrendelés törlése",
  order: "Rendelés",
  order_date: "Rendelés dátuma",
  info: "Kézbesítési információ",
  personal_info: "Személyes adat",
  name: "Név",
  total_price: "Teljes ár",
  order_status: "Rendelési státusz",
  phone: "Telefon",
  seller: "Eladó",
  cancelled: "Törölve",
  confirmed: "Megerősített",
  paid: "Fizetett",
  shipped: "Kiszállított",
  refused: "Megtagadva",
  return_requested: "Visszaadás kérve",
  returned: "Visszatért",
  pending: "Függőben levő",
  order_number: "Rendelésszám",
  items: "Tételek",
  item: "Tétel",
  order_cancellation: "Rendelés lemondása",
  confirm_cancel: "Erősítse meg a Mégse gombot",
  back_to_order: "Vissza a megrendeléshez",
  order_cancelled: "Megrendelését törölték",
  cancel_order_success: "Megrendelését törölték",
  cancel_order_failed: "Hiba történt a megrendelés törlésekor.",
  get_order_failed: "Valami elromlott. Kérlek próbáld újra.",
  payment_success: "Sikeres fizetés, most visszatérhet az alkalmazáshoz",
  complete_payment: "Teljes fizetés",
  order_detail: "Rendelés részletei",
  return_order: "Visszaküldés",
  order_returned_successfully: "A megrendelés sikeresen visszatért",
};

export { hu_hu };
