/**
 * Imports React and third party packages
 */
import useSWR from "swr";

/**
 * Imports other components and hooks
 */
import { useRequests } from "../../../hooks";
import { stringifyParams } from "../../../hooks/useRequests/useRequests";

const getProductSlugFromPath = (path) => {
  const arr = path.split("/");
  return arr[3];
};

const useMarketInfoSwr = () => {
  const { get } = useRequests();
  return useSWR("market/info", get);
};

const useMarketCategoriesSwr = () => {
  const { get } = useRequests();
  return useSWR("market/categories", get);
};

const useMarketSellersSwr = () => {
  const { get } = useRequests();

  return useSWR(`market/sellers`, get);
};

const useMarketProductsSwr = (props) => {
  const { filters = {}, condition = true } = props;
  const { get } = useRequests();

  const params = stringifyParams(filters);

  return useSWR(condition ? `market/products?${params}` : null, get);
};

const useMarketProductSwr = (props) => {
  const { id, condition = true } = props;
  const { get } = useRequests();
  return useSWR(condition ? `market/products/${id}` : null, get);
};

const useMarketCartSwr = () => {
  const { get } = useRequests();
  return useSWR("market/cart", get);
};

const useMarket = () => {
  const { post, deleteReq, put, get } = useRequests();

  const getProducts = (params) => {
    return get(`market/products`, params);
  };

  const createCart = (cart) => {
    return post("market/cart", cart);
  };

  const updateCart = (id, cart) => {
    return put(`market/cart/${id}`, cart);
  };

  const deleteCart = (id) => {
    return deleteReq(`market/cart/${id}`);
  };

  return {
    useMarketInfoSwr,
    useMarketCategoriesSwr,
    useMarketSellersSwr,
    useMarketProductSwr,
    useMarketProductsSwr,
    useMarketCartSwr,
    createCart,
    updateCart,
    deleteCart,
    getProducts,
  };
};

export default useMarket;
export {
  useMarketInfoSwr,
  useMarketCategoriesSwr,
  useMarketSellersSwr,
  useMarketProductSwr,
  useMarketProductsSwr,
  useMarketCartSwr,
  getProductSlugFromPath,
};
