/**
 * Component short description
 *
 * @see FilterMeal.md for details
 */

/**
 * Imports React and third party packages
 */
import React from "react";

/**
 * Imports other components and hooks
 */
import FilterListItem from "../../FilterListItem/FilterListItem";
import { useFilters } from "../../../hooks";
import {
  getSelectedArrayValues,
  getArrayOfKeys,
} from "../../../hooks/useFilters";
import FilterChips from "../../FilterChips/FilterChips";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./FilterMeal.data";

/**
 * Imports Material UI components
 */
import { ListItemText } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./FilterMeal.lang.ro-ro";
import { hu_hu } from "./FilterMeal.lang.hu-hu";
import { en_us } from "./FilterMeal.lang.en-us";
import { de_de } from "./FilterMeal.lang.de-de";

i18n.addResourceBundle("ro-RO", "FilterMeal", ro_ro);
i18n.addResourceBundle("hu-HU", "FilterMeal", hu_hu);
i18n.addResourceBundle("en-US", "FilterMeal", en_us);
i18n.addResourceBundle("de-DE", "FilterMeal", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterMeal = (props) => {
  const { filterListItem, defaultValue } = props;

  const { t } = useTranslation("FilterMeal");
  /**
   * Handles the filters
   */
  const { filters, setFiltersAndResetPagination, data } = useFilters();
  const { meals } = data;

  const selected = getSelectedArrayValues({
    key: "id",
    selected: filters.meals?.filterValue || defaultValue,
    items: meals,
  });

  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;

    setFiltersAndResetPagination({
      meals: {
        filterValue: getArrayOfKeys({ key: "id", items: value }),
        label: getArrayOfKeys({ key: "name", items: value }),
      },
    });
  };

  return (
    <FilterListItem {...filterListItem} primary={t("name")}>
      <Select
        multiple
        name="extas"
        value={selected}
        onChange={selectHandler}
        fullWidth
        displayEmpty
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <FilterChips selected={selected} emptyText={t("placeholder")} />
        )}
      >
        {meals.map((e) => {
          return (
            <MenuItem key={e.id} value={e}>
              <ListItemText primary={e.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FilterListItem>
  );
};
FilterMeal.propTypes = propTypes;
FilterMeal.defaultProps = defaultProps;

export default FilterMeal;
export {
  propTypes as FilterMealPropTypes,
  defaultProps as FilterMealDefaultProps,
};
