/**
 * Defines the data requirements for the component
 *
 * @see Contact.md for details
 */
import PropTypes from "prop-types";

/**
 * Imports prop types from other components
 */
import {
  ImageResponsivePropTypes,
  ImageResponsivePlaceholderCom169,
} from "../../ImageResponsive";
import {
  ContactFormPropTypes,
  ContactFormDefaultProps,
} from "../ContactForm/ContactForm";

/**
 * Defines the prop types
 */
const propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape(ImageResponsivePropTypes),
  contactForm: PropTypes.shape(ContactFormPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  description: "",
  image: ImageResponsivePlaceholderCom169,
  contactForm: ContactFormDefaultProps,
};

export { propTypes, defaultProps };
