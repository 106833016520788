const en_us = {
  request_role: "Request role",
  name: "Name",
  email: "Email",
  seller: "Seller",
  host: "Host",
  destination: "Destination",
  location: "Location",
  phone_number: "Phone Number",
  message: "Message",
  send_request: "Send Request",
  send_request_error: "Something went wrong. Please try again.",
  agree_text: "I agree to the",
  terms_and_conditions: "Terms and Conditions",
  description: "Description",
};

export { en_us };
